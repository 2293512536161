import { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Mixpanel from '../../utils/mixpanel';

const useMixpanel = () => {
  const { path, params } = useRouteMatch<{ projectId?: string }>();
  const currentPath = path === '/' ? '' : path;

  const mixpanelTrack = useCallback(
    (event: string, extraOptions?: {}) => Mixpanel.track(
      `${currentPath}/${event}`,
      params.projectId
        ? { projectId: params.projectId, ...extraOptions }
        : { ...extraOptions },
    ),
    [currentPath, params.projectId],
  );

  return { mixpanelTrack };
};

export default useMixpanel;

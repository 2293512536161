import { NetworkStatus, useApolloClient, useQuery } from '@apollo/client';
import { GQLWrapper } from 'components';
import Drive from 'components/Drive/Drive';
import { useHandleError } from 'hooks';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { User } from 'types/graphql';
import GET_PERSONAL_DRIVE from 'views/drive/apollo';
import { GET_PROJECT_DRIVE } from 'views/project/drive/apollo';

interface IProps {
  location: string;
  hasMultipleLocations: string[];
  onInitialLicence: (id: string) => void;
  currentUser: User;
}

const CreateDrive = ({ location, hasMultipleLocations, onInitialLicence, currentUser }: IProps) => {
  const [, enqueueSnackbar] = useHandleError();
  const client = useApolloClient();
  const history = useHistory();
  const { projectId } = useParams<{ projectId?: string; }>();

  const query = projectId ? GET_PROJECT_DRIVE : GET_PERSONAL_DRIVE;

  const isCached = client.readQuery({ query, variables: { id: projectId } });

  const { error, data, refetch, networkStatus } = useQuery(query, {
    fetchPolicy: isCached ? 'cache-only' : 'cache-first',
    nextFetchPolicy: 'cache-only',
    variables: { id: projectId || '' },
    onCompleted: (res) => {
      const isProject = res.project;

      if (isProject) {
        const licenceId = isProject.location.license.id;
        onInitialLicence(licenceId);
      }
    },
  });

  const isLoading = networkStatus === NetworkStatus.loading;

  const { name = '', slotsRemaining = 0 } = useMemo(() => currentUser?.tier || {}, [currentUser]);
  const projectRole = useMemo(() => data?.project?.me?.role, [data]);

  const onShowError = useCallback((condition: boolean, msg: string, path: string = '/') => {
    if (!condition) return;

    enqueueSnackbar(msg, { variant: 'error' });
    history.push(path);
  }, [enqueueSnackbar, history]);

  useEffect(() => {
    const condition = currentUser && name === 'Starter' && slotsRemaining <= 0;
    onShowError(condition, 'Not enough project spaces');
  }, [currentUser, name, slotsRemaining, onShowError]);

  useEffect(() => {
    const condition = projectRole && projectRole === 'VIEWER';
    const path = `/project/${projectId}`;
    onShowError(condition, 'Your role has been changed and do not have access to this feature.', path);
  }, [projectRole, onShowError, projectId]);

  return (
    <GQLWrapper loading={isLoading} error={error} renderChildren={!!data}>
      <Drive
        data={data?.project || data?.currentUser}
        driveType={projectId ? 'project' : 'personal'}
        refetch={refetch}
        refetchQueryAfterUpdate={{ query }}
        onCreation={{ location, hasMultipleLocations }}
      />
    </GQLWrapper>
  );
};

export default CreateDrive;

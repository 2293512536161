import { PeopleOutlined } from '@material-ui/icons';
import { useMixpanel } from 'hooks';
import React from 'react';
import { MemberFilter, SelectWithInputItem } from 'views/types';
import FilterButton from './FilterButton';
import OptionBox from './OptionBox';
import OptionHeader from './OptionHeader';

interface QuickFiltersProps {
  currentFilters?: MemberFilter;
  roleFilters: Set<MemberFilter>;
  setMemberFilters: React.Dispatch<React.SetStateAction<MemberFilter | undefined>>;
  setRoleFilters: React.Dispatch<React.SetStateAction<Set<MemberFilter>>>;
  options: SelectWithInputItem[];
  setFilterResult: React.Dispatch<React.SetStateAction<SelectWithInputItem[] | undefined>>;
}

export default function QuickFilters(props: QuickFiltersProps) {
  const { currentFilters, 
    setMemberFilters, 
    roleFilters, 
    setRoleFilters, 
    options, setFilterResult } = props;

  const { mixpanelTrack } = useMixpanel();
  const logToMixpanel = (value: MemberFilter, count: number) => {
    mixpanelTrack(`Clicked the ${value} in my organisation`, {
      'Members Counted': count,
    });
  };

  const hasAllActiveRoleFilters = (filter = roleFilters) => filter.has('Admins') && filter.has('Editors') && filter.has('Viewers');

  const handleRoleFilterClick = (value?: MemberFilter) => { 
    if (roleFilters.has(value!)) roleFilters.delete(value!);
    else roleFilters.add(value!);
    
    if (roleFilters.size === 0 ) return setFilterResult(options);
    if (hasAllActiveRoleFilters(roleFilters)) {
      setRoleFilters(new Set<MemberFilter>());
      setMemberFilters('All');
      return setFilterResult(options);
    }
    const keys = Array.from(roleFilters.keys());
    const filterOptions = options.filter((item) => keys.some(val => item.tag === val.substring(0, val.length - 1)));

    logToMixpanel(value!, filterOptions.length);
    setRoleFilters(roleFilters);
    setMemberFilters(undefined);
    return setFilterResult(filterOptions);
  };

  const handleFilterClick = (value?: MemberFilter) => {
    let filterOptions: SelectWithInputItem[] = [];
    if (value === 'All') filterOptions = options;
    if (value === 'Members in organisation') filterOptions = options.filter(val => val.sameOrg);
    setFilterResult(filterOptions);
    setMemberFilters(value);
    setRoleFilters(new Set<MemberFilter>());
    logToMixpanel(value!, filterOptions.length);
  };
  return (
    <OptionBox>
      <OptionHeader
        title="Quick group filters"
        icon={<PeopleOutlined />}
      />
      <div className='flex flex-col gap-2'>
        <FilterButton
          value="All"
          text='All project members'
          onClick={handleFilterClick}
          active={currentFilters === 'All'}
        />
        <div className='flex gap-2'>
          <FilterButton
            value='Admins'
            text='Admins'
            onClick={handleRoleFilterClick}
            active={roleFilters.has('Admins')}
          />
          <FilterButton
            value='Editors'
            text='Editors'
            onClick={handleRoleFilterClick}
            active={roleFilters.has('Editors')}
          />
          <FilterButton
            value='Viewers'
            text='Viewers'
            onClick={handleRoleFilterClick}
            active={roleFilters.has('Viewers')}
          />
        </div>
        <FilterButton
          value='Members in organisation'
          text='Project members in my organisation'
          onClick={handleFilterClick}
          active={currentFilters === 'Members in organisation'}
        />
      </div>
    </OptionBox>
  );
}
import React from 'react';
import { ErrorLayout } from 'tailwind';

const ErrorFallback = ({ resetError }: { resetError: () => void; }) => (
  <ErrorLayout
    title="Something has gone wrong"
    message="If the problem persists, please contact support."
    primaryButtonText="Return to home"
    withHeader={false}
    onPrimaryClick={() => {
      resetError();
      window.location.replace('/');
    }}
  />
);

export default ErrorFallback;

import React, { ReactNode, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InfoBox from 'tailwind/InfoBox/InfoBox';
import { ReactComponent as VisibilityIcon } from 'assets/icons/Visibility.svg';
import SelectedFilesLabel from 'views/createFromDrive/CreateDrive/SelectedFilesLabel';
import { DriveSelectedFilesContext } from 'components/Drive/DriveProvider/DriveProvider';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '@vucity/design_system';

interface UpdateAccessModalProps {
  open: boolean;
  hasOverlappingPermissions: boolean;
  children?: ReactNode;
  onClose?: () => void;
}

const UpdateAccessModal: React.FC<UpdateAccessModalProps> = ({ open, children, onClose, hasOverlappingPermissions }) => {
  const { selectedFiles } = useContext(DriveSelectedFilesContext);
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'md'}>
      <DialogContent className='p-3 box-border flex flex-col gap-2'>
        <div className='flex justify-between'>
          <div className='flex justify-between gap-2 items-center'>
            <p className='text-3xl'>Choose who can access your selected assets</p>
            <SelectedFilesLabel files={selectedFiles} />
          </div>
          <div className='flex-row-reverse flex h-4'>
            <IconButton className='p-1' onClick={onClose} aria-label="xButton">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        {hasOverlappingPermissions && (
          <InfoBox>
            <VisibilityIcon />
            <span className='text-sm'>You have selected multiple files with different permission settings. File access will be over written once updated.</span>
          </InfoBox>
        )}
        <div className='flex flex-col items-centers gap-2 flex-grow'>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateAccessModal;
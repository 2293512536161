import { useHandleError } from 'hooks';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorLayout } from 'tailwind';

const ErrorFallback = ({ error, resetError }: { error: Error, resetError: () => void; }) => {
  const history = useHistory();
  const [, enqueueSnackbar] = useHandleError();

  useEffect(() => {
    if (error && error.message.includes('Failed to fetch dynamically imported module')) {
      enqueueSnackbar('There is a new version. Please refresh the page.',
        { variant: 'info', preventDuplicate: true },
      );
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    history.listen(() => {
      if (error) resetError();
    });
  }, []);

  return (
    <ErrorLayout
      title="Something has gone wrong"
      message="If the problem persists, please contact support."
      primaryButtonText="Return to home"
      onPrimaryClick={() => {
        resetError();
        window.location.replace('/');
      }}
    />
  );
};

export default ErrorFallback;

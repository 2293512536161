import { useMutation, useQuery } from '@apollo/client';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { BillingPeriodToggle, TextModal } from 'components';
import DetailedSelect from 'components/DetailedSelect';
import { useCurrentUser, useHandleError, useMixpanel } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { BillingInterval, Organisation } from 'types/graphql';
import { formatBillingPeriod, parseGraphqlError } from 'utils/helpers';
import { ASSIGN_TIER } from 'views/account/apollo';
import { DECLINED_MEMBER_STATUS, REMOVED_MEMBER_STATUS } from 'views/organisation/members/constants';
import GET_ORG_MEMBERS, { GET_ORGANISATION_SEATSTATS } from 'views/organisation/members/data';
import { DetailedSelectItem } from 'views/types';

export const getAvailableSeats = (tierId: 'plus' | 'pro', organisation: Organisation, period: BillingInterval | 'trial') => {
  if (period === 'year') return organisation?.seatStats[tierId]?.availableAnnually;

  if (period === 'month') return organisation?.seatStats[tierId]?.availableMonthly;

  return organisation?.seatStats[tierId]?.available;
};

export const joinTierValueWithPeriod = (value?: string, period?: BillingInterval | 'trial') => `${value?.toLowerCase()}${period ? '-' : ''}${period || ''}`;

export const getTierFromValue = (jointNotationValue?: string) => (jointNotationValue || '').split('-')[0];

export const getPeriodFromValue = (jointNotationValue?: string): BillingInterval | 'trial' | undefined => {
  const stringifiedPeriod = (jointNotationValue || '').split('-')[1] ?? '';

  if (stringifiedPeriod === 'month') return 'month';
  if (stringifiedPeriod === 'year') return 'year';

  return undefined;
};

const OrgSubDropdown = ({
  value,
  row,
}: DataTypeProvider.ValueFormatterProps) => {
  const isTrialSeatAssigned = row.isTrialSeat as boolean;
  /**
   * ----------BillingInterval INCONSISTENCY warning------------------------------
   * The expression below is crucial for marking a trial subscription as undefined
   * rather than as the backend value of 'month' which this component works around.
   */
  const assignedBillingPeriod = isTrialSeatAssigned
    ? undefined
    : row.billingPeriod as BillingInterval;

  const { mixpanelTrack } = useMixpanel();
  /*
    * ----------BillingInterval INCONSISTENCY warning------------------------------
     <BillingInterval | 'trial'> is only used here because the toggle component requires it.
     ---------------------------
     A trial subscription needs to exist as a distinct BillingInterval enum option (Backend change)
     rather than as 'month' with a secondary `trialActive` flag
  */
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState<BillingInterval | 'trial'>(
    assignedBillingPeriod as BillingInterval,
  );
  const [dropdownValue, setDropdownValue] = useState<string | undefined>();
  const [handleError, enqueueSnackbar] = useHandleError();
  const [options, setOptions] = useState<any[]>([]);
  const [activeSessionModal, setActiveSessionModal] = useState(false);

  const { data } = useQuery(GET_ORGANISATION_SEATSTATS);
  const organisation = data?.currentUser.organisation;

  const { currentUser, floating: { hasFloating, floatingTotal } } = useCurrentUser();

  const { id: memberId, status, name } = row;

  const { subscriptions } = organisation ?? {};

  const activeSubscriptions = (subscriptions ?? []).filter((subscription) => subscription.status === 'active');

  const hasBothSubscriptions = !!activeSubscriptions?.some((subscription) => subscription.interval === 'year')
    && !!activeSubscriptions?.some((subscription) => subscription.interval === 'month');

  useEffect(() => {
    if (organisation) {
      const defaultOption = hasFloating ? {
        value: 'floating',
        title: 'Floating access',
        titleRightInfo: 'Unlimited',
        subtitle: <>
          <p>- Collaborate on unlimited projects</p>
          <p>- Shared access to VU.CITY 3D platform</p>
          <p><strong>({floatingTotal} x concurrent {floatingTotal === 1 ? 'user' : 'users'})</strong></p>
        </>,
      } : {
        value: 'starter',
        title: 'Starter',
        titleRightInfo: 'Unlimited',
        subtitle: 'Collaborate on up to 3 projects.',
      };

      const plusOption = {
        value: joinTierValueWithPeriod('plus', selectedBillingPeriod),
        title: 'Plus',
        titleRightInfo: `${getAvailableSeats('plus', organisation, selectedBillingPeriod)} available`,
        subtitle: 'Collaborate on unlimited projects.',
        disabled: !getAvailableSeats('plus', organisation, selectedBillingPeriod),
      };

      const proOption = {
        value: joinTierValueWithPeriod('pro', selectedBillingPeriod),
        title: hasFloating ? 'Dedicated Pro' : 'Pro',
        titleRightInfo: `${getAvailableSeats('pro', organisation, selectedBillingPeriod)} available`,
        subtitle: hasFloating ? <>
          <p>- Collaborate on unlimited projects</p>
          <p>- Uninterrupted access to the VU.CITY 3D platform</p>
        </>
          : 'Collaborate on unlimited projects and use the VU.CITY 3D App to visualise stories, undertake technical analysis, design in context and more.',
        disabled: !getAvailableSeats('pro', organisation, selectedBillingPeriod),
      };

      const newOptions = hasFloating ? [defaultOption, proOption] : [defaultOption, plusOption, proOption];

      setOptions(newOptions);
    }
  }, [selectedBillingPeriod, organisation, hasFloating, floatingTotal]);

  useEffect(() => {
    if (hasBothSubscriptions && !selectedBillingPeriod) {
      setSelectedBillingPeriod('year');
    }
  }, [hasBothSubscriptions, activeSubscriptions, selectedBillingPeriod]);

  useEffect(() => {
    const updatedValue = hasFloating && value === 'Pro' ? 'Dedicated Pro' : value;
    setDropdownValue(
      joinTierValueWithPeriod(
        updatedValue, assignedBillingPeriod,
      ),
    );
  }, [value, assignedBillingPeriod]);

  const [updateSubscription] = useMutation(ASSIGN_TIER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ORG_MEMBERS }],
    onError: (message) => {
      setDropdownValue(joinTierValueWithPeriod(value, assignedBillingPeriod));
      const [code] = parseGraphqlError(message.graphQLErrors);

      if (code === 'USER_ACTIVE_SESSION') setActiveSessionModal(true);
      else handleError(message);
    },
  });

  const handleChange = (item: DetailedSelectItem | null | undefined) => {
    const previousTierSelectionOnly = value.toLowerCase();
    if (item?.value === previousTierSelectionOnly) return;

    setDropdownValue(item?.value);
    updateSubscription({
      variables: {
        memberId,
        interval: selectedBillingPeriod,
        tierId: getTierFromValue(item?.value),
      },
    }).then(({ errors }) => {
      if (!errors) {
        const newTierSelectionOnly = getTierFromValue(item?.value);

        const knowledgeBaseAdvice = '\nGo to the Knowledge Base for more information on downgrading a member.';

        const [, email] = name;

        const mixpanelOptions = {
          'Affected member': email,
          'Affected member User ID': memberId,
          'Affected member status': status,
          'Affected member previous subscription': joinTierValueWithPeriod(previousTierSelectionOnly, assignedBillingPeriod),
          'Subscription assigned': item?.value,
        };

        if (newTierSelectionOnly === 'starter' && newTierSelectionOnly !== previousTierSelectionOnly) {
          enqueueSnackbar(`Successfully downgraded member to Starter.${knowledgeBaseAdvice}`, { variant: 'success', style: { whiteSpace: 'pre-line' } });
          mixpanelTrack(`Downgrade to ${item?.value} subscription`, mixpanelOptions);
        } else if (newTierSelectionOnly === 'plus' && previousTierSelectionOnly === 'pro') {
          enqueueSnackbar(`Successfully downgraded member to Plus.${knowledgeBaseAdvice}`, { variant: 'success', style: { whiteSpace: 'pre-line' } });
          mixpanelTrack(`Downgrade to ${item?.value} subscription`, mixpanelOptions);
        } else {
          enqueueSnackbar('Subscription updated', { variant: 'success' });
          mixpanelTrack(`Changed to ${item?.value} subscription`, mixpanelOptions);
        }
      }
    });
  };

  const canNotPerformUpdate = useCallback(() => currentUser?.organisation?.me?.role !== 'ADMIN', [currentUser]);

  if (status === REMOVED_MEMBER_STATUS || status === DECLINED_MEMBER_STATUS) return status;

  return (
    <>
      <DetailedSelect
        handleChange={handleChange}
        value={dropdownValue}
        items={options}
        isReadOnly={canNotPerformUpdate()}
        optionWidth={330}
        alternateLabel={(props) => {
          const period = getPeriodFromValue(dropdownValue);

          return (
            <div className='flex flex-col capitalize' {...props}>
              {getTierFromValue(dropdownValue)}
              {period && <p className='text-xs'>
                {formatBillingPeriod(period).adjective}
              </p>
              }
            </div>
          );
        }}
        optionalHeader={hasBothSubscriptions ? () => (
          <div className='flex items-center justify-between w-full p-2 border-b border-b-content-light'>
            <span className='text-sm'>Subscription</span>
            <BillingPeriodToggle
              isCompactMode={true}
              getLabelByPeriod={(period) => formatBillingPeriod(period).adverb}
              handleChange={(period: BillingInterval | 'trial') => {
                setSelectedBillingPeriod(period);
                mixpanelTrack(`toggled '${period}' subscription seats`);
              }}
              value={selectedBillingPeriod}
            />
          </div >
        ) : undefined}
      />

      <TextModal
        headerText='Cannot downgrade while in an active session'
        component='div'
        open={activeSessionModal}
        setOpen={setActiveSessionModal}
        closeBtnLabel='Close'
      >
        <p>
          This member is currently in an active VU.CITY session.
          Please wait to downgrade until the user has closed the session and saved their work.
        </p>
      </TextModal>
    </>
  );
};

export default OrgSubDropdown;

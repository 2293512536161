import mixpanel, { Dict } from 'mixpanel-browser';

const token = {
  LOCAL: '817f4b412f8464bf82dba0abd1917c72',
  DEVELOP: '817f4b412f8464bf82dba0abd1917c72',
  STAGING: '817f4b412f8464bf82dba0abd1917c72',
  PROD: 'c89351b8e94054b0725613a99152ec18',
};

mixpanel.init(token[import.meta.env.VITE_ENV as keyof typeof token], {
  api_host: 'https://mixpanel.vu.city',
  ignore_dnt: true,
}, '');

mixpanel.register({
  Platform: 'hub-client',
});

const Mixpanel = {
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  track: (name: string, props: Dict) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props: Dict) => {
      mixpanel.people.set(props);
    },
    increment: (name: string, by: number) => {
      mixpanel.people.increment(name, by);
    },
  },
  error: (message:string, request:string) => {
    mixpanel.track('Error on request', { message, request });
  },
};

export default Mixpanel;

import { gql } from '@apollo/client';

export const GET_INVITATIONS = gql`
  query getOrgInvitations {
    currentUser {
      id
      lastLogin
      orgInvites {
        id
        status
        name
        email
        role
        invitedBy {
          name
          email
        }
        organisation {
          id
          name
          logo
        }
      }
    }
  }
`;

export const RSVP_INVITE = gql`
  mutation($orgId: ID!, $response: RSVP!) {
    rsvpOrgInvite(orgId: $orgId, response: $response) {
      status
      organisation{
        id
        name
        email
      }
    }
  }
`;

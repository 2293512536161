import { gql, TypedDocumentNode } from '@apollo/client';
import { User } from 'types/graphql';

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      id
      name
      email
      jobTitle
      appEnvironment
      picture
      magicbellKey
      featureFlags {
        siteSolve
      }
      tier {
        name
        access3D
        slotsRemaining
      }
      projects {
        id
        me {
          status
          role
        }
      }
      orgInvites {
        id
        status
      }
      organisation{
        id
        name
        type
        email
        phone
        logo
        trialActive
        trialAvailable
        data {
          access
          defaultRole
          adminAccess
        }
        subscriptions {
          trial_end
          trial_start
        }
        seatStats {
          plus {
            available
          }
          pro {
            available
          }
        }
        address {
          line1,
          line2,
          postal_code
          country
          city
          state
        }
        me {
          role
          id
        }
      }
      tours {
        welcome {
          id
          completed
        }
        project_view {
          id
          completed
        }
        intro {
          id
          completed
        }
      }
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation ($name: String, $email: String, $jobTitle: String, $picture: String) {
    updateAccount(name: $name, email: $email, jobTitle: $jobTitle, picture: $picture) {
      id
      picture
      name
      jobTitle
    }
  }
`;

export const UPDATE_WELCOME_TOUR = gql`
  mutation {
    completeTour(tour: welcome){
      completed
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query getCurrentUser {
    currentUser {
      id
      appEnvironment
      appSessionIsActive
      organisation {
        id
        name
      }
    }
  }
`;

export const GET_APP_SESSION_LENGTH = gql`
  query getAppSessionLength {
    currentUser {
      id
      tier {
        id
      }
      appSessionIsActive {
        vu
        sslv
      }
      organisation {
        id
        members {
          id
          appSessionData {
            vu {
              length
              status
              id
            }
            sslv {
              length
              status
              id
            }
          }
          profile {
            name
            picture
            email
            id
          }
          seat {
            tier {
              id
            }
          }
        }
      }
    }
  }
`;

export const VERIFY_PROJECT_ID = gql`
  query ($projectId: ID) {
    currentUser {
      projectIsValid(projectId: $projectId)
    }
  }
`;

export const GET_ORGANISATION_SITE_LICENSES: TypedDocumentNode<{ currentUser: User; }> = gql`
  query getOrgSiteLicenses {
    currentUser {
      organisation {
      id
      siteLicenses {
          license {
            id
            stripeProduct {
              id
              name
            }
            location {
              id
              name
            }
          }
          status
        }
      }
    }
  }
`;

export const ASSIGN_SITE_LICENSE = gql`
  mutation AddUserSiteLicense($licenseId: ID!, $userId: ID!) {
  addUserSiteLicense(licenseId: $licenseId, userId: $userId) {
    licenseId
    name
    userId
  }
}
`;

export const REMOVE_SITE_LICENSE = gql`
  mutation RemoveUserSiteLicense($licenseId: ID!, $userId: ID!) {
  removeUserSiteLicense(licenseId: $licenseId, userId: $userId) {
    licenseId
    name
    userId
  }
}
`;

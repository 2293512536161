import { useAuth0 } from '@auth0/auth0-react';
import { Column } from '@devexpress/dx-react-grid';
import { Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { DataGrid } from 'components';
import { DataProvider, DateProvider, ImageProvider, StatusProvider } from 'components/DataGrid';
import { ProjectActions } from 'components/ProjectActions/ProjectActions';
import { ProjectWithSameOrg } from 'components/ProjectCard/ProjectCard';
import { useLimitedProjectAccess, useMixpanel } from 'hooks';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AddProjectBtn, Ellipsis } from 'tailwind';
import { Project, UserTier } from 'types/graphql';
import { extractCityName, nameSort } from 'utils/helpers';
import ProjectDescription from '../description/ProjectDescription';

import { ReactComponent as HiddenSvg } from 'assets/icons/VisibilityCrossed.svg';
import { ReactComponent as VisibleSvg } from 'assets/icons/VisibleFilled.svg';

interface IFormatter {
  column: Column;
  row?: any;
  value: any;
}

interface IListRow {
  id: string;
  image?: string;
  name: {
    name: string;
    description?: string;
  };
  owner?: string;
  location?: string;
  createdAt: Date;
  updatedAt: Date;
  status?: string;
  role?: string;
}

interface ListViewProps {
  projects: Project[];
  tier: UserTier;
  refetch: () => any;
  setVisiblityPreference: (preference: string) => void;
  view: string;
  visiblityPreference: string;
}

const ListView = ({ projects, tier, refetch, visiblityPreference, setVisiblityPreference }: ListViewProps) => {
  const { checkStatus, LOCKED, INVITED } = useLimitedProjectAccess();
  const history = useHistory();
  const { user } = useAuth0();

  const [upgradeModal, setUpgradeModal] = React.useState(false);
  const [inviteModal, setInviteModal] = React.useState(false);
  const [availableToAll, setAvailableToAll] = React.useState(false);
  const [selectedProject, setSelectedProject] = React.useState<Project>();

  const rows: IListRow[] = projects?.map((project: ProjectWithSameOrg) => ({
    id: project.id as string,
    image: project?.imageId,
    access: project?.access,
    name: { name: project?.name, description: project?.description },
    owner: project?.owner?.name,
    location: extractCityName(project?.location?.license?.stripeProduct.name),
    createdAt: new Date(project.createdAt),
    updatedAt: new Date(project.updatedAt),
    status: project.me?.status,
    role: project.me?.role,
    hiddenFor: project.hiddenFor,
    isSameOrg: project.isSameOrg,
  }));

  const columns: Column[] = [
    { name: 'image', title: 'Cover' },
    { name: 'state', title: 'State' },
    { name: 'name', title: 'Name' },
    { name: 'owner', title: 'Creator' },
    { name: 'location', title: 'Location' },
    { name: 'updatedAt', title: 'Last updated' },
    { name: 'status', title: 'Status' },
    { name: 'menu', title: ' ' },
  ];

  const columnWidth = [
    { columnName: 'image', width: '150px' },
    { columnName: 'state', width: '100px' },
    { columnName: 'name', width: 'auto' },
    { columnName: 'owner', width: 'auto' },
    { columnName: 'location', width: 'auto' },
    { columnName: 'updatedAt', width: 'auto' },
    { columnName: 'status', width: 'auto' },
    { columnName: 'menu', width: '100px' },
  ];

  const NoRowsComponent = () => (
    <tr>
      <td colSpan={6}>
        <div className='flex items-center justify-center min-h-[200px]'>
          There are currently no files
        </div>
      </td>
    </tr>
  );

  const ContextMenuFormatter = ({ row }: IFormatter) => {
    const { mixpanelTrack } = useMixpanel();
    const _project = projects.find(p => p.id === row.id);
    if (!_project) return <></>;
    const project = _project as ProjectWithSameOrg;
    const userStatus = project?.me?.status;
    const { isLimited, limitedUrl } = checkStatus(
      project.id, project.me?.role, project.me?.status,
    );
    const hidden = !!row.hiddenFor?.find((id: string) => id === user?.sub);
    const disabled = userStatus === INVITED || userStatus === LOCKED || userStatus === 'AVAILABLE_TO_ORG_ADMINS';
    const isSameOrg = project.isSameOrg;
    const access = project.access;

    const clickHandle = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      const isAvailableToAll = isSameOrg && access === 'AVAILABLE_TO_ALL';
      const isAvailableToOrgAdmins = isSameOrg && userStatus === 'AVAILABLE_TO_ORG_ADMINS';
      const mixpanelProps = { status: userStatus, projectId: project.id, access: project.access ?? 'INVITE_ONLY' };
      const mixpanel = {
        AVAILABLE_TO_ORG_ADMINS: 'Project card -> available to org admins - Clicked',
        AVAILABLE_TO_ALL: 'Project card -> available to all - Clicked',
        LIMITED: 'Clicked Project card - Limited access',
        default: 'Clicked Project card',
      };

      if (userStatus === INVITED || userStatus === 'AVAILABLE_TO_ORG_ADMINS') {
        e.preventDefault();
        if (isAvailableToAll || isAvailableToOrgAdmins) setAvailableToAll(true);
        else {
          return setInviteModal(true);
        }
      }

      if (userStatus === LOCKED) {
        e.preventDefault();

        if (!isLimited) setUpgradeModal(true);
        else {
          history.push(limitedUrl);
          return mixpanelTrack(mixpanel.LIMITED, mixpanelProps);
        }
      }

      if (isAvailableToAll) return mixpanelTrack(mixpanel.AVAILABLE_TO_ALL, mixpanelProps);
      if (isAvailableToOrgAdmins) return mixpanelTrack(mixpanel.AVAILABLE_TO_ORG_ADMINS, mixpanelProps);
      return mixpanelTrack(mixpanel.default, mixpanelProps);
    };

    return (
      <div className='flex justify-center max-w-full'>
        <ProjectDescription
          project={project}
          isLimited={isLimited}
          hidden={hidden}
          inactive={disabled}
          handleOnClick={clickHandle}
          isRelative
          refetch={refetch}
          setVisiblityPreference={setVisiblityPreference}
          visiblityPreference={visiblityPreference}
        />
      </div>
    );
  };

  const NameFormatter = ({ value }: IFormatter) => (
    <div className='flex items-center max-w-full'>
      <Ellipsis rows={1} isInline>{value.name}</Ellipsis>
    </div>
  );

  const StateFormatter = ({ row }: IFormatter) => {
    const hidden = !!row.hiddenFor?.find((id: string) => id === user?.sub);
    return (
      <div className='flex justify-center max-w-full'>
        {hidden ? <HiddenSvg className='text-primary-light' /> : <VisibleSvg className='text-primary-light' />}
      </div>
    );
  };


  const ContextMenuProvider = (props: any) => DataProvider(props, ContextMenuFormatter);
  const StateProvider = (props: any) => DataProvider(props, StateFormatter);

  const NameProvider = (props: any) => DataProvider(props, NameFormatter);

  const customNameSort = (a: { name: string; }, b: { name: string; }) => nameSort(a.name, b.name);

  const generateProjectLink = (data: any) => {
    setSelectedProject(data);
    const { limitedUrl, isLimited } = checkStatus(
      data.id, data.role, data.status,
    );
    const isAvailableToAll = data.isSameOrg && data.access === 'AVAILABLE_TO_ALL';
    const isAvailableToOrgAdmins = data.isSameOrg && data.status === 'AVAILABLE_TO_ORG_ADMINS';
    switch (data.status) {
      case 'INVITED':
      case 'AVAILABLE_TO_ORG_ADMINS':
        if (isAvailableToAll || isAvailableToOrgAdmins) setAvailableToAll(true);
        else setInviteModal(true);
        break;
      case 'ARCHIVED':
        break;
      case 'LOCKED':
        if (isLimited) history.push(limitedUrl);
        else setUpgradeModal(true);

        break;
      default:
        history.push(`/project/${data.id}`);
        break;
    }
  };

  const TableRow: React.FC<any> = ({ row, ...restProps }) => (
    <Table.Row
      className={row.status === 'ARCHIVED'
        ? ''
        : 'cursor_pointer'}
      onClick={() => generateProjectLink(row)}
      {...restProps}
    />
  );

  const HeaderCell: React.FC<TableHeaderRow.ContentProps> = (props) => {
    const { column } = props;
    if (column.name !== 'image') return <TableHeaderRow.Content {...props} />;
    return (
      <TableHeaderRow.Content {...props}>
        <AddProjectBtn compactMode tier={tier} />
      </TableHeaderRow.Content>
    );
  };

  return (
    <>
      <div className='pb-6 bg-white rounded'>
        <DataGrid
          rows={rows}
          columns={columns}
          columnWidth={columnWidth}
          defaultSort={[{ columnName: 'status', direction: 'desc' }]}
          rowComponent={TableRow}
          customNameSort={customNameSort}
          noDataRowComponent={NoRowsComponent}
          headerCellComponent={HeaderCell}
        >
          <StatusProvider for={['status']} />
          <ImageProvider for={['image']} />
          <NameProvider for={['name']} />
          <StateProvider for={['state']} />
          <DateProvider for={['updatedAt']} />
          <ContextMenuProvider for={['menu']} />
        </DataGrid>

        {selectedProject && <ProjectActions
          tier={tier}
          project={selectedProject}
          upgradeModal={upgradeModal}
          inviteModal={inviteModal}
          availableToAll={availableToAll}
          setInviteModal={setInviteModal}
          setUpgradeModal={setUpgradeModal}
          setAvailableToAll={setAvailableToAll}
        />}
      </div>
    </>
  );
};

export default ListView;

import { SvgIcon } from '@material-ui/core';
import { LogoutIcon, WorkOutlineIcon } from '@vucity/design_system';
import { useMixpanel } from 'hooks';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { UserTier } from 'types/graphql';
import Mixpanel from 'utils/mixpanel';

type ProfileDropdownProps = {
  onLogout: (arg: any) => void,
  tier: UserTier,
};

const ProfileDropdown = ({ onLogout, tier }: ProfileDropdownProps) => {
  const { mixpanelTrack } = useMixpanel();
  const history = useHistory();

  const { slotsRemaining = 0 } = tier;

  const handleUnlimitedProjects: any = !slotsRemaining
    ? <SvgIcon viewBox="0 0 24 24" className='p-0.5'>
      <path d="M0 0h24v24H0V0z" fill="none" /><path d="M18.6 6.62c-1.44 0-2.8.56-3.77 1.53L12 10.66 10.48 12h.01L7.8 14.39c-.64.64-1.49.99-2.4.99-1.87 0-3.39-1.51-3.39-3.38S3.53 8.62 5.4 8.62c.91 0 1.76.35 2.44 1.03l1.13 1 1.51-1.34L9.22 8.2C8.2 7.18 6.84 6.62 5.4 6.62 2.42 6.62 0 9.04 0 12s2.42 5.38 5.4 5.38c1.44 0 2.8-.56 3.77-1.53l2.83-2.5.01.01L13.52 12h-.01l2.69-2.39c.64-.64 1.49-.99 2.4-.99 1.87 0 3.39 1.51 3.39 3.38s-1.52 3.38-3.39 3.38c-.9 0-1.76-.35-2.44-1.03l-1.14-1.01-1.51 1.34 1.27 1.12c1.02 1.01 2.37 1.57 3.82 1.57 2.98 0 5.4-2.41 5.4-5.38s-2.42-5.37-5.4-5.37z" />
    </SvgIcon> : slotsRemaining;

  return (
    <div className='shadow-md absolute top-5 right-0 min-w-[210px] w-full bg-white z-50 flex flex-col rounded-sm text-primary p-1'>
      <div className='flex gap-1 p-1'>
        <div className='flex items-center justify-center w-3 h-3 rounded-full bg-primary-lightest'>
          {slotsRemaining !== null && slotsRemaining <= 0 ? '0' : handleUnlimitedProjects}
        </div>
        <span className='text-content'>
          {handleUnlimitedProjects <= 3 ? 'Projects remaining' : 'Unlimited projects'}
        </span>
      </div>

      <button
        className='flex gap-1 p-1 mb-1 transition duration-300 rounded-sm cursor-pointer hover:bg-grey-lighter'
        onClick={() => {
          Mixpanel.track('Top Menu > Profile > My Account Click', {});
          history.push('/account/profile');
        }}
      >
        <WorkOutlineIcon className='w-3 h-3' />
        <span className='text-content'>My account</span>
      </button>

      <hr className='border-grey-border' />

      <button
        className='flex gap-1 p-1 mt-1 transition duration-300 rounded-sm cursor-pointer hover:bg-grey-lighter'
        onClick={() => {
          mixpanelTrack('Top Menu > Log out');
          onLogout({ returnTo: window.location.origin });
        }}
      >
        <LogoutIcon color="error" className='w-3 h-3' />
        <span className='text-error'>Logout</span>
      </button>
    </div>
  );
};

export default ProfileDropdown;

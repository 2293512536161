import DetailedSelect from 'components/DetailedSelect';
import React from 'react';
import { License } from 'types/graphql';
import { extractCityName, sortLicences } from 'utils/helpers';

interface ILicensesProps {
  licenses: License[];
  selectedLicense?: string;
  onLicenseChange: (e: string) => any;
  disabled?: boolean;
  compactMode?: boolean;
  className?: string;
}

const Licenses: React.FC<ILicensesProps> = ({
  licenses, selectedLicense, onLicenseChange, compactMode = false, className,
}) => {
  const options = [
    { value: '', title: 'Select a location', disabled: true },
    ...sortLicences(licenses).map((license) => ({
      value: license.id, title: extractCityName(license.stripeProduct.name),
    })),
  ];

  return (
    <DetailedSelect
      className={`w-full py-1 ${compactMode ? 'h-5' : ''} [&_ul]:max-h-300 ${className}`}
      variant='outlined'
      value={selectedLicense || ''}
      items={options}
      handleChange={(e) => e && onLicenseChange(e.value)}
    />
  );
};

export default Licenses;

import React, { useState } from 'react';
import TruncateMarkup from 'react-truncate-markup';

import { useMixpanel, useRenderComment } from 'hooks';

interface IReadMoreProps {
  content: string;
  lines: number;
  mixpanelEvent: string;
  executeScroll?: (block?: ScrollLogicalPosition) => void;
}

const ReadMore = ({
  content: rawContent, lines, mixpanelEvent, executeScroll,
}: IReadMoreProps) => {
  const { mixpanelTrack } = useMixpanel();
  const renderComment = useRenderComment();
  const [showMore, setShowMore] = useState<boolean>(false);

  const content = (
    <div className='whitespace-pre-wrap text-content'>
      {renderComment(rawContent)}
    </div>
  );

  const readMore = (
    <a className='cursor-pointer text-primary' onClick={() => {
      setShowMore(true);
      mixpanelTrack(mixpanelEvent);
      if (executeScroll) executeScroll('start');
    }}>
      ... <span className='hover:underline'>See more</span>
    </a>
  );

  if (!showMore) {
    return (
      <TruncateMarkup lines={lines} ellipsis={readMore}>
        {content}
      </TruncateMarkup>
    );
  }

  return content;
};

export default ReadMore;

import { interpolateParams } from 'utils/helpers';
import content from './content.json';

export const addCityLicense = () => content.addCityLicense;
export const cityLicense = () => content.cityLicense;
export const noLicense = () => content.noLicense;
export const noSubscriptions = () => content.noSubscriptions;
export const Subs = () => content.Subs;
export const Total = () => content.Total;
export const Assigned = () => content.Assigned;
export const upgradeSub = () => content.upgradeSub;
export const createSub = () => content.createSub;
export const Upcoming = () => content.Upcoming;
export const Billing = () => content.Billing;
export const Payment = () => content.Payment;
export const subType = (type: string) => interpolateParams(content.subType, { type });

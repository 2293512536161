import { palette } from '../utils';

export const autocomplete = {
  root: {
    '&.openedAutocomplete': {
      '& .MuiInputBase-root': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: 'none',
      },
    },
  },
  input: {
    minHeight: '24px',
  },
  inputRoot: {
    color: palette.text.primary,
    '& .MuiSvgIcon-root': {
      marginLeft: 6,
      width: 17,
      color: palette.text.primary,
    },
  },
  paper: {
    margin: 0,
    padding: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: 'none',
  },
  listbox: {
    padding: 0,
  },
  option: {
    color: palette.gray.main,
    padding: 0,
    borderBottom: `1px solid ${palette.gray.light}`,
    '& .MuiSvgIcon-root': {
      width: 17,
    },
  },

  endAdornment: {
    color: palette.text.primary,
    '& .MuiSvgIcon-root': {
      width: 20,
      padding: 0,
      margin: 0,
    },
  },
};

export default autocomplete;

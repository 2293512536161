import styled from 'styled-components';
import MuiCard from '@material-ui/core/Card';
import { InfoInactive } from '@vucity/design_system';
import { CardMedia } from '@material-ui/core';

export const TooltipContent = styled(MuiCard)`
  ${({ theme }) => `
    padding: ${theme.spacing(2)}px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
  `}
`;

export const TooltipButton = styled(InfoInactive)`
  position: absolute;
  top: 16px;
  right: 16px;
  
  &:hover {
    & ~ ${TooltipContent} {
      opacity: 1;
      z-index: 1;
    }
  }
`;

export const CardProjectCover = styled(CardMedia) <{ imageid?: string }>`
  width: 100%;
  height: 138px;
  background-size: ${({ imageid }) => (imageid ? 'cover' : 'contain')};
`;

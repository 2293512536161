import { gql } from '@apollo/client';

export const FOLDERS = gql`
  fragment folderData on Folder {
    id
    name
    path
  }
`;

export const GET_PERSONAL_DRIVE_FOLDERS = gql`
  ${FOLDERS}
  query GetPersonalDrive{
    currentUser {
      id
      drive {
        nodeByPath {
          id
          path
          name
          ... on Folder {
            ...folderData
            folders {
              ...folderData
              folders {
                ...folderData
                folders {
                  ...folderData
                  folders {
                    ...folderData
                    folders {
                      ...folderData
                      folders {
                        ...folderData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

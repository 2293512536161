import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import PrivateRoute from 'utils/routes/PrivateRoute';
import { GET_INVITATIONS } from './apollo';
import OrganisationInvitations from './OrganisationInvitations';

const Invitations: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { data } = useQuery(GET_INVITATIONS);
  const [skip, setSkip] = useState(false);

  if (!data) {
    return null;
  }

  const SurfaceInvitations = () => (
    <OrganisationInvitations setSkip={setSkip} />
  );

  const showInvitations = !(skip || localStorage.getItem('skipOrgInvites') === 'true')
    && data?.currentUser?.orgInvites?.some((val: any) => val.status === 'INVITED');

  return showInvitations ? (
    <PrivateRoute component={SurfaceInvitations} />
  ) : (
    <>{children}</>
  );
};

export default Invitations;

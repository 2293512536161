import { palette } from '../utils';

export const card = {
  root: {
    borderRadius: '8px',

    '&:hover': {
      boxShadow: '0 0 10px rgb(166 175 185)',
    },
  },
};

export const cardActionArea = {
  root: {
    borderRadius: '8px',

    '&.Mui-disabled .MuiCardMedia-root': {
      opacity: 0.25,
    },

    '&.Mui-disabled .MuiCardContent-root': {
      backgroundColor: '#fff',
    },

    '&:hover $focusHighlight': {
      opacity: 0,
    },
  },
};

export const cardMedia = {
  root: {
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    backgroundColor: palette.placeholder.main,
    '&$img': {
      objectFit: 'contain',
      padding: '16px',
      boxSizing: 'border-box',
    },
  },
};

import { Box } from '@material-ui/core';
import styled from 'styled-components';

const NoAvatar = styled(Box)`
  width: 240px;
  height: 138px;
  background: #ebebeb;
  border-radius: 4px;
`;

export default NoAvatar;

import { Typography } from '@material-ui/core';
import { useMixpanel } from 'hooks';
import * as React from 'react';
import { Button } from 'tailwind';
import LoadingBar from '../../Loading/LoadingBar';
import CustomModal from '../Modal';

type TextProps = {
  headerText: string;
  open: boolean;
  component?: React.ElementType;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mainBtnLabel?: string;
  mainBtnHandle?: () => void;
  mainBtnDisabled?: boolean;
  closeBtnLabel: string;
  closeBtnHandle?: () => void;
  mixpanelPath?: string;
  loading?: boolean;
  onClose?: () => void;
  headerComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  closeBtnDisabled?: boolean;
};

const TextModal: React.FC<React.PropsWithChildren<TextProps>> = ({
  headerText,
  children,
  component = 'p',
  open,
  setOpen,
  mainBtnLabel = null,
  mainBtnHandle,
  mainBtnDisabled,
  closeBtnLabel,
  closeBtnHandle = () => setOpen(false),
  mixpanelPath = headerText,
  loading = false,
  onClose,
  headerComponent,
  footerComponent,
  closeBtnDisabled,
}) => {
  const { mixpanelTrack } = useMixpanel();
  const handleCustomModalClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  return (
    <CustomModal open={open}
      close={handleCustomModalClose}
      mixpanelPath={mixpanelPath}
      headerText={headerText}
      headerComponent={headerComponent}
      closeBtnDisabled={closeBtnDisabled}
    >
      <>
        {loading && <LoadingBar />}
        <div className='pt-3 pb-4'>
          <Typography className='h-auto' component={component} variant="body1" align="center">
            {children}
          </Typography>
        </div>

        <div className='flex items-center gap-2'>
          {mainBtnLabel &&
            <Button
              disabled={mainBtnDisabled || loading}
              variant="contained"
              onClick={() => {
                if (open) mixpanelTrack(`${mixpanelPath}/${mainBtnLabel}`);
                return mainBtnHandle && mainBtnHandle();
              }}
            >
              {mainBtnLabel}
            </Button>
          }
          <Button
            variant={mainBtnLabel ? 'outlined' : 'contained'}
            disabled={loading}
            onClick={() => {
              if (open) mixpanelTrack(`${mixpanelPath}/${closeBtnLabel}`);
              closeBtnHandle();
            }}
          >
            {closeBtnLabel}
          </Button>
        </div>

        {footerComponent}
      </>
    </CustomModal>
  );
};

export default TextModal;

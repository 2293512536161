import { ReactComponent as CancelOutlined } from 'assets/icons/CancelOutlined.svg';
import { Image } from 'components/InviteMembers/SelectWithInput/SelectWithInput.style';
import React from 'react';
import { Ellipsis, Label } from 'tailwind';
import { SelectWithInputItem } from 'views/types';

interface MemberChipProp extends SelectWithInputItem {
  onClick?: (e: React.MouseEvent) => void;
  onRemove?: (index: number, option: SelectWithInputItem) => void;
  index?: number;
  showBorder?: boolean;
}

export default function MemberChip(props: MemberChipProp) {
  const { onClick, label, onRemove, index, showBorder, ...item } = props;
  const { displayText, imgUrl, tag } = item;

  return (
    <div className={`flex 
      gap-1 
      min-h-4 h-4
      cursor-pointer 
      ${showBorder && 'border border-primary-lightest rounded-sm'}
      w-fit
      items-center py-0.5 px-1`}
      onClick={onClick}
    >
      {imgUrl && <Image src={imgUrl} alt={displayText} />}
      <Ellipsis rows={1}>{displayText}</Ellipsis>
      {tag && <div className="border border-primary-lightest text-xs rounded-sm h-3 w-[52px] flex items-center justify-center">{tag}</div>}
      {label && <Label children={label} />}
      {onRemove && (<button onClick={(e) => {
        e.stopPropagation();
        onRemove(index!, item);
      }}>
        <CancelOutlined />
      </button>)}
    </div>
  );
}
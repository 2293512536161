import { MenuItem } from '@material-ui/core';
import { IContextMenuBase } from 'components/Drive/types';
import { useCurrentUser, useMixpanel } from 'hooks';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { groupFiles } from 'utils/helpers';

const CreateProject = ({ projectId, files }: IContextMenuBase) => {
  const { mixpanelTrack } = useMixpanel();
  const { currentUser } = useCurrentUser();

  const { pathname } = useLocation();
  const history = useHistory();

  const url = projectId ? `/create/${projectId}/drive` : '/create/drive';

  const { name = '', slotsRemaining = 0 } = useMemo(() => currentUser?.tier || {}, [currentUser]);
  const groupedFiles = useMemo(() => groupFiles(files), [files]);

  const isDisabled = currentUser && (name === 'Starter' && slotsRemaining <= 0);

  const drive = projectId ? 'project' : 'personal';

  return (
    <>    
      <MenuItem
        disabled={isDisabled}
        onClick={() => {
          history.push({ pathname: url, state: { prevPath: pathname } });
          mixpanelTrack(`Quick create a project context menu clicked in ${drive} drive`, {
            'Total no. of files selected for import': groupedFiles.files.length,
            'Total no. of folders selected for import': groupedFiles.folders.length,
          });
        }}
      >
        Create a project
      </MenuItem>
      <hr className='my-1 border-grey-border' />
    </>
  );
};

export default CreateProject;

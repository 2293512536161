import { typography } from '../utils';

export const popover = {
  root: {
    '&.drive_context_menu': {
      '& .MuiPaper-root': {
        minHeight: 0,
      },

      '& .MuiList-root': {
        maxWidth: '400px',
        padding: 0,

        '& > li:first-child': {
          minWidth: '270px',
          marginTop: '8px',
        },

        '& > li:last-child': {
          marginBottom: '8px',
        },

        '& > div:not(.MuiLinearProgress-root)': {
          paddingTop: '10px',
          paddingBottom: '10px',
        },
      },
    },

    '&.drive_add_new': {
      '& .MuiList-root': {
        minWidth: '270px',
      },

      '& .MuiMenuItem-root': {
        fontSize: typography.body2.fontSize,
      },
    },
  },
};

export default popover;

import { IconButton } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { NorthIcon, SouthIcon } from '@vucity/design_system';
import { useMixpanel } from 'hooks';
import React from 'react';
import { SortOrder } from 'types/graphql';

interface ISortCommentProp {
  handleSort: React.Dispatch<React.SetStateAction<SortOrder>>;
  sortOrder: SortOrder;
}

const getDescription = (sortOrder: SortOrder) => (sortOrder === 'asc' ? 'Oldest' : 'Newest');

const SortComment: React.FC<ISortCommentProp> = ({ handleSort, sortOrder }) => {
  const { mixpanelTrack } = useMixpanel();
  const handleClick = (sortorder: SortOrder) => {
    handleSort((prev) => {
      mixpanelTrack(`#Comments/Sort Order/${getDescription(prev)} to ${getDescription(sortorder)}`);
      return sortorder;
    });
  };

  return (
    <div className='flex justify-end w-full mt-2 mb-1'>
      <span className='text-xs w-[50px] text-center mr-0.5'>Sort by</span>

      <IconButton
        onClick={() => handleClick('asc')}
        className={`${sortOrder === 'asc' ? 'text-primary' : 'text-placeholder'} p-0`}
      >
        <SouthIcon />
      </IconButton>
      <Divider variant='middle' orientation='vertical' flexItem className='mx-1' />
      <IconButton
        className={`${sortOrder === 'desc' ? 'text-primary' : 'text-placeholder'} p-0`}
        onClick={() => handleClick('desc')}
      >
        <NorthIcon />
      </IconButton>

      {sortOrder === 'asc' && <span className='text-xs w-[50px] text-center'> Oldest</span>}
      {sortOrder === 'desc' && <span className='text-xs w-[50px] text-center'>Newest</span>}
    </div>
  );
};

export default SortComment;

import { ReactComponent as Info } from 'assets/icons/Info.svg';
import { DetailedSelect, Tooltip } from 'components';
import { useMixpanel } from 'hooks';
import React from 'react';
import { useMap } from 'react-map-gl';
import { DetailedSelectItem } from 'views/types';
import { ICity } from '../types';
import { useHistory, useLocation } from 'react-router-dom';

interface IModelledCitiesProps {
  selectedCity: string;
  setSelectedCity: (arg: string) => void;
  cities: ICity[];
}

const ModelledCities = ({ cities, selectedCity, setSelectedCity }: IModelledCitiesProps) => {
  const { current: map } = useMap();
  const { mixpanelTrack } = useMixpanel();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(search);

  const options = [
    { value: '', title: 'Select a location', disabled: true },
    ...cities.sort((a, b) => a.city.localeCompare(b.city)).map((data) => ({
      value: `${data.id}`, title: data.city,
    })),
  ];

  const onChangeCity = (e: DetailedSelectItem | null | undefined) => {
    setSelectedCity(e?.value || '');
    queryParams.set('city', e?.value || '');
    history.replace({
      pathname: pathname,
      search: queryParams.toString(),
    });
    const { lat = 0, lng = 0, zoom, city } = cities.find(data => `${data.id}` === `${e?.value}`) || {};

    if (map)
      map.flyTo({
        center: [lng, lat],
        speed: 1.5,
        zoom,
      });

    mixpanelTrack('Selecting a city', { 'Selected city': `${city}` });
  };

  const showTooltip = (name: string) => {
    // TODO: Will need to handle 'Currently modelling' tooltip when we have the copy
    const content = name === 'Level of detail'
      ? 'Level of detail (LOD) is a scale level defined in a tiling scheme. It represents the zoom level value for a map view or a scene view'
      : 'Currently modelling vu.city description goes here';

    if (name === 'Level of detail')
      return <Tooltip
        placement="bottom-start"
        header={name}
        content={content}
      >
        <div>
          <Info
            className='[&_path]:fill-primary'
            onMouseOver={() => {
              if (name === 'Level of detail') return mixpanelTrack('Hover over level of detail tooltip');
            }} />
        </div>
      </Tooltip>;

    return <></>;
  };

  return (
    <section className='w-full'>
      <p className='text-base text-grey'>Modelled cities</p>
      <div className='h-40 mt-1'>
        <DetailedSelect
          className='h-5 py-1 [&_ul]:max-h-300 text-sm'
          variant='outlined'
          items={options}
          value={selectedCity}
          handleChange={onChangeCity}
          mixpanelEvent='Selecting the dropdown'
        />
      </div>

      {selectedCity && (
        <div className='relative flex-col mt-2 flex bg-primary-lightest rounded py-1 px-1.2'>
          {cities.filter(data => `${data.id}` === `${selectedCity}`).map(data => (
            <div key={data.id}>
              {data.metadata.map((item) => (
                <div key={item.name} className='flex justify-between w-full py-1 text-sm border-b border-b-primary-lighter last:border-b-0'>
                  <span className='flex gap-1'>{item.name}{showTooltip(item.name)}</span>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default ModelledCities;

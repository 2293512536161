import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ProjectMember } from 'types/graphql';
import { nameSort } from 'utils/helpers';
import GET_PROJECT_MEMBERS from './apollo';

export interface SortMembers {
  sortAsc?: boolean;
  sortBy?: 'name' | 'respondedAt';
}

const useProjectMembers = (sort: SortMembers = { sortAsc: true, sortBy: 'name' }) => {
  const { projectId } = useParams<{ projectId?: string }>();

  const { data, loading } = useQuery(GET_PROJECT_MEMBERS, { variables: { id: projectId } });
  if (loading || !data) return [[], {}];
  const accepted = data.project.members
    .filter((m: ProjectMember) => m.status === 'ACCEPTED' && !m.isUserRemoved);
  if (sort.sortBy === 'respondedAt') {
    return [accepted.sort((a: ProjectMember, b: ProjectMember) => (sort.sortAsc
      ? new Date(a.respondedAt).valueOf() - new Date(b.respondedAt).valueOf()
      : new Date(b.respondedAt).valueOf() - new Date(a.respondedAt).valueOf()
    ))];
  }
  return [accepted.sort((a: ProjectMember, b: ProjectMember) => (sort.sortAsc
    ? nameSort(a.profile?.name || '', b.profile?.name || '')
    : nameSort(b.profile?.name || '', a.profile?.name || '')))];
};

export default useProjectMembers;

import { gql } from '@apollo/client';

const GET_PROJECT_NAME = gql`
query getProject($id: ID!) {
  project(id: $id) {
    id
    name
  }
}
`;

export default GET_PROJECT_NAME;

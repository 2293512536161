import { CircularProgress } from '@material-ui/core';
import ScrollIntoView from 'components/ScrollIntoView/ScrollIntoView';
import React from 'react';
import { CommentAddedEvent, EventEdge } from 'types/graphql';
import Comment from './Comment/Comment';

interface IListProps {
  edges: EventEdge[];
  selectedComment: string;
  currentId: string;
  setCurrentId: React.Dispatch<string>;
  sentryRef: any;
  rootRef: any;
  hasNextPage: boolean;
  loading: boolean;
  called: boolean;
  fetchMore: () => void;
  setScrolled: (element: HTMLElement) => void;
  scrolled: boolean;
}

const ListComments: React.FC<IListProps> = ({
  edges, selectedComment, currentId, setCurrentId, rootRef,
  sentryRef, hasNextPage, loading, fetchMore, called,
  scrolled, setScrolled,
}) => (
  <ScrollIntoView elementId={selectedComment}
    hasNextPage={hasNextPage}
    loading={loading}
    called={called}
    fetchMore={fetchMore}
    scrolled={scrolled}
    setScrolled={setScrolled}>
    <div className='flex flex-col h-full overflow-x-hidden overflow-y-scroll' ref={rootRef}>
      {edges.map((edge) => (
        <div key={edge.node.id}>
          <Comment
            event={edge.node as CommentAddedEvent}
            selectedComment={selectedComment}
            currentId={currentId}
            setCurrentId={setCurrentId}
          />
          <hr className='pl-3 pr-1' />
        </div>
      ))}

      <div className='flex justify-center p-3'>
        {hasNextPage
          ? <CircularProgress ref={sentryRef} />
          : <p>There are no more comments</p>
        }
      </div>
    </div>
  </ScrollIntoView>
);

export default ListComments;

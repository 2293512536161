import React from 'react';
import { FileViewerProps } from 'views/types';

export default function ({ filePath }: FileViewerProps) {

  return (
    <div className='w-full h-full flex items-center justify-center'>
      <embed
        className='w-full h-full'
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(filePath)}`}
      />
    </div>
  );
}
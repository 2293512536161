import { sumBy } from 'lodash';
import { StripeSubscription } from 'types/graphql';
import { nameSort } from 'utils/helpers';

export const aggregateData = (subscriptions: StripeSubscription[]) => {
  const summedFloatingAssigned = sumBy(subscriptions, o => o?.stats?.floating?.assigned);
  const summedFloatingTotal = sumBy(subscriptions, o => o?.stats?.floating?.total);

  const summedProAssigned = sumBy(subscriptions, o => o?.stats?.pro?.assigned);
  const summedProTotal = sumBy(subscriptions, o => o?.stats?.pro?.total);
  const summedPlusAssigned = sumBy(subscriptions, o => o?.stats?.plus?.assigned);
  const summedPlusTotal = sumBy(subscriptions, o => o?.stats?.plus?.total);

  const aggregated = subscriptions.filter((sub: StripeSubscription) => sub?.interval === 'year' || sub?.interval === 'month').map((item: StripeSubscription) => {
    const allCityLicenses = subscriptions.filter((sub: StripeSubscription) => sub?.interval === 'year' || sub?.interval === 'month').map((obj: StripeSubscription) => obj?.cityLicenses).flat().sort((a: any, b: any) => nameSort(a?.license?.name, b?.license?.name)) || [];
    return {
      ...item,
      cityLicenses: allCityLicenses,
      stats: {
        ...item?.stats,
        floating: {
          ...item?.stats?.floating,
          assigned: summedFloatingAssigned,
          total: summedFloatingTotal,
        },
        pro: {
          ...item?.stats?.pro,
          assigned: summedProAssigned,
          total: summedProTotal,
        },
        plus: {
          ...item?.stats?.plus,
          assigned: summedPlusAssigned,
          total: summedPlusTotal,
        },
      },
    };
  });
  return aggregated[0];
};
import { useMixpanel } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import TabContent from './TabContent/TabContent';

interface ITabsProps {
  children: React.ReactElement | React.ReactElement[];
  onClick?: (index: number) => void;
  mixpanelProps?: {
    title: string,
    extra?: {};
  };
}

const Tabs = ({ children, onClick, mixpanelProps }: ITabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { mixpanelTrack } = useMixpanel();

  const childrenArray = useMemo(
    () => (Array.isArray(children) ? children : [children]) as React.ReactElement[],
    [children],
  );

  useEffect(() => {
    const initialTab = childrenArray.findIndex((child) => !child.props.disabled);
    setSelectedTab(initialTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnTabClick = (index: number) => {
    const { label } = childrenArray[index].props;
    setSelectedTab(index);
    if (onClick) onClick(index);

    if (mixpanelProps) {
      const { title, extra } = mixpanelProps;
      return mixpanelTrack(`${title} ${label} tab`, extra && extra);
    }

    return mixpanelTrack(`${label} - Button Clicked`);
  };

  return (
    <div className='my-2'>
      <ul className='flex flex-wrap items-center gap-2 mb-2'>
        {childrenArray.map((child, index) => (
          <TabContent
            {...child.props}
            key={index}
            index={index}
            active={index === selectedTab}
            onTabClick={handleOnTabClick}
          />
        ))}
      </ul>

      {childrenArray[selectedTab]}
    </div>
  );
};

export default Tabs;

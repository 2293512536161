const palette = {
  primary: {
    main: '#375675',
    light: '#80A6B1',
    lighter: '#BFD3D8',
    lightest: '#E6EDEF',
  },
  secondary: {
    main: '#16A087',
    light: '#8ACFC3',
    lighter: '#C5E7E1',
    lightest: '#E8F6F3',
  },
  tertiary: {
    light: '#f6f6f6',
    main: '#D8BC65',
  },
  text: {
    primary: '#333333',
    secondary: '#EBEBEB',
  },
  success: {
    light: '#C6E1CB',
    main: '#1D872E',
  },
  warning: {
    light: '#FDECBF',
    main: '#F7B500',
  },
  error: {
    light: '#F7C7C7',
    main: '#E02020',
  },
  black: {
    main: '#000000',
  },
  gray: {
    light: '#EFEFEF',
    main: '#5c5c5c',
  },
  white: {
    main: '#FFFFFF',
  },
  placeholder: {
    main: '#A6AFB9',
  },
  contrastThreshold: 3,
  tonalOffset: 0.5,
};

export default palette;

import { TextModal } from 'components';
import { useMixpanel } from 'hooks';
import * as React from 'react';
import { Button } from 'tailwind';
import { Organisation } from 'types/graphql';

interface CancelInviteProps {
  handleSubmit: any;
  org: Organisation;
}

const CancelInvite: React.FC<CancelInviteProps> = ({ handleSubmit, org }) => {
  const { mixpanelTrack } = useMixpanel();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    mixpanelTrack('cancelled Decline org invite');
    setOpen(false);
  };

  return (
    <>
      <Button
        className='w-[137px]'
        size='md'
        onClick={() => {
          setOpen(true);
          mixpanelTrack('Declined Org invite', {
            OrgID: org.id,
            OrgName: org.name,
          });
        }}
      >
        Decline
      </Button>
      <TextModal
        open={open}
        setOpen={setOpen}
        mixpanelPath="Invitation cancelled"
        closeBtnLabel="Cancel"
        closeBtnHandle={handleClose}
        mainBtnLabel="Decline Invite"
        mainBtnHandle={() => {
          setOpen(false);
          handleSubmit();
        }}
        headerText="Are you sure?"
      >
        By declining this invitation you will not be able to join this
        organisation and the invite will disappear. <br /><br />
        This organisation can re-invite you if needed at a later date.
      </TextModal>
    </>
  );
};

export default CancelInvite;

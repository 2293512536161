export const ACCOUNT_STATUS_ACTIVE = 'ACTIVE';
export const ACCOUNT_STATUS_INACTIVE = 'INACTIVE';
export const ACCOUNT_STATUS_ALL = 'ALL';
export const MEMBER_ROLE = 'MEMBER';
export const ADMIN_ROLE = 'ADMIN';
export const VIEWER_ROLE = 'VIEWER';
export const EDITOR_ROLE = 'EDITOR';
export const REMOVED_MEMBER_STATUS = 'Removed';
export const DECLINED_MEMBER_STATUS = 'Declined';
export const MEMBERS_INVITED_NOTIFICATION = 'Project_Invitations';
export const ORG_MEMBERS_INVITED_NOTIFICATION = 'Organisation_Invitations';
export const SUBSCRIPTION_UPDATED = 'Subscription_Updated';

export const ACCOUNT_STATUS_DESCRIPTIONS :Record<string, string> = {
  [ACCOUNT_STATUS_ACTIVE]: 'active',
  [ACCOUNT_STATUS_INACTIVE]: 'inactive',
  [ACCOUNT_STATUS_ALL]: 'all members',
};

import React, { useState } from 'react';
import { Folder } from 'types/graphql';
import { nameSort } from '../../../utils/helpers';
import { FolderContainer } from '../Drive.style';
import DriveFolders from './DriveFolder/DriveFolder';
import DriveSubfolder from './DriveSubfolder/DriveSubfolder';

interface IProps {
  treeData: any;
  rootFolder: string;
  disabledFolder?: any;
  folderControls: {
    selected: string[];
    expanded: string[];
    handleSelect: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void;
    handleToggle: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void;
  };
  onFolderClick?: (folder: Folder) => void;
  projectId?: string;
  driveType?: 'personal' | 'project';
  exclude?: boolean;
}

const FolderTree: React.FC<IProps> = ({
  treeData, rootFolder, folderControls, disabledFolder, onFolderClick,
  projectId, driveType, exclude,
}) => {
  const [initialPos, setInitialPos] = useState<number | null>(null);
  const [initialSize, setInitialSize] = useState<number | null>(null);

  const { selected, expanded, handleSelect, handleToggle } = folderControls;

  const generateFolders = (folder: Folder) => {
    const isDisabled = disabledFolder?.filter((id: string) => id === folder.id).length > 0;

    return (
      folder && (
        <DriveSubfolder
          key={folder.id}
          folder={folder}
          rootFolder={rootFolder}
          isDisabled={isDisabled}
          onFolderClick={() => onFolderClick && onFolderClick(folder)}
          driveType={driveType}
          projectId={projectId}
          exclude={exclude}
        >
          {folder?.folders?.slice()
            .sort((a: Folder, b: Folder) => nameSort(a.name, b.name))
            .map((subFolder: Folder) => generateFolders(subFolder))}
        </DriveSubfolder>
      )
    );
  };

  const initial = (e: { clientX: number; }) => {
    const resizable = document.getElementById('resizable');

    if (!resizable) return;

    setInitialPos(e.clientX);
    setInitialSize(resizable.offsetWidth);
  };

  const resize = (e: { clientX: number; }) => {
    const resizable = document.getElementById('resizable');

    if (!resizable || !initialPos) return;

    resizable.style.width = `${parseInt(`${initialSize}`, 10) + parseInt(`${e.clientX - initialPos}`, 10)}px`;
  };

  return (
    <FolderContainer
      className='relative top-0 left-0 w-full min-h-full px-2 py-1 h-max'
      onContextMenu={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
    >
      <div
        className='bg-transparent h-full w-1.2 cursor-col-resize right-0 top-0 opacity-0 absolute'
        draggable
        onDragStart={initial}
        onDrag={resize}
      />

      <DriveFolders
        selected={selected}
        expanded={expanded}
        handleSelect={handleSelect}
        handleToggle={handleToggle}
      >
        {treeData?.slice()
          .sort((a: any, b: any) => nameSort(a.name, b.name))
          .map((folder: Folder) => generateFolders(folder))}
      </DriveFolders>
    </FolderContainer>
  );
};

export default FolderTree;

import React, { ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { CloseIcon } from '@vucity/design_system';
import IconButton from '@material-ui/core/IconButton';
import { StyledDialogContent } from '../style';

interface ProjectDescriptionModalProps {
  open: boolean;
  children?: ReactNode;
  onClose?: (e: React.SyntheticEvent) => void;
}

const ProjectDescriptionModal: React.FC<ProjectDescriptionModalProps> = ({ open, onClose, children }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <StyledDialogContent className='p-0 pt-[-10px] flex flex-col gap-2 relative w-[526px]'>
        <div className='flex-row-reverse flex h-4 absolute w-full top-2 right-2 z-10'>
          <IconButton className='p-1' onClick={onClose} aria-label="xButton">
            <CloseIcon />
          </IconButton>
        </div>
        {children}
      </StyledDialogContent>
    </Dialog>
  );
};

export default ProjectDescriptionModal;
import { useMutation, useQuery } from '@apollo/client';
import { ClickAwayListener } from '@material-ui/core';
import * as turf from '@turf/turf';
import axios from 'axios';
import { useHandleError, useMixpanel } from 'hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { TextModal } from 'components';
import { Button } from 'tailwind';
import { ReactComponent as BasketIcon } from '../icons/basket.svg';
import { ReactComponent as BasketFilledIcon } from '../icons/basketFilled.svg';
import { NewUser, Tiles } from '../types';

import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { getAddress } from 'utils/helpers';
import { formatNumber } from '../CoverageMap';
import SubmitDetailsModal from '../publicAccess/SubmitDetails';
import { SEND_MODELLING_ORDER } from '../requests';
import AdditionalDetails from './AdditionalDetails';
import FileFormatModal from './FileFormatModal';
import OrderSummary from './OrderSummary';
import ThankYouModal from './ThankYouModal';
import { GET_USER_DETAILS_FOR_ORDER, SEND_ORDER } from './apollo';

export interface Summary {
  tiles: Tiles[];
  polygons: any[];
}

interface IBasketProps {
  uniqueTiles: string[];
  summary: Summary;
  onResetTiles: () => void;
  onSelect: (id: string, isTile?: boolean) => void;
  onDelete: (id: string, isTile?: boolean) => void;
}

const Basket = ({ uniqueTiles, summary, onSelect, onDelete, onResetTiles }: IBasketProps) => {
  const { user, loginWithPopup } = useAuth0();
  const { pathname } = useLocation();
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();

  const isPublic = pathname.includes('non/coveragemap');
  const notLoggedIn = isPublic && !user;

  const { data: qData } = useQuery(GET_USER_DETAILS_FOR_ORDER, {
    skip: notLoggedIn,
    fetchPolicy: 'cache-first',
  });

  // Basket state
  const [openBasket, setOpenBasket] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [error, setError] = useState(false);

  // Choose file format state
  const [openFormatModal, setOpenFormatModal] = useState(false);
  const [format, setFormat] = useState<string[]>([]);

  // Additional details modal
  const [additionalDetails, setAdditionalDetails] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [comment, setComment] = useState('');

  // Submit details for non-logged in users
  const [asAGuest, setAsAGuest] = useState(false);

  // Thank you modal state
  const [orderId, setOrderId] = useState('');

  const toggleBasket = () => {
    setOpenBasket(prev => !prev);
    mixpanelTrack('Toggle Basket Open/closed', { 'state': !openBasket });
  };

  const onFormatClick = useCallback((item: string) => {
    if (format.includes(item)) setFormat(format.filter(f => f !== item));
    else {
      mixpanelTrack('Selecting a file type', { 'File type': item });
      setFormat(prev => ([...prev, item]));
    }
  }, [format, mixpanelTrack]);

  const onCloseFileFormatModal = () => {
    setComment('');
    setFormat([]);
    setProjectName('');
    setOpenFormatModal(false);
  };

  const onReset = useCallback(() => {
    onResetTiles();
    onCloseFileFormatModal();
    setOpenBasket(false);
    setOrderLoading(false);
    setAsAGuest(false);
    setAdditionalDetails(false);
  }, [onResetTiles]);

  // HANDLE ORDER MODALS
  const fromStep1ToStep2 = () => {
    setOpenFormatModal(prev => !prev);
    setAdditionalDetails(prev => !prev);
  };

  const fromStep2ToStep3 = () => {
    setAdditionalDetails(prev => !prev);
    setAsAGuest(prev => !prev);
  };

  const order = useMemo(() => {
    const { polygons, tiles } = summary;

    return [
      ...polygons.map((polygon) => ({
        id: polygon.id,
        properties: polygon.properties,
        type: polygon.type,
        geometry: polygon.geometry,
      })),
      ...tiles.map((tile) => ({ ...tile.polygon })),
    ];
  }, [summary]);

  const [sendOrder, { loading }] = useMutation(SEND_ORDER);

  const { totalArea = 0, polygonCentre } = useMemo(() => {
    const projectedPolygons: turf.Position[][][] = [];

    order.forEach(feature => {
      const coordinates = feature.geometry.coordinates;
      projectedPolygons.push(coordinates);
    });

    if (!projectedPolygons.length) return 0;

    const polygons = projectedPolygons.map(coordinates => turf.polygon(coordinates));

    let unionPolygon: any = polygons[0];
    for (let i = 1; i < polygons.length; i++) {
      unionPolygon = turf.union(unionPolygon, polygons[i]);
    }

    const area = turf.area(unionPolygon);

    return {
      totalArea: formatNumber(turf.convertArea(area, 'meters', 'kilometers')),
      polygonCentre: turf.centroid(unionPolygon).geometry.coordinates,
    };
  }, [order]) as { totalArea: number, polygonCentre: number[]; };

  useEffect(() => {
    if (totalArea > 1) {
      setError(true);
      enqueueSnackbar(<div>You can select up to 1km<sup>2</sup></div>, { variant: 'error', preventDuplicate: true });
    } else setError(false);
  }, [enqueueSnackbar, totalArea]);

  const getCurrentUser = useCallback((newUser?: NewUser) => {
    let currentUser;

    if (!notLoggedIn) {
      currentUser = qData?.currentUser;
    } else {
      currentUser = {
        ...newUser,
        firstName: newUser?.firstName.trim(),
        lastName: newUser?.lastName.trim(),
        id: 'PUBLIC',
        name: `${newUser?.firstName.trim()} ${newUser?.lastName.trim()}`,
        organisation: {
          id: 'PUBLIC',
          name: newUser?.organisation.trim(),
          type: newUser?.type,
        },
      };
    }

    return currentUser;
  }, [notLoggedIn, qData?.currentUser]);

  const onSendQuote = useCallback(async (newUser?: NewUser) => {
    const currentUser = getCurrentUser(newUser);
    const currentCity = await getAddress({ lng: polygonCentre[0], lat: polygonCentre[1] }, 'place');
    const finalOrder = {
      type: 'FeatureCollection',
      features: order,
    };

    try {
      setOrderLoading(true);

      // Send modelling order to FME DB
      const { data } = await axios.post(SEND_MODELLING_ORDER, JSON.stringify({
        user: {
          id: currentUser?.id,
          name: currentUser?.name,
          email: currentUser?.email,
          jobTitle: currentUser?.jobTitle || 'unset',
          organisation: currentUser?.organisation?.name,
          organisationId: currentUser?.organisation?.id,
          organisationType: currentUser?.organisation?.type || 'unset',
        },
        order: finalOrder,
        format,
        comment,
        currentCity,
        projectName,
      }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setOrderId(data.orderID);

      if (!data.orderID) throw new Error('Something went wrong');

      // Send modelling order to GraphQL Hub DB
      sendOrder({
        variables: {
          order: {
            order: finalOrder,
            format,
          },
          comments: comment,
          data: {
            ...(notLoggedIn && { email: currentUser?.email }),
            orderId: data.orderID,
          },
        },
        onCompleted: () => {
          onReset();
          enqueueSnackbar('Order has been sent', { variant: 'success' });
        },
        onError: (err) => {
          handleError(err);
          onReset();
        },
      });

      mixpanelTrack('Clicking submit request', {
        'File types': format.join(', '),
        'Total length of input text': comment.length,
        'Tiles selected': uniqueTiles.length,
      });
    } catch (e) {
      setOrderLoading(false);
      enqueueSnackbar('Something went wrong. Try again later', { variant: 'error' });
    }
  }, [getCurrentUser, polygonCentre, order, format, comment, projectName, sendOrder, notLoggedIn, mixpanelTrack, uniqueTiles.length, onReset, enqueueSnackbar, handleError]);

  return (
    <section
      data-tour-key="3D-coverage-summary"
      className={`flex font-sans text-lg bg-white rounded-sm text-primary border ${error ? 'border-error' : 'border-transparent'}`}>
      {!openBasket && (
        <>
          <article className='p-1 flex gap-0.5'>
            <span className='font-medium'>
              {totalArea}km<sup>2</sup>
            </span>
            selected
          </article>

          <span className='my-1 border-l border-l-placeholder' />
        </>
      )}

      {uniqueTiles.length > 0 && (
        <div className='absolute top-[-8px] right-[-8px] px-0.5 bg-lightblue rounded-xs min-w-18 h-2.3 text-white text-xxs flex items-center justify-center'>
          {uniqueTiles.length}
        </div>
      )}

      <button
        onClick={toggleBasket}
        className='flex items-center justify-center w-5 h-5 transition duration-300 rounded-sm hover:bg-primary-lightest hover:cursor-pointer'
      >
        {openBasket ? <BasketFilledIcon /> : <BasketIcon />}
      </button>

      {/* ORDER SUMMARY */}
      {openBasket && (
        <ClickAwayListener onClickAway={toggleBasket}>
          <article className='absolute right-0 z-30 flex pt-1 top-full w-300'>
            <div className='flex flex-col w-full gap-2 px-2 py-3 bg-white rounded'>
              <div className='flex flex-col items-center gap-1'>
                <h3 className='text-primary'>{totalArea} km<sup>2</sup></h3>
                <span className='text-sm text-primary'>Selected area</span>
              </div>

              <Button
                variant='contained'
                fullWidth
                disabled={!totalArea || error}
                onClick={() => {
                  mixpanelTrack('Clicking Choose file format CTA');
                  setOpenFormatModal(true);
                }}
              >
                Choose file format
              </Button>

              <OrderSummary
                summary={summary}
                onSelect={onSelect}
                onDelete={onDelete}
              />
            </div>
          </article>
        </ClickAwayListener>
      )}

      {/* SEND ORDER MODALS */}
      {openFormatModal &&
        <TextModal
          open={true}
          setOpen={onCloseFileFormatModal}
          component='div'
          headerText='Choose file format'
          mainBtnLabel={notLoggedIn ? 'Log in / Sign up' : 'Continue'}
          mainBtnDisabled={!format.length}
          mainBtnHandle={notLoggedIn ? loginWithPopup : fromStep1ToStep2}
          closeBtnLabel='Return to map'
          footerComponent={notLoggedIn &&
            <>
              <hr className='w-full mt-3 mb-1 max-w-300' />
              <Button disabled={!format.length} variant='text' size='sm' padding='unset' onClick={() => {
                fromStep1ToStep2();
                mixpanelTrack('Clicked Continue as a Guest');
              }}>
                Continue as a guest
              </Button>
            </>
          }
        >
          <FileFormatModal data={{ format }} onFormatClick={onFormatClick} />
        </TextModal>
      }

      {/* ADD ANY ADDITIONAL COMMENTS */}
      {additionalDetails &&
        <AdditionalDetails
          data={{ comment, projectName }}
          setComment={setComment}
          setProjectName={setProjectName}
          loading={loading || orderLoading}
          mainBtnLabel={notLoggedIn ? 'Continue' : 'Submit request'}
          mainBtnDisabled={format.includes('Other')}
          onContinue={notLoggedIn ? fromStep2ToStep3 : onSendQuote}
          onBack={fromStep1ToStep2}
        />
      }

      {/* SUBMIT DETAILS MODAL FOR GUEST USERS */}
      {asAGuest &&
        <SubmitDetailsModal
          onSendQuote={onSendQuote}
          onClose={fromStep2ToStep3}
          loading={loading || orderLoading}
        />
      }

      {/* THANK YOU MODAL */}
      {orderId && !orderLoading &&
        <ThankYouModal orderId={orderId} setOrderId={setOrderId} />
      }
    </section>
  );
};

export default Basket;

import { Tooltip } from 'components';
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface IEllipsisProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  children: React.ReactNode;
  rows: number;
  isInline?: boolean;
  applyTooltip?: boolean;
}

const Ellipsis = ({
  rows,
  children,
  isInline = false,
  applyTooltip = false,
  ...props
}: IEllipsisProps) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [overflowActive, setOverflowActive] = useState(false);

  const isOverflowActive = useCallback((event: HTMLSpanElement | null) => {
    if (!event) return false;
    return event.offsetHeight < event.scrollHeight || event.offsetWidth < event.scrollWidth;
  }, []);

  const handleResize = useCallback(() => {
    if (isOverflowActive(spanRef.current)) {
      return setOverflowActive(true);
    }
    setOverflowActive(false);
  }, [isOverflowActive]);

  useEffect(handleResize, [handleResize]);

  useEffect(() => {
    const span = spanRef.current;

    if (span) {
      let parentElement = span.parentElement;
      if (parentElement) parentElement = parentElement.parentElement;

      if (parentElement) {
        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(parentElement);

        return () => {
          resizeObserver.disconnect();
        };
      }
    }
  }, [handleResize, isOverflowActive]);

  return (
    <Tooltip
      placement='right'
      content={children}
      isVisible={overflowActive && applyTooltip}
    >
      <span
        ref={spanRef}
        {...props}
        style={{
          display: isInline ? 'inline' : '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: rows,
          lineHeight: '1.4em',
          maxHeight: `calc(${rows} * 1.4em)`,
        }}
        className={`overflow-hidden leading-snug box break-words text-ellipsis ${props.className}`}
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default Ellipsis;

import { Avatar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ProfileCard from 'components/ProfileCard/ProfileCard';
import { useScroll } from 'hooks';
import { CommentAddedEvent as IEvent } from 'types/graphql';
import { formatRelativeDate } from 'utils/helpers';

import { Ellipsis, ReadMore } from 'tailwind';
import CopyLink from '../../CopyLink/CopyLink';
import DeleteComment from '../../DeleteComment/DeleteComment';
import EditComment from '../../EditComment/EditComment';
import ActionsMenu from './ActionsMenu';
import { HighlightComment } from './Comment.style';

type CommentProps = {
  event: IEvent;
  selectedComment: string;
  currentId: string;
  setCurrentId: React.Dispatch<string>;
};

const Comment: React.FC<CommentProps> = ({
  event, selectedComment, currentId, setCurrentId,
}) => {
  const { projectId } = useParams<{ projectId: string; }>();
  const [executeScroll, elRef] = useScroll();
  const [action, setAction] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (action && event.comment.id !== currentId) {
      setAction('');
    }
  }, [action, currentId, event]);

  useEffect(() => {
    if (action && !loading) executeScroll('end');
  }, [action, loading, executeScroll]);

  if (!event.projectMember) return <></>;

  const isUserRemoved = event.projectMember?.isUserRemoved;

  return (
    <HighlightComment
      {...{ ref: elRef }}
      className={`${selectedComment === event.comment.id ? 'selected' : ''} p-1 pl-3`}
      id={event.comment.id}
    >
      <div className='flex items-center justify-between pb-1'>
        <ProfileCard redirect email={isUserRemoved ? '' : event.projectMember.email} leaveDelay={800} projectId={projectId} placement='left-start'>
          <div className='flex items-start p-0.5 pb-[1px] cursor-default transition duration-300 hover:bg-grey-light'>
            <Avatar src={isUserRemoved ? '' : event.projectMember.profile && event.projectMember.profile.picture} style={{ width: '32px', height: '32px' }} />
            <div className='pl-1'>
              <Ellipsis rows={1} className='text-sm'>
                {isUserRemoved ? 'Deleted User' : event.projectMember?.profile?.name}
              </Ellipsis>

              <p className='text-xs'>
                {formatRelativeDate(event.createdAt)}
                {event.comment.editedAt ? ' (Edited)' : ''}
              </p>
            </div>
          </div>
        </ProfileCard>
        {event.projectMember && <ActionsMenu
          creator={event.projectMember.profile?.id || ''}
          eventId={event.comment.id}
          setAction={setAction}
          setCurrentId={setCurrentId}
          setLoading={setLoading} />}
      </div>

      {action !== 'edit'
        && <div className='mb-1 mr-1'>
          <ReadMore
            content={event.comment.message}
            lines={3}
            mixpanelEvent='Comment expanded'
            executeScroll={executeScroll}
          />
        </div>
      }

      {action === 'edit' && <EditComment
        comment={event.comment}
        setAction={setAction}
        setLoading={setLoading} />}
      {action === 'delete' && <DeleteComment
        id={event.comment.id}
        setAction={setAction}
        setLoading={setLoading} />}
      {action === 'copy' && <CopyLink
        id={event.comment.id}
        path={`project/${projectId}/drive/${event.comment.file?.id}`}
        setAction={setAction} />}
    </HighlightComment>
  );
};

export default Comment;

import React from 'react';
import { UserPermissions } from '../../types/graphql';
import { useCheckPermissions } from '../../hooks';

interface IfPermissionsProps {
  scopes: UserPermissions[],
  LoadingComponent?: React.FC<React.PropsWithChildren<unknown>>,
  FallbackComponent?: React.FC<React.PropsWithChildren<unknown>>,
  fallbackProps?: object,
  children: React.ReactNode,
  skipCheck?: boolean,
}

const IfPermissions: React.FC<React.PropsWithChildren<IfPermissionsProps>> = ({
  children,
  scopes,
  skipCheck,
  LoadingComponent = () => null,
  FallbackComponent = () => null,
  fallbackProps,
}) => {
  const [hasPermission, , loading] = useCheckPermissions(scopes);
  if (loading) return <LoadingComponent />;
  if (hasPermission || skipCheck) return <>{children}</>;
  if (fallbackProps && React.isValidElement(children)) {
    return <>{React.cloneElement(children, fallbackProps)}</>;
  }
  return <FallbackComponent />;
};

export default IfPermissions;

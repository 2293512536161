import { useQuery } from '@apollo/client';
import { Link } from '@material-ui/core';
import { useCurrentUser } from 'hooks';
import uniqBy from 'lodash/uniqBy';
import React, { ReactElement } from 'react';
import { AccountLayout, CityIcons, Row } from 'tailwind';
import { License, OrgLicense } from 'types/graphql';
import { extractCityName, sortLicences } from 'utils/helpers';
import FreeTrial from 'views/organisation/billing/components/FreeTrial';
import { GET_ACCOUNT_OVERVIEW } from '../apollo';

const privateSector = 'https://vu.city/tc-private';
const publicSector = 'https://vu.city/tc-public';
const privacyPolicy = 'https://vu.city/legal/privacy';

export default function Overview(): ReactElement {
  const { data, loading, error } = useQuery(GET_ACCOUNT_OVERVIEW);
  const { floating: { hasFloating, floatingTotal } } = useCurrentUser();

  const userLicenses = data?.currentUser?.cityLicenses || [];
  const orgSiteLicences = data?.currentUser?.organisation?.siteLicenses || [];
  const userSiteLicences = data?.currentUser?.siteLicenses || [];
  const tier = data?.currentUser?.tier;
  const { trialActive, subscriptions } = data?.currentUser?.organisation || {
    trialActive: null,
    subscriptions: null,
  };

  const remainingProjects = () => {
    if (tier?.slots === 0) {
      return 'Unlimited';
    }
    if (tier?.name === 'Starter' && tier?.slotsRemaining && tier?.slotsRemaining > 0) {
      return tier?.slotsRemaining;
    }
    return '0';
  };

  // eslint-disable-next-line consistent-return
  const activeProjects = () => {
    if (tier?.slots === 0) {
      return `${tier?.slotsUsed} out of unlimited`;
    }
    if (tier?.name === 'Starter' && tier?.slotsRemaining && tier?.slotsRemaining < 1) {
      return '3 out of 3';
    }
    if (tier?.name === 'Starter' && tier?.slotsRemaining && tier?.slotsRemaining > 0) {
      return `${tier?.slotsUsed} out of ${tier?.slots}`;
    }
  };

  // eslint-disable-next-line consistent-return
  const tierDescription = () => {
    if (tier?.name === 'Starter') return 'Collaborate on up to 3 projects.';
    if (tier?.name === 'Floating') return '- Collaborate on unlimited projects.\n- Shared access to VU.CITY 3D platform.';
    if (tier?.name === 'Plus') return 'Collaborate on unlimited projects.';
    if (tier?.name === 'Pro') {
      return '- Collaborate on unlimited projects.\n- Uninterrupted access to VU.CITY 3D platform.';
    }
  };

  // eslint-disable-next-line consistent-return
  const tierName = () => {
    if (tier?.name === 'Floating') return 'Floating access';
    if (tier?.name === 'Pro') return hasFloating ? 'Dedicated Pro' : 'Pro';
    return tier?.name;
  };

  const licences: License[] = userLicenses.length > 0 ? userLicenses.filter(({ status }) => status === 'active' || status === 'trialing')
    .map(({ license }: OrgLicense) => license) : [];
  const sortedLicences = licences && sortLicences(licences);

  const myOrgSiteLicences = orgSiteLicences
    .filter(({ license }) => userSiteLicences
      .some(({ licenseId }) => license.id === licenseId));

  const mySiteLicense: License[] = userLicenses.length > 0 ? myOrgSiteLicences
    .map(({ license }: OrgLicense) => license) : [];
  const mysortedLicences = mySiteLicense && sortLicences(mySiteLicense);

  const hasCityLicenseAccess = tier?.name === 'Pro' || 'Floating';


  return (
    <AccountLayout
      gql={{ loading, error, renderChildren: !!data && !!tier }}
      title='My Account'
    >
      <Row column1="Subscription">
        <div className='flex flex-col gap-1'>
          <div className='flex gap-1 text-base text-grey'>
            <span className='font-medium text-content'>{tierName()}</span>
            {trialActive && subscriptions && (
              <FreeTrial trialEnd={subscriptions[0]?.trial_end} />
            )}
          </div>
          <p className='whitespace-pre-wrap text-grey'>
            {tierDescription()}
          </p>
          {tier?.name === 'Floating' && (
            <p className='text-grey whitespace-break-spaces'>
              <span className='font-medium text-content'>{`(${floatingTotal} x concurrent ${floatingTotal === 1 ? 'user' : 'users'})`}</span>
            </p>
          )}
        </div>
      </Row>

      <Row column1="Projects usage">
        <div className='flex flex-col gap-1 text-grey'>
          {tier?.name === 'Floating' ?
            <p>
              <span className='font-medium text-content'>Unlimited Projects</span>
            </p>
            :
            <>
              <p>
                <span className='font-medium text-content'>{activeProjects()}</span>
                {' '} active projects
              </p>
              <p>
                <span className='font-medium text-content'>{remainingProjects()}</span>
                {' '} remaining projects
              </p>
            </>
          }
        </div>
      </Row>

      {hasCityLicenseAccess && (
        <Row column1="City licences">
          <div className='flex flex-col gap-1 text-grey'>
            <p>
              City licences are shared with your entire organisation and are
              required by Pro members to launch the 3D app for a specific
              city.
            </p>

            {userLicenses?.length > 0
              ? <div className='flex flex-wrap gap-2 mt-3 text-grey'>
                {uniqBy(sortedLicences, 'id').map((licence: License) => (
                  <div className='flex flex-col items-center text-center w-9' key={licence.id}>
                    <CityIcons locationId={licence?.location?.id} size='h-[95px] w-9' />
                    <div className='mt-1 text-xs'>{extractCityName(licence?.stripeProduct?.name)}</div>
                  </div>
                ))}
              </div>
              : <p className='text-grey'>You currently do not have any licences.</p>}
          </div>
        </Row>
      )}

      {tier?.name === 'Pro' && mySiteLicense.length > 0 && (
        <Row column1="Site licences">
          <div className='flex flex-col gap-1 text-grey'>
            <p>
              Site licences are assigned to specific members and are required by
              Pro members to launch the 3D app for projects in the specific site.
            </p>

            <div className='flex flex-wrap gap-2 mt-3 text-grey'>
              {uniqBy(mysortedLicences, 'id').map((licence: License) => (
                <div className='flex flex-col text-center' key={licence.id}>
                  <CityIcons locationId={licence?.location.id} size='h-[95px] w-[72px]' />
                  <div className='mt-1 text-xs'>{licence?.name}</div>
                </div>
              ))}
            </div>
          </div>
        </Row>
      )}

      <Row column1="Agreements">
        <p className='text-grey'>
          View the VU.CITY Terms and Conditions for: <Link target="_blank" href={privateSector}>Private Sector</Link>, {' '}
          <Link target="_blank" href={publicSector}>Public Sector</Link>, {' '}
          <Link target="_blank" href={privacyPolicy}>Privacy Policy</Link>.
        </p>
      </Row>
    </AccountLayout>
  );
}

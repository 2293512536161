import { useAuth0 } from '@auth0/auth0-react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { Avatar, Box } from '@material-ui/core';
import { KeyIcon, SuccessFilledIcon, theme } from '@vucity/design_system';
import LogoDark from 'assets/VU.CITY-Dark.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/FolderIcon.svg';
import Thumbnails from 'components/Drive/Thumbnails/Thumbnails';
import ProfileCard from 'components/ProfileCard/ProfileCard';
import copy from 'copy';
import { useCheckPermissions, useCloudinary } from 'hooks';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { FileNameEditor, Label } from 'tailwind';
import { OrgMemberRole, ProjectMemberRole, UserSiteLicense } from 'types/graphql';
import { bytesToSize } from 'utils/helpers';
import { MultiToggleOption, NameFormatterProps, SiteLicensesFormatter } from 'views/types';
import UpgradeModal from '../../Modal/Upgrade/Upgrade';
import KebabMenu from './KebabMenu';
import OrgRoleDropdown from './OrgRoleDropdown';
import OrgSubDropdown from './OrgSubDropdown';
import RoleSelector from './RoleSelector';
import ToggleDropdown from './ToggleDropdown';

interface IFormatter extends DataTypeProvider.ValueFormatterProps { }
interface IDataProps extends DataTypeProviderProps {
  myRole?: ProjectMemberRole;
  projectId?: string;
  driveType?: 'personal' | 'project';
  setIsUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  licenses?: MultiToggleOption[];
  userAssigned?: boolean;
  currentUser?: {
    id: string;
    role?: OrgMemberRole;
  };
  siteLicenses?: {
    memberId: string;
    siteLicenses: UserSiteLicense[];
  }[];
}

interface EditRoleProps {
  memberId: string;
  role: ProjectMemberRole;
  name: string;
}

interface IRoleFormatter extends IFormatter {
  value: [ProjectMemberRole, (props: EditRoleProps) => void];
  myRole?: ProjectMemberRole;
}

const StyledAvatar = styled(Avatar)`
  ${() => `
    max-width: 100%;
    width: 40px;
    height: 40px;
    background: ${theme.palette.primary.lighter};
    font-size: ${theme.typography.body1.fontSize};
    border-radius: 50%;
    overflow: hidden;

    & img {
      padding: 0;
      object-fit: cover;
    }
  `}
`;

const iconStyle = {
  width: '20px',
  height: '20px',
  marginRight: '10px',
};

const folderIcon = {
  minWidth: '22px',
  maxWidth: '22px',
  height: '22px',
  marginRight: '8px',
};

const ImageFormatter = ({ value }: IFormatter) => {
  const [cld, token] = useCloudinary();

  return (
    <div className='flex items-center justify-center w-10 h-5 overflow-hidden'>
      {value ? (
        <img className='w-full bg-transparent' src={`${cld.image(value).resize(scale().width(100)).toURL()}&${token}`} alt="Project thumbnail" />
      ) : (
        <img className='h-full bg-transparent' src={LogoDark} alt="Thumbnail placeholder" />
      )}
    </div>
  );
};
const ProjectRoleFormatter = ({ value: [value, openModal], row, myRole }: IRoleFormatter) => {
  const { user } = useAuth0();
  const handleChange = useCallback(
    (role: ProjectMemberRole) => openModal({
      memberId: row.id,
      name: row.name[0],
      role,
    }),
    [openModal, row],
  );
  const [hasPermission] = useCheckPermissions(['PROJECT_MEMBER_EDIT']);
  const isSelf = row.email === user?.email;

  if (!hasPermission || isSelf || !openModal) {
    return <>{copy.project.roles[value]}</>;
  }

  if (value === 'ADMIN' && myRole === 'EDITOR') {
    return <>{copy.project.roles[value]}</>;
  }

  return <RoleSelector onChange={handleChange} value={value} myRole={myRole} />;
};

const StatusFormatter = ({ value, row: { role } }: IFormatter) => {
  const container = (children: React.ReactNode) => (
    <Box display="flex" alignItems="center">
      {children}
    </Box>
  );

  switch (value) {
    case 'INVITED':
      return container(
        <>
          <KeyIcon htmlColor={theme.palette.text.primary} style={iconStyle} /> Invite Pending
        </>,
      );
    case 'ACCEPTED':
      return container(
        <>
          <SuccessFilledIcon color="primary" style={iconStyle} /> Active
        </>,
      );
    case 'ARCHIVED':
      return container(<>Archived</>);
    case 'AVAILABLE_TO_ORG_ADMINS':
      return container(
        <>
          <KeyIcon htmlColor={theme.palette.text.primary} style={iconStyle} /> Org Admins Only
        </>,
      );
    case 'LOCKED':
      return (
        <div className='flex items-center text-content'>
          <UpgradeModal /> {role === 'ADMIN' ? 'Limited' : 'Locked'}
        </div>
      );
    default:
      return <></>;
  }
};

const NameFormatter = ({ row, value, myRole }: NameFormatterProps) => {
  return (
    <div className='flex items-center h-3 max-w-full overflow-hidden' data-row-id={row.id}>
      {row.folder && <FolderIcon style={folderIcon} />}

      {!row.folder && <Thumbnails file={value.thumbnail} />}
      <FileNameEditor
        editingId='_filename'
        initialContent={value.filename}
        nodeId={row.id}
        type={row.__typename}
        readonly={myRole === 'VIEWER'}
        whitelist={row.whitelist}
      />

      {value.locked && (
        <Label>Primary</Label>
      )}
    </div>
  );
};



const NameFormatterNoCard = ({ value }: IFormatter) => {
  const onlyEmail = value[0] === value[1];
  const variant = onlyEmail ? 'text-sm' : 'text-xs';

  return (
    <div className='flex items-center gap-3'>
      <StyledAvatar src={value[3]} alt="avatar" />
      <div className='flex flex-col'>
        {!onlyEmail && (
          <p>{value[0]}</p>
        )}
        <p className={variant}>{value[1]}</p>
      </div>
    </div>
  );
};

const UserNameFormatter = (props: IFormatter) => {
  const { value } = props;

  return (
    <ProfileCard projectId={value[2]} email={value[1]} leaveDelay={400}>
      <div>
        <NameFormatterNoCard {...props} />
      </div>
    </ProfileCard>
  );
};

const SizeFormatter = ({ row, value }: IFormatter) => <>{row.size ? bytesToSize(value) : ''}</>;
const DateFormatter = ({ row, value }: IFormatter) => (row.createdAt ? value.toLocaleString('en-GB') : '');

const LicensesFormatter = ({
  row,
  licenses,
  currentUser,
  userAssigned,
  siteLicenses,
}: SiteLicensesFormatter) => <ToggleDropdown
    row={row}
    licenses={licenses}
    currentUser={currentUser}
    userAssigned={userAssigned}
    siteLicenses={siteLicenses}
  />;

export const DataProvider = (
  props: IDataProps,
  node: ({ value }: IFormatter) => JSX.Element,
) => <DataTypeProvider formatterComponent={node} {...props} />;

export const DateProvider = (props: IDataProps) => DataProvider(props, DateFormatter);
export const SizeProvider = (props: IDataProps) => DataProvider(props, SizeFormatter);
export const NameProvider = (customProps: IDataProps) => <DataTypeProvider
  for={customProps.for}
  formatterComponent={(props) => <NameFormatter {...{ ...props, ...customProps }} />}
/>;
export const UserNameProvider = (props: IDataProps) => DataProvider(props, UserNameFormatter);
export const NameProviderNoCard = (props: IDataProps) => DataProvider(props, NameFormatterNoCard);
export const StatusProvider = (props: IDataProps) => DataProvider(props, StatusFormatter);
export const ImageProvider = (props: IDataProps) => DataProvider(props, ImageFormatter);
export const DropdownProvider = (props: IDataProps) => DataProvider(props, OrgRoleDropdown);
export const ActionProvider = (props: IDataProps) => DataProvider(props, KebabMenu);
export const DropdownSubProvider = (props: IDataProps) => DataProvider(props, OrgSubDropdown);
export const ProjectRoleProvider = (customProps: IDataProps) => <DataTypeProvider
  for={customProps.for}
  formatterComponent={(props) => <ProjectRoleFormatter {...{ ...props, ...customProps }} />}
/>;

export const ToggleDropdownProvider = (customProps: IDataProps) => <DataTypeProvider
  for={customProps.for}
  formatterComponent={(props) => <LicensesFormatter {...{ ...props, ...customProps }} />}
/>;

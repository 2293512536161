import { palette } from '../utils';

export const checkbox = {
  root: {
    color: palette.primary.main,

    '&&[class*="TableSelect"]': {
      color: palette.primary.main,
    },

    '& .MuiIconButton-label .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
  },
};

export default checkbox;

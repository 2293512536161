import React from 'react';
import { ProjectMemberRole } from 'types/graphql';
import { DateProvider, NameProvider, SizeProvider } from '../../DataGrid';
import DataGrid, { IGridProps } from '../../DataGrid/DataGrid';
import { IFileRow } from '../types';

interface DriveGripProps extends IGridProps {
  projectId?: string;
  driveType?: 'personal' | 'project';
  setIsUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  role?: ProjectMemberRole;
  onShiftSelect: (arg: string, sortedRows: IFileRow[]) => void;
}

const DriveGrid = ({
  children, driveType, projectId, setIsUpdated, role, ...props
}: DriveGripProps) => (
  <DataGrid {...props}>
    {children}
    <NameProvider for={['name']}
      projectId={projectId}
      driveType={driveType}
      setIsUpdated={setIsUpdated}
      myRole={role}
    />
    <DateProvider for={['createdAt']} />
    <SizeProvider for={['size']} />
  </DataGrid>
);

export default DriveGrid;

import styled from 'styled-components';

export const Wrapper = styled.div`
  .MuiOutlinedInput-root {
    margin-bottom: 0;
  }

  #filter-createdBy,
  #sort-projects {
    min-width: 200px;
    width: 100%;
    padding: 8px 12px;
  }

  &#filter_sort #filter-createdBy {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  #sort-projects {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export default Wrapper;

import { useMutation } from '@apollo/client';
import React, { ReactElement, useEffect, useState } from 'react';

import { useHandleError, useMixpanel } from 'hooks';
import { Comment as IComment } from 'types/graphql';
import { getUniqueMentionIds } from 'utils/helpers';

import { Button } from 'tailwind';
import { EDIT_COMMENT } from '../apollo';
import Mentions from '../Mentions/Mentions';

interface Props {
  comment: IComment;
  setAction: React.Dispatch<string>;
  setLoading: React.Dispatch<boolean>;
}

function EditComment({ comment, setAction, setLoading }: Props): ReactElement {
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();
  const [dirty, setDirty] = useState(false);
  const [value, setValue] = useState(comment.message);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setLoading(false), []);

  const [editComment] = useMutation(EDIT_COMMENT, {
    onCompleted: () => enqueueSnackbar('This comment has been edited', { variant: 'success' }),
    onError: handleError,
  });

  const editHandler = () => {
    const mentions = getUniqueMentionIds(value);
    editComment({
      variables: {
        commentId: comment.id,
        message: value,
        mentions,
      },
    });
    mixpanelTrack('Confirm Edit');
    if (mentions.length > 0) {
      mixpanelTrack('Mentioned Members on edit comment', { mentioned: mentions });
    }
    setAction('');
  };

  const cancelHandler = () => {
    mixpanelTrack('Cancel Edit');
    setAction('');
  };

  return (
    <div className='mt-1 mb-2'>
      <Mentions
        value={value}
        singleLine={false}
        aria-label="Edit comment"
        placeholder="Edit comment"
        onChange={(e) => {
          setValue(e.target.value);
          setDirty(true);
        }}
      />
      <div className='flex justify-between mt-1'>
        <Button
          size='xs' variant='text' color="content" padding='none'
          onClick={cancelHandler}
        >
          Cancel
        </Button>

        <Button
          size='xs'
          variant="contained"
          disabled={!dirty || !value}
          onClick={editHandler}
        >
          Update comment
        </Button>
      </div>
    </div>
  );
}

export default EditComment;

import { gql } from '@apollo/client';

export const GET_ORG_FLOATING = gql`
  query orgFloatingLicence {
    currentUser {
      organisation {
        seatStats {
          floating {
            total
          }
        }
      }
    }
  }
`;
import { useLocalStorageState } from 'ahooks';
import DetailedSelect from 'components/DetailedSelect';
import { useMixpanel } from 'hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { File, Folder } from 'types/graphql';
import { lowerCaseFilter } from 'utils/helpers';
import { IDriveFilesData } from 'views/types';
import SearchInput from './SearchInput';
import { SearchPlace } from './SearchInput.style';

interface Props {
  setDrive: React.Dispatch<IDriveFilesData>;
  data: Folder[];
  driveType: string;
  setSearch: React.Dispatch<boolean>[];
}

const recurseSearch = (data: Folder, search: string): IDriveFilesData => {
  let result: IDriveFilesData = { files: [], folders: [] };

  if (data.folders?.length > 0) {
    data.folders.forEach((folder) => {
      const res = recurseSearch(folder, search);
      result = {
        folders: [...res.folders, ...result.folders],
        files: [...res.files, ...result.files],
      };
    });
  }

  const folders = data.folders?.filter(
    (folder: Folder) => folder.name && lowerCaseFilter(folder.name, search),
  ) || [];
  const files = data.files.filter(
    (file: File) => file.name && lowerCaseFilter(file.name, search),
  );

  return {
    folders: [...folders, ...result.folders],
    files: [...files, ...result.files],
  };
};

export default function Search({
  setDrive,
  setSearch,
  data,
  driveType,
}: Props): ReactElement {
  const [searchStr, setSearchStr] = useState('');
  const { mixpanelTrack } = useMixpanel();
  const [dirty, setDirty] = useState(false);
  const [searchFolder, setSearchFolder] = useState<string>('1'); // default is current folder
  const [resultCount, setResultCount] = useState([0, 0]);
  const [timeStarted, setTimeStarted] = useState<Date>(new Date());
  const [setSearchLoading, setSearchResults, setSearched] = setSearch;
  const [drive, folder] = data;
  const { params } = useRouteMatch<{ projectId?: string; }>();

  const [searchRecents, setSearchRecents] = useLocalStorageState<any[]>(
    `searchRecents ${params.projectId || 'personal'}`,
    { defaultValue: [] },
  );

  useEffect(() => setSearchStr(''), [folder]);

  useEffect(() => {
    if (searchStr.length > 0) setSearchLoading(true);
    setTimeStarted(new Date());

    const delay = setTimeout(() => {
      if (searchStr) {
        setDirty(true);
        const searchResult = recurseSearch(
          searchFolder === '1' ? folder : drive,
          searchStr,
        );
        setSearchResults(
          searchResult.files.length > 0 || searchResult.folders.length > 0,
        );
        setResultCount([
          searchResult.files.length,
          searchResult.folders.length,
        ]);
        setDrive(searchResult);
      } else if (dirty) {
        setSearchResults(true);
        setResultCount([folder.files.length, folder.folders.length]);
        setDrive({ files: [...folder.files], folders: [...folder.folders] });
      }

      setSearchLoading(false);
    }, 500);

    return () => clearTimeout(delay);
  }, [searchFolder, searchStr, folder, drive]);

  const searchEndHandle = (fromRecents: boolean) => {
    const isFolder = searchFolder === '1';

    setSearched(true);
    mixpanelTrack(`Searched in ${isFolder ? 'folder' : 'drive'}`, {
      searchFilesCount: resultCount[0],
      searchFoldersCount: resultCount[1],
      searchResultsCount: resultCount[0] + resultCount[1],
      searchInputLength: searchStr.length,
      searchedIn: isFolder ? `${driveType} Folder` : driveType,
      searchedTimeInSeconds: new Date().getSeconds() - timeStarted.getSeconds(),
      fromRecents,
    });
  };

  const options = useMemo(() => ([
    { value: '0', title: driveType },
    { value: '1', title: 'This folder' }]
  ), [driveType]);

  return (
    <div className='relative flex items-start flex-grow'>
      <div className='w-full max-w-330'>
        <SearchInput
          placeholder={`Search ${driveType.toLowerCase()}`}
          storingHandle={[searchRecents, setSearchRecents]}
          searchHandle={[searchStr, setSearchStr]}
          searchEndHandle={searchEndHandle}
        />
      </div>

      <SearchPlace>
        <DetailedSelect
          id='searchPlace'
          variant="outlined"
          value={searchFolder}
          items={options}
          handleChange={(e) => setSearchFolder(`${e?.value}`)}
        />
      </SearchPlace>
    </div>
  );
}

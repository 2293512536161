const placeholderVisible = {
  opacity: '1',
};

export const inputBase = {
  input: {
    // Show and hide the placeholder logic
    'label[data-shrink=false] + $formControl &': {
      '&::-webkit-input-placeholder': placeholderVisible,
      '&::-moz-placeholder': placeholderVisible,
      // Firefox 19+
      '&:-ms-input-placeholder': placeholderVisible,
      // IE 11
      '&::-ms-input-placeholder': placeholderVisible,
      // Edge
      '&:focus::-webkit-input-placeholder': placeholderVisible,
      '&:focus::-moz-placeholder': placeholderVisible,
      // Firefox 19+
      '&:focus:-ms-input-placeholder': placeholderVisible,
      // IE 11
      '&:focus::-ms-input-placeholder': placeholderVisible, // Edge
    },
  },
};

export default inputBase;

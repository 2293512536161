import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import { AddIcon } from '@vucity/design_system';
import LoadingBar from 'components/Loading/LoadingBar';
import { useCheckPermissions, useHandleError, useMixpanel } from 'hooks';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'tailwind';
import { getUniqueMentionIds } from 'utils/helpers';
import { ADD_COMMENT } from '../apollo';
import Mentions from '../Mentions/Mentions';
import { AddUserButton, AddUserOption } from './AddComment.style';

interface IAddCommentProps {
  projectId: string;
  fileId: string;
  onScrollToBottom?: () => void;
}

const AddComment: React.FC<IAddCommentProps> = ({
  projectId,
  fileId,
  onScrollToBottom,
}) => {
  const { mixpanelTrack } = useMixpanel();
  const [handleError] = useHandleError();

  const [touched, setTouched] = useState(false);
  const [comment, setComment] = useState('');
  const [hasPermission] = useCheckPermissions(['PROJECT_MEMBER_INVITE']);

  const onResetComment = () => {
    setTouched(false);
    setComment('');
  };

  const [addComment, { loading }] = useMutation(ADD_COMMENT, {
    onCompleted: (res) => {
      if (!res.addComment.file.id) return null;

      if (onScrollToBottom) onScrollToBottom();
      return onResetComment();
    },
    onError: handleError,
  });

  const onPostComment = () => {
    const mentions = getUniqueMentionIds(comment);
    mixpanelTrack('Posted a comment', {
      'Comment length': comment.length,
      'User mentioned': mentions.length > 0 ? mentions : 0,
    });
    addComment({
      variables: {
        projectId,
        fileId,
        message: comment.trim(),
        mentions,
      },
    });
    if (mentions.length > 0) {
      mixpanelTrack('Mentioned Members on new comment', {
        mentioned: mentions,
      });
    }
  };

  return (
    <div className='pt-1'>
      <Mentions
        id='comment'
        value={comment}
        singleLine={!touched}
        autoFocus={touched}
        aria-label='Leave a comment'
        placeholder={touched ? '' : 'Leave a comment'}
        onClick={(e) => {
          e.preventDefault();
          setTouched(true);
        }}
        onBlur={() => !comment && setTouched(false)}
        onChange={(e) => setComment(e.target.value)}
      />

      {touched && (
        <div className='flex justify-between'>
          {hasPermission
            ? (<Link
              to={`/project/${projectId}/members?mode=invite`}
              target='_blank'
            >
              <AddUserButton
                disabled={!comment || loading}
                startIcon={
                  <AddUserOption className='flex items-center justify-center'>
                    <AddIcon color='primary' />
                  </AddUserOption>
                }
              >
                <Box pl={1} fontSize='14px'>
                  Add a new member
                </Box>
              </AddUserButton>
            </Link>) : <div />
          }

          <Button
            size='xs'
            variant='contained'
            disabled={!comment || loading}
            onClick={onPostComment}
          >
            Post comment
          </Button>
        </div>
      )}

      {loading && <LoadingBar />}
    </div>
  );
};

export default AddComment;

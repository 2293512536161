import React from 'react';

interface IRenderActionProps {
  condition: boolean;
  children: React.ReactNode;
}

const RenderAction: React.FC<IRenderActionProps> = ({ condition, children }) => {
  if (condition) return <>{children}</>;

  return null;
};

export default RenderAction;

import { useAuth0 } from '@auth0/auth0-react';
import MagicBell, {
  FloatingNotificationInbox,
  INotification,
  NotificationPreferences,
  useMagicBellEvent,
} from '@magicbell/magicbell-react';
import { Backdrop } from '@material-ui/core';
import { AddCircleIcon, ProfileIcon, theme } from '@vucity/design_system';
import React, { useEffect, useState } from 'react';
import './TopNavButtons.style.css';

import { useCheckPermissions, useCurrentUser, useMixpanel } from 'hooks';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { User } from 'types/graphql';
import { formatRelativeToNow, getInitials } from 'utils/helpers';
import FreeTrial from 'views/organisation/billing/components/FreeTrial';
import { MEMBERS_INVITED_NOTIFICATION, ORG_MEMBERS_INVITED_NOTIFICATION, SUBSCRIPTION_UPDATED } from 'views/organisation/members/constants';

import { GQLWrapper, Tooltip } from 'components';
import { Button } from 'tailwind';
import NotificationItem from './Notification/NotificationItem';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';
import { HelpButton, NotificationBellIcon, Settings, StyledAvatar, magicBellTheme } from './TopNavButtons.style';

interface TopBarProps {
  user: User;
}

const TopNavButtons = ({ user }: TopBarProps) => {
  const history = useHistory();
  const { projectId: id } = useParams<{ projectId?: string; }>();
  const { pathname } = useLocation();
  const { user: auth0User, logout } = useAuth0();
  const { mixpanelTrack } = useMixpanel();
  const { setInviteModal, setUpgradeModal } = useCurrentUser();

  const [hasPermission] = useCheckPermissions(['PROJECT_MEMBER_INVITE']);

  const [openProfile, setOpenProfile] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);

  const logNotificationChange = (data: unknown, title: string) => {
    const { category, sanitizedContent } = data as INotification;
    mixpanelTrack(title, {
      'Notification category': category,
      'Notification Content': sanitizedContent,
    });
  };

  useMagicBellEvent('notifications.read', (data) => logNotificationChange(data, 'Notification Marked as read'));
  useMagicBellEvent('notifications.delete', (data) => logNotificationChange(data, 'Notification deleted'));

  const { trialActive, trialAvailable } = user?.organisation ?? {};

  useEffect(() => {
    history.listen(() => {
      if (openProfile) setOpenProfile(false);
    });
  }, [openProfile, setOpenProfile, history]);

  const renderAvatar = () => {
    if (!auth0User) {
      return (
        <ProfileIcon
          fontSize="inherit"
          htmlColor={theme.palette.text.primary}
        />
      );
    }

    return (
      <StyledAvatar $isActive={openProfile} alt="Аvatar" src={user.picture}>
        {getInitials(user.name)}
      </StyledAvatar>
    );
  };

  const handleHelpClick = () => mixpanelTrack('Help & Support Click', { page: history.location.pathname });

  const handleProfileClick = () => {
    setOpenProfile(!openProfile);
    mixpanelTrack('Top Menu > Profile Click', { page: history.location.pathname });
  };

  const handleOrgSettingsClick = () => {
    history.push('/account/organisation/details');
    mixpanelTrack('Top Menu > Settings Click', { page: history.location.pathname });
  };

  const canShowFreeTrialOffer = () => {
    if (user.organisation?.me?.role !== 'ADMIN') return false;
    return trialAvailable && !trialActive;
  };

  const handleNotificationClick = (notification: INotification) => {
    const { actionUrl, category, sanitizedContent, sentAt, customAttributes } = notification;

    mixpanelTrack('Notification clicked', {
      'Notification Content': sanitizedContent,
      Category: category,
      'Time triggered': formatRelativeToNow(sentAt?.toISOString() as string),
    });

    if (actionUrl) window.open(actionUrl);
    if (customAttributes) {
      const { projectId } = (customAttributes as any);
      const queryParam = `?rsvpProjectId=${projectId}`;
      const project = user.projects.find((proj) => proj.id === projectId);

      const isHome = pathname === '/';

      switch (category) {
        case ORG_MEMBERS_INVITED_NOTIFICATION: {
          return history.push('/account/organisation/invitations');
        }
        case SUBSCRIPTION_UPDATED: {
          return history.push('/account/overview');
        }
        case MEMBERS_INVITED_NOTIFICATION: {
          if (isHome && project?.me?.status === 'INVITED') {
            setInviteModal(true);
          }
          if (isHome && project?.me?.status === 'LOCKED') {
            setUpgradeModal(true);
          }
          return history.push(`/${queryParam}`);
        }
        default: return false;
      }
    }
  };

  const handleToggle = (isOpen: boolean) => {
    mixpanelTrack('Top Menu > Notifications Click');
    setNotificationOpen(!isOpen);
  };

  return (
    <GQLWrapper renderChildren={!!user}>
      <div className='flex items-center justify-end w-full h-full gap-2'>
        {canShowFreeTrialOffer() && (
          <Button
            size='md'
            padding='sm'
            variant='contained'
            className='max-[990px]:hidden'
            onClick={() => {
              history.push('/contact', { trial: true, prevPath: pathname });
              mixpanelTrack('Start free 7 day trial - Home page', {
                role: user.organisation?.me?.role,
              });
            }}
          >
            Start free trial
          </Button>
        )}

        {trialActive &&
          <div className='max-[700px]:hidden'>
            <FreeTrial trialEnd={user.organisation?.subscriptions[0].trial_end} />
          </div>
        }

        {(!id || !hasPermission) ? <></> : (
          <Button
            size='md'
            padding='sm'
            onClick={() => {
              history.push(`/project/${id}/members?mode=invite`);
            }}
            startIcon={<AddCircleIcon />}
          >
            New members
          </Button>
        )}

        <Tooltip
          header='Help & support'
          enterDelay={1000}
          placement="bottom-start"
          id='help-button'
        >
          <HelpButton
            className='w-3 h-3 transition duration-300 cursor-pointer bg-primary hover:bg-primary-light support'
            target="_blank"
            type="button"
            href="https://kb.vu.city/home/"
            onClick={handleHelpClick}
            rel="noopener"
          />
        </Tooltip>

        <MagicBell
          apiKey={(user as User).magicbellKey}
          userEmail={user.email}
          theme={magicBellTheme}
          BellIcon={<NotificationBellIcon open={notificationOpen} />}
          onToggle={handleToggle}
        >
          {(props) => (
            <FloatingNotificationInbox
              onNotificationClick={handleNotificationClick}
              NotificationItem={NotificationItem}
              height={450}
              NotificationPreferences={() => <NotificationPreferences />}
              closeOnNotificationClick={false}
              {...props}
            />
          )}
        </MagicBell>

        <Settings
          className='w-3 h-3 transition duration-300 cursor-pointer bg-primary hover:bg-primary-light'
          target="_blank"
          type="button"
          onClick={handleOrgSettingsClick}
          rel="noopener"
          $isActive={pathname.includes('organisation/details')}
        />

        <div className='relative ml-1' onClick={handleProfileClick}>
          {renderAvatar()}

          {openProfile && (
            <ProfileDropdown
              onLogout={logout}
              tier={user.tier}
            />
          )}
        </div>
      </div>

      <Backdrop
        style={{ zIndex: 1 }}
        invisible
        open={openProfile}
        onClick={() => setOpenProfile(false)}
      />
    </GQLWrapper>
  );
};

export default TopNavButtons;

import MuiBox from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import styled from 'styled-components';

export const AddUserOption = styled(MuiBox)`
  width: 22px;
  height: 22px;
  text-align: center;
  border: 1px solid #375675;
  border-radius: 50%;

  & svg {
    width: 7px;
    height: 100%;
  }
`;

export const AddUserButton = styled(MuiButton)`
  padding: 0;
  font-weight: normal;

  &:hover {
    background-color: transparent;
  }

  &:disabled ${AddUserOption} {
    border: 1px solid gray;

    & svg {
      fill: gray;
    }
  }

  & .MuiButton-startIcon {
    margin: 0;
  }
`;

import { gql } from '@apollo/client';

const GET_FILE = gql`
  query getNodeSignedUrl($fileId: ID!) {
    currentUser {
      id
      drive {
        id
        nodeByID(id: $fileId) {
          ... on File {
            siblingFiles {
              id
              name
              path
              createdAt
              creator {
                name
                email
              }
              ...on File {
                isUserRemoved
                signedUrl
                mimeType
                size
                annotations
                metadata {
                  camera {
                    resolution {
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ANNOTATIONS = gql`
  mutation updateAnnotations($node: NodeInput!, $projectId: ID, $annotations: JSON) {
    addAnnotations(node: $node, projectId: $projectId, annotations: $annotations) {
      name
      id
      annotations
    }
  }
`;

export default GET_FILE;

import { useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import GQLWrapper from 'components/GQLWrapper/GQLWrapper';
import React, { useEffect, useState } from 'react';
import { User } from 'types/graphql';
import { GET_CURRENT_USER, VERIFY_PROJECT_ID } from 'utils/apollo/user';

import { useQueryParam } from 'hooks';
import useFloating from 'hooks/useFloating';
import InvitationLockModal from './InvitationLockModal';
import ProjectInviteModal from './ProjectInviteModal';

interface UserContextState {
  currentUser: User;
  floating: { hasFloating: boolean, floatingTotal: number, defaultTier: (initTier?: string) => string | undefined; };
  setInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUpgradeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserContext = React.createContext({} as UserContextState);

const UserProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [inviteModal, setInviteModal] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [activeProject, setActiveProject] = useState(null);

  const query = useQueryParam();
  const projectId = query.get('rsvpProjectId') || '';

  const { user: auth0User } = useAuth0();

  const [checkProjectId] = useLazyQuery(VERIFY_PROJECT_ID, {
    variables: { projectId },
  });

  const [getCurrentUser, { data: user, called }] = useLazyQuery(GET_CURRENT_USER);
  const { hasFloating, floatingTotal, defaultTier } = useFloating();

  useEffect(() => {
    if (!called) {
      getCurrentUser();
    }
  }, [called, getCurrentUser]);

  useEffect(() => {
    if (user && user.currentUser && projectId) {
      checkProjectId({
        onCompleted: (data) => {
          const userProjects = [...user.currentUser.projects];
          const project = userProjects.find((proj) => proj.id === projectId);
          setActiveProject(project);
          if (data.currentUser.projectIsValid
            && auth0User?.email_verified) {
            const status = project?.me?.status;
            if (status === 'INVITED') setInviteModal(true);
            if (status === 'LOCKED') setUpgradeModal(true);
          }
        },
      });
    }
  }, [auth0User, checkProjectId, projectId, user]);

  return (
    <GQLWrapper renderChildren={!!user}>
      <UserContext.Provider
        value={{
          currentUser: user?.currentUser,
          floating: { hasFloating, floatingTotal, defaultTier },
          setInviteModal,
          setUpgradeModal,
        }}
      >

        {children}

        {user?.currentUser && (
          <>
            <ProjectInviteModal
              inviteModal={inviteModal}
              setInviteModal={setInviteModal}
              tier={user?.currentUser?.tier}
              projectId={projectId as string}
              project={activeProject}
            />
            <InvitationLockModal
              upgradeModal={upgradeModal}
              setUpgradeModal={setUpgradeModal}
            />
          </>
        )}
      </UserContext.Provider>
    </GQLWrapper>
  );
};

export default UserProvider;

import { IntegratedSorting, Sorting, SortingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  GridProps,
  Table,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Checkbox } from '@material-ui/core';
import { DriveSelectedFilesContext } from 'components/Drive/DriveProvider/DriveProvider';
import { IFileRow } from 'components/Drive/types';
import { useMixpanel } from 'hooks';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getExt, nameSort } from 'utils/helpers';
import { SortLabel } from './GridElements';

export type ICompare = string & string[] & { filename: string; } & { name: string; };
export interface IGridProps extends GridProps {
  sorting?: boolean;
  columnWidth?: {
    columnName: string;
    width: string;
  }[];
  defaultSort?: [{ columnName: string; direction: 'asc' | 'desc'; }];
  canBeSelected?: boolean;
  noDataRowComponent?: React.ComponentType<Table.RowProps>;
  customNameSort?: (a: ICompare, b: ICompare) => number;
  children?: React.ReactNode;
  rowComponent?: React.ComponentType<Table.DataRowProps>;
  isVirtualised?: boolean;
  virtualHeight?: string;
  headerCellComponent?: React.ComponentType<TableHeaderRow.ContentProps>;
  tableComponent?: React.ComponentType<object> | undefined;
  selectAllDisabled?: boolean;
  onShiftSelect?: (arg: string, sortedRows: IFileRow[]) => void;
}

interface CheckboxProps {
  selected: boolean;
  sortedRows: IFileRow[];
  row: IFileRow;
  onToggle: (arg: string) => void;
  onShiftSelect?: (arg: string, sortedRows: IFileRow[]) => void;
}

const CustomCheckbox = React.memo(({ sortedRows, row, onToggle, selected, onShiftSelect }: CheckboxProps) => {
  const { mixpanelTrack } = useMixpanel();
  const { id, mimeType, name, folder, creator } = row;

  const handleClick = (event: any) => {
    if (event.shiftKey && onShiftSelect) onShiftSelect(id, sortedRows);
    else onToggle(id);
  };

  return (
    <td
      className="MuiTableCell-root MuiTableCell-body TableCell-cell-62 TableCell-cellNoWrap-66"
      colSpan={1}
      style={{ padding: '0 0 0 20px' }}
    >
      <Checkbox
        checked={selected}
        color='primary'
        style={{ padding: '8px 0' }}
        inputProps={{ style: { padding: '20px' } }}
        onClick={(e) => {
          e.stopPropagation();

          const type = mimeType === 'vucity/project' ? row.mimeType : getExt(name.filename);
          if (!selected) {
            mixpanelTrack('File selected', {
              'File ID': id,
              'File type': folder ? 'folder' : type,
              'File creator': creator,
            });
          }

          handleClick(e);
        }}
      />
    </td>
  );
});

const DataGrid: React.FC<IGridProps> = ({
  isVirtualised,
  virtualHeight,
  rows,
  columns,
  columnWidth,
  defaultSort,
  rowComponent,
  noDataRowComponent,
  canBeSelected,
  customNameSort,
  children,
  sorting,
  headerCellComponent,
  tableComponent,
  selectAllDisabled,
  onShiftSelect,
  ...props
}) => {
  const [sortedRows, setSortedRows] = useState<IFileRow[]>(rows as IFileRow[]);
  const { setRef } = useContext(DriveSelectedFilesContext);
  const vtRef = useRef<any>();

  useEffect(() => {
    if (vtRef.current) {
      setRef(vtRef.current);
    }
  }, [setRef, vtRef]);

  const sortRows = useCallback((sort: Sorting[]) => {
    if (!sort.length) return rows;

    const { columnName, direction } = sort[0] || [];
    const driveRows = [...rows] as IFileRow[];

    switch (columnName) {
      case 'name':
        if (direction === 'asc') return driveRows.sort((a, b) => nameSort(a.name.filename, b.name.filename));
        return driveRows.sort((a, b) => nameSort(b.name.filename, a.name.filename));
      case 'creator':
        if (direction === 'asc') return driveRows.sort((a, b) => nameSort(a.creator, b.creator));
        return driveRows.sort((a, b) => nameSort(b.creator, a.creator));
      case 'location':
        if (direction === 'asc') return driveRows.sort((a, b) => nameSort(a.location, b.location));
        return driveRows.sort((a, b) => nameSort(b.location, a.location));
      case 'date':
        if (direction === 'asc') return driveRows.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        return driveRows.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      case 'size':
        if (direction === 'asc') return driveRows.sort((a, b) => a.size - b.size);
        return driveRows.sort((a, b) => b.size - a.size);
      case 'type':
        if (direction === 'asc') return driveRows.sort((a, b) => nameSort(a.mimeType, b.mimeType));
        return driveRows.sort((a, b) => nameSort(b.mimeType, a.mimeType));
      default:
        return driveRows;
    }
  }, [rows]);

  return (
    <Grid rows={rows} columns={columns} {...props}>
      <SortingState
        defaultSorting={defaultSort}
        columnExtensions={[{ columnName: 'image', sortingEnabled: false }]}
        onSortingChange={(sort) => {
          if (!canBeSelected) return;
          const newSortedRows = sortRows(sort) as IFileRow[];
          setSortedRows(newSortedRows);
        }}
      />
      <IntegratedSorting
        columnExtensions={[{ columnName: 'name', compare: customNameSort }]}
      />

      {isVirtualised
        ? (
          <VirtualTable
            rowComponent={rowComponent}
            columnExtensions={columnWidth}
            noDataRowComponent={noDataRowComponent}
            ref={vtRef}
            height={virtualHeight}
            estimatedRowHeight={41}
            {...tableComponent && { tableComponent }}
          />
        ) : (
          <Table
            rowComponent={rowComponent}
            columnExtensions={columnWidth}
            noDataRowComponent={noDataRowComponent}
          />
        )}

      {children}

      {sorting !== false ? (
        <TableHeaderRow
          contentComponent={headerCellComponent || (({ children: nodes }) => <>{nodes}</>)}
          sortLabelComponent={SortLabel}
          showSortingControls
        />
      ) : (
        <TableHeaderRow
          contentComponent={headerCellComponent || (({ children: nodes }) => <>{nodes}</>)} />
      )}

      {canBeSelected && <TableSelection
        showSelectAll
        headerCellComponent={(headerProps) => (
          <TableSelection.HeaderCell
            {...headerProps}
            {...selectAllDisabled && { disabled: selectAllDisabled, className: 'table--selectall' }}
          />
        )}
        cellComponent={({ row, ...restProps }) => (
          <CustomCheckbox
            {...restProps}
            sortedRows={sortedRows}
            onShiftSelect={onShiftSelect}
            row={row}
          />
        )} />}
    </Grid>
  );
};

export default React.memo(DataGrid);

import DetailedSelect from 'components/DetailedSelect';
import { useMixpanel } from 'hooks';
import React from 'react';
import { Label } from 'tailwind';
import { OrgMemberRole, ProjectAccess, ProjectMemberRole } from 'types/graphql';
import { capitalizeWord } from 'utils/helpers';
import { RadioOption } from 'views/account/desktop/Desktop.style';
import preferenceRoles from './roles';

interface PreferenceOptionProps {
  setting: ProjectAccess;
  isChecked: boolean;
  heading: string;
  subHeading: string;
  handleClick: (setting: ProjectAccess) => void;
  isDefault?: boolean;
  hasDropdown?: boolean;
  defaultRole?: ProjectMemberRole;
  onRoleChange?: (role: ProjectMemberRole) => void;
  isReadonly?: boolean;
  projectRole?: ProjectMemberRole | OrgMemberRole;
}

export default function PreferenceOption(props: PreferenceOptionProps) {
  const {
    handleClick,
    isChecked,
    setting,
    isDefault,
    heading,
    subHeading,
    hasDropdown,
    defaultRole,
    onRoleChange,
    isReadonly,
    projectRole,
  } = props;

  const { mixpanelTrack } = useMixpanel();
  const logToMixpanel = (access: ProjectAccess) => {
    mixpanelTrack(`Projects > Project Info > Project Preferences > ${capitalizeWord(access).replace(/_/g, ' ')} radio button`, {
      'Project Role': projectRole,
    });
  };

  const handleRadioOptionClick = (pref: ProjectAccess, e: React.MouseEvent) => {
    // prevent clicking beyond the input element
    if (isReadonly) return;
    if (!(e.target as HTMLElement).classList.contains('__radio-option')) return;
    handleClick(pref);
    logToMixpanel(pref);
  };

  const handleRoleChange = (role: ProjectMemberRole) => {
    mixpanelTrack(`Projects > Project Info > Project Preferences > ${capitalizeWord(setting)} Role selection`, {
      'Project role set': role,
    });
    onRoleChange?.(role);
  };

  return (
    <div>
      <RadioOption
        onClick={handleRadioOptionClick.bind(null, setting)}
        disabled={isReadonly}
      >
        <input type="radio" name='preference' value='Invite only' checked={isChecked} disabled={isReadonly} />
        <label className='__radio-option' htmlFor={setting}>{heading} {!!isDefault && <Label className='ml-1'>Default</Label>}</label>

        <p className='whitespace-pre-line text-grey ml-[28px]'>
          {subHeading}
        </p>

        {!!hasDropdown && (
          <div
            className='mt-1 ml-[28px] p-2 bg-primary-lightest w-330 max-w-full flex flex-col rounded border border-placeholder'
            onClick={(e) => e.stopPropagation()}
          >
            <p className='mb-1 text-sm'>Set the default role for a project member</p>
            <DetailedSelect
              value={defaultRole}
              items={preferenceRoles}
              variant="outlined"
              handleChange={(item) => handleRoleChange(item?.value as ProjectMemberRole)}
              isReadOnly={isReadonly}
            />
          </div>
        )}
      </RadioOption>
    </div>
  );
}

import React, { ReactElement, useEffect } from 'react';
import { ErrorLayout } from 'tailwind';
import { useMixpanel } from '../../hooks';

export default function InsufficientAccess(): ReactElement {
  const { mixpanelTrack } = useMixpanel();

  useEffect(() => {
    mixpanelTrack('You don’t have access to this page');
  }, [mixpanelTrack]);

  return (
    <ErrorLayout
      title='You don’t have access to this page'
      message='Your access role does not have permissions to view this page.'
      withHeader={false} />
  );
}

import React from 'react';

interface IBackdropProps {
  handleMouseClick: React.MouseEventHandler<HTMLDivElement>;
}

const DriveBackdrop: React.FC<IBackdropProps> = ({ handleMouseClick }) => (
  <>
    {/* Since PagingPanel can't be changed directly,
      added a div on top to disable default browser context menu
      and only leave the paging buttons interactable */}
    <tfoot
      className='absolute block w-full pb-5'
      style={{ height: '-webkit-fill-available' }}
      onContextMenu={handleMouseClick}
    />
  </>
);

export default DriveBackdrop;

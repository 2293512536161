import { Popper, PopperProps } from '@material-ui/core';
import MuiPaper from '@material-ui/core/Paper';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import styled from 'styled-components';

const PopperComponent = ({ style, ...props }: PopperProps) => <Popper {...props} style={{ ...style, height: '0', zIndex: 10001 }} placement="bottom" />;

const PaperComponent = styled(MuiPaper)`
  border-top: 0;
  border-left: 1px solid #004e63;
  border-right: 1px solid #004e63;
  
  & li:hover{
    background-color:#E6EDEF;
  }

  .MuiAutocomplete-option:last-child {
    border-bottom: 1px solid #004e63;
  }
`;

export default styled(Autocomplete).attrs({ PaperComponent, PopperComponent, ListboxProps: { style: { maxHeight: '20vh' } } })`
  .MuiInputBase-root {
    padding: 13px 9px;
  }
` as typeof Autocomplete;

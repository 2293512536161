import { InfoInactive } from '@vucity/design_system';
import { Tooltip } from 'components';
import React from 'react';
import { TierStats } from 'types/graphql';
import { subType } from '../text';
import CitiesTable from './CitiesTable';
import FreeTrial from './FreeTrial';
import SubscriptionsTable from './SubscriptionsTable';

interface ISubsProps {
  subs: any;
  hasFloating?: boolean;
  floating?: TierStats;
  trial?: boolean;
}

const floatingTooltip = `This is a licence purchase for your entire organisation. It enables assigned members to share access to work within the VU.CITY 3D platform. Each seat enables access on a one-at-a-time basis. \n 
'Pro Concurrency' is the number of shared access seats, which can be concurrently used by members of your organisation. \n
For example, 2 x Pro Concurrency means your organisation has purchased the ability for up to two members to be using the VU.CITY 3D platform at the same time. \n
Once the seats become available again, other members can then use those 'Floating' seats instead`;

const Subscriptions = ({ subs, hasFloating, floating, trial }: ISubsProps) => {
  let formatSubs: any[] = [];
  if (subs && subs?.stats && subs?.stats?.__typename) {
    const { stats: { __typename, ...rest } } = subs;

    formatSubs = Object.keys(rest).filter(val => val !== 'floating').map((val) => ({
      type: val,
      total: rest[val].total,
      assigned: rest[val].assigned,
    })).filter((sub) => hasFloating ? sub.type !== 'plus' : sub);
  }

  return (
    <div className='flex w-full mt-4 lg:max-w-[50%] lg:min-w-[50%]'>
      <div className='flex flex-col w-full'>
        <div className='flex items-center gap-1'>
          <p className='text-lg font-medium'>
            {subs?.interval === 'month' ? subType('Monthly') : subType('Annual')}
          </p>
          {trial && <FreeTrial trialEnd={subs?.trial_end} />}
        </div>

        <SubscriptionsTable
          firstColumnTitle={!hasFloating ? 'Subscriptions' : 'Dedicated'}
          subs={formatSubs}
        />

        {(hasFloating && subs?.interval !== 'month') ?
          <SubscriptionsTable
            firstColumnTitle={
              <>
                Floating access
                <Tooltip placement='right-start' contentClassName='whitespace-pre-wrap' header='What is Floating access?' content={floatingTooltip}>
                  <div><InfoInactive color='primary' className='w-2 h-2 p-0' /></div>
                </Tooltip>
              </>
            }
            subs={[{
              type: 'Pro concurrency',
              total: floating?.total,
              assigned: 'N/A',
            }]}
          />
          : hasFloating && <div className='lg:min-h-[75px] mt-1' />}

        <CitiesTable licenses={subs?.cityLicenses || []} />
      </div>
    </div>
  );
};

export default Subscriptions;

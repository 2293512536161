import { gql } from '@apollo/client';

const GET_PROJECT_MEMBERS = gql`
query getProjectMembers($id: ID!) {
  project(id: $id) {
    id
    name
    owner {
     id
    }
    me {
      role
    }
    members {
      id
      role
      status
      invitedAt
      respondedAt
      isUserRemoved
      invitedBy {
        name
      }
      email
      name
      hasCityLicense     
      profile {
        id
        name
        nickname
        picture
      }
    }
  }
}
`;

export default GET_PROJECT_MEMBERS;

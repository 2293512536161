import { gql } from '@apollo/client';

const GET_DOWNLOAD_URL = gql`
  mutation DesktopDownload {
    createDesktopRelease
  }
`;

export const UPDATE_LAUNCH_OPTION = gql`
  mutation ($environment: AppEnvironment!) {
    updateAppEnvironment(environment: $environment)
  }
`;

export default GET_DOWNLOAD_URL;

import React, { useEffect } from 'react';
import { useHandleError } from 'hooks';
import LoadingBar from '../Loading/LoadingBar';

interface IWrapperProps {
  loading?: boolean;
  renderChildren?: boolean;
  error?: any;
}

const GQLWrapper: React.FC<React.PropsWithChildren<IWrapperProps>> = ({
  loading,
  error,
  children,
  renderChildren = true,
}) => {
  const [handleError] = useHandleError();

  useEffect(
    () => {
      if (error) handleError(error);
    },
    [error, handleError],
  );

  return (
    <>
      {loading && <LoadingBar />}
      {renderChildren && children}
    </>
  );
};

export default GQLWrapper;

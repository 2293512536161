import { palette } from '../utils';

export const tableRow = {
  root: {
    '&.cursor_pointer': {
      cursor: 'pointer',

      '&.selected_vu_file, &:hover': {
        backgroundColor: palette.primary.lightest,
      },

      '&.row--error:not(&.row--disabled)': {
        backgroundColor: palette.error.light,
      },
      '&.row--disabled': {
        pointerEvents: 'none',
        opacity: '0.3',
      },
    },
    '&:hover, &.focused': {
      '&:not(.MuiTableRow-head)': {
        backgroundColor: palette.primary.lightest,
      },
    },
  },
};

export const tableCell = {
  root: {
    padding: '8px 16px',

    '&& .MuiCheckbox-root': {
      color: palette.primary.main,

      '& input': {
        zIndex: '0',
      },
    },
    '& img': {
      objectFit: 'contain',
      width: '100%',
    },
  },
  head: {
    color: palette.gray.main,
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 'normal',
  },
};

export const events = (value?: string) => ({
  createFromDrive: {
    onKeyDown: 'Create a quick project > Admin field active',
    onRemove: 'Create a quick project > Admin removed from project creation list',
    onSelect: value === 'all'
      ? 'Create a quick project > Add entire org > Selected'
      : 'Create a quick project > Admin selected from Organisation dropdown list in the project creation flow',
  },
  create: {
    onKeyDown: 'Create a Project > Invite members > Add emails or select members > Search',
    onRemove: 'Create a Project > Invite members > Member removed from project creation list',
    setIndividualRole: 'Create a Project > Invite members > Add emails or select members > Set role',
    onSelect: value === 'all'
      ? 'Create a Project > Invite members > Add emails or select members > Add entire organisation'
      : 'Create a Project > Invite members > Add emails or select members',
  },
});
import { useQuery } from '@apollo/client';
import { useMixpanel } from 'hooks';
import uniqBy from 'lodash/uniqBy';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AccountLayout, Tab, Tabs } from 'tailwind';
import GET_SITE_LICENCES from './apollo';
import SiteLicence from './components/SiteLicence';

const CustomPurchases = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { mixpanelTrack } = useMixpanel();
  const { data, loading, error } = useQuery(GET_SITE_LICENCES);

  const { userLicences, orgSiteLicences, orgMembers, orgProjects } = useMemo(() => {
    const { siteLicenses, members, projects } = data?.currentUser?.organisation || {};

    return {
      userLicences: data?.currentUser?.siteLicenses || [],
      orgSiteLicences: uniqBy(siteLicenses, 'license.id') || [],
      orgMembers: members?.map((mem) => mem.siteLicenses).flat() || [],
      orgProjects: uniqBy(projects, 'id') || [],
    };
  }, [data]);

  return (
    <AccountLayout
      title='Custom purchases'
      subtitle='Explore all the custom purchases you have made with VU.CITY.'
      gql={{ loading, error, renderChildren: !!data }}
      button={{
        text: 'Get in touch with our team',
        variant: 'outlined',
        onClick: () => {
          history.push('/contact', { prevPath: pathname });
          mixpanelTrack("Clicked on 'Get in touch with our team' from Custom purchases page");
        },
      }}
    >
      <Tabs>
        <Tab label='Site licences'>
          <p className='mb-2 text-grey'>
            Site licences have been custom modelled by VU.CITY for your organisation.
            They must be assigned to specific Org members and require a Pro subscription
            to launch in VU.CITY 3D.
          </p>

          <div className='grid gap-2 grid-cols-[repeat(auto-fit,minmax(260px,1.5fr))]'>
            {orgSiteLicences.filter(({ status }) => status === 'active').map((licence) => {
              const { id } = licence.license;

              return (
                <SiteLicence
                  key={licence.id}
                  licence={licence}
                  isDisabled={!userLicences.find(({ licenseId }) => licenseId === id)}
                  stats={{
                    projects: orgProjects
                      .filter(({ location }) => location?.license?.id === id).length,
                    members: orgMembers
                      .filter((member) => member?.licenseId === id).length,
                  }}
                />
              );
            })}
          </div>
        </Tab>
      </Tabs>
    </AccountLayout>
  );
};

export default CustomPurchases;

import * as React from 'react';

const Page404 = () => (
  <section className='flex flex-col items-center justify-center w-full h-full p-4 text-lg rounded'>
    <h1 className='text-primary'>404</h1>
    Page not found
  </section>
);

export default Page404;

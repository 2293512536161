import { InputAdornment, TextField } from '@material-ui/core';
import {
  Autocomplete, AutocompleteRenderInputParams, createFilterOptions,
} from '@material-ui/lab';
import { CloseIcon, SearchIcon } from '@vucity/design_system';
import { IFuncUpdater } from 'ahooks/lib/createUseStorageState';
import { useMixpanel } from 'hooks';
import { debounce } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { Paper, Popper } from './SearchInput.style';

interface Props {
  searchEndHandle: (fromRecents: boolean) => void;
  searchHandle: [string, React.Dispatch<string>];
  storingHandle: [any[] | undefined, (value: any[] | IFuncUpdater<any[]>) => void];
  advancedSearch?: (toSearch: string | null) => void;
  placeholder?: string;
}

export default function SearchInput({
  searchHandle,
  searchEndHandle,
  advancedSearch,
  storingHandle,
  placeholder,
}: Props): ReactElement {
  const { mixpanelTrack } = useMixpanel();
  // unneded; cleanup implementation on drive search refactor
  const [searchStr, setSearchStr] = searchHandle;
  const [opened, setOpened] = useState(false);
  const [fromRecents, setFromRecents] = useState(false);
  const [searchRecents = [], setSearchRecents] = storingHandle;

  const addRecentHandle = (option: string) => {
    if (!option || searchRecents.indexOf(option) >= 0) return;
    mixpanelTrack('Added input to recent search');

    setSearchRecents([option, ...searchRecents]);
  };

  const removeRecentHandle = (
    e: { stopPropagation: () => void; },
    option: string,
  ) => {
    e.stopPropagation();
    const removed = searchRecents.filter((s) => s !== option);
    mixpanelTrack('Removed input to recent search');
    setSearchRecents(removed);
  };

  const handleOnChange = debounce((value: string | null, recents: boolean) => {
    setFromRecents(recents);
    if (advancedSearch) advancedSearch(value);
    setSearchStr(value ?? '');
  }, 300);

  useEffect(() => () => {
    if (searchStr) {
      handleOnChange.cancel();
    }
  }, [searchStr, handleOnChange]);

  const recentSearches = searchRecents.filter((str) => str.includes(searchStr));

  return (
    <Autocomplete
      id="search"
      freeSolo
      fullWidth
      className={opened ? 'openedAutocomplete' : ''}
      options={recentSearches || []}
      getOptionLabel={(option) => option}
      filterOptions={createFilterOptions({
        limit: 5,
      })}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      PopperComponent={Popper}
      PaperComponent={Paper}
      onChange={(_, newValue: string | null) => handleOnChange(newValue, false)}
      value={searchStr}
      renderOption={(option: string) => (
        <>
          <SearchIcon />

          <div className='flex justify-between w-full mx-1'>
            {option}
          </div>

          <CloseIcon
            style={{ width: '13px' }}
            onClick={(e: React.MouseEvent) => removeRecentHandle(e, option)}
          />
        </>
      )}
      renderInput={(props: AutocompleteRenderInputParams) => (
        <TextField
          {...props}
          variant="outlined"
          placeholder={placeholder || 'Search'}
          value={searchStr}
          onChange={(e) => handleOnChange(e.currentTarget.value, false)}
          onClick={() => mixpanelTrack('Clicking search field')}
          onBlur={(e) => {
            addRecentHandle(e.currentTarget.value);
            searchEndHandle(fromRecents);
          }}
          InputProps={{
            ...props.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

import React, { useMemo } from 'react';
import { StepNumber } from 'tailwind';
import { TILE_SIZE, formatNumber } from '../CoverageMap';
import { Summary } from './Basket';
import OrderItem from './OrderItem';

interface OrderSummaryProps {
  summary: Summary;
  onSelect: (id: string, isTile?: boolean) => void;
  onDelete: (id: string, isTile?: boolean) => void;
}

const OrderSummary = ({ summary, onSelect, onDelete }: OrderSummaryProps) => {
  const { polygons, tiles } = summary;
  const disabled = !polygons.length && !tiles.length;

  const uniquePolygons = useMemo(() => {
    let polygonTiles: string[] = [];
    const tileIDs = tiles?.map(({ id }) => id);

    return polygons
      .map((polygon) => {
        if (!polygon.tiles) return { ...polygon, tiles: [] };
        const removeDuplicateTiles = polygon.tiles.filter((tile: string) => !polygonTiles?.includes(tile) && !tileIDs.includes(tile));
        polygonTiles.push(...polygon.tiles);

        return {
          ...polygon,
          tiles: removeDuplicateTiles,
        };
      });
  }, [polygons, tiles]);

  return (
    <div className='flex flex-col gap-1.2'>
      <p className='text-lg'>Order summary</p>
      <hr className='border-b border-b-grey-light' />

      <div className='flex items-center gap-1'>
        <StepNumber number={1} active complete={false} size='md' />
        <p className='text-sm text-primary'>Select your area</p>
      </div>

      {!disabled ?
        <div className='overflow-auto max-h-300 py-0.5 flex flex-col gap-2 ml-[30px]'>
          {tiles.length > 0 && (
            <OrderItem
              title='Tile selection'
              tiles={tiles.map(({ id }) => id)}
              area={formatNumber(TILE_SIZE * tiles.length)}
              onClick={onSelect}
              onDelete={onDelete}
            />
          )}

          {uniquePolygons.length > 0 && uniquePolygons.map(({ id, area, properties }) => (
            <OrderItem
              key={id}
              id={id}
              title={properties.circleRadius
                ? `${properties.circleRadius?.toFixed(2)}km Radius Circle`
                : 'Custom selection'
              }
              area={area}
              onClick={onSelect}
              onDelete={onDelete}
            />
          ))}
        </div>
        : null}

      <hr className='border-b border-b-grey-light' />
    </div>
  );
};

export default OrderSummary;

import { gql } from '@apollo/client';

const GET_ORG_PROJECTS = gql`
  query getOrgProjects {
    currentUser {
      lastLogin
      organisation {
        trialAvailable
        subscriptions {
          status
        }
        members {
          id
          status
          isUserRemoved
        }
        projects {
          name
          id
          reference
          members {
            id
          }
        }
      }
    }
  }
`;

export default GET_ORG_PROJECTS;

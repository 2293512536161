import { useMutation } from '@apollo/client';
import { Check, Edit } from '@material-ui/icons';
import { ReactComponent as VisibilityIcon } from 'assets/icons/Visibility.svg';
import { LoadingBar } from 'components';
import { useHandleError, useMixpanel } from 'hooks';
import { default as React, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getExt, invalidCharsRegEx } from 'utils/helpers';
import { RENAME_NODES } from './apollo';

interface IEditorProps {
  nodeId: string;
  initialContent: string;
  type: 'File' | 'Folder';
  editingId?: string;
  readonly?: boolean;
  isPreview?: boolean;
  isFolder?: boolean;
  whitelist?: string[];
}

const FileNameEditor = ({ nodeId, editingId, readonly, isPreview, isFolder, initialContent, type, whitelist }: IEditorProps) => {
  const [filename, extension] = [initialContent.substring(0, initialContent.lastIndexOf('.')).trim(), getExt(initialContent)];
  const name = type === 'File' && !!filename ? filename : initialContent;

  const ref = useRef() as any;
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();
  const { projectId } = useParams<{ projectId?: string; }>();

  const [isEditing, setIsEditing] = useState(false);
  const [editable, setEditable] = useState(false);
  const [error, setError] = useState(false);
  const [newContent, setNewContent] = useState(name);

  const resetInput = useCallback(() => {
    setEditable(false);
    setError(false);
    setNewContent(initialContent);
  }, [initialContent]);

  useEffect(() => {
    if (isPreview) {
      resetInput();
    }
  }, [isPreview, resetInput]);

  useLayoutEffect(() => {
    if (ref.current && isPreview) {
      ref.current.style.height = 'auto';
      ref.current.style.height = `${Math.max(ref.current.scrollHeight)}px`;
    }
  }, [newContent, isPreview, initialContent]);

  const generateMixpanel = (description: string) => {
    const extra = { 'Character count': newContent.trim().length };

    const drive = projectId ? 'Project drive' : 'Personal drive';
    const view = isPreview ? 'Preview' : type;

    mixpanelTrack(`${drive} > ${description} ${view}`, extra);
  };

  const [rename, { loading }] = useMutation(RENAME_NODES, {
    onCompleted: () => {
      generateMixpanel('Successfully Quick Renamed');
      enqueueSnackbar('Asset successfully renamed', { variant: 'success' });
      setEditable(false);
    },
    onError: (err) => {
      generateMixpanel('Error to Quick Rename');
      setError(true);
      handleError(err);
    },
  });

  const onChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const isInValid = invalidCharsRegEx.test(e.target.value);

    if (isInValid && !error) {
      enqueueSnackbar('The specified name is not allowed. Avoid: <, >, :, ”, /, \'\', |, ?, *', {
        variant: 'error',
      });
    }
    setError(isInValid);
    setNewContent(e.target.value);
  };

  const onEdit = (e: React.MouseEvent) => {
    e.stopPropagation();

    generateMixpanel('Click to Quick Rename');
    setEditable(true);
    setNewContent(name);
    setTimeout(() => ref?.current?.focus(), 100);
  };

  const onSave = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();

    if (loading) return null;
    if (error) return resetInput();

    const data = newContent.trim();

    if (data === name || !data) return resetInput();

    const fileExtension = !!filename ? `${extension}` : 'vu';
    const updatedName = type === 'Folder' ? data : `${data}.${fileExtension}`;

    return rename({
      variables: {
        node: { id: nodeId },
        projectId,
        name: updatedName,
      },
    });
  };

  const onType = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Escape') resetInput();
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSave(e);
    }
  };

  const onMouseOver = (e: React.MouseEvent) => {
    e.stopPropagation();

    const activeEditing = document.querySelector(`#${editingId}`) !== null;
    setIsEditing(activeEditing);
  };

  const iconSize = isFolder ? 'w-2 h-2' : 'w-2.5 h-2.5';
  const readonlyPreview = isPreview && readonly;

  if ((readonly || !editable) && (!isPreview || readonlyPreview)) {
    return (
      <div
        className={`flex ${readonlyPreview ? '' : 'h-3'} justify-between items-center m-[1px] w-full overflow-hidden text-ellipsis px-0.5 transition group border border-transparent`}
        onMouseOver={onMouseOver}
      >
        <p className='w-full overflow-hidden text-ellipsis whitespace-nowrap'>
          {initialContent}
        </p>

        {!readonly && !isEditing && (
          <div className='flex cursor-pointer'>
            <Edit className={`hidden ${iconSize} text-primary group-hover:flex`} onClick={onEdit} />
          </div>
        )}
        {(whitelist && whitelist?.length > 0) &&
          <span className='ml-0.5'><VisibilityIcon /></span>
        }
      </div>
    );
  }

  return (
    <>
      {loading && !isFolder && <LoadingBar />}

      <div
        id={editingId}
        className={`flex m-[1px] border justify-between w-full text-ellipsis overflow-hidden transition rounded-sm duration-100 group 
          ${editable ? `bg-white ${error ? 'border-error' : 'border-placeholder'}` : 'hover:bg-primary-lightest border-transparent'}
          ${isPreview ? '' : 'h-3 items-center'}
        `}
      >
        <textarea
          wrap={isPreview ? 'on' : 'off'}
          ref={ref}
          className={`w-full px-0.5 h-3 bg-transparent break-all overflow-hidden resize-none outline-0 text-ellipsis tracking-wide ${isFolder && 'py-0.5'}`}
          value={editable ? newContent : initialContent}
          disabled={!editable}
          rows={1}
          onClick={(e) => e.stopPropagation()}
          onFocus={(e) => e.target.select()}
          onChange={onChangeInput}
          onKeyDown={onType}
        />

        <div className='flex cursor-pointer text-primary w-2.5'>
          {!editable
            ? <Edit className='hidden w-2.5 h-2.5 group-hover:flex' onClick={onEdit} />
            : <Check className='w-2.5 h-2.5' onClick={onSave} />
          }
        </div>
      </div>
    </>
  );
};

export default FileNameEditor;

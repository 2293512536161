import React from 'react';
import { TreeView } from '@material-ui/lab';
import { ArrowDropDownIcon, ArrowDropUpIcon } from '@vucity/design_system';

interface IDriveFolders {
  children: React.ReactNode;
  expanded: string[];
  selected: string[];
  handleToggle: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void;
  handleSelect: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void;
}

const DriveFolders: React.FC<IDriveFolders> = ({
  selected, expanded, handleToggle, handleSelect, children,
}) => (
  <TreeView
    id='tree_view'
    defaultCollapseIcon={<ArrowDropUpIcon />}
    defaultExpandIcon={<ArrowDropDownIcon />}
    expanded={expanded}
    selected={selected}
    onNodeToggle={handleToggle}
    onNodeSelect={handleSelect}
  >
    {children}
  </TreeView>
);

export default DriveFolders;

import { Dialog, DialogContent, DialogProps, IconButton } from '@material-ui/core';
import { CloseIcon } from '@vucity/design_system';
import { useMixpanel } from 'hooks';
import * as React from 'react';
import { useEffect } from 'react';

interface ModalProps extends DialogProps {
  headerText: string;
  mixpanelPath?: string;
  closeMixpanel?: string;
  children: React.ReactNode;
  close?: () => void;
  open: boolean;
  headerComponent?: React.ReactNode;
  closeBtnDisabled?: boolean;
}

export default function CustomModal({
  headerText, mixpanelPath = headerText, children, close, open, headerComponent, closeBtnDisabled,
  closeMixpanel, ...props
}: ModalProps) {
  const { mixpanelTrack } = useMixpanel();

  useEffect(() => {
    if (open) mixpanelTrack(`${mixpanelPath} modal opened`);
  }, [open, mixpanelTrack, mixpanelPath]);

  const onCloseHandle = (reason: string) => {
    mixpanelTrack(closeMixpanel || `${mixpanelPath} modal closed/${reason}`);
    if (close) close();
  };

  return <Dialog disableBackdropClick={closeBtnDisabled} style={{ zIndex: '9999' }} open={open} onClose={(e, reason) => onCloseHandle(reason)} fullWidth={true} maxWidth={'sm'} {...props}>
    <DialogContent>
      <div className='flex-row-reverse flex h-[44px]'>
        <IconButton disabled={closeBtnDisabled} onClick={() => onCloseHandle('X')} aria-label="xButton">
          <CloseIcon />
        </IconButton>
      </div>

      <div className='flex flex-col items-center mb-5'>
        <h3 className='text-center'>
          {!headerComponent ? headerText : headerComponent}
        </h3>
        {children}
      </div>
    </DialogContent>
  </Dialog>;
}

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { TooltipProps, Avatar, Tooltip, Box, Typography } from '@material-ui/core';

import { ProjectMember, ProjectMemberStatus } from 'types/graphql';
import { useMixpanel } from 'hooks';

import { capitalizeWord } from 'utils/helpers';
import { Link } from 'react-router-dom';
import GET_PROJECT_MEMBER from './apollo';
import { Card, Popper } from './ProfileCard.style';

interface IProfileProps {
  email?: string;
  userId?: string;
  children: React.ReactElement<any, any>;
  redirect?: boolean;
  projectId?: string;
  mixpanelContext?: string;
  placement?: TooltipProps['placement'];
  leaveDelay?: number;
}

const ProfileCard = React.forwardRef(({
  leaveDelay = 0, email = '', userId = '', mixpanelContext = '', projectId, redirect, placement = 'bottom-start', children,
}: IProfileProps, ref) => {
  const [user, setUser] = useState<ProjectMember | null>(null);
  const { mixpanelTrack } = useMixpanel();

  useQuery(GET_PROJECT_MEMBER, {
    variables: { id: projectId },
    onCompleted: ({ project }) => {
      const member = project.members
        .find((m: ProjectMember) => (userId && m.id === userId) || m.email === email);

      setUser(member);
    },
  });

  const renderRole = (status?: ProjectMemberStatus) => {
    switch (status) {
      case 'ACCEPTED': return user ? capitalizeWord(user.role.toLowerCase()) : '';
      case 'INVITED': return 'Invited';
      default: return 'Inactive';
    }
  };

  const renderCard = () => {
    if (user) {
      const ifInactive = user.status !== 'ACCEPTED' && user.status !== 'INVITED';
      const redirectUrl = redirect && projectId
        ? `/project/${projectId}/members${ifInactive ? '?mode=inactive' : ''}`
        : '';

      const trackClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!redirectUrl) return e.preventDefault();
        return mixpanelTrack(`${mixpanelContext}Clicked Profile Card`, { 'UserId of Profile card': user.id });
      };

      const { profile } = user;

      return (
        <Link to={redirectUrl} onClick={(e) => trackClick(e)}>
          <Card elevation={0} id="profile_card">
            <Box p={1} display="flex" alignItems="center" bgcolor="primary.main" color="#fff">
              <Avatar src={profile && profile.picture} />
              <Box display="flex" flexDirection="column" ml={1}>
                <Typography variant="body2">{profile?.name || user.email}</Typography>
                <Typography variant="subtitle2">{renderRole(user.status)}</Typography>
              </Box>
            </Box>

            {profile && profile.jobTitle && !ifInactive && (
              <Box p={1} bgcolor="#fff">
                <Typography variant="subtitle2">Job title</Typography>
                <Typography variant="body2">{profile.jobTitle}</Typography>
              </Box>
            )}
          </Card>
        </Link>
      );
    }

    return <></>;
  };

  return (
    <>
      <Tooltip
        ref={ref}
        leaveDelay={leaveDelay}
        enterNextDelay={leaveDelay}
        PopperComponent={Popper}
        placement={placement}
        interactive
        onClose={() => mixpanelTrack('Opened Profile card', { 'UserId of Profile card': user?.id })}
        title={renderCard()}
      >
        {children}
      </Tooltip>
    </>
  );
});

ProfileCard.displayName = 'ProfileCard';

export default ProfileCard;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { ProjectPreference, AdminOversight } from 'components';
import NoAvatar from 'components/NoAvatar/NoAvatar.styles';
import OrgAvatar from 'components/OrgAvatar/OrgAvatar.styles';
import { useCloudinary, useCurrentUser, useHandleError, useMixpanel, useQueryParam } from 'hooks';
import { AccountLayout, Button, Row, Tab, Tabs } from 'tailwind';
import { Address, Organisation as Org, ProjectAccess, ProjectMemberRole, AdminProjectAccess } from 'types/graphql';
import { capitalizeWord, getCountry, isLogo } from 'utils/helpers';
import { UPDATE_ORGANISATION_DETAILS } from 'views/account/apollo';
import EditDetails from './edit/EditDetails';

const Organisation = () => {
  const history = useHistory();
  const { mixpanelTrack } = useMixpanel();
  const [cld, token] = useCloudinary();

  const query = useQueryParam();
  const { currentUser } = useCurrentUser();
  const [handleError, enqueueSnackbar] = useHandleError();

  const [updateOrganisationDetail, { loading }] = useMutation(UPDATE_ORGANISATION_DETAILS);

  const [defaultRole, setDefaulRole] = useState<ProjectMemberRole>(currentUser?.organisation?.data?.defaultRole || 'VIEWER');
  const [defaultAccess, setdefaultAccess] = useState<ProjectAccess>(currentUser?.organisation?.data?.access || 'INVITE_ONLY');
  const [defaultOversight, setdefaultOversight] = useState<AdminProjectAccess>(currentUser?.organisation?.data?.adminAccess || 'INVITE_ONLY_OVERSIGHT');

  useEffect(() => {
    if (currentUser) {
      setDefaulRole(currentUser?.organisation?.data?.defaultRole || 'VIEWER');
      setdefaultAccess(currentUser?.organisation?.data?.access || 'INVITE_ONLY');
      setdefaultOversight(currentUser?.organisation?.data?.adminAccess || 'INVITE_ONLY_OVERSIGHT');
    }
  }, [currentUser]);

  const { name, type, email, phone, logo, address } = currentUser?.organisation as Org || {};
  const { country, line1, line2, city, postal_code, state } = address || ({} as Partial<Address>);

  const orgLogo = `${cld.image(logo).resize(scale().width(240)).toURL()}&${token}`;
  const checkLogo = isLogo(orgLogo);
  const isReadonly = currentUser?.organisation?.me?.role !== 'ADMIN';
  const getOrgDetails = () => ({
    name,
    type,
    email,
    phone: phone || '',
    logo,
    address: {
      line1: address?.line1 || '',
      line2: address?.line2 || '',
      country: address?.country || '',
      postal_code: address?.postal_code || '',
      state: address?.state || '',
    },
  });

  const handleAccessChange = (access: ProjectAccess) => {
    updateOrganisationDetail({
      variables: {
        id: currentUser.organisation.id,
        details: {
          ...getOrgDetails(),
          access,
          adminAccess: defaultOversight,
          defaultRole: access === 'INVITE_ONLY' ? 'VIEWER' : defaultRole,
        },
      },
      onCompleted: () => {
        enqueueSnackbar('Updated your organisation’s default project setting', { variant: 'success' });
        setdefaultAccess(access);
      },
      onError: handleError,
    });
  };

  const handleOversightChange = (oversight: AdminProjectAccess) => {
    updateOrganisationDetail({
      variables: {
        id: currentUser.organisation.id,
        details: {
          ...getOrgDetails(),
          adminAccess: oversight,
          access: defaultAccess,
          defaultRole,
        },
      },
      onCompleted: () => {
        enqueueSnackbar("Updated your organisation's default project setting", { variant: 'success' });
        setdefaultOversight(oversight);
      },
      onError: handleError,
    });
  };

  const handleRoleChange = (role: ProjectMemberRole) => {
    updateOrganisationDetail({
      variables: {
        id: currentUser.organisation.id,
        details: {
          ...getOrgDetails(),
          access: defaultAccess,
          defaultRole: role,
          adminAccess: defaultOversight,
        },
      },
      onCompleted: () => {
        enqueueSnackbar(`Updated the default role for a project member to ${capitalizeWord(role)}`,
          { variant: 'success' });
        setDefaulRole(role);
      },
      onError: handleError,
    });
  };

  if (query.get('mode') === 'edit') return <EditDetails organisation={currentUser.organisation} />;

  return (
    <AccountLayout
      gql={{ loading, renderChildren: !!currentUser }}
      title='Settings'
      subtitle="Your organisation details and VU.CITY preferences."
    >
      <Tabs
        mixpanelProps={{
          title: 'Organisation Settings >',
          extra: { 'Organisation role': currentUser?.organisation?.me?.role },
        }}
      >
        <Tab label="Organisation details">
          <div className='mt-2'>
            <Row column1="Name">{name}</Row>
            <Row column1="Type">{type}</Row>

            <Row column1="Logo">
              {checkLogo
                ? <OrgAvatar
                  src={orgLogo}
                  alt={`${name} logo`}
                  variant="rounded"
                />
                : <NoAvatar />}
            </Row>
          </div>

          <Row column1="Address">
            {line1 && <p>{line1}</p>}
            {line2 && <p>{line2}</p>}
            {city && <p>{city}</p>}
            {state && <p>{state}</p>}
            {postal_code && <p>{postal_code}</p>}
            {country && <p>{getCountry(country)?.label}</p>}
          </Row>

          <Row column1="Contact Email">{email}</Row>
          <Row column1="Contact Number">{phone}</Row>

          <div className='flex justify-end w-full'>
            {currentUser?.organisation?.me?.role === 'ADMIN' && (
              <Button
                onClick={() => {
                  mixpanelTrack('Edit Details');
                  history.push('/account/organisation/details?mode=edit');
                }}
              >
                Edit Details
              </Button>
            )}
          </div>
        </Tab>

        <Tab label="Project preferences">
          <ProjectPreference
            isReadOnly={isReadonly}
            defaultRole={defaultRole}
            onAccessChange={handleAccessChange}
            onRoleChange={handleRoleChange}
            preference={defaultAccess}
            projectRole={currentUser?.organisation?.me?.role}
          />
        </Tab>

        <Tab label="Admin preferences">
          <AdminOversight
            isReadOnly={isReadonly}
            onOversightChange={handleOversightChange}
            oversight={defaultOversight}
          />
        </Tab>
      </Tabs>
    </AccountLayout>
  );
};

export default Organisation;

import { useQuery } from '@apollo/client';
import { CancelProcessModal, GQLWrapper } from 'components';
import Drive from 'components/Drive/Drive';
import { DriveSelectedFilesContext } from 'components/Drive/DriveProvider/DriveProvider';
import TransferLoading from 'components/Drive/TransferContextMenu/TransferLoading/TransferLoading';
import { IFileRow } from 'components/Drive/types';
import { useCopyNodes, useMixpanel } from 'hooks';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FullwidthLayout } from 'tailwind';
import { Folder } from 'types/graphql';
import { IRouteProps } from 'utils/routes/routerParams';
import GET_PERSONAL_DRIVE from './apollo';

const ProjectDriveTransfer: React.FC<IRouteProps> = ({ match }) => {
  const { projectId: id, folderId } = match.params;
  const { mixpanelTrack } = useMixpanel();
  const history = useHistory();

  const { setSelectedFiles } = useContext(DriveSelectedFilesContext);

  const { loading: driveLoading, error: driveError, data, refetch } = useQuery(GET_PERSONAL_DRIVE, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  const onRedirect = () => history.push(`/project/${id}/drive`);

  const { copyNodes, loading, errorData, errorCode, setErrorCode } = useCopyNodes(
    () => { onRedirect(); setSelectedFiles([]); },
  );

  const onTransferFiles = (files: IFileRow[] | Folder[]) => {
    const filesToTransfer = (files as IFileRow[]).map((file: IFileRow) => ({ id: file.id }));

    mixpanelTrack('Upload to Project');
    copyNodes(filesToTransfer, folderId, id);
  };

  return (
    <FullwidthLayout>
      <GQLWrapper loading={driveLoading} error={driveError} renderChildren={!!data}>
        <div className='absolute right-3'>
          <CancelProcessModal
            onCancel={onRedirect}
            message="The files you have selected won't be uploaded to the project drive."
          />
        </div>

        <h5 className='mb-2'>
          Select the files to upload to {data?.project?.name}
        </h5>

        <Drive
          data={data?.currentUser}
          driveType="personal"
          refetch={refetch}
          refetchQueryAfterUpdate={{ query: GET_PERSONAL_DRIVE, variables: { id } }}
          onTransfer={onTransferFiles}
        />

        <TransferLoading
          projectId={id}
          state={{ loading, error: errorCode }}
          errorData={errorData}
          onCancel={onRedirect}
          onCloseModal={() => setErrorCode('')}
        />
      </GQLWrapper>
    </FullwidthLayout>
  );
};

export default ProjectDriveTransfer;

import React from 'react';
import { SelectButton } from 'tailwind';
import { ISingleOverlay } from '../../types';

import { Layer, Source } from 'react-map-gl';
import { ReactComponent as ModelCoverageFilled } from '../../icons/modelCoverageFilled.svg';
import { ReactComponent as ModelCoverageOutlined } from '../../icons/modelCoverageOutlined.svg';

const ModelCoverage = ({ selected, disabled, area, onClick }: ISingleOverlay) => {
  return (
    <>
      <SelectButton
        fullWidth
        selected={selected}
        onClick={() => onClick('coverage')}
        startIcon={selected || disabled ? <ModelCoverageFilled /> : <ModelCoverageOutlined />}
        disabled={disabled}
      >
        Model coverage
      </SelectButton>

      {selected && area && (
  <>
    <Source id='coverage-fill' type='geojson' data={area}>
      <Layer
        id='coverage-fill'
        type='fill'
        paint={{
          'fill-color': '#333',
          'fill-opacity': 0.15,
          'fill-outline-color': '#5C5C5C',
        }}
        minzoom={6}
      />
    </Source>

    <Source id='coverage-line' type='geojson' data={area}>
      <Layer
        id='coverage-line'
        type='line'
        paint={{
          'line-color': '#FFFFFF',
          'line-width': 1,
          'line-dasharray': [2, 2],
        }}
        minzoom={6}
      />
    </Source>
  </>
      )}
    </>
  );
};

export default ModelCoverage;

import { gql, TypedDocumentNode } from '@apollo/client';
import { License, User } from 'types/graphql';

export const GET_FORM_DATA: TypedDocumentNode<{ currentUser: User; cityLicenses: License[] }> = gql`
  query formData {
    currentUser {
      name
      email
      jobTitle
      contactNumber
      organisation {
        name
        type
      }
    }
    cityLicenses {
      id
      name
    }
  }
`;

export default GET_FORM_DATA;

import { useAuth0 } from '@auth0/auth0-react';
import { useQueryParam } from 'hooks';
import * as React from 'react';
import { Route, RouteComponentProps, RouteProps, useHistory, useLocation } from 'react-router-dom';

interface IPrivateRouteProps extends RouteProps {
  compProps?: any,
}

interface ILoginArgs {
  appState: object,
  fragment?: string,
}

const PrivateRoute = ({ component, compProps, ...rest }: IPrivateRouteProps) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { pathname, search, hash } = useLocation();
  const history = useHistory();
  const query = useQueryParam();

  if (!component) {
    throw Error('component is undefined');
  }
  const Component = component; // JSX Elements have to be uppercase.

  const loginArgs: ILoginArgs = {
    appState: {
      returnTo: { pathname, hash, search },
    },
  };

  if (query.get('invite')) loginArgs.fragment = `invite=${query.get('invite')}`;

  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    if (isAuthenticated) {
      return <Component {...props} {...compProps} />;
    }

    if (query.get('error')) {
      history.push(`/error?msg=${query.get('error_description')}`);
    }

    loginWithRedirect(loginArgs);
    return null;
  };

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;

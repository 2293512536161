import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useMixpanel } from 'hooks';
import { MobileLayout } from 'tailwind';

import { ReactComponent as Maintenance } from 'assets/icons/Maintenance.svg';
import { ReactComponent as Emoji } from 'assets/icons/Smiley.svg';

const MobileFallback = ({ variant = 'mobile' }: { variant?: string; }) => {
  const { mixpanelTrack } = useMixpanel();
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      mixpanelTrack('Mobile > Action');
    }
  }, [mixpanelTrack, user?.sub, isAuthenticated]);

  const content = {
    mobile: {
      icon: <Emoji />,
      title: 'We\'re working on mobile!',
      subtitle: 'To harness the full experience of VU.CITY, please login via desktop.',
    },
    maintenance: {
      icon: <Maintenance />,
      title: 'Down for maintenance',
      subtitle: 'The hub is currently down for maintenance and will be back in action shortly.',
    },
  } as { [key: string]: any; };

  const { icon, title, subtitle } = content[variant];

  return (
    <MobileLayout>
      <div className='flex flex-col items-center justify-center w-full px-3 py-4 bg-white rounded'>
        {icon}
        <h3 className='my-2 text-center'>{title}</h3>
        <p className='text-lg tracking-wide text-center'>
          {subtitle}
        </p>
      </div>
    </MobileLayout>
  );
};

export default MobileFallback;

import React from 'react';
import { FileViewerProps } from 'views/types';

export default function ({ filePath }: FileViewerProps) {

  return (
    <div
    className='w-full h-full flex items-center justify-center'
    >
      <embed
        type='application/pdf'
        src={filePath}
        className='w-full h-full'
      />
    </div>
  );
}


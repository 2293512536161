import { useMixpanel } from 'hooks';
import React from 'react';
import { Label } from 'tailwind';
import { AdminProjectAccess } from 'types/graphql';
import { capitalizeWord } from 'utils/helpers';
import { RadioOption } from 'views/account/desktop/Desktop.style';

interface AdminOversightOptionProps {
  setting: AdminProjectAccess;
  isChecked: boolean;
  heading: string;
  subHeading: string;
  handleClick: (setting: AdminProjectAccess) => void;
  isDefault?: boolean;
  isReadonly?: boolean;
}

export default function AdminOversightOption(props: AdminOversightOptionProps) {
  const {
    handleClick,
    isChecked,
    setting,
    isDefault,
    heading,
    subHeading,
    isReadonly,
  } = props;

  const { mixpanelTrack } = useMixpanel();
  const logToMixpanel = (access: AdminProjectAccess) => {
    mixpanelTrack(`Organisation Settings > ${capitalizeWord(access).replace(/_/g, ' ')} radio button`);
  };

  const handleRadioOptionClick = (pref: AdminProjectAccess, e: React.MouseEvent) => {
    // prevent clicking beyond the input element
    if (isReadonly) return;
    if (!(e.target as HTMLElement).classList.contains('__radio-option')) return;
    handleClick(pref);
    logToMixpanel(pref);
  };

  return (
    <div>
      <RadioOption
        onClick={handleRadioOptionClick.bind(null, setting)}
        disabled={isReadonly}
      >
        <input type="radio" name='oversight' value='oversight' checked={isChecked} disabled={isReadonly} />
        <label className='__radio-option' htmlFor={setting}>{heading} {!!isDefault && <Label className='ml-1'>Default</Label>}</label>

        <p className='whitespace-pre-line text-grey ml-[28px]'>
          {subHeading}
        </p>
      </RadioOption>
    </div>
  );
}

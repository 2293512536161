import { palette, typography } from '../utils';

export const inputLabel = {
  root: {
    paddingBottom: '8px',
    color: palette.text.primary,
    fontSize: typography.subtitle1.fontSize,
    lineHeight: '16px',

    '&$focused': {
      color: palette.text.primary,
    },

    '&$error': {
      color: palette.error.main,
    },
  },
  formControl: {
    position: 'relative',
    transform: 'unset',
  },
  shrink: {
    transform: 'none',
  },
  animated: {
    transition: 'none',
  },

};

export default inputLabel;

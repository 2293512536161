import React, { useCallback } from 'react';

import DetailedSelect from 'components/DetailedSelect';
import { DetailedSelectItem } from 'views/types';
import { roleOptions } from 'utils/helpers';
import { ProjectMemberRole } from 'types/graphql';
import { useMixpanel } from 'hooks';

interface IProps {
  value: ProjectMemberRole;
  onChange: (role: ProjectMemberRole) => void;
  myRole?: ProjectMemberRole;
}

const RoleSelector: React.FC<IProps> = ({ onChange, value, myRole }) => {
  const { mixpanelTrack } = useMixpanel();
  const handleChange = useCallback(
    (item: DetailedSelectItem | undefined | null) => {
      mixpanelTrack('EditRole', { role: item?.title });
      onChange(item?.value as ProjectMemberRole);
    },
    [onChange, mixpanelTrack],
  );

  return (
    <DetailedSelect
      items={roleOptions(myRole)}
      value={value}
      handleChange={handleChange}
      optionWidth={306}
    />
  );
};

export default RoleSelector;

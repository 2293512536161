import { DetailedSelect } from 'components';
import SearchInput from 'components/Drive/Search/SearchInput';
import { useMixpanel } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { Project } from 'types/graphql';
import * as JsSearch from 'js-search';

import { Wrapper } from './SearchProjects.style';
import { IFuncUpdater } from 'ahooks/lib/createUseStorageState';
import { IFilters } from 'views/types';

interface ISearchProps {
  projects: Project[];
  view: string;
  setFilteredProjects: (arg: Project[]) => void;
  setSearchLoading: (arg: boolean) => void;
  filters: IFilters;
  filterProjects: (selectedFilters: IFilters, sortOption: string) => Project[];
  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  searchRecents: any[];
  setSearchRecents: (value: any[] | IFuncUpdater<any[]>) => void;
  searched: JsSearch.Search;
}

const options = [
  { value: 'anyone', title: 'Created by anyone' },
  { value: 'me', title: 'Created by me' },
  { value: 'others', title: 'Created by others' },
];

const sortOptions = [
  { value: 'lastOpened', title: 'Last opened' },
  { value: 'recentlyCreated', title: 'Recently created' },
  { value: 'a-z', title: 'Alphabetically A-Z' },
  { value: 'z-a', title: 'Alphabetically Z-A' },
];

const SearchProjects = ({
  projects, view, setFilteredProjects, setSearchLoading,
  filterProjects, filters, setSort, sort, setSearchRecents,
  searchRecents, searched,
}: ISearchProps) => {
  const { mixpanelTrack } = useMixpanel();

  const showResults = useCallback((filter: IFilters, searchResults: Project[]) => {
    setTimeout(() => {
      setFilteredProjects(searchResults);
      setSearchLoading(false);

      if (filter.query) {
        const event = !searchResults.length ? 'Hub Home - Project Search No Results' : 'Hub Home - Projects Searched Successfully';
        mixpanelTrack(event, {
          searchedIn: 'Projects',
          searchInput: filter.query,
          searchResultsCount: searchResults.length,
        });
      }
    }, 800);
  }, [mixpanelTrack, setFilteredProjects, setSearchLoading]);

  useEffect(() => {
    searched.addDocuments(Array.from(projects));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  return (
    <Wrapper className='flex flex-wrap items-start w-full max-w-full gap-1' id={view === 'grid' ? 'filter_sort' : ''}>
      <div className='w-full max-w-300'>
        <SearchInput
          placeholder='Search projects or location'
          searchHandle={[
            filters.query as string,
            (value) => filterProjects({ ...filters, query: value }, sort),
          ]}
          storingHandle={[searchRecents, setSearchRecents]}
          searchEndHandle={() => { }}
        />
      </div>

      <div className='flex items-start'>
        <DetailedSelect
          id='filter-createdBy'
          variant='outlined'
          value={filters.createdBy}
          items={options}
          mixpanelEvent='Selecting quick filter'
          handleChange={(e) => {
            mixpanelTrack(`Hub home - clicked on 'Created by ${e?.value}'`);
            const _filters = { ...filters, createdBy: e?.value };
            showResults(_filters, filterProjects(_filters, sort));
          }}
          optionWidth={200}
        />
        {view === 'grid' && (
          <DetailedSelect
            id='sort-projects'
            variant='outlined'
            value={sort}
            items={sortOptions}
            mixpanelEvent='Sort dropdown selected'
            handleChange={(e) => {
              if (e) {
                setSort(e.value);
                showResults(filters, filterProjects(filters, e.value));
              }
              mixpanelTrack(`Hub home - clicked on sort '${e?.title}'`);
            }}
            optionWidth={200}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default SearchProjects;

import { ApolloError, useMutation } from '@apollo/client';
import { Menu } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Folder } from 'types/graphql';
import {
  getApolloErrorData,
  getMimeType,
  invalidFileExtensions,
  parseGraphqlError,
} from 'utils/helpers';

import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
  useCheckPermissions,
  useCreateFolder, useHandleError, useMixpanel,
} from 'hooks';
import { Button } from 'tailwind';
import { compressImages } from 'utils/compressImages';
import Tooltip from '../../Tooltip/Tooltip';
import RefreshDrive from '../RefreshDrive/RefreshDrive';
import { IRefetchQuery } from '../types';
import AddFolder from './AddFolder/AddFolder';
import Transfer from './Transfer/Transfer';
import UploadFiles from './UploadFiles/UploadFiles';
import { MULTIPLE_FILE_UPLOAD } from './apollo';
import { IFilesProps } from './types';

interface IUploadProps {
  currentFolder: Folder;
  type: string;
  driveSize: number[];
  projectId?: string;
  isUpdated: boolean;
  setIsUpdated: React.Dispatch<boolean>;
  refetchQueryAfterUpdate: IRefetchQuery;
  onRefreshHandle: () => void;
}

const uploadToS3 = async ({ file, url }: {
  file: any;
  url: string;
  data: { signedUploadUrl: string; name: string; };
}) => {
  const type = !file?.type ? 'application/octet-stream' : file?.type;
  const compressedFile = await compressImages(file);

  return axios.put(url, compressedFile, {
    headers: { 'Content-Type': type },
  });
};

const chunker = (arr: any[]) => arr.reduce((acc, val, i) => {
  const chunkIndex = Math.floor(i / 5);

  if (!acc[chunkIndex]) {
    acc[chunkIndex] = [];
  }

  acc[chunkIndex].push(val);

  return acc;
}, []);

const Upload: React.FC<IUploadProps> = ({
  projectId,
  type,
  currentFolder,
  isUpdated,
  setIsUpdated,
  refetchQueryAfterUpdate,
  driveSize,
  onRefreshHandle,
}) => {
  const [handleError, enqueueSnackbar] = useHandleError();
  const { mixpanelTrack } = useMixpanel();
  const history = useHistory();
  const [hasPermission] = useCheckPermissions(['PROJECT_ASSET_CREATE']);
  const [newFolder, setNewFolder] = useState<string>('');
  const [files, setFiles] = useState<IFilesProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [uploadSize, setUploadSize] = useState(0);
  const [storageError, setStorageError] = useState(false);
  const refreshButton = document.querySelector('#refresh-drive');

  const checkPath = (folder: Folder) => {
    if (folder.path) return folder.path;
    return '/';
  };

  const uploadExecutor = (data: any[]) => {
    if (!data.length) {
      enqueueSnackbar('Files uploaded', { variant: 'success' });

      setTimeout(() => {
        setLoading(false);
        setIsUpdated(true);
        if (refreshButton) (refreshButton as HTMLButtonElement).click();
      }, 1000);
      return;
    }

    let requests: any = [];

    data[0].forEach((val: { signedUploadUrl: string; fileNid: string; name: string; }) => {
      const currentFile = files.find(({ name }: IFilesProps) => name === val.name);

      requests.push(
        uploadToS3({
          file: currentFile,
          url: decodeURIComponent(val.signedUploadUrl),
          data: val,
        }),
      );
    });

    axios
      .all(requests)
      .then(() => {
        requests = [];
        const cleanData = data;
        cleanData.shift();
        uploadExecutor(cleanData);
      })
      .catch((err) => {
        Sentry.captureException(err);
        setLoading(false);
        enqueueSnackbar('File upload failed!', { variant: 'error' });
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    mixpanelTrack('Add new');
    setAnchor(event.currentTarget);
  };

  const updateFolder = (e: React.ChangeEvent<HTMLInputElement>) => setNewFolder(e.target.value);

  const [multipleFileUpload] = useMutation(MULTIPLE_FILE_UPLOAD, {
    onError: (err: ApolloError) => {
      mixpanelTrack('Upload files Failed');
      const [code] = parseGraphqlError(err.graphQLErrors);
      if (code === 'INSUFFICIENT_DRIVE_SPACE') {
        const { spaceNeeded } = getApolloErrorData(err, {
          spaceNeeded: 0,
        });
        setStorageError(true);
        setUploadSize(spaceNeeded);
      } else {
        handleError(err);
      }
    },
    onCompleted: (data) => {
      mixpanelTrack('Upload files Successful');
      const dataToSend = chunker(data.createUploads);
      uploadExecutor(Array.isArray(dataToSend[0]) ? dataToSend : [dataToSend]);
    },
  });

  const { createFolder, folderLoading } = useCreateFolder(
    [refetchQueryAfterUpdate],
    () => {
      setIsUpdated(true);
      setNewFolder('');
    },
  );

  const onUploadFiles = async () => {
    setAnchor(null);

    const invalidExtensionsNames = invalidFileExtensions(files);

    if (invalidExtensionsNames.length) {
      // runs if there are one or more invalid file types

      const fileLength = invalidExtensionsNames.length > 1 ? 'files types' : 'file type';
      const msg = `Invalid ${fileLength}: ${invalidExtensionsNames.join(', ')}`;
      enqueueSnackbar(msg, { variant: 'error' });
    } else {
      // runs if all files are valid (no other restricted file types)
      const path = checkPath(currentFolder);
      mixpanelTrack('Upload files');
      setLoading(true);
      multipleFileUpload({
        variables: {
          folder: {
            id: currentFolder.id,
            path,
          },
          files: files.map((val) => ({
            name: val.name,
            mimeType: getMimeType(val),
            licenceName: val.licenceName,
          })),
          projectId,
        },
      });
    }
  };

  const onCreateFolder = async () => {
    setAnchor(null);
    const path = checkPath(currentFolder);
    const tooManyLevels = path.split('/').length > 6;

    if (tooManyLevels) {
      return enqueueSnackbar(
        'You have reached the maximum amount of levels within this folder.',
        { variant: 'error' },
      );
    }

    return createFolder(currentFolder.id, newFolder, projectId);
  };

  return (
    <div className='px-2 py-1'>
      <Tooltip
        isVisible={!hasPermission && !!projectId}
        placement="bottom-start"
        header="Viewers cannot add new assets"
        content="Only Admin and Editor roles are able to add new assets to the project drive."
      >
        <span className='flex gap-1'>
          <Button
            size='md'
            padding='sm'
            variant="contained"
            onClick={handleClick}
            disabled={!hasPermission && !!projectId}
          >
            Add new
          </Button>

          <RefreshDrive onRefreshHandle={onRefreshHandle} />
        </span>
      </Tooltip>

      <Menu
        elevation={1}
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className="drive_add_new"
      >
        <AddFolder
          newFolder={newFolder}
          setNewFolder={updateFolder}
          onCreateFolder={onCreateFolder}
          isUpdated={isUpdated}
          loading={folderLoading}
        />
        <UploadFiles
          projectId={projectId}
          files={files}
          setFiles={setFiles}
          onUploadFiles={onUploadFiles}
          isUpdated={isUpdated}
          loading={loading}
          driveSize={driveSize}
          useUploadSize={[uploadSize, setUploadSize]}
          useStorageError={[storageError, setStorageError]}
        />
        {type !== 'personal' && (
          <Transfer
            onTransfer={() => history.push(`/project/${projectId}/transfer/${currentFolder.id}`)
            }
          />
        )}
      </Menu>
    </div>
  );
};

export default Upload;

import { KeyboardBackspace } from '@material-ui/icons';
import React from 'react';
import { Button } from 'tailwind';

interface IBackButtonProps {
  onClick: () => void;
  text?: string;
}

const BackButton = ({ onClick, text = 'Back', ...rest }: IBackButtonProps) => (
  <Button
    variant='text'
    padding='unset'
    startIcon={<KeyboardBackspace />}
    onClick={onClick} {...rest}
  >
    {text}
  </Button>
);

export default BackButton;

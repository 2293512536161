import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { LocationIcon } from '@vucity/design_system';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { Marker, useMap } from 'react-map-gl';

import { getAddress } from 'utils/helpers';
import Geocoder from '../Geocoder/Geocoder';

interface IDrawMapProps {
  selectedCoords: any;
  setSelectedCoords: any;
  setAddress: any;
  bbox?: MapboxGeocoder.Bbox | undefined;
}

const DrawMap: React.FC<IDrawMapProps> = ({
  setAddress,
  setSelectedCoords,
  selectedCoords,
  bbox,
}) => {
  const { current: map } = useMap();
  const { enqueueSnackbar } = useSnackbar();

  const setCoordsAndAddress = async (coords: { lng: number; lat: number; }, address: string = '') => {
    await setSelectedCoords(coords);
    await setAddress(address);
  };

  const onClickMap = useCallback(() => {
    if (!map) return;
    map.on('click', async (e: any) => {
      if (!e.originalEvent.target.classList.contains('mapboxgl-canvas')) return;
      const centerMap = {
        lng: map.getCenter().lng,
        lat: map.getCenter().lat,
        zoom: map.getZoom(),
      };
      try {
        const address = await getAddress(e.lngLat);
        await setCoordsAndAddress(e.lngLat, address);

        await map
          .setCenter({ lng: centerMap.lng, lat: centerMap.lat })
          .setZoom(centerMap.zoom);
      } catch (err) {
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    });
  }, []);

  useEffect(() => {
    onClickMap();

    map?.on('mouseover', () => {
      map.getCanvas().style.cursor = 'default';
    });
  }, []);

  useEffect(() => {
    if (map) {
      const { lng, lat } = selectedCoords;
      setTimeout(() => {
        map.flyTo({
          center: [lng, lat],
          speed: 1.5,
        });
      });
    }
  }, [selectedCoords.lng, selectedCoords.lat, map, selectedCoords]);

  return (
    <>
      <div className='w-full h-full'>
        <Geocoder
          selectedLocation={{
            bbox,
            coords: selectedCoords,
          }}
          setCoordsAndAddress={setCoordsAndAddress}
        />
        <Marker longitude={selectedCoords.lng} latitude={selectedCoords.lat} anchor='bottom'>
          <LocationIcon color="primary" style={{ height: '40px', width: '40px' }} />
        </Marker>
      </div>
    </>
  );
};

export default DrawMap;

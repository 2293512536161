import { Avatar } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import CogFilled from 'assets/icons/CogFilled.svg';
import HelpOutlined from 'assets/icons/HelpOutlined.svg';
import CogOutlined from 'assets/icons/OrgSettingsIcon.svg';

import { ReactComponent as NotificationFilled } from 'assets/icons/NotificationsFilled.svg';
import { ReactComponent as NotificationOutlined } from 'assets/icons/NotificationsIcon.svg';

import { IMagicBellTheme } from '@magicbell/magicbell-react';
import { DeepPartial } from '@magicbell/magicbell-react/dist/lib/types';

export const StyledAvatar = styled(Avatar) <{ $isActive: boolean; }>`
  ${({ theme, $isActive }) => `
    max-width: 100%;
    width: 40px;
    height: 40px;
    background: ${theme.palette.primary.lighter};
    font-size: ${theme.typography.body1.fontSize};
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #fff;

    :hover {
      border: 2px solid ${theme.palette.primary.lighter};
    }

    ${$isActive && `
      border: 2px solid ${theme.palette.primary.main};
    `}
  `}
`;

export const HelpButton = styled.a <any>`
  mask: url(${HelpOutlined}) no-repeat center;
`;

export const Settings = styled.a<{ $isActive?: boolean; }>`
  mask: ${({ $isActive }) => (`url(${$isActive ? CogFilled : CogOutlined}) no-repeat center`)};    
`;

export const NotificationBellIcon = ({ open }: { open: boolean; }) => {
  const icon = 'w-2 h-auto text-primary transition duration-300 hover:text-primary-light';

  return (
    <button className='flex items-center justify-center cursor-pointer'>
      {open ? <NotificationFilled className={icon} /> : <NotificationOutlined className={icon} />}
    </button>
  );
};

export const magicBellTheme: DeepPartial<IMagicBellTheme> = {
  header: {
    textColor: '#375675',
    backgroundColor: '#FFFFFF',
  },
  footer: {
    textColor: '#375675',
    backgroundColor: '#FFFFFF',
  },
  unseenBadge: {
    textColor: '#FFFFFF',
    backgroundColor: '#4C7BAA',
  },
};

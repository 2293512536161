import React, { useState } from 'react';
import { Button, MultipleEmailBox } from 'tailwind';
import { ProjectMemberInput, ProjectMemberRole } from 'types/graphql';

interface IMultipleProps {
  onUpdate: (members: ProjectMemberInput[]) => void;
  setView: (arg: string) => void;
  setMultiCounter: (arg: number) => void;
  defaultRole?: ProjectMemberRole;
}

const MultipleInvites = ({ setView, onUpdate, setMultiCounter, defaultRole }: IMultipleProps) => {
  const [multiMembers, setMultiMembers] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const onAddMember = (emails: string[]) => {
    setMultiMembers(emails);
    setMultiCounter(emails.length);
  };

  const onReview = () => {
    const members: ProjectMemberInput[] = multiMembers.map(m => ({ email: m, role: defaultRole }));
    members.unshift({ email: '', role: defaultRole });

    if (errors.length > 0) setErrors(errors);
    onUpdate(members);
    setView('review');
  };

  const counter = multiMembers.filter(m => m).length;

  return (
    <div className='flex items-start gap-2'>
      <MultipleEmailBox
        className='min-w-350'
        emails={multiMembers}
        setEmails={onAddMember}
        setErrors={setErrors}
        label='Enter email separated by space'
      />

      <Button
        className='mt-3'
        padding='sm'
        variant='outlined'
        disabled={!counter || errors.length > 0}
        onClick={onReview}
      >
        Review invites
      </Button>
    </div>
  );
};

export default MultipleInvites;

import { SatisfiedIcon } from '@vucity/design_system';
import React from 'react';

const NoProjects = () => (
  <div className='absolute flex flex-col items-center justify-center w-full gap-2 mt-3 top-1/2 text-content'>
    <SatisfiedIcon htmlColor="#80A6B1" fontSize="large" />
    <p className='text-lg'>You currently have no projects</p>
  </div>
);

export default NoProjects;

import { gql, TypedDocumentNode } from '@apollo/client';
import { StripeSubscription } from 'types/graphql';

export const CREATE_STRIPE_SESSION: TypedDocumentNode<CreateStripeSession> = gql`
  mutation createStripeSession(
    $licenses: [LineItem]
    $tiers: [LineItem]
    $cancelUrl: String
    $successUrl: String
  ) {
    createStripeSession(
      licenses: $licenses
      tiers: $tiers
      cancelUrl: $cancelUrl
      successUrl: $successUrl
    )
  }
`;

export const CREATE_TRIAL_SUBSCRIPTION: TypedDocumentNode<{ createTrialSubscription: StripeSubscription }> = gql`
  mutation createTrialSubscription(
    $licenses: [LineItem]
    $tiers: [LineItem]
  ) {
    createTrialSubscription(
      licenses: $licenses
      tiers: $tiers
    ){
      created
      stats {
        plus {
          total
        }
        pro {
          total
        }
    }
    }
  }
`;

export const UPGRADE_SUBSCRIPTION: TypedDocumentNode<{ upgradeSubscription: StripeSubscription }> = gql`
  mutation UpgradeSubscription($interval: BillingInterval!, $tiers: SeatQuantities, $licenses: [ID], $prorationDate: Int) {
    upgradeSubscription(
      interval: $interval, 
      tiers: $tiers, 
      licenses: $licenses, 
      proration_date: $prorationDate
    ) {
      status
      id
    }
  }
`;

interface CreateStripeSession {
  createStripeSession: string;
}

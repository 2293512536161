import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { Divider } from '@material-ui/core';
import { UndoIcon } from '@vucity/design_system';
import { useLocalStorageState } from 'ahooks';
import LogoDark from 'assets/VU.CITY-Dark.svg';
import { ReactComponent as HiddenSvg } from 'assets/icons/VisibleCrossed.svg';
import { ReactComponent as VisibleSvg } from 'assets/icons/VisibleFilled.svg';
import { ProjectWithSameOrg } from 'components/ProjectCard/ProjectCard';
import ToggleStatus from 'components/ProjectCard/ToggleStatus/ToggleStatus';
import { useHandleError, useMixpanel } from 'hooks';
import useCloudinary from 'hooks/useCloudinary';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Label } from 'tailwind';
import { capitalizeWord } from 'utils/helpers';
import KebabMenu from '../ProjectsVisibilityMenu/KebabMenu';
import CustomMenuItem from '../ProjectsVisibilityMenu/MenuItem';
import { UPDATE_PROJECT_VISIBILITY } from '../apollo';
import ProjectDescriptionModal from './modal';
import { StyledLogo } from './style';

interface ProjectDescriptionProps {
  project: ProjectWithSameOrg;
  isLimited: boolean;
  hidden: boolean;
  handleOnClick: (e: React.SyntheticEvent) => void;
  inactive: boolean;
  isRelative?: boolean;
  refetch: () => any;
  setVisiblityPreference: (preference: string) => void;
  visiblityPreference: string;
}

const ProjectDescription: React.FC<ProjectDescriptionProps> = ({ project,
  isLimited,
  hidden,
  handleOnClick,
  inactive,
  refetch,
  visiblityPreference,
  setVisiblityPreference,
  isRelative }) => {
  const [open, setOpen] = React.useState(false);
  const { mixpanelTrack } = useMixpanel();
  const [showMore, setShowMore] = useState(false);
  const [cld, token] = useCloudinary();
  const { user } = useAuth0();
  const [projectView] = useLocalStorageState('Home view', { defaultValue: 'grid' });
  const [updateProjectVisibility] = useMutation(UPDATE_PROJECT_VISIBILITY);
  const [handleError, enqueueSnackbar] = useHandleError();

  const handleOnClose = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    mixpanelTrack('Project Card > Kebab Menu > Project Description > X');
    setOpen(false);
  };

  const handleKebabMenuClick = () => {
    mixpanelTrack('Project Card > Kebab Menu', {
      'Hub View': projectView,
      'Visibility View': visiblityPreference,
      'Project State': project.access,
    });
  };

  const handleMenuItemClick = () => {
    mixpanelTrack('Project Card > Kebab Menu > Project Description', {
      'Hub View': projectView,
      'Visibility View': visiblityPreference,
      'Project State': project.access,
    });
    setOpen(true);
  };

  const handleShowMore = (e: React.SyntheticEvent, state: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMore(state);
  };

  const renderDescription = (description: string) => {
    if (description.length < 215) return <p className='text-sm'>{description}</p>;
    if (description.length > 215) return (
      <>
        {!showMore && (<p className='text-sm' onClick={(e) => e.stopPropagation()}>
          {description.substring(0, 215)}...<span onClick={(e) => handleShowMore(e, true)} className='underline text-primary font-medium text-xs cursor-pointer'>See more</span>
        </p>)}
        {showMore && <p className='text-sm' onClick={(e) => e.stopPropagation()}>
          {description}
          <span onClick={(e) => handleShowMore(e, false)} className='underline text-primary font-medium text-xs cursor-pointer'>&nbsp;See less</span>
        </p>}
      </>
    );
  };

  const addMeToHiddenFor = () => [...project.hiddenFor!, user?.sub!];
  const removeMeFromHiddenFor = () => project.hiddenFor!?.filter(id => id !== user?.sub!);

  const updateProjectState = (customMessage: JSX.Element, undo?: boolean) => {
    let list: string[] = [];
    if (undo && hidden) list = addMeToHiddenFor();
    if (undo && !hidden) list = removeMeFromHiddenFor();
    if (!undo) list = hidden ? removeMeFromHiddenFor() : addMeToHiddenFor();
    updateProjectVisibility({
      variables: {
        id: project.id,
        project: {
          hiddenFor: list,
        },
      },
      onCompleted: async () => {
        if (!undo) enqueueSnackbar(customMessage, { variant: 'success' });
        await refetch();
      },
      onError: handleError,
    });
  };

  const handleOpenClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const clickedElement = e.target as HTMLElement;
    if (!clickedElement.classList.contains('open-button')) return e.preventDefault();
    setOpen(false);
    mixpanelTrack('Project Card > Kebab Menu > Project Description > Open Project', {
      'Project ID': project.id,
    });
    if (project.me?.status === 'INVITED') {
      mixpanelTrack('Project Card > Kebab Menu > Project Description > Join Project', {
        'Project ID': project.id,
      });
    }
    handleOnClick(e);
  };

  const viewProjects = (visible: boolean) => {
    if (visible) return setVisiblityPreference('Visible Projects');
    setVisiblityPreference('Hidden Projects');
  };

  const renderCustomMessage = (view: string) => {
    const handleUndo = () => {
      updateProjectState(renderCustomMessage(view), true);
    };
    return (
      <>
        {view === 'Hide project' && (
          <>
            Your project has been hidden,&nbsp;
            <span className='underline cursor-pointer' onClick={() => viewProjects(false)}>view hidden projects</span>
          </>
        )}
        {view === 'Show project' && (<>
          Your project is visible,&nbsp;
          <span
            className='underline cursor-pointer'
            onClick={() => viewProjects(true)}
          >
            view visible projects
          </span>
        </>)}&nbsp;&nbsp;
        <UndoIcon className='cursor-pointer' onClick={handleUndo} />
      </>
    );
  };

  const toggleViewState = (view: string) => {
    updateProjectState(renderCustomMessage(view));
  };

  return (
    <>
      <KebabMenu
        className={`w-3 h-3 ${isRelative ? 'relative' : 'absolute top-2 right-1'} bg-content-light rounded-full flex`}
        onClick={handleKebabMenuClick}
      >
        <CustomMenuItem
          value='Project description'
          onClick={handleMenuItemClick}
          className='text-content hover:bg-primary-lightest text-base rounded-sm px-1 py-0.25'
        >
          Project description
        </CustomMenuItem>
        <Divider className='mt-1 mb-1' />
        <CustomMenuItem
          value={!hidden ? 'Hide project' : 'Show project'}
          onClick={toggleViewState}
          className='text-content hover:bg-primary-lightest text-base rounded-sm px-1 py-0.25'
        >
          {hidden ? 'Show' : 'Hide'} project
        </CustomMenuItem>
      </KebabMenu>
      <ProjectDescriptionModal open={open} onClose={handleOnClose}>
        <Link
          to={`/project/${project.id}`}
          onClick={handleOpenClick}
        >
          <StyledLogo
            imageId={project.imageId}
            image={project.imageId ? `${cld
              .image(project.imageId)
              .resize(scale().width(526).height(180))
              .toURL()}&${token}` : ''}
          >
            {!project.imageId && (
              <div className='w-full h-full bg-placeholder flex items-center justify-center'>
                <img src={LogoDark} alt='VU.CITY Project' />
              </div>
            )}
            <ToggleStatus access={project.access} status={isLimited ? 'LIMITED' : project.me?.status} isSameOrg={project.isSameOrg} />
            {project.location && (
              <Label className='absolute bottom-2 left-2'>{project.location.license?.name}</Label>
            )}
            <button
              className='absolute bottom-2 right-2.5 w-4 h-3 p-0.5 bg-white rounded flex justify-center items-center'
            >
              {!hidden ? <VisibleSvg className='text-primary' /> : <HiddenSvg className='text-primary' />}
            </button>
          </StyledLogo>
          <div className='w-full p-3 flex flex-col gap-2'>
            <h1 className='font-medium text-2xl'>{project.name}</h1>
            {project.description && renderDescription(project.description)}
            <div className='flex justify-between gap-2'>
              <div>
                <p className='text-sm font-medium'>Creator</p>
                <p className='text-xs'>{project.owner.name}</p>
              </div>
              <div>
                <p className='text-sm font-medium'>My project role</p>
                <p className='text-xs'>{capitalizeWord(project.me?.role)}</p>
              </div>
              <div>
                <p className='text-sm font-medium'>Created</p>
                <p className='text-xs'>{new Date(project.createdAt).toLocaleDateString('en-GB')}</p>
              </div>
              <div>
                <Button
                  variant='contained'
                  size='sm'
                  color='primary'
                  className='open-button'
                >
                  {inactive ? 'Join project' : 'Open project'}
                </Button>
              </div>
            </div>
          </div>
        </Link>
      </ProjectDescriptionModal>
    </>
  );
};

export default ProjectDescription;
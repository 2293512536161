import { useCallback, useEffect } from 'react';
import { useMap } from 'react-map-gl';

let activeId: string | null = null;
let activeVertex: string | null = null;

const Polygon = () => {
  const { current } = useMap();

  const onHover = useCallback((e: any) => {
    if (!current) return;
    if (!e.featureTarget) return current.getCanvas().style.cursor = 'default';

    if (activeId && e.featureTarget.properties.id === activeId && !activeVertex)
      current.getCanvas().style.cursor = 'move';
    else current.getCanvas().style.cursor = 'pointer';
  }, [current]);

  const onCircleClick = useCallback((e: any) => {
    if (!e.featureTarget || !current) {
      activeVertex = null;
      return activeId = null;
    }

    const { properties } = e.featureTarget || {};
    const isVertex = properties.meta === 'vertex';

    if (!isVertex) {
      activeVertex = null;
      activeId = properties.id;
      current.getCanvas().style.cursor = 'pointer';
    } else {
      activeId = null;
      activeVertex = properties.parent;
      current.getCanvas().style.cursor = 'move';
    }
  }, [current]);

  // Mouse move effect on the polygon
  useEffect(() => {
    current?.on('mousemove', '250_fill_layer', onHover);
    return () => { current?.off('mousemove', '250_fill_layer', onHover); };
  }, [current, onHover]);

  // Click on the polygon
  useEffect(() => {
    current?.on('mousedown', '250_fill_layer', onCircleClick);
    return () => { current?.off('mousedown', '250_fill_layer', onCircleClick); };
  }, [current, onCircleClick]);

  return null;
};

export default Polygon;

import { GQLWrapper } from 'components';
import React from 'react';
import Button, { ButtonProps } from 'tailwind/Buttons/BaseButton';

interface AccountLayoutProps {
  children: React.ReactNode;
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  button?: {
    text: string;
    variant: ButtonProps['variant'];
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  };
  gql?: {
    loading?: boolean;
    error?: any;
    renderChildren?: boolean;
  };
}

const AccountLayout = ({ children, title, subtitle, button, gql }: AccountLayoutProps) => {
  const { text, variant = 'contained', onClick } = button || {};
  const { loading, error, renderChildren } = gql || {};

  return (
    <main className='flex-grow bg-white text-content'>
      <GQLWrapper loading={loading} error={error} renderChildren={renderChildren}>
        <div className='flex flex-wrap items-start justify-between gap-2 mb-3'>
          <div className='flex flex-col gap-0.5'>
            <h4 className='flex items-center gap-1'>{title}</h4>
            {subtitle && (
              <p className='flex items-center gap-1 text-grey text-base [&>svg]:h-2.5 [&>svg]:w-2.5'>
                {subtitle}
              </p>
            )}
          </div>

          {button && (
            <Button padding='sm' variant={variant} onClick={onClick}>
              {text}
            </Button>
          )}
        </div>

        {children}
      </GQLWrapper>
    </main>
  );
};

export default AccountLayout;

import React, { useRef, useState } from 'react';
import { DetailedSelectItem } from 'views/types';
import Tooltip from 'components/Tooltip/Tooltip';

const SelectOption = (props: {
  item: DetailedSelectItem;
  isActive: boolean;
  showTooltip?: boolean;
}) => {
  const optionRef = useRef<HTMLParagraphElement>(null);
  const [isOptionHovered, setIsOptionHovered] = useState(false);
  const isOverflowing = () =>
    isOptionHovered && optionRef.current
      ? optionRef.current.scrollWidth > optionRef.current.offsetWidth
      : false;

  return (
    <div
      onMouseEnter={() => setIsOptionHovered(true)}
      onMouseLeave={() => setIsOptionHovered(false)}
      className={`h-3 flex flex-row items-center justify-between overflow-hidden ${
        props.isActive ? 'text-primary font-medium' : ''
      }`}
    >
      <Tooltip
        isVisible={props.showTooltip && props.item.customTooltipValue || props.showTooltip && isOverflowing()}
        placement='top-start'
        content={props.item.customTooltipValue ? props.item.customTooltipValue : props.item.title}
      >
        <p
          ref={optionRef}
          className='block overflow-hidden text-ellipsis whitespace-nowrap'
        >
          {props.item.title}
        </p>
      </Tooltip>

      {props.item?.titleRightInfo && (
        <span className='px-1 py-0.5 flex items-center m-0 text-xs rounded bg-primary-lightest text-primary'>
          {props.item?.titleRightInfo}
        </span>
      )}
    </div>
  );
};

export default SelectOption;

import { gql } from '@apollo/client';

export const RENAME_NODES = gql`
  mutation ($node: NodeInput, $projectId: ID, $name: String!, $policy: WritePolicy) {
    renameNode(node: $node, projectId: $projectId, name: $name, policy: $policy) {
      name
      id
      path
    }
  }
`;

import React from 'react';
import * as icon from './cityIcons';

export const componentMap: { [key: string]: React.ReactNode; } = {
  1: <icon.London />,
  14: <icon.Manchester />,
  15: <icon.Brighton />,
  16: <icon.Oxford />,
  20: <icon.Birmingham />,
  28: <icon.Belfast />,
  29: <icon.NewYork />,
  31: <icon.Woking />,
  32: <icon.Edinburgh />,
  36: <icon.Bristol />,
  37: <icon.Liverpool />,
  38: <icon.Dublin />,
  39: <icon.Sidney />,
  40: <icon.Berlin />,
  41: <icon.Cambridge />,
  42: <icon.Nottingham />,
  43: <icon.Sheffield />,
  44: <icon.Cardiff />,
  45: <icon.SouthendOnSea />,
  46: <icon.Slough />,
  47: <icon.Leeds />,
  51: <icon.Portsmouth />,
  54: <icon.Glasgow />,
  56: <icon.Guildford />,
  59: <icon.Rochdale />,
  69: <icon.Watford />,
  70: <icon.Dacorum />,
  73: <icon.Medway />,
  76: <icon.Southampton />,
  84: <icon.Bolton />,
};

interface CityIconProps {
  locationId: string;
  size: string;
}

const CityIcon = ({ locationId, size }: CityIconProps) =>
  <svg className={`flex-shrink-0 inline-block fill-current ${size}`} viewBox="0 50 244 359">
    {componentMap[locationId] || <icon.London />}
  </svg>;

export default CityIcon;

export const grid = {
  root: {
    '&.step2_licenses': {
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
  },
};

export default grid;

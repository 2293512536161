import { gql } from '@apollo/client';

export const MULTIPLE_FILE_UPLOAD = gql`
  mutation MultipleFileUpload($folder: NodeInput, $files: [FileToUpload]!, $projectId: ID, $metadata: FileMetaInput) {
    createUploads(folder: $folder, files: $files, projectId: $projectId, metadata: $metadata, policy: RENAME) {
      name
      signedUploadUrl
      fileNid
    }
  }
`;

export default MULTIPLE_FILE_UPLOAD;

import { useMutation, useQuery } from '@apollo/client';
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';
import UnableToJoin from 'components/Modal/UnableToJoin/UnableToJoin';
import { useHandleError, useMixpanel } from 'hooks';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AccountLayout } from 'tailwind';
import { OrgMember } from 'types/graphql';
import { GET_INVITATIONS, RSVP_INVITE } from 'views/invitations/apollo';
import OrganisationInvitation from 'views/invitations/components/OrganisationActions';

const Invitations = () => {
  const history = useHistory();
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();

  const [invitations, setInvitations] = useState<OrgMember[]>();
  const [open, setOpen] = useState(false);

  useQuery(GET_INVITATIONS, {
    onCompleted: ({ currentUser }) => {
      setInvitations(currentUser.orgInvites);
    },
  });

  const [rsvpInvite] = useMutation(RSVP_INVITE, {
    refetchQueries: [{ query: GET_INVITATIONS }],
    awaitRefetchQueries: true,
    onCompleted: ({ rsvpOrgInvite: { status, organisation: org } }) => {
      const predicateText = status !== 'ACCEPTED' ? 'Decline' : '';
      enqueueSnackbar(status === 'ACCEPTED' ? 'Invitation accepted' : 'Invitation declined', { variant: 'success' });
      if (status === 'ACCEPTED') history.push('/account/organisation/details');
      else mixpanelTrack(`Confirmed Org invite ${predicateText}`, { OrgID: org?.id, OrgName: org?.name });
    },
    onError: (error) => {
      if (handleError(error) === 'CANNOT_JOIN_ORG') setOpen(true);
    },
  });

  const handleInvite = (response: string, id: string) => {
    rsvpInvite({
      variables: {
        orgId: id,
        response,
      },
    });
  };

  return (
    <AccountLayout
      title='Invitations'
      subtitle='Choose an organisation to join'
    >
      <div className='flex items-start gap-1.2 py-2 pl-1.2 mb-3 border border-l-8 rounded border-primary-light bg-primary-lightest'>
        <InfoIcon className='w-[30px] h-[30px] [&>path]:fill-primary-light' />

        <div className='text-sm'>
          <div>You are currently a member of an organisation (this may be your own).</div>
          <div className='font-medium'>
            You can choose to join another organisation, if you have been invited to,
            except where:
          </div>

          <ul className='mt-2 ml-3 text-sm list-disc'>
            <li>You are already part of a multi-member organisation</li>
            <li>Your organisation has an active paid subscription</li>
            <li>
              You will not be able to leave the new organisation
              unless an organisation Admin removes you
            </li>
          </ul>
        </div>
      </div>

      <div className='w-full [&>div]:w-auto flex flex-col gap-2'>
        {invitations?.map((val: OrgMember) => (
          <OrganisationInvitation
            key={val.id}
            handleInvite={handleInvite}
            {...val}
          />
        ))}
      </div>

      <UnableToJoin open={open} handleCloseModal={() => setOpen(false)} />
    </AccountLayout>
  );
};

export default Invitations;

import React from 'react';
import { IfPermissions } from '../../components';
import InsufficientAccess from '../../components/Errors/InsufficientAccess';
import { UserPermissions } from '../../types/graphql';
import PrivateRoute from './PrivateRoute';

interface Props {
  scopes: UserPermissions[],
  path: string,
  compProps: any,
  component: React.ComponentType<any> | undefined
}

const RestrictedRoute = (props: Props) => (
  <IfPermissions scopes={props.scopes} FallbackComponent={() => <InsufficientAccess />}>
    <PrivateRoute path={props.path} exact component={props.component} compProps={props.compProps} />
  </IfPermissions>
);

export default RestrictedRoute;

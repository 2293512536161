import { useLazyQuery, useMutation } from '@apollo/client';
import { GQLWrapper } from 'components';
import { DELETE_NODES } from 'components/Drive/DriveContextMenu/DriveActions/apollo';
import { DriveSelectedFilesContext } from 'components/Drive/DriveProvider/DriveProvider';
import { useCreateProjectMembers, useCurrentUser, useHandleError, useLicences, useMixpanel } from 'hooks';
import uniqBy from 'lodash/uniqBy';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, FullwidthLayout } from 'tailwind';
import { ProjectInput } from 'types/graphql';
import { extractCityName, groupFiles, recurseSearch } from 'utils/helpers';
import GET_PERSONAL_DRIVE from 'views/drive/apollo';
import { LocationState } from 'views/types';
import { Back, CreateDrive, CreateForm } from '.';
import DeleteFilesModal from './DeleteFilesModal/DeleteFilesModal';
import { COPY_NODES, CREATE_PROJECT, GET_ROLE } from './apollo';

const CreateFromDrive = () => {
  const [mismatched, setMismatched] = useState<string[]>([]);

  const { handleSubmit, register, formState: { errors }, setError, clearErrors, setValue, watch } = useForm<ProjectInput>();

  const { createProjectMembers } = useCreateProjectMembers();
  const { currentUser } = useCurrentUser();
  const { allLicences } = useLicences();
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();

  const history = useHistory();
  const location = useLocation<LocationState>();
  const { projectId } = useParams<{ projectId?: string; }>();

  const { selectedFiles, setSelectedFiles } = useContext(DriveSelectedFilesContext);

  const back = location.state?.prevPath || (projectId ? `/project/${projectId}/drive` : '/drive');

  const [getProjectRole] = useLazyQuery(GET_ROLE);
  const [createProject, { loading }] = useMutation(CREATE_PROJECT);
  const [copyNodes, { loading: copyLoading }] = useMutation(COPY_NODES);
  const [deleteNodes] = useMutation(DELETE_NODES);
  const removeFilesRef = useRef<boolean>(false);
  const [open, setOpen] = useState(false);
  const [isDuplicateName, setIsDuplicateName] = useState(false);

  const { orgAccess, orgDefaultRole } = {
    orgAccess: currentUser?.organisation?.data?.access || 'INVITE_ONLY',
    orgDefaultRole: currentUser?.organisation?.data?.defaultRole,
  };

  const groupedFiles = useMemo(() => groupFiles(selectedFiles), [selectedFiles]);
  const { files, parentFolder } = useMemo(() => recurseSearch(groupedFiles), [groupedFiles]);

  const isDisabled = !watch('name')
    || !watch('location')
    || !!Object.keys(errors).length
    || loading
    || copyLoading;

  // Handle .VU files that don't match the selected project location
  useEffect(() => {
    const { licenseId, name } = watch('location') || {};

    const filesWithMultipleLocations = uniqBy(files, 'metadata.licenseName');
    const filesNoMatchLocation = filesWithMultipleLocations
      .filter(({ metadata }) => metadata.licenseName !== name);

    if (filesWithMultipleLocations.length > 1 || (licenseId && filesNoMatchLocation.length > 0)) {
      setError('location', {
        message: `
          You have selected .vu files from multiple city locations.
          <p class='font-medium'>Please choose .vu files matching the same location only.</p>
        `,
      });

      const mismatchedFiles = selectedFiles
        .filter(({ id }) => files.some((file) => id === file.id))
        .map(({ id }) => id);

      setMismatched([...parentFolder, ...mismatchedFiles]);
    } else { clearErrors('location'); setMismatched([]); }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedFiles, files, parentFolder, setError, selectedFiles, clearErrors, watch('location')]);

  const checkRole = () => {
    let isViewer: boolean = false;

    if (projectId) {
      getProjectRole({
        variables: { id: projectId },
        onCompleted: (res) => {
          const role = res?.project?.me?.role || '';

          if (role === 'VIEWER') {
            isViewer = true;
            return enqueueSnackbar('Your role has been changed and do not have access to this feature.', { variant: 'error' });
          }

          return null;
        },
      });
    }

    return isViewer;
  };

  const onCreateProject = async () => {
    const { licenseId, coords } = watch('location') || {};
    const { x, y } = coords || {};

    const nodes = selectedFiles.map((file) => ({ id: file.id }));
    const members = createProjectMembers(watch('members') || [], 'ADMIN');
    const isViewer = checkRole();

    const redirectToProject = (projId: string) => {
      history.push(`/project/${projId}`);
      setSelectedFiles([]);
    };

    if (isViewer && projectId) return redirectToProject(projectId);

    return createProject({
      variables: {
        project: {
          name: watch('name'),
          location: { licenseId, coords: { x, y } },
          members,
          access: orgAccess,
          defaultRole: orgDefaultRole,
        },
      },
      onError: handleError,
      onCompleted: (res) => {
        const { id } = res.createProject;
        const rootFolderId = res.createProject.drive.nodeByPath.id;
        enqueueSnackbar('Your project has been successfully created. Up to a maximum of 6 levels deep (sub-folders).', {
          variant: 'success',
        });
        if (!selectedFiles.length) return redirectToProject(id);

        return copyNodes({
          variables: {
            projectId: id,
            nodes,
            folder: { id: rootFolderId },
          },
          onError: (err) => {
            handleError(err);
            redirectToProject(id);
          },
          onCompleted: () => {
            if (!removeFilesRef.current) return redirectToProject(id);
            return deleteNodes({
              variables: {
                nodes: selectedFiles.map(({ id: nodeId, path }) => ({ id: nodeId, path })),
              },
              awaitRefetchQueries: true,
              refetchQueries: [GET_PERSONAL_DRIVE],
              onCompleted: () => {
                enqueueSnackbar('Your files have been successfully removed from your personal drive', {
                  variant: 'success',
                });
                redirectToProject(id);
              },
              onError: (err) => {
                handleError(err);
                redirectToProject(id);
              },
            });
          },
        });
      },
    });
  };

  const onLicenceChange = useCallback((e: string) => {
    const findLicence = allLicences.find(({ id }) => id === e);
    const coords = findLicence?.location.centre.convert;

    if (!findLicence || !coords) return;

    setValue('location', {
      licenseId: e,
      coords,
      name: extractCityName(findLicence.stripeProduct.name),
    });
    mixpanelTrack('Project location updated', {
      'Project location selected': findLicence.stripeProduct.name,
    });
  }, [allLicences, mixpanelTrack, setValue]);

  const onTrackBtn = (position: string) => {
    const members = createProjectMembers(watch('members') || [], 'ADMIN');
    const isAllSelected = watch('members') || [].filter(({ email }) => email === 'all').length > 0;
    const type = projectId ? 'project' : 'personal';

    mixpanelTrack(`Create project (${position}) from ${type} drive`, {
      'Drive source': type,
      'Total no. of files copied': selectedFiles.length,
      'Total no. of .VU files copied': files.length,
      'Total no. of Admins invited': members.length,
      'Entire organisation selected': isAllSelected,
    });

    if (type === 'project') return handleSubmit(onCreateProject)();

    return setOpen(true);
  };

  const handleModalActions = (deleteFiles: boolean) => {
    removeFilesRef.current = deleteFiles;
    const extra = {
      'Total no. of files copied': selectedFiles.length,
      'Total no. of .VU files copied': files.length,
    };
    const event = deleteFiles
      ? 'Create project from personal drive/Delete files/Yes, delete'
      : 'Create project from personal drive/Delete files/No, keep the files';
    mixpanelTrack(event, extra);
    handleSubmit(onCreateProject)();
    setOpen(false);
  };

  return (
    <FullwidthLayout>
      <GQLWrapper loading={loading || copyLoading}>
        <div className='flex items-start justify-between mb-1'>
          <Back backUrl={back} setSelectedFiles={setSelectedFiles} />

          <Button
            variant='contained'
            onClick={() => onTrackBtn('top')}
            disabled={isDisabled}
          >
            Create project
          </Button>
        </div>

        <h3>Create your new project</h3>
        <p className='mt-1'>
          You must select one location to create a project from your drive.
          You cannot import any .vu project files that do not match this location.
        </p>

        <section className='flex items-start w-full gap-2 mt-2 mb-4'>
          <article className='w-full max-w-[350px] flex-shrink-0 pb-3 bg-primary-lightest border border-placeholder rounded-[12px]'>
            <p className='px-2 py-3 pb-1 text-lg font-medium'>
              Confirm your project info
            </p>

            <CreateForm
              watch={watch}
              setValue={setValue}
              allLicences={allLicences}
              onLicenceChange={onLicenceChange}
              formData={{ register, errors, setError, clearErrors }}
              isDuplicateName={isDuplicateName}
              setIsDuplicateName={setIsDuplicateName}
            />
          </article>

          <article className='w-full max-w-[calc(100%-367px)] flex-shrink-0 py-3 px-2 bg-primary-lightest border border-placeholder rounded-[12px]'>
            <CreateDrive
              currentUser={currentUser}
              onInitialLicence={onLicenceChange}
              location={watch('location')?.name || ''}
              hasMultipleLocations={mismatched}
            />
          </article>
        </section>

        <div className='flex justify-end w-full'>
          <Button
            variant='contained'
            onClick={() => onTrackBtn('bottom')}
            disabled={isDisabled}
          >
            Create project
          </Button>
        </div>
      </GQLWrapper>

      <DeleteFilesModal
        open={open}
        handleMainButtonClick={() => handleModalActions(true)}
        handleNoClick={() => handleModalActions(false)}
        selectedFiles={selectedFiles}
        handleClose={() => setOpen(false)}
      />
    </FullwidthLayout>
  );
};

export default CreateFromDrive;

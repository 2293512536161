import { MenuItem } from '@material-ui/core';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  selected?: boolean;
  value: string;
  onClick?: (value: string) => void;
  className?: string;
}

export default function CustomMenuItem({ children, onClick, value, selected, className }: Props) {
  return (
    <MenuItem
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(value);
      }}
      className={!className ? `hover:text-primary ${selected && 'bg-primary-lightest text-primary font-bold rounded'}` : className}
    >
      {children}
    </MenuItem>
  );
}
import { useQuery } from '@apollo/client';
import { useCurrentUser, useMixpanel } from 'hooks';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { StripeSubscription } from 'types/graphql';

import { AccountLayout } from 'tailwind';
import { GET_ORG_SUBSCRIPTIONS } from './apollo';
import Subscriptions from './components/Subscriptions';
import { fakeMonthlySubscription } from './factory';
import { noSubscriptions } from './text';
import { aggregateData } from './helpers';

const BillingView = () => {
  const { mixpanelTrack } = useMixpanel();
  const { floating: { hasFloating } } = useCurrentUser();

  const [subs, setSubs] = useState<StripeSubscription[]>([]);

  const history = useHistory();
  const { pathname } = useLocation();

  const { data, loading, error } = useQuery(GET_ORG_SUBSCRIPTIONS, {
    onCompleted: ({ currentUser: { organisation } }) => {
      const showEmptyMonthlyIfAnnualProExists = organisation?.subscriptions.some(
        (subscription: StripeSubscription) => subscription.interval === 'year' && subscription.stats.pro.total > 0,
      )
        && !organisation?.subscriptions.some(
          (subscription: StripeSubscription) => subscription.interval === 'month',
        );

      setSubs(
        showEmptyMonthlyIfAnnualProExists
          ? [...organisation?.subscriptions, fakeMonthlySubscription]
          : organisation?.subscriptions,
      );
    },
  });

  const { siteLicenses } = data?.currentUser?.organisation ?? {};

  const subsCopy = [...subs];

  const annualSubs: StripeSubscription[] = subsCopy.filter((sub: StripeSubscription) => sub?.interval === 'year');
  const monthlySubs: StripeSubscription[] = subsCopy.filter((sub: StripeSubscription) => sub?.interval === 'month');

  const annualAggregated = aggregateData(annualSubs);
  const monthlyAggregated = aggregateData(monthlySubs);
 
  const allSubs: StripeSubscription[] = (annualSubs.length > 1 || monthlySubs.length > 1) ? [annualSubs.length > 1 ? annualAggregated : annualSubs[0], monthlySubs.length > 1 ? monthlyAggregated : monthlySubs[0]] : subsCopy;
  const sortedSubs = allSubs?.sort((sub: StripeSubscription) => (sub?.interval === 'year' ? -1 : 1))
    ?.map((sub) => ({
      ...sub,
      cityLicenses: sub?.cityLicenses.filter((lic) => !(siteLicenses || [])
        .some(({ license }) => license.id === lic.license.id)),
    }));

  const orgSubs = sortedSubs.filter((sub) =>
    !(sub.interval === 'year' &&
      !sub.cityLicenses.length &&
      sub.stats.pro.total === 0 &&
      sub.stats.plus.total === 0 &&
      sub.stats.floating.total === 0),
  );

  return (
    <AccountLayout
      title='Billing and payments'
      gql={{ loading, error, renderChildren: !!data }}
      button={{
        text: 'Get in touch with our team',
        variant: 'outlined',
        onClick: () => {
          history.push('/contact', { prevPath: pathname });
          mixpanelTrack("Clicked on 'Get in touch with our team' from Billing and payments page");
        },
      }}
    >
      <div className='text-content'>
        <div className='flex lg:gap-2 max-lg:flex-wrap'>
          {subs?.length > 0 && (
            orgSubs?.map((val: StripeSubscription, index: number) => (
              <Subscriptions
                key={`${index}_${val?.interval}`}
                subs={val}
                hasFloating={hasFloating}
                trial={data?.currentUser?.organisation?.trialActive}
                floating={data?.currentUser?.organisation?.seatStats.floating}
              />
            ))
          )}
          {(!subs?.length && !hasFloating) && (
            <div className='pt-4 text-lg'>
              {noSubscriptions()}
            </div>
          )}
        </div>
      </div>
    </AccountLayout>
  );
};

export default BillingView;

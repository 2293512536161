import { useMutation } from '@apollo/client';
import TextModal from 'components/Modal/TextModal/TextModal';
import { useHandleError } from 'hooks';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Project, UserTier } from 'types/graphql';
import { GET_CURRENT_USER } from 'utils/apollo/user';
import { RSVP_PROJECT } from 'views/project/apollo';
import GET_PROJECTS from 'views/projects/apollo';

interface ProjectInviteModalProps {
  inviteModal: boolean;
  setInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  tier: UserTier;
  projectId: string;
  project: Project | null;
}

const ProjectInviteModal: React.FC<ProjectInviteModalProps> = (props) => {
  const { inviteModal, setInviteModal, tier, projectId, project } = props;
  const history = useHistory();
  const [handleError, enqueueSnackbar] = useHandleError();

  const [rsvpProject] = useMutation(RSVP_PROJECT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PROJECTS }, GET_CURRENT_USER],
    onCompleted: (data: any) => {
      const {
        rsvpProject: { status },
      } = data;
      enqueueSnackbar(
        `Project invite ${status === 'ACCEPTED' ? 'accepted' : 'declined'}`,
        { variant: 'success' },
      );
      if (status === 'ACCEPTED') {
        history.push(`/project/${projectId}`);
      }
      return setInviteModal(false);
    },
    onError: handleError,
  });

  const handleInvite = (response: 'ACCEPT' | 'DECLINE') => {
    rsvpProject({
      variables: {
        projectId,
        response,
      },
    });
  };

  return (
    <TextModal
      headerText="Join project?"
      open={inviteModal}
      setOpen={setInviteModal}
      mixpanelPath="Join project"
      closeBtnLabel="Decline invite"
      closeBtnHandle={() => handleInvite('DECLINE')}
      mainBtnLabel="Accept invite"
      mainBtnHandle={() => handleInvite('ACCEPT')}
    >
      {!!tier.slotsRemaining && (
        <>
          You will use <strong>one</strong> of your free project spaces. <br />
          If you accept this invite you will have {tier.slotsRemaining - 1} free left.
          <div className='pb-2' />
        </>
      )}
      <div className='flex flex-col space-y-2 !text-start'>
        <p>
          If you join this project you will have the <b>{project?.me?.role}</b> role.
        </p>
        <p>
          If you decline this invite you will need to be re-invited.
        </p>
      </div>
    </TextModal>
  );
};

export default ProjectInviteModal;

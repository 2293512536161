import { Box, IconButton } from '@material-ui/core';
import { CloseIcon } from '@vucity/design_system';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationState } from 'views/types';
import TextModal from '../TextModal/TextModal';

type CancelProcessProps = {
  onCancel?: () => void;
  message?: string;
};

const CancelProcessModal: React.FC<CancelProcessProps> = ({
  onCancel, message = 'By cancelling this process you will lose all progress.',
}) => {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const location = useLocation<LocationState>();

  const backUrl = location.state?.prevPath || '/';

  return (
    <>
      <IconButton onClick={() => setOpen(true)} aria-label="Cancel">
        <CloseIcon />
      </IconButton>

      <TextModal
        open={open}
        setOpen={setOpen}
        headerText="Are you sure?"
        mainBtnLabel="Yes"
        mainBtnHandle={() => {
          if (onCancel) return onCancel();
          return history.push(backUrl);
        }}
        closeBtnLabel="No"
        mixpanelPath="Cancel process"
        component='div'
      >
        {message}
        <Box pb={1} />
        {!message && 'Do you still want to quit?'}
      </TextModal>
    </>
  );
};

export default CancelProcessModal;

import { gql } from '@apollo/client';

const eventQuery = `
  id
  type
  createdAt
  projectMember {
    id
    name
    email
    isUserRemoved
    profile {
      id
      name
      email
      picture
    }
  }
  ... on NodesDeletedEvent {
    folder {
      id
    }
    nodes {
      id
      type
      path
      name
      locked
    }
    isUserRemoved
  }
  ... on NodesCreatedEvent {
    nodes {
      id
      type
      path
      name
      parentId
      createdAt
      creator {
        id
        name
      }
    }
    isUserRemoved
  }
  ... on NodesMovedEvent {
    nodes {
      id
      type
      path
      name
      locked
    }
    folder {
      id
      path
    }
    isUserRemoved
  }
  ... on NodeUpdatedEvent {
    node {
      id
      type
      path
      name
      path
      locked
    }      
    change {
      name
    }
    previous {
      name
    }
    isUserRemoved
  }
  ...on PrimaryVuEvent {
    file {
      name
      id
      locked
    }
    previousFile {
      name
      id
      locked
    }
    isUserRemoved
  }
  ...on SecondaryVuEvent {
    file {
      name
      id
      locked
    }
    previousFile {
      name
      id
      locked
    }
    isUserRemoved
  }
  ...on ProjectCreatedEvent {
    project {
      name
    }
    isUserRemoved
  }
  ...on CommentAddedEvent {
    comment {
      id
      message
      mentions {
        profile {
          name
        }
      }
      file {
        id
        name
        locked
      }
    }
    isUserRemoved
  }
  ...on MemberUpdatedEvent {
    member {
      id
      email
      isUserRemoved
      profile {
        name
        picture
        email
        id
      }
      invitedBy {
        name
      }
      status
      role
    }
    change {
      status
      role
    }
    previous {
      status
      role
    }
    isUserRemoved
  }
  ...on MembersInvitedEvent {
    members {
      email
      role
      isUserRemoved
      profile {
        name
      }
    }
    isUserRemoved
  }
  ...on InfoUpdatedEvent {
    change {
      name
      description
      reference
      imageId
      access
    }
    isUserRemoved
  }
  ...on LocationUpdatedEvent {
    location {
      name
      coords {
        x
        y
      }
      license {
        name
        stripeProduct {
          name
        }
      }
    }
    isUserRemoved
  }
  ... on AnnotationAddedEvent {
    node {
      id
      type
      path
      name
      locked
    }
    isUserRemoved
  }
  ... on NodesPermissionsUpdatedEvent {
    nodes {
      id
      type
      name
      path
      whitelist
      locked
    }
    isUserRemoved
  }
`;

export const GET_PROJECT_EVENTS = gql`
  query getProjectEvents($id: ID!, $cursor: ID, $types: [EventType], $sortOrder: SortOrder ) {
    project(id: $id) {
      id
      masterVuFile {
        id
        path
        name
      }
      eventsPaged(
        types: $types,
        omitDeletedComments: true
        after: $cursor
        sortOrder: $sortOrder
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            ${eventQuery}
          }
          cursor
        }
      }
    }
  }
`;

export const EVENTS_SUBSCRIPTION = gql`
  subscription projectEventSubscription($projectId: ID, $allProjects: Boolean) {
    eventAdded(projectId: $projectId, allProjects: $allProjects) {
      ${eventQuery}
      ...on CommentDeletedEvent {
        comment {
          id
        }
      }
      ...on CommentEditedEvent {
        comment {
          id
          message
          mentions {
            profile {
              name
            }
          }
          file {
            id
            name
          }
        }
      }
    } 
  }
`;

export const APP_LAUNCH_EVENT_SUBSCRIPTION = gql`
  subscription AppLaunchEvent($userId: ID, $orgId: ID) {
  appLaunch(userId: $userId, orgId: $orgId) {
    id
    actualUser {
      id
      email
    }
    __typename
  }
}
`;

export const RECORD_SESSION_CLASH = gql`
mutation ($projectId: ID!) {
  recordSessionClash(projectId: $projectId) {
    userId
    projectId
  }
}
`;

export const APP_LAUNCH_PENDING = gql`
mutation ($projectId: ID!, $appId: AppId!) {
  appLaunchPending(projectId: $projectId, appId: $appId) {
    userId
    projectId
    LaunchState
  }
}
`;

export const CLOSE_APP_SESSION = gql`
  mutation ($projectId: ID!, $appId: AppId!) {
    closeAppSession(projectId: $projectId, appId: $appId)
  }
`;
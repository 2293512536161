import { IFileRow } from 'components/Drive/types';
import FileSaver from 'file-saver';
import { GET_NODE_SIGNED_URL } from 'hooks/useDownload/apollo';
import { getExt } from './helpers';
import JsZip from 'jszip';


export const downloadDrive = async ({ files, isSingleFile, setDownloadingInProgress, client, mixpanelTrack, getSignedUrl }: {
  files: IFileRow[];
  setDownloadingInProgress: (inProgress: boolean) => void;
  isSingleFile: boolean;
  client: any;
  getSignedUrl: any;
  mixpanelTrack: any;
}) => {
  const extensions = files
    .map(({ type, name: { filename } }: IFileRow) => (type
      ? filename.substring(filename.lastIndexOf('.'))
      : 'folder'))
    .join(', ');

  mixpanelTrack('Download', { 'Number of files': files.length, 'File format': extensions });

  if (!isSingleFile) {
    const zip = new JsZip();
    const downloadFile = async (file: any) => {
      if (file.__typename === 'Folder') {
        if (file.files.length) for (const f of file.files) await downloadFile(f);
        if (file.folders.length) for (const folder of file.folders) await downloadFile(folder);
        if (!file.files.length && !file.folders.length) zip.folder(file.path);
      } else {
        let fileUrl = '';

        if (file.signedUrl) {
          fileUrl = file.signedUrl;
        } else {
          const gqlResponse = await client.query({ query: GET_NODE_SIGNED_URL, variables: { fileId: file.id } });
          fileUrl = gqlResponse?.data?.currentUser?.drive?.nodeByID?.signedUrl || '';
        }
        
        let path = file.path.replace(/(\r\n|\n|\r)/gm, '').substring(1);
        const name = file.name.filename || file.name;
        
        if (file.mimeType === 'vucity/project' && getExt(name) !== 'vu') {
          path = `${path}.vu`;
        }
        
        zip.file(path, await (await fetch(fileUrl)).blob());
      }
    };
  
    setDownloadingInProgress(true);
    for (const file of files) await downloadFile(file);
    return zip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: {
        level: 9,
      },
    })
      .then(function (content: any) {
        FileSaver.saveAs(content, 'download.zip');
        setDownloadingInProgress(false);
      });
  }
  return getSignedUrl(files[0].id);
};
import React from 'react';
import { Button } from './IconButton.style';

interface IIconButtonProps {
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  defaultIcon: React.ReactNode;
  activeIcon?: React.ReactNode;
  label?: string;
  active?: boolean;
  className?: string;
  disabled?: boolean;
}

const IconButton = ({ defaultIcon, activeIcon, active, onClick, label, className, disabled }: IIconButtonProps) => (
  <Button className={!className ? 'w-7 h-5 bg-white rounded-sm flex' : className} active={active} onClick={onClick} aria-label={label} disabled={disabled}>
    {active ? activeIcon : defaultIcon}
  </Button>
);

export default IconButton;

import { useLazyQuery } from '@apollo/client';
import { useMixpanel, useScroll } from 'hooks';
import { get } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useParams } from 'react-router-dom';
import { GQL } from 'types';
import { SortOrder } from 'types/graphql';

import AddComment from './AddComment/AddComment';
import { COMMENTS_SUBSCRIPTION, GET_COMMENTS } from './apollo';
import ListComments from './ListComments/ListComments';
import SortComment from './SortComment/SortComment';

const Comments: React.FC<{ fileId: string, commentId: string; }> = ({ fileId, commentId }) => {
  const [, elRef] = useScroll();
  const { projectId } = useParams<{ projectId: string; }>();
  const [currentId, setCurrentId] = React.useState('');
  const [sortOrder, setSortOrder] = React.useState<SortOrder>('asc');
  const { mixpanelTrack } = useMixpanel();
  const [scrolled, setScrolled] = React.useState(false);
  const [getComments, { data, fetchMore, error, loading, called, refetch, subscribeToMore }] = useLazyQuery(GET_COMMENTS,
    { variables: { projectId, fileId, sortOrder }, fetchPolicy: 'cache-and-network' });

  const onScrollToBottom = useCallback(() => {
    if (elRef.current) {
      const offsetBottom = elRef.current.offsetTop + elRef.current.scrollHeight;
      elRef.current.scrollTo({ top: offsetBottom });
    }
  }, [elRef]);

  const pageInfo = data?.project?.drive?.nodeByID?.eventsPaged?.pageInfo || {};
  const { hasNextPage } = pageInfo;

  const handleFetchMore = useCallback(() => {
    if (fetchMore !== undefined) {
      mixpanelTrack('#Comments/Fetch More');
      fetchMore({ variables: { cursor: pageInfo.endCursor } });
    }
  }, [pageInfo.endCursor, fetchMore, mixpanelTrack]);

  const handleSetScroll = (element: HTMLElement) => {
    setScrolled(true);
    setTimeout(() => element?.classList.remove('selected'), 1500);
  };

  useEffect(() => {
    if (!called) {
      mixpanelTrack('#Comments/Initial Fetch');
      getComments();
    }
    if (refetch !== undefined && called) {
      mixpanelTrack('#Comments/Refetch');
      refetch({ sortOrder });
    }
  }, [getComments, sortOrder, refetch, called, mixpanelTrack]);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: handleFetchMore,
    disabled: !!error,
    rootMargin: '0px 0px 100px 0px',
  });

  useEffect(() => {
    if (subscribeToMore !== undefined) {
      subscribeToMore({
        document: COMMENTS_SUBSCRIPTION,
        variables: { projectId, fileId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const event = subscriptionData.data.eventAdded;
          const { comment, id, __typename } = event;

          const edges = prev?.project?.drive?.nodeByID?.eventsPaged?.edges || [];

          let nextEdges = edges;

          switch (__typename) {
            case 'CommentDeletedEvent': {
              nextEdges = edges.filter((e: GQL.EventEdge) => get(e, ['node', 'comment', 'id']) !== comment.id);
              break;
            }
            case 'CommentEditedEvent': {
              nextEdges = edges.map((e: GQL.EventEdge) => (
                get(e, ['node', 'comment', 'id']) === comment.id ? { ...e, node: { ...e.node, comment: { ...comment, file: fileId } } } : e
              ));
              break;
            }
            case 'CommentAddedEvent': {
              if (!edges.find((e: GQL.EventEdge) => e.node.id === id)) {
                if (sortOrder === 'desc') {
                  nextEdges = [
                    {
                      node: {
                        ...subscriptionData.data.eventAdded,
                        file: {
                          id: fileId,
                        },
                      },
                      cursor: id,
                    },
                    ...edges,
                  ];
                }
                if (sortOrder === 'asc' && !pageInfo.hasNextPage) {
                  nextEdges = [
                    ...edges,
                    {
                      node: {
                        ...subscriptionData.data.eventAdded,
                        file: {
                          id: fileId,
                        },
                      },
                      cursor: id,
                    },
                  ];
                }
              }
              break;
            }
            default: break;
          }

          return {
            project: {
              ...(prev?.project ?? {}),
              drive: {
                ...(prev?.project?.drive ?? {}),
                nodeByID: {
                  ...(prev?.project?.drive?.nodeByID ?? {}),
                  eventsPaged: {
                    ...(prev?.project?.drive?.nodeByID?.eventsPaged ?? {}),
                    edges: nextEdges,
                  },
                },
              },
            },
          };
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const edges = data?.project?.drive?.nodeByID?.eventsPaged?.edges || [];

  return (
    <>
      <div className='pl-3 pr-1'>
        <p className='text-sm'>
          Comments
        </p>

        <AddComment onScrollToBottom={onScrollToBottom} projectId={projectId} fileId={fileId} />
        {edges.length > 0 && <SortComment handleSort={setSortOrder} sortOrder={sortOrder} />}
      </div>

      <ListComments
        rootRef={rootRef}
        edges={edges}
        selectedComment={commentId}
        currentId={currentId}
        setCurrentId={setCurrentId}
        sentryRef={sentryRef}
        hasNextPage={hasNextPage || loading}
        loading={loading}
        fetchMore={handleFetchMore}
        called={called}
        scrolled={scrolled}
        setScrolled={handleSetScroll}
      />
    </>
  );
};

export default Comments;

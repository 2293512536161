import styled from 'styled-components';
import theme from 'theme/theme';

export const Extension = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  color: #fff;
  background: ${theme.palette.primary.main};
  
  border-radius: 2px;
  
  font-size: 10px;
  letter-spacing: -2%;
  line-height: 14px;
  font-family: Alata, sans-serif;
`;

export const Image = styled.div<{ bgImg: string; }>`
  background: ${({ bgImg }) => `url(${bgImg}) center no-repeat`};
  background-size: cover;
  height: 22px;
  width: 22px;
  border-radius: 2px;
`;

import { useMixpanel } from 'hooks';
import React, { FC } from 'react';
import { Button } from 'tailwind';
import { StepProps } from '../types';
import LogoDark from './step1.svg';

const Step1: FC<StepProps> = ({ handleNext }) => {
  const { mixpanelTrack } = useMixpanel();

  return (
    <>
      <img
        width='208'
        height='208'
        src={LogoDark}
        className='block mx-auto mb-3'
        alt="Thumbnail placeholder"
      />
      <h3 className='mb-3 font-normal'>Welcome to VU.CITY</h3>

      <p className='text-lg mb-2.5 text-center'>
        VU.CITY - bringing teams together to collaborate on projects, to make better
        decisions, faster.
      </p>

      <Button
        variant="contained"
        onClick={() => { mixpanelTrack('Lets get started'); handleNext(); }}
      >
        Lets get started
      </Button>
    </>
  );
};

export default Step1;

import React, { Suspense, lazy } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import { LoadingBar } from 'components';
import { File } from 'types/graphql';
import { getExt, isSupported3DFile, isSupportedImageFile } from 'utils/helpers';
import { Container, Wrapper } from './FileViewer.style';
import PreviewControls from './PreviewControls/PreviewControls';
import UnsupportedComponent from './UnsupportedComponent/UnsupportedComponent';
import Viewer3D from './Viewer3D/Viewer3D';
import getViewer from './viewerFactory';

interface IFileViewer {
  file: File;
  isPano: boolean;
  handleNextClick?: () => void;
  handlePreviousClick?: () => void;
}

const ImageFileViewer = lazy(() => import('../ImageFileViewer/ImageFileViewer'));

const FileViewer = ({ file, isPano, handleNextClick, handlePreviousClick }: IFileViewer) => {
  const fullScreen = useFullScreenHandle();
  const fileType = getExt(file.name);
  const Viewer = getViewer(fileType);

  if (!file.signedUrl) return <></>;
  const isDocVisible = fileType.startsWith('xls') || !file.size;

  return (
    <Wrapper>
      <FullScreen handle={fullScreen}>
        <Container $isdoc={!isSupportedImageFile(fileType) && !isSupported3DFile(fileType)}>
          <PreviewControls
            fullScreen={fullScreen.active}
            exitFullScreen={fullScreen.exit}
            enterFullScreen={fullScreen.enter}
            handleNextClick={handleNextClick}
            handlePreviousClick={handlePreviousClick}
          />

          <Suspense fallback={<LoadingBar />}>
            {isSupported3DFile(fileType) && (
              <Viewer3D
                fileType={fileType}
                filePath={file.signedUrl as string}
                fileSize={file.size}
              />)
            }
            {isSupportedImageFile(fileType) && (
              <ImageFileViewer
                key={file.id}
                id={file.id}
                filePath={file.signedUrl}
                fileType={fileType}
                fileName={file.name}
                fileAnnotations={file.annotations}
                isPano={isPano}
                unSupportedComponent={<UnsupportedComponent />}
              />)
            }
            {!isSupportedImageFile(fileType) && !isSupported3DFile(fileType)
              && <Viewer
                key={file.id}
                id={file.id}
                fileType={!isDocVisible ? fileType : ''}
                filePath={file.signedUrl}
              />
            }
          </Suspense>
        </Container>
      </FullScreen>
    </Wrapper>
  );
};

export default FileViewer;

import { gql } from '@apollo/client';

const CLOUDINARY_TOKEN = gql`
  query currentUserToken {
    currentUser {
      cloudinaryToken
    }
  }
`;

export default CLOUDINARY_TOKEN;

import { useQuery } from '@apollo/client';
import useCurrentUser from 'hooks/useCurrentUser';
import { useMemo } from 'react';
import { ProjectMemberInput, ProjectMemberRole } from 'types/graphql';
import { GET_ORG_MEMBERS } from './apollo';

const useCreateProjectMembers = () => {
  const { currentUser } = useCurrentUser();

  const { data } = useQuery(GET_ORG_MEMBERS);
  const orgMembers = useMemo(() => data?.currentUser?.organisation?.members || [], [data]);

  const createProjectMembers = (members: ProjectMemberInput[], defaultRole?: ProjectMemberRole) => {
    const addedMembers = members.filter((m) => !!m.email);

    const allOptionIndex = addedMembers.findIndex((({ email }) => email === 'all'));
    const { role = defaultRole } = addedMembers.find(({ email }) => email === 'all') || {};

    const activeOrgMembers = orgMembers
      .filter(({ email, status }) => (status === 'ACCEPTED' || status === 'INVITED') && email !== currentUser.email)
      .map(({ email }) => ({ email, role }));

    if (allOptionIndex > -1) {
      addedMembers.splice(allOptionIndex, 1, ...activeOrgMembers);
    }

    return [...addedMembers];
  };

  return { createProjectMembers, orgMembers };
};

export default useCreateProjectMembers;

import styled from 'styled-components';
import { Box as MuiBox } from '@material-ui/core';
import spinner from '../../../assets/spinner-primary-large.png';

import PreviewControls from './PreviewControls/PreviewControls.style';

export const Wrapper = styled(MuiBox)`
  flex: 1;
  height: 100%;
  position: relative;
  width: calc(100% - 380px);

  .fullscreen {
    height: 100%;
    width: 100%;
  }
`;

export const Loading = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  width: 100%;
  background: white;
  position: absolute;
  z-index: 0;

  .loading {
    background-image: url(${spinner});
    background-repeat: no-repeat;
    display: inline-block;
    height: 96px;
    width: 96px;
    animation: rotating 2s linear infinite;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

interface ContainerInterface {
  $isdoc?: boolean;
}

export const Container = styled(MuiBox) <ContainerInterface>`
  flex: 1;
  height: 100%;
  position: relative;

  &:hover {
    ${PreviewControls} {
      opacity: 1;
    }
  }
  
  .pg-viewer {
    overflow-y: ${({ $isdoc }) => ($isdoc ? 'auto' : 'none')};
  }

  .pg-viewer-wrapper {
    overflow-y: unset !important;
    width: 100% !important;
    height: 100% !important;
  }

  .pg-viewer .photo360 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pg-viewer .photo360 canvas {
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important;
  }

  .pg-viewer-wrapper .document-container,
  .photo-viewer-container > img {
    max-width: fit-content;
    word-break: break-word;
  }

  .video-container > video {
    max-width: 100%;
  }

  .photo-viewer-container {
    width: 100% !important;
    height: 100% !important;
  }

  .pdf-viewer canvas,
  .photo-viewer-container > img {
    width: unset !important;
    height: unset !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .unsupported-message {
    background: #efefef;
    height: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex !important;
    flex-direction: column;
  }

  .unsupported-message svg {
    width: 72px;
    height: 72px;
  }

  .pdf-canvas {
    text-align: center;
  }

  .loading-container {
    width: 100% !important;
    height: 100% !important;
  }
`;

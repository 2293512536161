import Menu from '@material-ui/core/Menu';
import { ReactComponent as KebabIconVertical } from 'assets/icons/KebabIconVertical.svg';
import IconButton from 'components/IconButton/IconButton';
import React from 'react';

interface KebabMenuProps {
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  className?: string;
}


const KebabMenu: React.FC<KebabMenuProps> = (props) => {
  const { onClick, children, className } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  return (
    <div>
      <IconButton
        className={className}
        activeIcon={<KebabIconVertical />}
        defaultIcon={<KebabIconVertical />}
        aria-label="KebabMenu"
        onClick={(e) => {
          e?.stopPropagation();
          setAnchorEl(e!.currentTarget);
          onClick?.(e);
        }}
      />
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={e => e.stopPropagation()}
        PaperProps={{
          className: 'w-[226px] py-[6px] px-1.2 mt-1',
        }}
      >
        {children}
      </Menu>
    </div>
  );
};

export default KebabMenu;
import { RefObject, useRef } from 'react';

type ScrollProps<T extends HTMLElement> = [
  (block?: ScrollLogicalPosition) => void,
  RefObject<T>,
];

const useScroll = <T extends HTMLElement>(): ScrollProps<T> => {
  const elRef = useRef<T>(null);
  const executeScroll = (block: ScrollLogicalPosition = 'center'): void => {
    if (elRef.current) {
      elRef.current.scrollIntoView({
        block,
        inline: 'center',
        behavior: 'smooth',
      });
    }
  };

  return [executeScroll, elRef];
};

export default useScroll;

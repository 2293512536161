import { Link } from '@material-ui/core';
import { ArrowLeft, ArrowRight, ExpandSizeIcon, ReduceIcon } from '@vucity/design_system';
import React, { useCallback } from 'react';

import { useMixpanel } from 'hooks';

import Wrapper, { Column, SwitchAssets } from './PreviewControls.style';

interface IPreviewControls {
  fullScreen: boolean;
  enterFullScreen: () => any;
  exitFullScreen: () => any;
  handleNextClick?: () => void;
  handlePreviousClick?: () => void;
  handleZoomIn?: (scale: number) => void;
  handleZoomOut?: (scale: number) => void;
}

const PreviewControls: React.FC<IPreviewControls> = ({
  fullScreen,
  enterFullScreen,
  exitFullScreen,
  handleNextClick,
  handlePreviousClick,
}) => {
  const { mixpanelTrack } = useMixpanel();

  const handleKeyPress = useCallback(
    (e:React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'ArrowLeft' && handlePreviousClick) handlePreviousClick();
      else if (e.key === 'ArrowRight' && handleNextClick) handleNextClick();
    },
    [handleNextClick, handlePreviousClick],
  );

  const focusDiv = useCallback((node: HTMLDivElement) => {
    if (node !== null) node.focus();
  }, []);

  return (
    <Column id='previewColumn' tabIndex={-1} onKeyDown={handleKeyPress} ref={focusDiv}>
      <Wrapper>
        <Link
          component="button"
          onClick={() => {
            if (!fullScreen) {
              mixpanelTrack('Asset preview - Fullscreen');
              enterFullScreen();
            }
            mixpanelTrack('Asset preview - Close Fullscreen');
            exitFullScreen();
          }}
          aria-label={fullScreen ? 'Exit full screen' : 'Enter full screen'}
        >
          {fullScreen
            ? <ReduceIcon htmlColor="#fff" />
            : <ExpandSizeIcon htmlColor="#fff" />
          }
        </Link>
      </Wrapper>
      <SwitchAssets>
        {handlePreviousClick && (
          <Link
            component="button"
            className='left'
            onClick={handlePreviousClick}
          >
            <ArrowLeft htmlColor="#fff" />
          </Link>
        )}
        {handleNextClick && (
          <Link
            component="button"
            className='right'
            onClick={handleNextClick}
          >
            <ArrowRight htmlColor="#fff" />
          </Link>
        )}
      </SwitchAssets>
    </Column>
  );
};
export default PreviewControls;

import { MenuItem, TextField } from '@material-ui/core';
import { AddFolderIcon, theme } from '@vucity/design_system';
import { useMixpanel } from 'hooks';
import React, { useEffect, useState } from 'react';
import { TextModal } from '../../../Modal';

interface IAddFolderProps {
  newFolder: string;
  setNewFolder: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCreateFolder: () => void;
  isUpdated: boolean;
  loading: boolean;
  disabled?: boolean;
}

const iconStyle = {
  width: '20px',
  height: '16px',
  marginRight: '8px',
  color: theme.palette.text.primary,
};

const AddFolder: React.FC<IAddFolderProps> = ({
  newFolder, setNewFolder, onCreateFolder, disabled, loading, isUpdated,
}) => {
  const { mixpanelTrack } = useMixpanel();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isUpdated) { setOpen(false); }
  }, [isUpdated]);

  const onAddNewFolder = () => {
    setOpen(!open);
    mixpanelTrack('Add new folder');
  };

  return (
    <>
      <MenuItem onClick={onAddNewFolder} disabled={disabled}>
        <AddFolderIcon style={iconStyle} />
        <p className='text-base'>Add new folder</p>
      </MenuItem>

      <TextModal
        headerText="Add new folder"
        open={open}
        setOpen={setOpen}
        mainBtnLabel="Create"
        mainBtnHandle={onCreateFolder}
        closeBtnLabel="Cancel"
        loading={!isUpdated && loading}
        component='div'
      >
        <div className='w-full min-w-330'>
          <TextField
            autoFocus
            fullWidth
            placeholder="New Folder"
            variant="outlined"
            value={newFolder}
            onChange={setNewFolder}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
      </TextModal>
    </>
  );
};

export default AddFolder;

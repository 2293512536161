import { useAuth0 } from '@auth0/auth0-react';
import { CloseIcon } from '@vucity/design_system';
import { DetailedSelect, SelectWithInput } from 'components';
import { useCreateProjectMembers, useMixpanel } from 'hooks';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectMemberInput, ProjectMemberRole } from 'types/graphql';
import { roleOptions } from 'utils/helpers';
import { Errors, SelectWithInputItem } from 'views/types';
import { events } from './mixpanelEvents';

interface IIndividualProps extends Errors {
  members: ProjectMemberInput[];
  onUpdate: (members: ProjectMemberInput[]) => void;
  resetView?: () => void;
  defaultRole?: ProjectMemberRole;
  showRole?: boolean;
  mixpanelEvent?: 'create' | 'createFromDrive';
}

const IndividualInvites = ({
  members, defaultRole, errors, showRole, mixpanelEvent, clearErrors, setError, onUpdate, resetView,
}: IIndividualProps) => {
  const { user } = useAuth0();
  const { projectId } = useParams<{ projectId?: string; }>();
  const { mixpanelTrack } = useMixpanel();
  const { orgMembers } = useCreateProjectMembers();

  const source = { 'Drive source': projectId ? 'project' : 'personal' };
  const generateMixpanel = (key: any, extra?: {}, value?: string) => {
    if (!mixpanelEvent) return;

    const event: any = events(value)[mixpanelEvent];
    const mixpanelKey = event[key];
    if (!mixpanelKey) return;
    return mixpanelTrack(mixpanelKey, { ...extra });
  };

  const getSelectOptions = useMemo(() => orgMembers
    .filter((m) => (m.status === 'ACCEPTED' || m.status === 'INVITED'))
    .map((o) => (({
      displayText: o.profile?.name || o.email,
      value: o.email.toLowerCase(),
      imgUrl: o.profile?.picture,
    }) as SelectWithInputItem)) || [], [orgMembers]);

  const onFocus = () => document?.getElementsByName('email-0')[0]?.focus();
  const onChangeHandle = (value: string, i: number, selected?: boolean) => {
    const current = `email-${i}`;
    let nd: ProjectMemberInput[] = [...members];

    const addEmpty = () => nd.unshift({ email: '', role: defaultRole });
    const isOrgMember = getSelectOptions.find((option) => option.value === value);

    if (value.length > 0 || nd.length === 1) {
      nd[i] = { ...nd[i], email: value.toLowerCase() };
    } else nd.splice(i, 1);

    if (selected) {
      clearErrors(current);
      if (i === 0) addEmpty();
      onFocus();

      generateMixpanel('onSelect', {
        'No. of members': orgMembers.length,
        ...(mixpanelEvent === 'createFromDrive' && { ...source }),
      }, value);
    }

    if (!value && errors[current]) {
      if (!nd.filter(n => !n.email).length) addEmpty();
      clearErrors(current);
    }

    if (nd.filter(({ email }) => email === 'all').length > 0) {
      nd = nd.filter((mem) => !getSelectOptions.some((option) => option.value === mem.email));
      if (isOrgMember) addEmpty();
    }

    onUpdate(nd);
  };

  const onKeyDown = (skipBlur: boolean) => {
    generateMixpanel('onKeyDown', { ...(mixpanelEvent === 'createFromDrive' && { ...source }) });
    const nd: ProjectMemberInput[] = [...members];

    if (nd.length === nd.filter(({ email }) => email.length >= 2).length && !errors['email-0']) {
      nd.unshift({ email: '', role: defaultRole });
    }

    onUpdate(nd);
    if (!skipBlur) {
      onFocus();
    }
  };

  const onRemove = (index: number) => {
    generateMixpanel('onRemove', {
      'Organisation or email removed': members[index].email,
      ...(mixpanelEvent === 'createFromDrive' && { ...source }),
    });

    const current = `email-${index}`;
    const removedMember = members.filter((_, i) => i !== index);

    if (errors[current]) clearErrors(current);
    onUpdate(removedMember);

    if (resetView) resetView();
  };

  return (
    <>
      {members.map(((member, index) => index === 0 && (
        <article key={index} className='flex items-start gap-2'>
          <SelectWithInput
            addAll
            index={index}
            email={user?.email}
            members={members.map(({ email }) => email)}
            options={getSelectOptions}
            onChange={onChangeHandle}
            onKeyDown={onKeyDown}
            placeholder='Who will you work with?'
            hasError={{ errors, setError, clearErrors }}
            className={showRole ? 'w-[55%]' : 'w-full'}
          />

          {showRole && (
            <div className='flex items-center w-[45%] gap-1'>
              <DetailedSelect
                className='w-full'
                variant='outlined'
                value={member.role}
                items={roleOptions('ADMIN')}
                handleChange={(option) => {
                  if (option?.value) {
                    const updateMembers: any[] = members.map((m, i) => (
                      { ...m, role: i === index ? option.value : m.role }
                    ));

                    generateMixpanel('setIndividualRole', {
                      'Role set': option.value,
                    });
                    onUpdate(updateMembers);
                  }
                }}
              />
              <div className='min-w-[40px] h-5' />
            </div>
          )}
        </article>
      )))}

      {members.map(((member, index) => index > 0 && (
        <article key={index} className='relative flex items-start gap-2 group'>
          <SelectWithInput
            addAll
            index={index}
            email={user?.email}
            members={members.map(({ email }) => email)}
            options={getSelectOptions}
            onChange={onChangeHandle}
            onKeyDown={onKeyDown}
            placeholder='Who will you work with?'
            hasError={{ errors, setError, clearErrors }}
            className={showRole ? 'w-[55%]' : 'w-full'}
          />

          {showRole ? (
            <div className='flex items-center w-[45%] gap-1'>
              <DetailedSelect
                className='w-full'
                variant='outlined'
                value={member.role}
                items={roleOptions('ADMIN')}
                handleChange={(option) => {
                  if (option?.value) {
                    const updateMembers: any[] = members.map((m, i) => (
                      { ...m, role: i === index ? option.value : m.role }
                    ));

                    generateMixpanel('setIndividualRole', {
                      'Role set': option.value,
                    });
                    onUpdate(updateMembers);
                  }
                }}
              />
              <button
                className={'disabled:pointer-events-none disabled:opacity-40 cursor-pointer flex items-center justify-center transition duration-300 h-5 min-w-[40px] rounded-full hover:bg-grey-light'}
                disabled={members.length < 2 || !member.email}
                onClick={() => onRemove(index)}
              >
                <CloseIcon className='w-[18px] h-[18px]' />
              </button>
            </div>
          ) : (
            <button
              className='absolute h-full transition duration-300 opacity-0 cursor-pointer right-2 text-placeholder group-hover:opacity-100'
              disabled={members.length < 2 || !member.email}
              onClick={() => onRemove(index)}
            >
              <CloseIcon className='w-[14px] h-[14px]' />
            </button>
          )}
        </article>
      )))}
    </>
  );
};

export default IndividualInvites;

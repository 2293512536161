import React from 'react';
import TopNav from 'tailwind/TopNav/TopNav';

interface ILayoutProps {
  children: React.ReactNode;
}

const FullwidthLayout = ({ children }: ILayoutProps) => (
  <>
    <TopNav />
    <div className='relative w-full px-3 py-4 overflow-x-hidden overflow-y-scroll h-content text-content'>
      <div className='w-full mx-auto max-w-1440'>
        {children}
      </div>
    </div>
  </>
);

export default FullwidthLayout;
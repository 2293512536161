import { useQuery } from '@apollo/client';
import { ReactComponent as InfoSvg } from 'assets/icons/Info.svg';
import { formatDistance } from 'date-fns';
import { useCurrentUser } from 'hooks';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AccountLayout } from 'tailwind';
import { capitalizeWord, nameSort } from 'utils/helpers';
import FreeTrial from '../billing/components/FreeTrial';
import MembersBody from './MembersBody';
import {
  ACCOUNT_STATUS_ACTIVE,
  ACCOUNT_STATUS_INACTIVE,
  ADMIN_ROLE,
} from './constants';
import GET_ORG_MEMBERS from './data';
import { RestructuredMember } from './types';

const format = (date: string) => formatDistance(new Date(date), new Date(), { addSuffix: false });

type RestructureData = {
  members: RestructuredMember[];
  hasInactiveMembers: boolean;
};

export default function OrganisationMembers(): ReactElement {
  const history = useHistory();
  const { floating } = useCurrentUser();

  const { defaultTier } = floating || {};

  const [restructureData, setRestructuredData] = useState<RestructureData>({
    hasInactiveMembers: false,
    members: [],
  });

  const { data, loading, error } = useQuery(GET_ORG_MEMBERS);
  const { currentUser } = data ?? {};
  const { id: userId = '', organisation, siteLicenses } = currentUser ?? {};
  const { members = [], id: orgId = '', me, name: orgName = '' } = organisation ?? {};

  const parseData = useCallback(() => {
    let hasInactiveMembers = false;

    const sortMembers = members?.slice().sort((a, b) => nameSort(a.email, b.email));

    const updatedMembers = sortMembers.filter(item => !item.isUserRemoved);
    const filteredUpdatedMembers = updatedMembers.filter(item => item.status !== 'REMOVED');

    const restructuredMembers = filteredUpdatedMembers?.map(
      ({ profile = {}, id, role, status, email: memberEmail, seat, siteLicenses: orgMemberSiteLicenses }) => {
        const { id: memberUserId, name, email, picture, lastLogin }: any = profile || {};
        let activeStatus = ACCOUNT_STATUS_ACTIVE;

        if (status !== 'ACCEPTED') {
          activeStatus = ACCOUNT_STATUS_INACTIVE;
          hasInactiveMembers = true;
        }

        const tier = defaultTier?.(seat?.tier?.name);

        return {
          id,
          activeStatus,
          organisation: orgName,
          name: [name || email || memberEmail, email || memberEmail, id, picture],
          status: status && capitalizeWord(status.toLowerCase()),
          role: role && capitalizeWord(role.toLowerCase()),
          lastLogin: activeStatus === ACCOUNT_STATUS_ACTIVE && lastLogin && format(lastLogin || '').replace(/about/g, ''),
          userId: memberUserId,
          subscription: tier,
          billingPeriod: tier === 'Floating' ? null : seat?.tier?.interval,
          isTrialSeat: seat?.status === 'trialing',
          siteLicenses: orgMemberSiteLicenses,
        } as RestructuredMember;
      },
    );

    return { hasInactiveMembers, members: restructuredMembers };
  }, [data, defaultTier]);

  useEffect(() => {
    if (data) {
      const vals = parseData();
      setRestructuredData(vals);
    }
  }, [parseData, data]);

  return (
    <AccountLayout
      gql={{ loading, error, renderChildren: !!data }}
      title={
        <>
          Members
          {organisation?.trialActive && (
            <span className='ml-1'>
              <FreeTrial trialEnd={organisation?.subscriptions[0]?.trial_end} />
            </span>
          )}
        </>
      }
      subtitle={
        <>
          <InfoSvg />
          <span>Only admins can add members to your organisation.</span>
        </>
      }
      {...me?.role === ADMIN_ROLE && {
        button: {
          text: 'Invite members',
          variant: 'contained',
          onClick: () => history.push('/organisation-invite'),
        },
      }}
    >
      <div className='text-content'>
        <MembersBody
          userId={userId}
          orgId={orgId}
          isAdmin={me?.role === ADMIN_ROLE}
          userSiteLicenses={siteLicenses}
          userRole={me?.role}
          {...restructureData}
        />
      </div>
    </AccountLayout >
  );
}

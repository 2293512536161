import styled from 'styled-components';
import theme from 'theme/theme';

export const Button = styled.button<{ active?: boolean }>`
  border: 0;
  cursor: pointer;
  transition: 0.3s ease-out;
  align-items: center;
  justify-content: center;

  svg {
    color:  ${theme.palette.primary.main};
    width: 18px;
    height: 18px;
  }

  &:hover {
    svg {
      opacity: 0.5;
    }
  }

  &:focus {
    outline: 0;
  }

  ${({ active }) => active && `
    border: 1px solid ${theme.palette.primary.main};

    &:hover {
      border: 1px solid ${theme.palette.primary.light};
    }
  `}
`;

export default Button;

import { useMutation } from '@apollo/client';
import {
  Avatar, FormHelperText,
  InputLabel,
  Link,
  styled,
  TextField,
} from '@material-ui/core';

import OutlinedInput from 'tailwind/Input/TextField';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  Autocomplete, ConfirmChangesModal, UploadImageModal,
} from 'components';
import { useHandleError, useMixpanel } from 'hooks';
import { User } from 'types/graphql';
import { auth0Credentials, jobTitles } from 'utils/configs';
import { UPDATE_ACCOUNT } from 'views/account/apollo';

import { Picture } from 'components/Modal/UploadPhoto/UploadImage';
import { AccountLayout, Button, Row } from 'tailwind';
import { splitName } from 'utils/helpers';
import { IDataProps } from 'views/types';
import Camera from './assets/Camera.svg';

interface IEditProfileProps {
  user: {
    avatar: string;
    data: User;
  };
}

export const StyledAvatar = styled(Avatar)({
  cursor: 'pointer',
  '& > img:hover': {
    opacity: 0.5,
  },
  '&:hover::after': {
    content: `url(${Camera})`,
    position: 'absolute',
  },
});

const EditProfile: React.FC<IEditProfileProps> = ({ user }) => {
  const { domain, clientId } = auth0Credentials;

  const history = useHistory();
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();
  const { handleSubmit, register, formState: { errors }, trigger } = useForm<IDataProps>();

  const [givenName, familyName] = splitName(user.data.name);

  const [openModal, setOpenModal] = useState(false);
  const [openAuto, setOpenAuto] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [passMsg, setPassMsg] = useState('');
  const [picture, setPicture] = useState<Picture>({
    img: user.avatar,
    picturePublicId: '',
  });
  const [data, setData] = useState<IDataProps>({
    name: user.data.name,
    email: user.data.email,
    familyName,
    givenName,
    jobTitle: user.data.jobTitle || '',
    picture: picture?.img,
  });

  const [updateAccount, { loading }] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: () => {
      history.push('/account/profile');
      mixpanelTrack('Update account Successful');
      enqueueSnackbar('User details updated', { variant: 'success' });
    },
    onError: handleError,
  });

  const changeHandle = ({
    currentTarget: { id, value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const nd: IDataProps = data;
    nd[id] = value;
    if (id === 'familyName' || id === 'givenName') {
      nd.name = `${nd.givenName?.trim()} ${nd.familyName?.trim()}`;
    }
    setIsDirty(true);
    setData(nd);
  };

  const handleJobTitle = (value: string | null) => {
    mixpanelTrack('Job title Changed');
    setIsDirty(true);
    setData({ ...data, jobTitle: value });
  };

  const submitHandle = () => {
    const { name, jobTitle } = data;
    const { img } = picture;

    updateAccount({
      variables: {
        name: name?.trim(),
        jobTitle: jobTitle?.trim(),
        picture: img,
        imagePublicId: picture?.picturePublicId,
      },
    });
  };

  const changePassword = async () => {
    try {
      const options = {
        method: 'POST',
        url: `https://${domain}/dbconnections/change_password`,
        headers: { 'content-type': 'application/json' },
        data: {
          client_id: clientId,
          email: data.email,
          connection: 'Username-Password-Authentication',
        },
      };
      const response = await axios.request(options);
      setPassMsg(response.data);

      mixpanelTrack('Requested Change Password');
    } catch (error) {
      mixpanelTrack('Requested Change Password Failed');
    }
  };

  const handleModal = () => setOpenModal(!openModal);

  const handleSetPicture = (image: Picture) => {
    setPicture({ ...image, img: image.img.toURL() });
    setIsDirty(true);
  };
  
  return (
    <AccountLayout title='Edit Profile'>
      <Row column1='Photo' className='pt-0 border-t-0'>
        <div className='w-[104px] h-[104px]'>
          <StyledAvatar
            onClick={handleModal}
            src={picture?.img}
            alt={`${user.data.name} profile`}
            className="w-full h-full"
          />
        </div>
        <UploadImageModal
          type="profile"
          openModal={openModal}
          handleModal={handleModal}
          handleSetPicture={handleSetPicture}
        />
      </Row>

      <Row column1="Name">
        <div className='flex flex-row flex-wrap flex-shrink gap-3'>
          <div className='w-330'>
            <InputLabel htmlFor="givenName" error={!!errors.givenName}>
              First name
            </InputLabel>
            <OutlinedInput
              defaultValue={data.givenName as string}
              id="givenName"
              placeholder="First Name"
              error={!!errors.givenName}
              {...register('givenName', {
                required: true,
                onChange: (
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                ) => changeHandle(e),
              })}
            />
            {errors.givenName && (
              <FormHelperText error>{errors.givenName.message}</FormHelperText>
            )}
          </div>

          <div className='w-330'>
            <InputLabel htmlFor="familyName" error={!!errors.familyName}>
              Last name
            </InputLabel>
            <OutlinedInput
              defaultValue={data.familyName as string}
              id="familyName"
              placeholder="Last Name"
              {...register('familyName', {
                required: true,
                onChange: (
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                ) => changeHandle(e),
              })}
              error={!!errors.familyName}
            />
            {errors.familyName && (
              <FormHelperText error>{errors.familyName.message}</FormHelperText>
            )}
          </div>
        </div>
      </Row>

      <Row column1="Email address">{data.email}</Row>

      <Row column1="Password">
        {passMsg.length > 0
          ? <p className='text-xs text-grey'>{passMsg}</p>
          : <Link component="button" variant="body2" onClick={changePassword}>
            Change password
          </Link>
        }
      </Row>

      <Row column1="Job title">
        <div className='w-full'>
          <Autocomplete
            id="jobTitle"
            freeSolo
            disablePortal
            className={`[&_.MuiInputBase-root]:mb-0 ${openAuto ? 'openedAutocomplete' : ''}`}
            options={jobTitles}
            value={data.jobTitle}
            onOpen={() => setOpenAuto(true)}
            onClose={() => setOpenAuto(false)}
            onChange={(e, value: string | null) => handleJobTitle(value)}
            renderInput={(props: AutocompleteRenderInputParams) => (
              <TextField
                {...props}
                aria-label="Job title"
                variant="outlined"
                placeholder="Select title"
                onChange={(e) => changeHandle(e)}
              />
            )}
          />
        </div>
      </Row>

      <div className='flex items-center justify-end'>
        <ConfirmChangesModal
          headerText="Edit Profile"
          trigger={trigger}
          disabled={loading || !isDirty}
          clickHandle={handleSubmit(submitHandle)}
        />
        <Button
          variant="text"
          size='md'
          onClick={() => {
            mixpanelTrack('Edit Profile/Cancel');
            history.push('/account/profile');
          }}
        >
          Cancel
        </Button>
      </div>
    </AccountLayout>
  );
};

export default EditProfile;

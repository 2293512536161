import React from 'react';

const baseURL = 'https://portal.productboard.com/btesngonkvscy3qdxn8jrzwo/tabs/8-under-consideration';

const Roadmap: React.FC<{ history: { location: { pathname: string; }; }; }> = ({
  history,
}) => {
  const url = history?.location?.pathname === '/roadmap' ? '' : '/submit-idea';
  const token = import.meta.env.VITE_PRODUCTBOARD_TOKEN;

  return (
    <>
      {token && (
        <iframe
          title='roadmap'
          src={`${baseURL}${url}?token=${token}`}
          frameBorder="0"
          width="100%"
          height="100%"
        />
      )}
    </>
  );
};

export default Roadmap;

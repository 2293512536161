import { useMutation } from '@apollo/client';
import { AnnotationsContext } from 'components/Preview/Preview';
import GET_FILE, { UPDATE_ANNOTATIONS } from 'components/Preview/apollo';
import { useMixpanel } from 'hooks';
import * as markerjs2 from 'markerjs2';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import { TextureLoader } from 'three';
import { FileViewerProps } from 'views/types';
import Loader from '../../Loader';
import AnnotationButtons from './AnnotationButtons';

let markerArea: markerjs2.MarkerArea;

const PhotoViewer = ({ fileName, filePath, fileAnnotations, id: fileId }: FileViewerProps) => {
  const imageRef: any = useRef();
  const origImageRef: any = useRef();
  const containerRef: any = useRef();

  const { isAnnotating, imageUrl, setImageUrl, setIsAnnotationg } = useContext(AnnotationsContext);
  const { mixpanelTrack } = useMixpanel();

  const { projectId } = useParams<{ projectId?: string; }>();

  const [isSaving, setIsSaving] = useState(false);
  const [markers, setMarkers] = useState<markerjs2.MarkerAreaState>(fileAnnotations);
  const [loaded, setLoaded] = useState(false);
  const [hasFinishedLoading, setHasFinishedLoading] = useState(false);

  const [addAnnotations] = useMutation(UPDATE_ANNOTATIONS, {
    refetchQueries: [{ query: GET_FILE, variables: { fileId } }],
  });

  const showMarkerArea = useCallback((isPopup: boolean) => {
    if (!imageRef?.current && !origImageRef?.current && !containerRef?.current) return;
    const sourceImg = origImageRef?.current;
    const targetImg = imageRef?.current;
    const container = containerRef?.current;

    const previewWindow = document.getElementById('file--preview_window');
    const togglePreviewWindow = (display: 'none' | 'block') => {
      if (previewWindow)
        previewWindow.style.display = display;
    };

    markerArea = new markerjs2.MarkerArea(sourceImg);

    markerArea.uiStyleSettings.zIndex = '99999';
    markerArea.renderAtNaturalSize = true;
    markerArea.renderImageQuality = 1;
    markerArea.uiStyleSettings.resultButtonBlockVisible = false;

    markerArea.addEventListener('markercreating', () => {
      const markerName = markerArea.currentMarker?.typeName;
      if (markerName) mixpanelTrack(`File annotations / Clicked on ${markerName}`, { fileId });
      togglePreviewWindow('none');
    });
    markerArea.addEventListener('markerselect', () => togglePreviewWindow('none'));
    markerArea.addEventListener('close', () => {
      setIsAnnotationg(false);
      togglePreviewWindow('block');
    });

    markerArea.addEventListener('render', event => {
      setIsAnnotationg(false);
      if (isPopup)
        addAnnotations({
          variables: {
            node: { id: fileId },
            annotations: event.state,
            projectId,
          },
          onCompleted: () => setIsSaving(false),
        });
      togglePreviewWindow('block');
      targetImg.src = event.dataUrl;
      setMarkers(event.state);
      setImageUrl(event.dataUrl);
    });

    if (isPopup) {
      markerArea.settings.displayMode = 'popup';
    } else {
      markerArea.targetRoot = container;
      markerArea.settings.displayMode = 'inline';
      markerArea.uiStyleSettings.hideToolbox = true;
      markerArea.uiStyleSettings.hideToolbar = true;
    }

    markerArea.show();

    setTimeout(() => {
      if (markers) {
        if (isPopup) markerArea.restoreState(markers);
        else markerArea.renderState(markers);
      }
    }, 100);
  }, [mixpanelTrack, fileId, setIsAnnotationg, addAnnotations, projectId, setImageUrl, markers]);

  useEffect(() => {
    if (isAnnotating) showMarkerArea(true);
  }, [isAnnotating, markers, showMarkerArea]);

  useEffect(() => {
    const loader = new TextureLoader();
    loader.crossOrigin = 'anonymous';
    // load a resource
    loader.load(filePath, () => {
      setLoaded(true);
      const sourceImg = origImageRef?.current;
      const targetImg = imageRef?.current;
      const container = containerRef?.current;

      if (targetImg && sourceImg && container) {
        targetImg.src = filePath;
        sourceImg.src = filePath;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAnnotating && markers && hasFinishedLoading) showMarkerArea(false);
    else if (!markers && imageUrl) setImageUrl('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFinishedLoading]);

  const onCloseAnnotations = (e: any) => {
    e.stopPropagation();
    mixpanelTrack('File annotations / Close annotations', { fileId });
    markerArea.close();
  };

  const onSaveAnnotations = async (e: any) => {
    e.stopPropagation();
    setIsSaving(true);
    mixpanelTrack('File annotations / Save annotations', { fileId });
    await markerArea.startRenderAndClose();
  };

  return (
    <>
      {!loaded && <Loader />}
      {loaded && (
        <>
          <div ref={containerRef} className='relative flex items-center justify-center w-full h-full'>
            <img ref={origImageRef} alt={fileName} crossOrigin='anonymous' onLoad={() => setHasFinishedLoading(true)} />
            <img ref={imageRef} alt={fileName} crossOrigin='anonymous' className='absolute' />
          </div>
        </>
      )}

      {isAnnotating && createPortal(
        <AnnotationButtons disabled={isSaving} onSave={onSaveAnnotations} onClose={onCloseAnnotations} />,
        document.body,
      )}
    </>
  );
};

export default PhotoViewer;

import { useQuery, useSubscription } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { CloudIcon, InfoIcon, ProfileIcon, ProjectIcon, ProjectOverviewIcon } from '@vucity/design_system';
import React, { Suspense, createContext, lazy, useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import { LoadingBar } from 'components';
import copy from 'copy';
import { useCurrentUser, useHandleError, useLimitedProjectAccess, useMixpanel } from 'hooks';
import { MainLayout } from 'tailwind';
import { File } from 'types/graphql';
import PrivateRoute from 'utils/routes/PrivateRoute';
import RestrictedRoute from 'utils/routes/RestrictedRoute';
import { IRouteProps } from 'utils/routes/routerParams';
import { LaunchOption } from 'views/types';
import { EVENTS_SUBSCRIPTION } from './activity/apollo';
import { GET_PROJECT_STATUS } from './apollo';

const ProjectDrive = lazy(() => import('./drive/ProjectDrive'));
const Activity = lazy(() => import('./activity/Activity'));
const Information = lazy(() => import('./information/Information'));
const ProjectLocation = lazy(() => import('./information/Location/Location'));
const ProjectOverview = lazy(() => import('./overview'));
const ProjectMembers = lazy(() => import('./users/ProjectMembers'));

export type AppLauncherFile = {
  vucityFileId: string;
  siteSolveFileId: string;
  appId: 'vucity' | 'sitesolve';
  selectedTech: LaunchOption;
};

export const FileSelectionContext = createContext({
  appLauncherFile: {
    vucityFileId: '',
    siteSolveFileId: '',
    appId: 'vucity',
    selectedTech: 'CLOUD',
  } as AppLauncherFile,
  setAppLauncherFile: (() => { }) as (React.Dispatch<React.SetStateAction<AppLauncherFile>>),
});

const Project = ({ match }: IRouteProps) => {
  const { currentUser } = useCurrentUser();
  const [appLauncherFile, setAppLauncherFile] = useState<AppLauncherFile>({
    vucityFileId: '',
    siteSolveFileId: '',
    appId: 'vucity',
    selectedTech: currentUser?.appEnvironment ? currentUser?.appEnvironment : 'CLOUD',
  });
  const { projectId: id } = match.params;
  const { PROJECT_ACCESS_REMOVED } = copy.errors.codes;
  const { REMOVED } = copy.project.status;

  const { checkStatus } = useLimitedProjectAccess();
  const [, enqueueSnackbar] = useHandleError();

  const { pathname } = useLocation();
  const { user } = useAuth0();
  const history = useHistory();
  const { mixpanelTrack } = useMixpanel();

  const [isLimited, setIsLimited] = useState(true);

  useQuery(GET_PROJECT_STATUS, {
    variables: { id },
    fetchPolicy: 'no-cache',
    onCompleted: ({ project }) => {
      const { status, role } = project.me;
      const { limitedUrl, isLimited: limited } = checkStatus(
        id, role, status,
      );
      setIsLimited(limited);
      if (limited && !pathname.includes('members')) {
        enqueueSnackbar('Not enough project spaces to access this project. Limited to Member Admin.', { variant: 'error' });
        history.push(limitedUrl);
      }
    },
  });

  const handleDriveLinkLink = (fileId: string) => {
    history.push(`/project/${id}/drive/${fileId}`);
  };

  useSubscription(
    EVENTS_SUBSCRIPTION,
    {
      variables: { allProjects: true },
      fetchPolicy: 'network-only',
      onSubscriptionData: ({ client, subscriptionData }) => {
        const event = subscriptionData.data.eventAdded;
        const { __typename, type } = event;
        if (__typename === 'NodesCreatedEvent' && type === 'NODES_CREATED') {
          const savedCopy: File | undefined = event.nodes.find((val: File) => val.name.toLowerCase().includes('autosave'));
          if (savedCopy) {
            mixpanelTrack('Duplicate app session file saved successfully');
            enqueueSnackbar(<div className='flex flex-col gap-0 text-base'>
              <span>A copy of {savedCopy?.name.toLowerCase().replace(/\w+\([0-9]+\)/gm, '').trimLeft()} has been successfully saved to project</span>
              <span onClick={() => handleDriveLinkLink(savedCopy.id)}>
                <u className='cursor-pointer'>View file in project drive</u>
              </span>
            </div>, { variant: 'success' });
          }
        }
        // return if event is not related to the current project
        if (__typename === 'InfoUpdatedEvent') {
          client.cache.modify({
            id: client.cache.identify({ __typename: 'Project', id }),
            fields: Object.entries(event.change)
              .reduce((fields, [key, value]) => {
                if (!value) return fields;
                return { ...fields, [key]: () => value };
              }, {}),
          });
        }

        if (__typename === 'LocationUpdatedEvent') {
          client.cache.modify({
            id: client.cache.identify({ __typename: 'Project', id }),
            fields: {
              location: () => event.location,
            },
          });
        }

        if (__typename === 'PrimaryVuEvent' && type === 'PRIMARY_VU_NOMINATED') {
          client.cache.modify({
            id: client.cache.identify({ __typename: 'Project', id }),
            fields: {
              masterVuFile: (existing = {}) => ({ ...existing, ...event.file }),
            },
          });
        }

        if (__typename === 'MemberUpdatedEvent') {
          const isRemoved = event.change.status === REMOVED;

          if ((event?.member?.profile?.id === user?.sub) && isRemoved) {
            enqueueSnackbar(PROJECT_ACCESS_REMOVED, { variant: 'error' });
            history.push('/');
          }

          client.cache.modify({
            id: client.cache.identify({ __typename: 'ProjectMember', id: event.member.id }),
            fields: {
              status: () => event.member.status,
              role: () => event.member.role,
            },
          });
        }
      },
    },
  );

  const projectMenu = [[
    { icon: <ProjectOverviewIcon />, title: 'Overview', url: `/project/${id}`, isLimited },
    { icon: <CloudIcon />, title: 'Drive', url: `/project/${id}/drive`, isLimited },
    { icon: <InfoIcon />, title: 'Project info', url: `/project/${id}/information`, isLimited },
    { icon: <ProjectIcon />, title: 'Activity', url: `/project/${id}/activity`, isLimited },
    { icon: <ProfileIcon />, title: 'Members', url: `/project/${id}/members` },
  ]];

  return (
    <MainLayout items={projectMenu}>
      <Suspense fallback={<LoadingBar />}>
        <FileSelectionContext.Provider value={{ appLauncherFile, setAppLauncherFile }}>
          <Switch>
            <PrivateRoute path="/project/:projectId" exact component={ProjectOverview} />
            <PrivateRoute path="/project/:projectId/drive" component={ProjectDrive} compProps={{ id }} />
            <PrivateRoute path="/project/:projectId/information" exact component={Information} compProps={{ id }} />
            <PrivateRoute path="/project/:projectId/members" exact component={ProjectMembers} compProps={{ id }} />
            <RestrictedRoute path="/project/:projectId/information/location" scopes={['PROJECT_INFO_EDIT']} component={ProjectLocation} compProps={{ id }} />
            <PrivateRoute path="/project/:projectId/activity" exact component={Activity} />
          </Switch>
        </FileSelectionContext.Provider>
      </Suspense>
    </MainLayout>
  );
};

export default Project;

import React from 'react';

export default function InfoBox({ children }: { children?: React.ReactNode } ) {
  return (
    <div className="h-6
      flex
      items-center
      w-full
      bg-primary-lightest 
      rounded border
      border-l-8
      gap-1
      pl-1
    border-primary-light"
    >
      {children}
    </div>
  );
}

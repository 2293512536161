import { useLazyQuery } from '@apollo/client';
import { Avatar, MenuItem } from '@material-ui/core';
import { AddFileIcon, theme } from '@vucity/design_system';
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Ellipsis } from 'tailwind';
import { bytesToSize, getExt } from 'utils/helpers';
import { GET_PROJECT_STATUS } from 'views/project/apollo';
import LoadingBar from '../../../Loading/LoadingBar';
import CustomModal, { StorageLimit } from '../../../Modal';
import { IFilesProps } from '../types';

interface UploadFilesProps {
  projectId?: string;
  files: IFilesProps[];
  setFiles: React.Dispatch<React.SetStateAction<IFilesProps[]>>;
  onUploadFiles: () => void;
  isUpdated: boolean;
  loading: boolean;
  driveSize: number[];
  useStorageError: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  useUploadSize: [number, React.Dispatch<React.SetStateAction<number>>];
}

const iconStyle = {
  width: '20px',
  height: '19px',
  marginRight: '10px',
  color: theme.palette.text.primary,
};

const UploadFiles = ({
  projectId, files, setFiles, loading, driveSize,
  onUploadFiles, isUpdated, useStorageError, useUploadSize,
}: UploadFilesProps) => {
  const [uploadSize, setUploadSize] = useUploadSize;
  const [storageError, setStorageError] = useStorageError;
  const [isOpen, setIsOpen] = useState(false);

  const [location, setLocation] = useState('');
  const [vuError, setVuError] = useState<string[]>([]);

  const [getProjectLocation] = useLazyQuery(GET_PROJECT_STATUS, {
    variables: { id: projectId },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    onCompleted: (res) => setLocation(res.project?.location?.license?.name),
  });

  const onOpenModal = () => {
    setIsOpen(true);
    if (projectId) getProjectLocation();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: true,
    onDrop: (acceptedFiles: any) => {
      const filesToUpload = acceptedFiles.map((file: any) => Object.assign(file,
        { preview: !file.type.includes('video') && URL.createObjectURL(file) }));

      const promises = filesToUpload.map((file: Blob) => {
        if (getExt(file.name) === 'vu') {
          return new Promise(async (resolve) => {
            const decoder = new TextDecoder('utf-8');
            const reader = file.stream().getReader();
            let buffer = '';

            const processChunk = (result: ReadableStreamReadResult<Uint8Array>) => {
              const { done, value } = result;

              if (done) {
                resolve(file);
                return;
              }

              buffer += decoder.decode(value, { stream: true });

              const licenceNameMatch = buffer.match(/"licenceName"\s*:\s*"([^"]+)"/);
              if (licenceNameMatch) {
                const licenceName = licenceNameMatch[1];
                if (licenceName !== location && projectId) setVuError(prev => [...prev, file.name]);

                // Update file with the licenceName found
                const updatedFile: any = new File([file], file.name, { ...file, type: 'vucity/project' });
                updatedFile.licenceName = licenceName;

                resolve(updatedFile); // Resolve the promise once we find the licenceName
                reader.cancel();
                return;
              }

              reader.read().then(processChunk);
            };

            reader.read().then(processChunk);
          });
        } else {
          return Promise.resolve(file);
        }
      });

      Promise.all(promises).then((updatedFiles) => {
        const finalFiles = updatedFiles.filter(file => !(file.licenceName && file.licenceName !== location));
        setFiles(projectId ? finalFiles : updatedFiles);
      });
    },
  });

  const resetModal = (ifModal = true) => {
    if (loading) return null;
    if (ifModal) setIsOpen(false);
    setFiles([]);
    setVuError([]);
    setStorageError(false);
    return setUploadSize(0);
  };

  useEffect(() => files.forEach((file: IFilesProps) => URL.revokeObjectURL(file.preview)), [files]);
  useEffect(() => { if (isUpdated) resetModal(); }, [isUpdated]);

  const thumbs = useMemo(() => (
    <div className='min-h-[125px] max-h-[300px] mx-3 flex gap-2 justify-center flex-wrap overflow-y-auto overflow-x-hidden'>
      {files.map((file: IFilesProps) => (
        <div key={file.name} className='flex flex-col justify-center items-center w-full max-w-[100px]'>
          <Avatar alt={file.name} src={file.preview || file.name[0]} />
          <Ellipsis rows={1} className='w-full font-medium text-center mt-0.5'>
            {file.name}
          </Ellipsis>
          <p>{bytesToSize(file.size)}</p>
        </div>
      ))}
    </div>
  ), [files]);

  const exceedsStorage = () => {
    const [used, total] = driveSize;
    const fileTotalSize = files.reduce((acc, currentValue) => acc + currentValue.size, 0);
    setUploadSize(fileTotalSize);

    return used + fileTotalSize > total;
  };

  const onUpload = () => {
    if (exceedsStorage()) { return setStorageError(true); }

    return onUploadFiles();
  };

  return (
    <>
      <MenuItem onClick={onOpenModal}>
        <AddFileIcon style={iconStyle} />
        Upload files
      </MenuItem>
      <CustomModal open={isOpen && !storageError} close={resetModal} headerText="Upload files">
        <>
          {loading && <LoadingBar />}

          <div className='w-full pt-3 pb-4'>
            {files.length === 0
              ? <div {...getRootProps({ className: 'dropzone border-dashed min-h-[125px] h-full border-placeholder border-2 rounded bg-grey-lighter flex items-center justify-center cursor-pointer mx-3' })}>
                <input {...getInputProps()} />
                Drag and drop files here.
              </div>
              : thumbs
            }
          </div>

          {vuError.length > 0 && (
            <div className='px-2 py-1 mb-2 text-sm bg-red-100 rounded'>
              <span className='font-medium'>{vuError.join(', ')}</span> {vuError.length === 1 ? 'doesn\'t' : 'don\'t'} match the project location and will be skipped. Expected <span className='font-medium'>{location}</span>
            </div>
          )}

          <div>
            {files.length > 0 ? (
              <Button variant="contained" disabled={loading} onClick={onUpload}>
                {!loading ? 'Upload files' : 'Please wait... Uploading'}
              </Button>
            ) : (
              <Button variant="contained" onClick={open}>
                Select files from your computer
              </Button>
            )}
          </div>
        </>
      </CustomModal>

      {storageError && (
        <StorageLimit
          projectId={projectId}
          spaceNeeded={uploadSize}
          closeModal={() => resetModal(false)}
          closeBtnHandle={() => resetModal(true)}
          mainBtn="Upload new files"
        />
      )}
    </>
  );
};

export default UploadFiles;

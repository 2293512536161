import { BrokenImageIcon } from '@vucity/design_system';
import React from 'react';

const UnsupportedComponent = () => (
  <div 
    className='flex flex-col w-full h-full 
      items-center justify-center gap-2 bg-grey-light'
    >
    <BrokenImageIcon className='h-9 w-9' htmlColor='#5C5C5C' />
    <span className='text-lg'>We can’t currently preview this file type.</span>
  </div>
);
export default UnsupportedComponent;

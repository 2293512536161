import { TextModal } from 'components';
import { IFileRow } from 'components/Drive/types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BackButton } from 'tailwind';

interface IBackProps {
  backUrl: string;
  setSelectedFiles: (files: IFileRow[]) => void;
}

const Back = ({ backUrl, setSelectedFiles }: IBackProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();

  return (
    <>
      <BackButton onClick={() => setOpen(true)} />
      <TextModal
        open={open}
        setOpen={setOpen}
        headerText="Are you sure you want to go back?"
        mainBtnLabel="Yes"
        mainBtnHandle={() => { history.push(backUrl); setSelectedFiles([]); }}
        closeBtnLabel="No"
        mixpanelPath="Cancel process"
      >
        By cancelling this process, you will lose all your progress.
      </TextModal>
    </>
  );
};

export default Back;

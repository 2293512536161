import { BillingPeriodToggle, DetailedSelect } from 'components';
import { joinTierValueWithPeriod } from 'components/DataGrid/GridElements/OrgSubDropdown';
import { useCurrentUser, useMixpanel } from 'hooks';
import React, { useEffect, useState } from 'react';
import { BillingInterval } from 'types/graphql';
import { DetailedSelectItem } from 'views/types';
import { Seats } from '.';

interface ISubscriptionProps {
  hasBoth: { year?: number; month?: number; };
  seats: { [key: string]: Seats; };
  setSeats: React.Dispatch<React.SetStateAction<ISubscriptionProps['seats']>>;
  onSubscriptionChange: (tierId: string, interval: BillingInterval | 'trial') => void;
  initialSeat?: string;
}

const SubscriptionDropdown = ({
  hasBoth, seats, setSeats, initialSeat, onSubscriptionChange,
}: ISubscriptionProps) => {
  const { year, month = 0 } = hasBoth;

  const { floating: { hasFloating, floatingTotal } } = useCurrentUser();
  const { mixpanelTrack } = useMixpanel();

  const [value, setValue] = useState('');
  const [billingPeriod, setBillingPeriod] = useState<BillingInterval | 'trial'>('year');
  const [options, setOptions] = useState<DetailedSelectItem[]>([]);

  const calculateSeats = (tierId: string) => {
    let updatedPro: number = seats[billingPeriod].pro;
    let updatedPlus: number = seats[billingPeriod].plus;

    if (initialSeat === 'pro') updatedPro = updatedPro + 1;
    if (initialSeat === 'plus') updatedPlus = updatedPlus + 1;
    if (tierId === 'pro') updatedPro = updatedPro - 1;
    if (tierId === 'plus') updatedPlus = updatedPlus - 1;

    setSeats((prev) => ({
      ...prev,
      [billingPeriod]: {
        plus: updatedPlus,
        pro: updatedPro,
      },
    }));
  };

  const onUpdateSubs = ({ item, period }: { item?: DetailedSelectItem | null, period: BillingInterval | 'trial'; }) => {
    if (!item) return;

    const tierId = item.value.split('-')[0];

    setValue(item.value);
    calculateSeats(tierId);
    onSubscriptionChange(tierId, period);
  };

  const onChange = (item?: DetailedSelectItem | null) => {
    onUpdateSubs({ item, period: billingPeriod });
    mixpanelTrack(`Subscription menu clicked - ${item?.value.toLowerCase()}`);
  };

  useEffect(() => {
    if (initialSeat !== 'starter' && initialSeat !== 'floating')
      setValue(joinTierValueWithPeriod(initialSeat, billingPeriod));
    else setValue(initialSeat);
  }, [billingPeriod, initialSeat]);

  useEffect(() => {
    if (!year && month > 0) setBillingPeriod('month');
  }, [month, year]);

  useEffect(() => {
    const defaultOption = hasFloating ? {
      value: 'floating',
      title: 'Floating access',
      titleRightInfo: 'Unlimited',
      subtitle: <>
        <p>- Collaborate on unlimited projects</p>
        <p>- Shared access to VU.CITY 3D platform</p>
        <p><strong>({floatingTotal} x concurrent {floatingTotal === 1 ? 'user' : 'users'})</strong></p>
      </>,
    } : {
      value: 'starter',
      title: 'Starter',
      titleRightInfo: 'Unlimited',
      subtitle: 'Collaborate on up to 3 projects.',
    };

    const plus = {
      value: joinTierValueWithPeriod('plus', billingPeriod),
      title: 'Plus',
      titleRightInfo: `${seats[billingPeriod].plus} available`,
      subtitle: 'Collaborate on unlimited projects.',
      disabled: seats[billingPeriod].plus <= 0,
    };

    const pro = {
      value: joinTierValueWithPeriod('pro', billingPeriod),
      title: hasFloating ? 'Dedicated Pro' : 'Pro',
      titleRightInfo: `${seats[billingPeriod].pro} available`,
      subtitle: hasFloating ? <>
        <p>- Collaborate on unlimited projects</p>
        <p>- Uninterrupted access to the VU.CITY 3D platform</p>
      </>
        : 'Collaborate on unlimited projects and use the VU.CITY 3D App to visualise stories, undertake technical analysis, design in context and more.',
      disabled: seats[billingPeriod].pro <= 0,
    };

    const newOptions = hasFloating ? [defaultOption, pro] : [defaultOption, plus, pro];

    setOptions(newOptions);
  }, [billingPeriod, floatingTotal, hasFloating, seats]);

  const formatPeriod = (period: BillingInterval | 'trial') => period === 'year' ? 'Annual' : 'Monthly';

  const formatTitle = (item: DetailedSelectItem | null | undefined) => {
    if (!item) return '';

    if (item.value === 'starter' || item.value === 'floating') return item.title;
    return `${item.title} - ${formatPeriod(billingPeriod)}`;
  };

  return (
    <DetailedSelect
      value={value}
      items={options}
      variant="outlined"
      handleChange={onChange}
      optionalHeader={year && month ? () =>
        <div className='flex items-center justify-between w-full p-2 border-b border-b-content-light'>
          <span className='m-0 text-sm'>Subscription</span>

          <BillingPeriodToggle
            isCompactMode
            getLabelByPeriod={formatPeriod}
            value={billingPeriod as BillingInterval}
            handleChange={(period: BillingInterval | 'trial') => {
              if (period === billingPeriod) return setBillingPeriod(period);
              setBillingPeriod(period);
              onUpdateSubs({ item: options[0], period });
              mixpanelTrack(`toggled '${period}' subscription seats`);
            }}
          />
        </div>
        : undefined}
      formatTitle={formatTitle}
    />
  );
};

export default SubscriptionDropdown;

import { MenuItem } from '@material-ui/core';
import React from 'react';
import { useMixpanel } from 'hooks';

interface IProps {
  setIsTransferring: React.Dispatch<React.SetStateAction<
  { selected: boolean; toPersonal: boolean }>>;
}

const MoveTo: React.FC<IProps> = ({ setIsTransferring }) => {
  const { mixpanelTrack } = useMixpanel();

  return (
    <MenuItem onClick={() => {
      setIsTransferring({ selected: true, toPersonal: false });
      mixpanelTrack('Move to');
    }}>
      Move to
    </MenuItem>
  );
};

export default MoveTo;

import { useMutation, useQuery } from '@apollo/client';
import LogoDark from 'assets/VU.CITY-Dark.svg';
import UnableToJoin from 'components/Modal/UnableToJoin/UnableToJoin';
import { useHandleError, useMixpanel } from 'hooks';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'tailwind';
import { OrgMember } from 'types/graphql';
import { GET_INVITATIONS, RSVP_INVITE } from './apollo';
import OrganisationActions from './components/OrganisationActions';

const OrganisationInvitations = ({ setSkip }: { setSkip: (value: boolean) => void; }) => {
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();
  const history = useHistory();

  const [invitations, setInvitations] = useState<OrgMember[]>();
  const [currentInviteId, setCurrentInviteId] = useState<string>();
  const [open, setOpen] = useState(false);

  useQuery(GET_INVITATIONS, {
    onCompleted: ({ currentUser }) => {
      setInvitations(currentUser.orgInvites);
    },
  });

  const [rsvpInvite] = useMutation(RSVP_INVITE, {
    awaitRefetchQueries: true,
    onCompleted: ({ rsvpOrgInvite: { status } }) => {
      const orgInvite = invitations?.find((invite) => invite?.organisation?.id === currentInviteId);
      const org = orgInvite?.organisation;
      enqueueSnackbar(status === 'ACCEPTED' ? 'Invitation accepted' : 'Invitation declined', { variant: 'success' });
      mixpanelTrack('Confirmed Org invite', { OrgID: org?.id, OrgName: org?.name });
      history.go(0);
    },
    onError: (error) => {
      const code = handleError(error);
      if (code === 'CANNOT_JOIN_ORG') setOpen(true);
    },
  });

  const handleSkip = () => {
    setSkip(true);
    mixpanelTrack('Skipped Org invites');
  };

  const handleInvite = (response: string, id: string) => {
    setCurrentInviteId(id);
    rsvpInvite({
      variables: {
        orgId: id,
        response,
      },
    });
  };

  return (
    <>
      <div className='flex flex-col items-center w-full h-screen gap-2 px-2 pt-8 pb-4 overflow-auto text-content'>
        <img className='h-[42px] w-[190px]' src={LogoDark} alt="VuCity Logo" />
        <h2 className='mt-4 mb-1 font-normal'>Choose an organisation to join</h2>

        {invitations?.map((val: OrgMember) => (
          <OrganisationActions key={val.id} handleInvite={handleInvite} {...val} />
        ))}

        <div className='w-[940px] max-w-full flex flex-col mt-3 text-lg text-grey items-center'>
          <hr className='w-full border-t-0 border-b border-primary-lightest' />
          <p className='px-2 bg-white mt-[-12px]'>or</p>
        </div>

        <Button variant="text" color="primary" onClick={handleSkip}>
          Skip for now &gt;
        </Button>

        <p className='text-center'>
          You will be able to review your outstanding organisation invitations under Settings &gt; Organisation &gt; Invitations
        </p>
      </div>

      <UnableToJoin open={open} handleCloseModal={() => setOpen(false)} />
    </>
  );
};

export default OrganisationInvitations;

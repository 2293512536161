import React from 'react';
import Button, { ButtonProps } from '../BaseButton';

interface SelectButtonProps extends ButtonProps {
  selected: boolean;
  className?: string;
  ref?: any;
}

const SelectButton = ({
  selected, size = 'xs', className, children, ...props
}: SelectButtonProps) => {
  const isSelected = selected ? 'font-medium text-primary bg-primary-lightest' : 'font-normal bg-white';

  return (
    <Button
      size={size}
      padding='unset'
      variant='light'
      className={`justify-start px-1.2 w-fit ${isSelected} ${className}`}
      {...props}
    >
      {children}
    </Button>
  );
};

export default SelectButton;

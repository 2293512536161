import CancelFilled from 'assets/icons/CancelFilled.svg';
import CancelOutlined from 'assets/icons/CancelOutlined.svg';
import styled from 'styled-components';

export const DeleteButton = styled.span`
  mask: url(${CancelOutlined}) no-repeat center;
  
  :hover {
    mask: url(${CancelFilled}) no-repeat center;
  }
`;

export const Button = styled.button<{ isError?: boolean; }>`
  ${({ isError }) => isError && `
    ${DeleteButton} {
      background-color: #e02020;
    }
  `}
`;


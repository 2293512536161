import { gql, TypedDocumentNode } from '@apollo/client';
import { Tier, License, StripeInvoice } from 'types/graphql';

export const TIERS: TypedDocumentNode<{ tiers: Tier[] }> = gql`
  query tiers {
    tiers {
      id
      name
      description
      stripeProduct {
        id
        description
        prices {
          id
          currency
          unit_amount
          recurring {
            interval
          }
        }
      }
    }
  }
`;

export const LICENSES: TypedDocumentNode<{ cityLicenses: License[] }> = gql`
  query licenses {
    cityLicenses {
      id
      name
      public
      location {
        id
      }
      stripeProduct {
        id
        name
        prices {
          unit_amount
          currency
          id
          recurring {
            interval
          }
        }
        metadata {
          url
          sqkm
        }
      }
    }
  }
`;

export const DRAFT_UPGRADE_SUBSCRIPTION: TypedDocumentNode<{ draftUpgradeSubscription: StripeInvoice }> = gql`
  query DraftUpgradeSubscription($interval: BillingInterval!, $tiers: SeatQuantities, $licenses: [ID]) {
    draftUpgradeSubscription(
      interval: $interval
      tiers: $tiers
      licenses: $licenses
    ) {
      amount_due
      subtotal
      subscription_proration_date
      next_amount_due
      subscription {
        days_until_due
        current_period_end
        status
        interval
        start_date
      }
    }
  }
`;

import React from 'react';

interface OptionHeaderProps {
  title: string;
  icon: JSX.Element;
  label?: JSX.Element;
}

export default function OptionHeader({ title, icon, label }: OptionHeaderProps) {
  return (
    <div className='flex gap-1 mb-1'>
      {React.cloneElement(icon, { className: 'text-primary w-3 h-3' })}
      <span className='text-base font-medium'>{title}</span>
      {label}
    </div>
  );
}
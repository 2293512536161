import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

export const StyledIconButton = styled(IconButton)`
  border: 1px solid #004e63;
  border-radius: 4px;
  padding: 6px;
  color: #004e63;

  :disabled {
    border: 1px solid #bfd3d8;
  }

  :last-child {
    color: #fff;
    background-color: #004e63;
  }
`;

export const Input = styled.input`
  width: 71px;
  height: 48px;
  border: 1px solid #004e63;
  border-radius: 8px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #004e63;
  margin: 0 14.2px;

  :focus-visible {
    outline: #004e63 auto 1px;
  }
`;

import MuiBox from '@material-ui/core/Box';
import styled, { keyframes } from 'styled-components';

const highlightComment = keyframes`
  0% {
    background-color: #E6EDEF;
  }
  100% {
    background-color: none;
  }
`;

export const HighlightComment = styled(MuiBox)`
  &.selected {
    animation: 5s ${highlightComment} ease-in-out;
  }
`;

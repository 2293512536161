import { MenuItem } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';

import { IFileRow } from 'components/Drive/types';
import { useMixpanel } from 'hooks';
import { NavLink } from 'react-router-dom';
import { getExt, isWindowsPlatform } from 'utils/helpers';
import { FileSelectionContext } from 'views/project';

interface IOpenAppProps {
  project: { id: string; location: string; isPublic: boolean; };
  files: IFileRow[];
}

const OpenApp: React.FC<IOpenAppProps> = ({ project, files }) => {
  const { mixpanelTrack } = useMixpanel();
  const [open, setOpen] = useState<boolean>(false);
  const { setAppLauncherFile } = useContext(FileSelectionContext);

  const handleModal = useCallback(() => setOpen(!open), [open]);

  const isVucityProject = files[0].type === 'vucity/project';
  const isSiteSolveFile = ['sslv'].includes(getExt(files[0].name.filename));

  if (!isVucityProject && !isSiteSolveFile) return <></>;

  const triggerMixpanel = (platform: string) => {
    mixpanelTrack(`Clicked the Open in ${isVucityProject ? `VU.CITY ${platform}` : 'SiteSolve'}`, {
      platform,
      'Name of licence': project.location,
    });
  };

  return (
    <>
      <MenuItem
        onClick={() => {
          handleModal();
          triggerMixpanel(isWindowsPlatform ? 'Desktop' : 'Cloud');
        }}
      >
        <NavLink
          exact={true}
          to={`/project/${project?.id}`}
          onClick={() => {
            const appId = isVucityProject ? 'vucity' : 'sitesolve';
            if (appId === 'vucity') {
              setAppLauncherFile(prevValues => ({
                ...prevValues,
                vucityFileId: files[0].id,
                appId: appId,
              }));
            } else if (appId === 'sitesolve') {
              setAppLauncherFile(prevValues => ({
                ...prevValues,
                siteSolveFileId: files[0].id,
                appId: appId,
              }));
            }
          }}
        >
          {isVucityProject ? 'Open in VU.CITY' : isSiteSolveFile && 'Open in SiteSolve'}
        </NavLink>
      </MenuItem>
      <hr className='my-1 border-grey-border' />
    </>
  );
};

export default OpenApp;

import { useQuery } from '@apollo/client';
import { Column } from '@devexpress/dx-react-grid';
import { Table, TableColumnVisibility } from '@devexpress/dx-react-grid-material-ui';
import { MenuItem, Select } from '@material-ui/core';
import { useLocalStorageState } from 'ahooks';
import { DataGrid } from 'components';
import { ActionProvider, DropdownProvider, DropdownSubProvider, NameProviderNoCard, ToggleDropdownProvider } from 'components/DataGrid';
import SearchInput from 'components/Drive/Search/SearchInput';
import { useMixpanel } from 'hooks';
import * as JsSearch from 'js-search';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { OrgLicense, OrgMemberRole, UserSiteLicense } from 'types/graphql';
import { GET_ORGANISATION_SITE_LICENSES } from 'utils/apollo/user';
import { extractCityName } from 'utils/helpers';
import { MultiToggleOption } from 'views/types';
import { ACCOUNT_STATUS_ACTIVE, ACCOUNT_STATUS_ALL, ACCOUNT_STATUS_DESCRIPTIONS, ACCOUNT_STATUS_INACTIVE, MEMBER_ROLE } from './constants';
import { Filters, RestructuredMember, RestructuredMembers } from './types';

const columns: Column[] = [
  { name: 'name', title: 'Name' },
  { name: 'role', title: 'Role' },
  { name: 'status', title: 'Status' },
  { name: 'subscription', title: 'Subscription' },
  { name: 'siteLicenses', title: 'Site Licenses' },
  // { name: 'lastLogin', title: 'Last Login' },
  { name: 'action', title: ' ' }, // an empty column config for actions
];

const columnWidth = [
  { columnName: 'name', width: '29%' },
  { columnName: 'role', width: 'auto' },
  { columnName: 'status', width: 'auto' },
  { columnName: 'subscription', width: 'auto' },
  { columnName: 'siteLicenses', width: 'auto' },
  // { columnName: 'lastLogin', width: 'auto' },
  { columnName: 'action', width: '50px' },
];

const TableRow = ({ row, ...restProps }: any) => (
  <Table.Row role="row" {...restProps} />
);

function splitActive(results: RestructuredMembers, orgId: string) {
  const splitRows: SplitRows = [[], []];
  results.forEach((member: any) => {
    splitRows[member.activeStatus === ACCOUNT_STATUS_ACTIVE ? 0 : 1].push({ ...member, orgId });
  });
  return splitRows;
}

interface MembersBodyProps {
  members: RestructuredMember[];
  hasInactiveMembers: boolean;
  userId: string;
  orgId: string;
  isAdmin?: boolean;
  userSiteLicenses?: UserSiteLicense[];
  userRole?: OrgMemberRole;
}

type SplitRows = [RestructuredMembers, RestructuredMembers];

const MembersBody: React.FC<MembersBodyProps> = ({
  members,
  hasInactiveMembers,
  userId,
  orgId,
  isAdmin,
  userSiteLicenses = [],
  userRole,
}) => {
  const { mixpanelTrack } = useMixpanel();
  const [searchRecents, setSearchRecents] = useLocalStorageState<any[]>(`searchRecents ${orgId + userId}`, {
    defaultValue: [],
  });

  const [{ searchString, activeStatus }, setFilters] = useState<Filters>({ activeStatus: ACCOUNT_STATUS_ALL, searchString: '' });
  const [[rowsActive, rowsInactive], setRows] = useState<SplitRows>([[], []]);
  const searched: React.MutableRefObject<JsSearch.Search | undefined> = useRef();

  useEffect(() => {
    searched.current = new JsSearch.Search('name');
    searched.current.addIndex('email');
    searched.current.addIndex('name');
  }, []);
  const [showSiteLicense, setShowSiteLicense] = useState(false);

  const hideStatusColumn = !isAdmin ? ['status', 'action'] : [];

  const { data } = useQuery(GET_ORGANISATION_SITE_LICENSES, {
    variables: { orgId },
    onCompleted: ({ currentUser }) => {
      if (isAdmin && currentUser.organisation.siteLicenses.length > 0) setShowSiteLicense(true);
      if (!isAdmin && userSiteLicenses?.length > 0) setShowSiteLicense(true);
    },
  });

  const siteLicenses = useMemo(() => uniqBy(data?.currentUser?.organisation?.siteLicenses, 'license.id') || [], [data]);

  const filterMembers = (filters: Filters) => {
    const timeStarted = new Date();
    const results = (filters.searchString
      ? searched?.current?.search(filters.searchString) : members) as RestructuredMembers;
    setFilters(filters);
    setRows(splitActive(results, orgId));

    // Mixpanel event to track search
    if (searchString) {
      mixpanelTrack('Searched: ', {
        searchedIn: 'Org members list',
        searchInputLength: searchString.length,
        searchResultsCount: results.length,
        searchedTimeInSeconds: new Date().getSeconds() - timeStarted.getSeconds(),
      });
    }
  };

  useEffect(() => {
    searched?.current?.addDocuments(Array.from(members));
    setRows(splitActive(members, orgId));
  }, [members, orgId]);

  const orgLicenses: MultiToggleOption[] = useMemo(() => siteLicenses
    .filter(({ status }) => status === 'active')
    .map((siteLicense: OrgLicense) => ({
      header: extractCityName(siteLicense.license?.stripeProduct?.name),
      subHeader: siteLicense?.license?.location?.name?.split('_')[0],
      value: siteLicense?.license?.id,
    } as MultiToggleOption
    )), [siteLicenses]);

  return (
    <>
      <div className='relative flex flex-wrap items-center gap-2'>
        {hasInactiveMembers
          && <Select
            variant='outlined'
            className='h-[42px] py-1 w-[200px] [&.Mui-focused]:shadow-none'
            value={activeStatus}
            labelWidth={0}
            placeholder='searchPlace'
            onChange={({ target }: any) => {
              const newStatus = target?.value;
              mixpanelTrack('Members filtered:', { selectedState: ACCOUNT_STATUS_DESCRIPTIONS[newStatus] });
              filterMembers({ searchString, activeStatus: newStatus });
            }}
          >
            <MenuItem value={ACCOUNT_STATUS_ALL}>All members</MenuItem>
            <MenuItem value={ACCOUNT_STATUS_ACTIVE}>Active members</MenuItem>
            <MenuItem value={ACCOUNT_STATUS_INACTIVE}>Inactive members</MenuItem>
          </Select>
        }
        <div className='w-full max-w-330'>
          <SearchInput
            searchHandle={[
              searchString as string,
              (value) => filterMembers({ activeStatus, searchString: (value) }),
            ]}
            storingHandle={[searchRecents, setSearchRecents]}
            searchEndHandle={() => { }}
          />
        </div>
      </div>

      {(activeStatus !== ACCOUNT_STATUS_INACTIVE)
        && <div className='mt-2 mb-4 [&_.MuiTableCell-head:first-child]:pl-0'>
          <p className='text-lg font-medium'>Active members</p>

          <DataGrid
            rows={rowsActive}
            columns={columns}
            columnWidth={columnWidth}
            rowComponent={TableRow}
            sorting={false}
            noDataRowComponent={() => <tr><td>No results match your search</td></tr>}
          >
            <NameProviderNoCard for={['name']} />
            <DropdownProvider for={['role']} />
            <ActionProvider for={['action']} />
            <DropdownSubProvider for={['subscription']} />
            <ToggleDropdownProvider
              for={['siteLicenses']}
              licenses={orgLicenses}
              currentUser={{ id: userId, role: userRole }}
              siteLicenses={members.map((member) => ({ memberId: member.userId, siteLicenses: member.siteLicenses }))}
              userAssigned={userRole === MEMBER_ROLE && Boolean(userSiteLicenses?.length)}
            />
            {showSiteLicense && <TableColumnVisibility
              defaultHiddenColumnNames={hideStatusColumn} />}
            {!showSiteLicense && <TableColumnVisibility
              defaultHiddenColumnNames={[...hideStatusColumn, 'siteLicenses']} />}
          </DataGrid>
        </div>
      }

      {(hasInactiveMembers && (activeStatus !== ACCOUNT_STATUS_ACTIVE))
        && <div className='mt-2 mb-4 [&_.MuiTableCell-head:first-child]:pl-0'>
          <p className='text-lg font-medium'>Inactive members</p>

          <DataGrid
            rows={rowsInactive}
            columns={columns}
            columnWidth={columnWidth}
            rowComponent={TableRow}
            sorting={false}
            noDataRowComponent={() => <tr><td>No results match your search</td></tr>}
          >
            <NameProviderNoCard for={['name']} />
            <DropdownProvider for={['role']} />
            <ActionProvider for={['action']} />
            <DropdownSubProvider for={['subscription']} />
            <ToggleDropdownProvider
              for={['siteLicenses']}
              licenses={orgLicenses}
              currentUser={{ id: userId, role: userRole }}
              userAssigned={userRole === MEMBER_ROLE && Boolean(userSiteLicenses?.length)}
            />
            {showSiteLicense && <TableColumnVisibility
              defaultHiddenColumnNames={hideStatusColumn} />}
            {!showSiteLicense && <TableColumnVisibility
              defaultHiddenColumnNames={[...hideStatusColumn, 'siteLicenses']} />}
          </DataGrid>
        </div>
      }
    </>
  );
};

export default MembersBody;

import styled from 'styled-components';
import MuiLinearProgress from '@material-ui/core/LinearProgress';

const LinearProgress = styled(MuiLinearProgress)`
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  height: 5px;

  &#white {
    background-color: #2196f3;
    
    & .MuiLinearProgress-bar {
      background-color: #fff;
    }
  }
`;

export default LinearProgress;

import without from 'lodash/without';

import useProjectPermissions from '../useProjectPermissions';
import { UserPermissions } from '../../types/graphql';

const useCheckPermissions = (scopes: UserPermissions[]) => {
  const { permissions, loading } = useProjectPermissions();

  const missingPermissions = without(scopes, ...permissions);

  return [!missingPermissions.length, missingPermissions, loading];
};

export default useCheckPermissions;

import { NetworkStatus, useQuery } from '@apollo/client';
import React from 'react';
import { GQLWrapper } from '../../components';
import Drive from '../../components/Drive/Drive';
import { GET_PERSONAL_DRIVE } from './apollo';

export const PersonalDrive: React.FC = () => {
  const { error, data, refetch, networkStatus } = useQuery(GET_PERSONAL_DRIVE);

  const isLoading = networkStatus === NetworkStatus.loading;

  return (
    <GQLWrapper loading={isLoading} error={error} renderChildren={!!data}>
      <Drive
        data={data?.currentUser}
        user={data?.currentUser.tier}
        driveType="personal"
        refetch={refetch}
        refetchQueryAfterUpdate={{ query: GET_PERSONAL_DRIVE }}
      />
    </GQLWrapper>
  );
};

export default PersonalDrive;

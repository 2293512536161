import React from 'react';
import { ReactComponent as StepComplete } from './step-complete.svg';

interface IStepNumberProps {
  number: number;
  active: boolean;
  complete: boolean;
  size?: 'md' | 'lg';
}

const StepNumber = ({ number, active, complete, size = 'lg' }: IStepNumberProps) => {
  const cls = {
    active: 'border-primary bg-primary-lighter text-primary',
    default: 'border-content-light bg-content-light text-placeholder',
    size: {
      md: 'h-2.5 w-2.5 text-xs',
      lg: 'h-5 w-5',
    },
  };

  return (
    <>
      {complete
        ? <StepComplete className={`${cls.size[size]} cursor-pointer`} />
        : <div className={`${cls.size[size]} flex items-center justify-center border rounded-full ${active ? cls.active : cls.default}`}>
          {number}
        </div>
      }
    </>
  );
};

export default StepNumber;

import styled from 'styled-components';

export const SpinnerBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
  background: white;
  
  z-index: 0;
`;
export const Scene = styled.div`

  height: 100%;
  width: 100%;
  canvas {
    max-width: 100%;
  }
`;

export default SpinnerBox;

export const select = {
  root: {
    borderRadius: '8px',
  },
  select: {
    width: '240px',
    maxwidth: '100%',
    borderRadius: '8px',

    '&:focus': {
      backgroundColor: '#fff',
      borderRadius: '8px',
    },
  },
  outlined: {
    borderRadius: '8px',
  },
};

export default select;

import React from 'react';
import TopNav from 'tailwind/TopNav/TopNav';

const MobileLayout = ({ children }: { children: React.ReactNode; }) => (
  <>
    <TopNav showButtons={false} />
    <div className='w-full p-3 pt-5 h-content bg-primary-lightest text-content'>
      {children}
    </div>
  </>
);

export default MobileLayout;
import { TreeItem } from '@material-ui/lab';
import { ReactComponent as FolderIcon } from 'assets/icons/FolderIcon.svg';
import React from 'react';
import { FileNameEditor } from 'tailwind';
import { Folder } from 'types/graphql';

interface IDriveSubfolders {
  folder: Folder;
  onFolderClick?: () => void;
  children: React.ReactNode;
  rootFolder: string;
  isDisabled?: boolean;
  projectId?: string;
  driveType?: 'personal' | 'project';
  exclude?: boolean;
}

const DriveSubfolder: React.FC<IDriveSubfolders> = ({
  folder, onFolderClick, rootFolder, isDisabled, children, exclude = false,
}) => (
  <TreeItem
    nodeId={`${folder.id}`}
    key={folder.id}
    onLabelClick={onFolderClick}
    className={isDisabled ? 'cursor-default pointer-events-none [&_svg]:text-placeholder text-placeholder' : '[&_.MuiTreeItem-iconContainer_svg]:w-1.2'}
    label={
      <div className="flex items-center h-3 text-sm">
        {folder.name === '/' && (
          <div className='flex-[5%]'>
            <FolderIcon className='h-[15px] w-[30px]' />
          </div>
        )}

        <FileNameEditor
          editingId='_folder-edit'
          initialContent={folder.name === '/' ? rootFolder : folder.name}
          nodeId={folder.id}
          type='Folder'
          readonly={folder.name === '/' || exclude}
          isFolder
        />
      </div>
    }
  >
    {children}
  </TreeItem>
);

export default DriveSubfolder;

import { useQuery } from '@apollo/client';
import { useContext } from 'react';

import { VerifyEmailContext } from '../../components/AuthProvider/ApolloProvider';

import GET_EMAIL_VERIFIED from './apollo';

const useIsVerified = () => {
  const setShowVerifyEmail = useContext(VerifyEmailContext);

  const { data } = useQuery(GET_EMAIL_VERIFIED, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  });

  const isVerified = data?.currentUser?.emailVerified || false;
  return [isVerified, setShowVerifyEmail];
};

export default useIsVerified;

import copy from 'copy';
import { useCurrentUser, useMixpanel } from 'hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { emailRegex, lowerCaseFilter, nameSort } from 'utils/helpers';
import { Errors, SelectWithInputItem } from 'views/types';
import { Error, Image, Input, List, ListOptions, Option, Wrapper } from './SelectWithInput.style';
import { invalidDomains } from '../../../utils/constants';
import { ProjectMember } from 'types/graphql';

const { emailErrorMsg } = copy;

interface Props {
  options: SelectWithInputItem[] | [];
  index: number;
  members: string[];
  email: string | undefined;
  placeholder?: string;
  hasError: Errors;
  onChange: (e: string, i: number, selected?: boolean) => void;
  onKeyDown?: (skipBlur: boolean) => void;
  addAll?: boolean;
  className?: string;
  invitedMembers?: ProjectMember[];
}

const SelectWithInput = ({
  index, options, members, email, hasError, placeholder, addAll, className, onChange, onKeyDown, invitedMembers,
}: Props) => {
  const { currentUser } = useCurrentUser();
  const { mixpanelTrack } = useMixpanel();

  const ref = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  const { errors, setError, clearErrors } = hasError;

  const data = Array.from(new Set([...options]));
  const member = members ? members[index] : '';

  const isAllSelected = members.includes('all');

  const findOption = data.length > 0 ? data.find(({ value }) => value === member) : null;

  const filteredOptions = data.length > 0 && !isAllSelected
    ? data
      .filter(({ value }) => value !== email?.toLowerCase() && !members.some((m) => m === value && value !== 'all'))
      .filter((opt) => lowerCaseFilter(opt?.value || '', member)
        || (opt.displayText && lowerCaseFilter(opt.displayText, member)))
      .sort((a, b) => nameSort(a.displayText, b.displayText))
    : [];

  useEffect(() => {
    if (open) {
      const listener = (e: any) => {
        const current = e.target || {};
        if (current?.parentElement.id.startsWith('list--options')) return false;
        return setOpen(false);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }

    return undefined;
  }, [open]);

  const currentInput = useMemo(() => `email-${index}`, [index]);

  const logToMixpanel = (event: string) => {
    mixpanelTrack(event, {
      'Job title': currentUser?.jobTitle,
      'Org role': currentUser?.organisation?.me?.role?.toString(),
    });
  };

  const validate = (val: string) => {
    const isSelected = data.find(({ value }) => value === val);

    if (member === 'all') return true;

    if (val === email) {
      setError(currentInput, { message: emailErrorMsg.OWN_EMAIL });
      return false;
    }

    if (members && members.filter((m) => m === val.toLowerCase()).length > 1) {
      setError(currentInput, { message: emailErrorMsg.REPEATED });
      return false;
    }

    if (!emailRegex.test(val) && val !== '') {
      setError(currentInput, { message: emailErrorMsg.INVALID });
      return false;
    }

    for (const domain of invalidDomains) {
      if (val.includes(domain) && !isSelected) {
        setError(currentInput, { message: emailErrorMsg.UNAUTHORISED });
        return false;
      }
    }

    const alreadyInvited = invitedMembers?.some((value: ProjectMember) => val === value?.email.toLowerCase() && value?.isUserRemoved !== true);
    if (alreadyInvited) {
      setError(currentInput, { message: emailErrorMsg.ALREADY_INVITED });
      return false;
    }

    if (!isSelected && val) {
      logToMixpanel('Invite Org Members via Project Creation > Search > No Results - Action');
    }

    clearErrors(currentInput);
    return true;
  };

  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      <Wrapper $isOpen={open && filteredOptions.length > 0}>
        <Input onClick={() => data.length > 0 && setOpen(true)}>
          {findOption?.imgUrl && <Image src={findOption.imgUrl} alt={findOption.displayText} />}
          <input
            ref={ref}
            readOnly={member === 'all'}
            autoComplete='off'
            onClick={() => data.length > 0 && setOpen(true)}
            onKeyDown={(e: any) => {
              const target = e.target.value;
              if (e.key === 'Enter' && target && validate(target)) {
                e.target.blur();

                if (onKeyDown && !errors[currentInput]) {
                  onKeyDown(false);
                  if (open) setOpen(false);
                }
              }
            }}
            type="email"
            name={currentInput}
            value={member === 'all' ? 'Entire organisation' : findOption?.displayText || member}
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e.target.value.toLowerCase().trim(), index);
            }}
            onPaste={(e) => {
              e.preventDefault();
              const clipboardData = e.clipboardData;
              const existingValue = e.currentTarget.value;
              const combinedValue = existingValue + clipboardData.getData('text').trim();
              onChange(combinedValue, index, true);
            }}
            onBlur={(e) => {
              validate(findOption?.value || e.target.value);

              if (onKeyDown && !errors[currentInput]) {
                onKeyDown(true);
              }
            }}
          />
        </Input>

        {filteredOptions.length > 0 && open && (
          <List id='list--options'>
            <ListOptions id='list--options--container'>
              {filteredOptions.map((opt, i) => (
                <Option
                  id={`list--options-${i}`}
                  key={opt.value}
                  onClick={() => {
                    onChange(opt.value, index, true);
                    setOpen(false);

                    if (ref.current?.value) {
                      logToMixpanel('Invite Org Members via Project Creation > Search - Action');
                    }

                    logToMixpanel('Invite Org Members via Project Creation > Search > Successfully Adding Members - Action');
                    validate(opt.value);
                  }}
                >
                  <Image src={opt.imgUrl} alt={opt.displayText} />
                  <div>{opt.displayText}</div>
                </Option>
              ))}
            </ListOptions>

            {addAll && (
              <Option
                $all
                id={'list--options-all'}
                onClick={() => {
                  onChange('all', index, true);
                  setOpen(false);
                  logToMixpanel('Create a quick project > Add entire org > Selected');
                }}
              >
                <div>Add entire organisation</div>
              </Option>
            )}
          </List>
        )}
      </Wrapper>

      {errors[currentInput]
        && <Error>{errors[currentInput].message}</Error>}
    </div>
  );
};

export default SelectWithInput;

import { useMutation } from '@apollo/client';
import { MenuItem } from '@material-ui/core';
import { IContextMenuBase, IFileRow } from 'components/Drive/types';
import GQLWrapper from 'components/GQLWrapper/GQLWrapper';
import { TextModal } from 'components/Modal';
import { useHandleError, useMixpanel } from 'hooks';
import React, { useState } from 'react';
import { DELETE_NODES } from '../apollo';

interface IDeleteProps extends IContextMenuBase { }

const Delete: React.FC<IDeleteProps> = ({
  files, closeContextMenu, resetFiles,
}) => {
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();
  const [open, setOpen] = useState<boolean>(false);

  const openModal = () => { setOpen(true); mixpanelTrack('Delete'); };

  const closeModal = () => {
    setOpen(false);
    closeContextMenu();
    resetFiles();
  };

  const [deleteNodes, { loading }] = useMutation(DELETE_NODES, {
    onCompleted: () => {
      mixpanelTrack('Delete Successful');
      enqueueSnackbar('Files deleted', { variant: 'success' });
      closeModal();
    },
    onError: handleError,
    update: (cache, result) => {
      const isDeleted = result.data.deleteNodes;
      if (isDeleted) {
        files.forEach((file) => {
          cache.evict({ id: cache.identify({ __typename: file.__typename, id: file.id }) });
          cache.gc();
        });
      }
    },
  });

  const onDelete = () => {
    const filesToBeDeleted = files
      .filter((file: IFileRow) => !file.name.locked)
      .map((file: IFileRow) => ({
        id: file.id,
      }));

    deleteNodes({
      variables: {
        nodes: filesToBeDeleted,
      },
    });
  };

  return (
    <GQLWrapper loading={loading}>
      <MenuItem onClick={openModal} disabled={files?.length === 1 && files[0]?.name.locked}>
        Delete
      </MenuItem>

      <TextModal
        headerText="Are you sure you want to delete?"
        open={open}
        setOpen={closeModal}
        mixpanelPath='Delete files'
        closeBtnLabel='No'
        mainBtnLabel='Yes, delete'
        mainBtnHandle={onDelete}
        loading={loading}
        component='div'
      >
        This will result in a permanent deletion of your file or files, and you <br /> will
        <span className='font-medium'> not</span> be able to recover it.

        <p className='mt-2'>
          Do you still want to permanently delete this item?
        </p>
      </TextModal>
    </GQLWrapper>
  );
};

export default Delete;

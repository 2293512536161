import React from 'react';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import { SpinnerBox } from '../Viewer3D.style';

const Rendering = (): JSX.Element => (
    <SpinnerBox>
      <Typography variant="body2">Rendering...</Typography>
      <CircularProgress />
    </SpinnerBox>
);

export default Rendering;

import { TextModal } from 'components';
import React from 'react';

interface ThankModalProps {
  orderId: string;
  setOrderId: (arg: string) => void;
}

const ThankYouModal = ({ orderId, setOrderId }: ThankModalProps) => (
  <TextModal
    headerText='Thank you for the quote request'
    component='div'
    closeBtnLabel='Close'
    open={true}
    setOpen={() => setOrderId('')}
  >
    <>
      <p>You will soon receive an email, confirming your request.</p>
      <p>A member of our team will be in contact with you shortly.</p>

      <p className='pt-2'>Your email will contain your quotation ID:</p>

      <p className='py-2 text-3xl font-medium'>{orderId}</p>

      <p>Please use this in any correspondence relating to your request.</p>
    </>
  </TextModal>
);

export default ThankYouModal;

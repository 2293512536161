import { palette, typography } from '../utils';

export const dataGrid = {
  root: {
    border: '0',

    '& .MuiDataGrid-columnsContainer, & .MuiDataGrid-cell': {
      borderBottom: `1px solid ${palette.placeholder.main}`,
    },

    '&& .MuiDataGrid-row:hover': {
      backgroundColor: 'unset',
    },

    '& .MuiDataGrid-window': {
      overflowX: 'hidden',
    },

    '& .MuiDataGrid-sortIcon': {
      fontSize: '16px',
      color: palette.text.primary,
    },

    '& .MuiDataGrid-colCellTitle': {
      color: palette.gray.main,
      fontSize: typography.subtitle1.fontSize,
      fontWeight: '400',
      width: ' 100%',
    },

    '& .MuiDataGrid-colCellTitleContainer': {
      '& .MuiButtonBase-root:not(.MuiCheckbox-root) .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
        color: palette.text.primary,
      },
    },
  },
};

export default dataGrid;

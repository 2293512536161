import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';

import { useNotification } from '@magicbell/magicbell-react';
import { ListItemProps } from '@magicbell/magicbell-react/dist/components/NotificationList';
import { ClickAwayListener } from '@material-ui/core';
import { ReactComponent as KebabDot } from 'assets/icons/KebabIconHorizontal.svg';
import { ReactComponent as NotificationDot } from 'assets/icons/NotificationDot.svg';

import { NotificationArticle, NotificationItemContainer, NotificationState, NotificationTitle, OptionsMenu } from './Notification.style';

export default function NotificationItem({ notification, onClick }: ListItemProps) {
  const remoteNotification = useNotification(notification);
  const {
    sentAt,
    title,
    content,
    isRead,
    markAsRead,
    markAsUnread,
    delete: deleteMessage,
  } = remoteNotification;

  dayjs.extend(relativeTime);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleKebabClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClick = () => {
    if (!isRead) markAsRead();
    onClick?.(remoteNotification);
  };

  const handleStageChange = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isRead) return markAsRead();
    return markAsUnread();
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    deleteMessage();
  };

  return (
    <NotificationItemContainer
      className={`${isRead ? 'bg-white' : 'bg-primary-lightest'} min-h-[32px] rounded h-auto text-content text-left w-auto flex items-center relative gap-1 no-underline`}
      onClick={handleClick}
    >
      <NotificationState isRead={isRead}>
        <NotificationDot />
      </NotificationState>

      <div className='relative block w-full'>
        <NotificationTitle>{title}</NotificationTitle>
        <NotificationArticle>{content}</NotificationArticle>
      </div>

      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <div className='relative flex flex-col items-end ml-auto text-xs'>
          <div>{dayjs(sentAt as dayjs.Dayjs).toNow(true)}</div>

          <button className='cursor-pointer' onClick={handleKebabClick}>
            <KebabDot />
          </button>

          <OptionsMenu
            open={open}
            anchorEl={anchorEl}
            disablePortal
          >
            <button onClick={handleStageChange}>Mark as {isRead ? 'unread' : 'read'}</button>
            <button onClick={handleDelete}>Delete</button>
          </OptionsMenu>
        </div>
      </ClickAwayListener>
    </NotificationItemContainer>
  );
}

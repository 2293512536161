import styled from 'styled-components';
import { Box as MuiBox } from '@material-ui/core';

const Wrapper = styled(MuiBox)`
  position: absolute;
  z-index: 1;
  justify-content: space-between;
  width: 100%;
  opacity: 0;
  display: flex;
  transition: opacity 0.2s ease-in-out;
  background: linear-gradient(rgb(108, 108, 108), rgba(108, 108, 108, 0.5) 73.1%, rgba(108, 108, 108, 0) 100%);

  button {
    margin-left: auto;
  }

  svg {
    margin: 14px;
    width: 18px;
  }
`;
export const SwitchAssets = styled(Wrapper)`
  margin: -48px 0;
  .MuiLink-button{
    background: #6C6C6C;
    opacity: 0.5;
    border-radius: 100%;
    width:48px;
    height:48px;
    position: absolute;
    top: 50vh;
  }
  .left{
    left:16px;
  }
  .right{
    right:16px;
  }
  svg {    
    margin: 12px 18px;
    width: 12px;
    height: 24px;
  }
`;
export const Column = styled.div`
  z-index: 1;
  justify-content: space-between;
  display: flex;
`;

export default Wrapper;

import { ApolloError, useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingScreen from 'components/Loading/LoadingScreen/LoadingScreen';
import { useHandleError } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Project } from 'types/graphql';
import { GET_CURRENT_USER } from 'utils/apollo/user';
import PrivateRoute from 'utils/routes/PrivateRoute';
import WelcomeTour from './WelcomeTour';

declare global {
  interface Window {
    userGuiding?: any;
    mixpanel?: any;
  }
}

const Welcome: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState();
  const [handleError] = useHandleError();

  const [getCurrentUser, { data, error, called }] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    onError: (err: ApolloError) => {
      setErrorMessage(handleError(err));
    },
  });
  const history = useHistory();
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (!called) getCurrentUser();
  }, [called, getCurrentUser]);

  // TODO: Initiate the identify here for now
  // will need to be moved to a separate provider once we have UserContext (!!!) set
  useEffect(() => {
    if (data && isAuthenticated) {
      const { name, jobTitle, projects, tier, tours } = data.currentUser || {};
      const { email, email_verified, sub } = user || {};

      const acceptedProjects = projects?.filter(
        (p: Project) => p?.me?.status === 'ACCEPTED' || p?.me?.status === 'INVITED',
      );

      window.mixpanel.identify(sub);
      window.userGuiding.identify(sub, {
        email,
        name,
        Projects: acceptedProjects.length,
        Environment: import.meta.env.VITE_ENV?.toLowerCase(),
        Tier: tier?.name,
        'Job title': jobTitle,
        'Email verified': email_verified,
        'Intro Tour Seen': !!tours.intro?.completed,
        'Project view Tour Seen': !!tours.project_view?.completed,
      });
    }
  }, [data, isAuthenticated, user]);

  /**
   * We must return null until we have data to test; otherwise 2 things happen
   * 1. may tigger child modal views; confirm email, Tours ...
   * 2. Ugly flash of / home page, to be replaced with welcome tour
   */

  if (!data && !error) {
    return <LoadingScreen />;
  }

  // if our backend service fails, we should redirect to error page
  if (errorMessage === 'Failed to fetch') history.push('/error');


  const showWelcome = data?.currentUser
    && !data?.currentUser?.tours?.welcome?.completed;

  return showWelcome
    ? <PrivateRoute component={WelcomeTour} />
    : <>{children}</>;
};

export default Welcome;

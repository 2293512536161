import { useQuery } from '@apollo/client';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AccountLayout, Button } from 'tailwind';
import { Project } from 'types/graphql';
import GET_ORG_PROJECTS from './apollo';
import { ReactComponent as MembersIcon } from './assets/Members.svg';
import { ReactComponent as ProjectsIcon } from './assets/Projects.svg';

const OrganizationOverview = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { data, loading, error } = useQuery(GET_ORG_PROJECTS);
  const {
    currentUser: { organisation },
  } = data ?? {
    currentUser: {
      organisation: {
        trialAvailable: false,
        subscriptions: [],
      },
      lastLogin: null,
    },
  };

  const membersCount: number = organisation?.members
    ?.filter(({ status, isUserRemoved }: { status: string; isUserRemoved: boolean; }) => status === 'ACCEPTED' && !isUserRemoved)
    .length || 0;

  const projectsCount: number = organisation?.projects
    ?.reduce((projects: Project[], item: Project) => {
      if (!projects.some((obj: any) => obj.id === item.id)) {
        projects.push(item);
      }

      return projects;
    }, []).length || 0;

  return (
    <AccountLayout
      title='Overview'
      subtitle="Summary of your organisation's members and subscriptions."
      gql={{ loading, error, renderChildren: !!data }}
      button={{
        text: 'Get in touch with our team',
        variant: 'outlined',
        onClick: () => {
          const url = '/contact';
          history.push({
            pathname: url,
            state: { fromDashboard: true, prevPath: pathname },
          });
        },
      }}
    >
      <div className='flex flex-wrap gap-3'>
        <div className='p-2 w-[300px] rounded-sm border border-primary-lightest'>
          <div className='flex flex-col items-start gap-1'>
            <MembersIcon className='h-2.5 w-2.5 mb-1' />

            <div>
              <h5>Active members</h5>
              <p className='text-grey'>
                Total organisation members
              </p>
            </div>

            <div className='flex items-end'>
              <h2>{membersCount}</h2>
              <span className='mb-1 ml-1 text-sm text-grey'>
                member{membersCount !== 1 && 's'}
              </span>
            </div>
          </div>
        </div>

        <div className='p-2 w-[300px] rounded-sm border border-primary-lightest'>
          <div className='flex flex-col items-start gap-1'>
            <ProjectsIcon className='h-2.5 w-2.5 mb-1' />

            <div>
              <h5>Active projects</h5>
              <p className='text-grey'>
                Projects with ongoing activity
              </p>
            </div>

            <div className='flex items-end'>
              <h2>{projectsCount}</h2>
              <span className='mb-1 ml-1 text-sm text-grey'>
                project{projectsCount !== 1 && 's'}
              </span>
            </div>

            <div className='flex justify-end w-full mt-1'>
              <Button size='md' padding='sm' onClick={() => { history.push('/'); }}>
                View projects
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AccountLayout>
  );
};

export default OrganizationOverview;

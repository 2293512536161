import MuiBox from '@material-ui/core/Box';
import ResizeIcon from 'assets/icons/ResizeIcon.svg';
import styled from 'styled-components';

export const DriveGrid = styled(MuiBox)`
  ${({ theme }) => `
    width: 100%;
    position: relative;
    color: ${theme.palette.text.primary};
    overflow: auto hidden;

    
    & .table--selectall > span {
      opacity: 0.3;
    }
    
    & .MuiButton-text.MuiButtonBase-root {
      padding: 0;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      color: ${theme.palette.text.primary};
      font-weight: normal;
      font-size: ${theme.typography.subtitle1.fontSize};
      margin: 0 4px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
    
    & .MuiButton-text.MuiButtonBase-root.Mui-disabled,
    & .MuiButton-text.MuiButtonBase-root[tabindex*='-1'] {
      background-color: rgba(0, 0, 0, 0.04);
    }

    & .MuiIconButton-root[aria-label="Previous"],
    & .MuiIconButton-root[aria-label="Next"] {
      padding: 0;
      height: 32px;
      width: 32px;
      margin: 0 4px;
      font-size: ${theme.typography.subtitle1.fontSize};
    }

    & .MuiTableCell-head {
      > div:last-of-type {
        width: 16px;
        right: 10px;
        background-color: ${theme.palette.primary.main};
        mask: url(${ResizeIcon}) no-repeat center;
        opacity: 0;
        cursor: grabbing;
        transition: 0.3s ease-in-out;

        & div {
          display: none;
        }
      }

      &:hover {
        > div:last-of-type {
          opacity: 1;
        }
      }
    }
  `}
`;

export const FolderContainer = styled(MuiBox)`
  & #tree_view  {
    > .MuiTreeItem-root:first-child {
      > .MuiTreeItem-content:first-child .MuiTreeItem-iconContainer {
        display: none;
      }
    }

    .MuiTreeItem-group {
      margin-left: 9px;
    }
  }
`;

export default DriveGrid;

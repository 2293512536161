import React, { useCallback, useEffect } from 'react';
import { useMap } from 'react-map-gl';

import { useMixpanel } from 'hooks';
import { Tiles } from '../types';

let hoverId: string | number | undefined = '';

interface ITileSelectionProps {
  tiles: Tiles[];
  setTiles: React.Dispatch<React.SetStateAction<Tiles[]>>;
  selected: boolean;
}

const TileSelection = ({ tiles, setTiles, selected }: ITileSelectionProps) => {
  const { current } = useMap();
  const { mixpanelTrack } = useMixpanel();

  const mixpanelEvent = '3D model coverage / Tool menu / Tile tool';

  const onHover = useCallback((e: any) => {
    if (!current) return;

    if (!e.features || !selected) {
      current?.setFeatureState({
        source: '250_fill_source',
        sourceLayer: 'default',
        id: hoverId,
      }, { hover: false });
      return hoverId = '';
    }

    if (e.features.length > 0) {
      current.getCanvas().style.cursor = 'pointer';

      if (hoverId) {
        current?.setFeatureState({
          source: '250_fill_source',
          sourceLayer: 'default',
          id: hoverId,
        }, { hover: false });
      }

      if (e.features[0].id) {
        hoverId = e.features[0].id;
        current?.setFeatureState({
          source: '250_fill_source',
          sourceLayer: 'default',
          id: hoverId,
        }, { hover: true });
      }
    }
  }, [current, selected]);

  const onLeaveHover = useCallback(() => {
    if (hoverId) {
      current?.setFeatureState({
        source: '250_fill_source',
        sourceLayer: 'default',
        id: hoverId,
      }, { hover: false });
    }
    hoverId = '';
  }, [current]);

  // Hover effect on the tiles
  useEffect(() => {
    current?.on('mousemove', '250_fill_layer', onHover);
    return () => { current?.off('mousemove', '250_fill_layer', onHover); };
  }, [current, onHover, selected]);

  useEffect(() => {
    current?.on('mouseleave', '250_fill_layer', onLeaveHover);
    return () => { current?.off('mouseleave', '250_fill_layer', onLeaveHover); };
  });

  const onTileSelection = useCallback((e: any) => {
    if (!selected || !e.features) return;

    const { lngLat } = e;
    const id = e.features[0].properties?.id;

    const feature = e.features[0];

    let newTiles = [...tiles];

    if (tiles.find(tile => tile.id === id)) {
      newTiles = newTiles?.filter(t => t.id !== id);

      mixpanelTrack(`${mixpanelEvent} / Remove tile`, { 'Tile removed': id });

      current?.setFeatureState({
        source: '250_fill_source_selected',
        sourceLayer: 'default',
        id,
      }, { selected: false });
    } else {
      newTiles = [...newTiles, {
        id, center: lngLat, polygon: {
          id: feature.id,
          type: feature.type,
          properties: feature.properties,
          geometry: feature.geometry,
        },
      }];

      mixpanelTrack(`${mixpanelEvent} / Select tile`, { 'Tile selected': id });

      current?.setFeatureState({
        source: '250_fill_source_selected',
        sourceLayer: 'default',
        id,
      }, { selected: true });
    }

    setTiles(newTiles);
  }, [selected, tiles, setTiles, mixpanelTrack, current]);

  const onStyleChange = useCallback(() => {
    if (!current) return;

    const waitForSource = current.getSource('250_fill_source_selected');
    if (tiles.length && waitForSource) {
      tiles.forEach((tile) => {
        current?.setFeatureState({
          source: '250_fill_source_selected',
          sourceLayer: 'default',
          id: tile.id,
        }, { selected: true });
      });
    }
  }, [tiles, current]);

  // Show selected tiles when map style changes
  useEffect(() => {
    current?.on('styledata', onStyleChange);
    return () => { current?.off('styledata', onStyleChange); };
  }, [current, onStyleChange]);

  // Click event on the tiles
  useEffect(() => {
    current?.on('click', '250_fill_layer_selected', onTileSelection);
    return () => { current?.off('click', '250_fill_layer_selected', onTileSelection); };
  }, [onTileSelection, selected, current]);

  return null;
};

export default TileSelection;

import { ApolloError, PureQueryOptions, RefetchQueriesFunction, useMutation } from '@apollo/client';
import { useState } from 'react';

import { useHandleError } from 'hooks';
import { getApolloErrorData, parseGraphqlError } from 'utils/helpers';

import useMixpanel from '../useMixpanel';
import COPY_NODES from './apollo';

export type IRefetchProps = (string | PureQueryOptions)[] | RefetchQueriesFunction | undefined;

const useCopyNodes = (onCompleted: any, onError?: any) => {
  const [handleError, enqueueSnackbar] = useHandleError();
  const { mixpanelTrack } = useMixpanel();
  const [errorCode, setErrorCode] = useState<string>('');

  const [copyNodes, { data, loading, error }] = useMutation(COPY_NODES, {
    onError: (err: ApolloError) => {
      const [code] = parseGraphqlError(err.graphQLErrors);
      setErrorCode(code);
      mixpanelTrack('Copy nodes Failed');
      if (onError) onError(code);

      if (code !== 'INSUFFICIENT_DRIVE_SPACE' && code !== 'FILE_DRIVE_LOCATION_MISMATCH') {
        handleError(err);
      }
    },
    onCompleted: (resData) => {
      mixpanelTrack('Copy nodes Successful');
      if (onCompleted) onCompleted(resData.copyNodes);
      enqueueSnackbar('Your files have been successfully copied. Up to a maximum of 6 levels deep (sub-folders).', { variant: 'success' });
    },
  });

  const handleCopyNodes = async (
    nodes: { id: string }[], folderId?: string, projectId?: string,
  ) => {
    copyNodes({
      variables: {
        projectId,
        nodes,
        folder: { id: folderId },
      },
    });
  };

  const { invalidFiles, spaceNeeded, spaceAvailable } = getApolloErrorData(error, {
    invalidFiles: [], spaceNeeded: 0, spaceAvailable: 0,
  });

  return {
    copyNodes: handleCopyNodes,
    data,
    loading,
    error: error?.message,
    errorData: { invalidFiles, spaceNeeded, spaceAvailable },
    errorCode,
    setErrorCode,
  };
};

export default useCopyNodes;

import { palette } from '../utils';

export const step = {
  root: {
    textAlign: 'center',
  },
};

export const stepLabel = {
  label: {
    color: palette.placeholder.main,
    '&$alternativeLabel': {
      marginTop: '10px',
    },
  },
  iconContainer: {
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },
};

export const stepButton = {
  root: {
    color: palette.placeholder.main,
    background: palette.gray.light,
    width: '136px',
    padding: '19px 5px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    height: '88px',
    '&$disabled': {
      color: palette.placeholder.main,
      background: palette.gray.light,
    },
    '&.active': {
      color: palette.primary.main,
      background: palette.primary.lightest,
      boxShadow: '0px 0px 10px 0px #375675',
    },
    '&.complete': {
      color: palette.primary.main,
      background: palette.primary.lightest,
    },
  },
};

export const stepConnector = {
  alternativeLabel: {
    top: '22px',
    left: 'calc(-50% + 88px)',
    right: 'calc(50% + 88px)',
  },
};

import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useMoveNodes } from '../../../../../hooks';
import { GET_PERSONAL_DRIVE } from '../../../../../views/drive/apollo';
import { GET_PROJECT_DRIVE } from '../../../../../views/project/drive/apollo';
import GQLWrapper from '../../../../GQLWrapper/GQLWrapper';
import TransferContextMenu from '../../../TransferContextMenu/TransferContextMenu';
import { IFileRow } from '../../../types';
import { GET_PERSONAL_DRIVE_FOLDERS } from '../UploadToPersonal/apollo';
import { GET_PROJECT_DRIVE_FOLDERS } from './apollo';

interface IUploadProps {
  projectId?: string;
  files: IFileRow[];
  closeContextMenu: () => void;
  setIsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

const MoveToContextMenu: React.FC<IUploadProps> = ({
  projectId, files, closeContextMenu, setIsUpdated,
}) => {
  const moveData = projectId ? {
    query: GET_PROJECT_DRIVE_FOLDERS,
    refetch: GET_PROJECT_DRIVE,
    root: 'Project drive',
  } : {
    query: GET_PERSONAL_DRIVE_FOLDERS,
    refetch: GET_PERSONAL_DRIVE,
    root: 'Personal drive',
  };

  const { loading, error, data } = useQuery(moveData.query, {
    variables: { id: projectId },
  });
  const treeData = useMemo(() => (projectId
    ? data?.project?.drive.nodeByPath
    : data?.currentUser?.drive.nodeByPath), [data, projectId]);

  return (
    <GQLWrapper loading={loading} error={error} renderChildren={!!data}>
      <TransferContextMenu
        projectId={projectId}
        treeData={treeData}
        files={files.map((file: IFileRow) => file.id)}
        closeContextMenu={closeContextMenu}
        useCopyOrMoveNodes={useMoveNodes}
        refetchQuery={{ query: moveData.refetch, variables: { id: projectId } }}
        rootFolder={moveData.root}
        initialQueryLoading={loading}
        setIsUpdated={setIsUpdated}
        isMoving={true}
      />
    </GQLWrapper>
  );
};

export default MoveToContextMenu;

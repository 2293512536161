import React, { useState } from 'react';
import { Stepper } from 'tailwind';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const steps = [
  { title: 'Welcome', button: "Let's get started" },
  { title: 'About you', button: 'Confirm job title' },
  { title: 'Getting started', button: 'Start using VU.CITY' },
];
const stepViews = [Step1, Step2, Step3];

const WelcomeTour = () => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const StepView = stepViews[activeStep];

  return (
    <div className='h-screen px-3 py-5 overflow-auto'>
      <div className='max-w-[682px] w-full h-full mx-auto'>
        <Stepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          steps={steps}
          mixpanelLabel='Welcome journey'
        />

        <div className='flex flex-col items-center justify-center mb-5'>
          <StepView {...{ handleNext, handleBack }} />
        </div>
      </div>
    </div>
  );
};

export default WelcomeTour;

import React from 'react';
import { getTrialDays } from 'utils/helpers';

const FreeTrial = ({ trialEnd }: { trialEnd?: number; }) => {
  const [number, time] = getTrialDays(trialEnd);

  return (
    <div className='bg-primary-lightest min-w-max w-[145px] py-0.5 px-1 text-xs flex items-center rounded justify-center text-primary'>
      Trial -
      <span className='font-medium mx-0.25'>{number}</span>
      {time} remaining
    </div>
  );
};

export default FreeTrial;

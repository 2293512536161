import React, { createContext, MutableRefObject, useRef, useState } from 'react';
import { IFileRow } from '../types';

export const DriveSelectedFilesContext = createContext({
  selectedFiles: [] as IFileRow[],
  setSelectedFiles: (() => {}) as (files: IFileRow[]) => void,
  vtRef: {} as MutableRefObject<any>,
  setRef: (() => {}) as (ref: MutableRefObject<any>) => void,
});

const DriveProvider: React.FC<React.PropsWithChildren<{ }>> = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = useState<IFileRow[]>([]);
  const vtRef = useRef<any>();
  const setRef = (ref: MutableRefObject<any>) => {
    vtRef.current = ref;
  };
  return (
    <DriveSelectedFilesContext.Provider
      value={{ selectedFiles,
        setSelectedFiles,
        vtRef, setRef }}>
      {children}
    </DriveSelectedFilesContext.Provider>
  );
};

export default DriveProvider;

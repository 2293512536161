import { gql, TypedDocumentNode } from '@apollo/client';
import { User } from 'types/graphql';

const GET_SITE_LICENCES: TypedDocumentNode<{ currentUser: User }> = gql`
  query siteLicences {
    currentUser {
      siteLicenses {
        licenseId
      }
      organisation {
        projects {
          id
          location {
            license {
              id
            }
          }
        }
        members {
          siteLicenses {
            licenseId
          }
        }
        siteLicenses {
          id
          license {
            id
            stripeProduct {
              name
              description
              images
            }
          }
          status
          subscription {
            id
            current_period_end
          }
        }
      }
    }
  }
`;

export default GET_SITE_LICENCES;

import React, { ComponentProps } from 'react';

interface TextFieldProps extends ComponentProps<'input'> {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  error?: boolean;
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(({ className, 
  startAdornment,
  error = false, 
  endAdornment, ...props }, ref) => {
  return (
    <div className={`text-content 
      w-full cursor-text box-border inline-flex
      items-center text-lg font-normal
      relative group
      pointer-events-none
      rounded mb-1 
      focus-within:shadow-input
      focused-within:outline-0 
      focused-within:border-0`}
      >
        {startAdornment && startAdornment}
        <input className='text-content 
          py-1.2 px-2 text-base 
          rounded box-border min-h-[48px] w-full
          pointer-events-auto
          border-0
          m-0
          outline-0
          bg-white
          min-w-0
          h-[1.1876em]
          block
          ' 
          ref={ref} {...props} 
        />
        {endAdornment && endAdornment}
        <fieldset className={`border border-solid
          absolute pointer-events-none 
          rounded
          top-0 py-0 px-1 bottom-0 left-0 right-0 overflow-hidden
          pl-1 ${error ? 'border-error group-hover:border-error' : 'border-placeholder group-hover:border-primary'}`}
        >
        </fieldset>
    </div>
  );
});



export default TextField;
import React from 'react';
import LoadingBar from '../../Loading/LoadingBar';
import CustomModal from '../Modal';

interface IModalProps {
  title: string;
  message: string;
}

const SimpleLoadingModal: React.FC<IModalProps> = ({ title, message }) => (
  <CustomModal open={true} headerText={title}>
    <>
      <LoadingBar />
      <div className='w-1/2 pt-3 pb-4 text-center'>
        <p>
          {message}
        </p>
      </div>
    </>
  </CustomModal>
);

export default SimpleLoadingModal;

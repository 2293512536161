import { palette } from '../utils';

const padding = '12px 40px';
const smallPadding = '12px 24px';
const paddingMBorder = '11px 39px';
const smallPaddingMBorder = '11px 23px';
const borderRadius = '8px';

export const button = {
  root: {
    borderRadius,
    padding,
    textTransform: 'none',
    fontSize: '18px',

    '&::placeholder': {
      color: 'gray',
    },

    '&.disabled_launch': {
      background: palette.primary.lighter,
      color: palette.white.main,

      '&:hover': {
        background: palette.primary.lighter,
        color: palette.white.main,
        outline: 0,
        backgroundColor: palette.primary.light,
      },
    },

    '&.spaced': {
      margin: '0 16px',
    },
    '&.smaller': {
      padding: '8px 40px',
      '& .MuiButton-label': {
        fontSize: '14px',
        lineHeight: '16px',
      },
    },
    '&.iconOnly': {
      padding: '8px',
      minWidth: '0',

      '&.spaced': {
        marginLeft: '8px',
      },

      '& .MuiButton-label > svg': {
        fontSize: '24px',
      },
    },
    '&.red': {
      color: palette.error.main,
      borderColor: palette.error.main,
    },
  },
  label: {
    lineHeight: '24px',
  },
  sizeSmall: {
    padding: smallPadding,
    fontSize: '18px',
  },

  contained: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  containedPrimary: {
    '&:hover': {
      color: '#375675',
      backgroundColor: '#ffffff',
      outline: '1px solid #375675',
    },
    '&$disabled': {
      background: palette.primary.lighter,
      color: palette.white.main,

      '&:hover': {
        backgroundColor: palette.primary.light,
      },
    },
  },
  containedSecondary: {
    '&$disabled': {
      background: palette.secondary.lighter,
      color: palette.white.main,
    },
  },

  outlined: {
    borderRadius,
    padding: paddingMBorder,
  },
  outlinedPrimary: {
    borderColor: palette.primary.main,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: palette.primary.light,
      color: palette.primary.light,
    },
    '&$disabled': {
      borderColor: palette.primary.lighter,
      color: palette.primary.lighter,
    },
  },
  outlinedSecondary: {
    borderColor: palette.secondary.main,
    '&$disabled': {
      borderColor: palette.secondary.lighter,
      color: palette.secondary.lighter,
    },
  },
  outlinedSizeSmall: {
    padding: smallPaddingMBorder,
  },

  text: {
    borderRadius,
    padding,
  },
  iconSizeSmall: {
    '& > *:first-child': {
      fontSize: 20,
    },
  },

};

export const iconButton = {
  label: {
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
  },
};

import { useMutation } from '@apollo/client';
import { useHandleError, useMixpanel } from 'hooks';
import React, { ReactElement, useEffect } from 'react';
import { Button } from 'tailwind';
import { DELETE_COMMENT } from '../apollo';

interface Props {
  id: string;
  setAction: React.Dispatch<string>;
  setLoading: React.Dispatch<boolean>;
}

function DeleteComment({ id, setAction, setLoading }: Props): ReactElement {
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setLoading(false), []);

  const [deleteComment] = useMutation(DELETE_COMMENT, {
    onCompleted: () => enqueueSnackbar('This comment has been deleted', { variant: 'success' }),
    onError: handleError,
  });

  const deleteHandler = () => {
    deleteComment({ variables: { commentId: id } });
    mixpanelTrack('Confirm Delete');
    setAction('');
  };

  const cancelHandler = () => {
    mixpanelTrack('Cancel Delete');
    setAction('');
  };

  return (
    <div className='flex justify-between mt-1 mb-2'>
      <Button
        size='xs'
        padding='none'
        variant="text"
        color="content"
        onClick={cancelHandler}
      >
        Cancel
      </Button>
      <Button
        color='error'
        size='xs'
        onClick={deleteHandler}
      >
        Delete comment
      </Button>
    </div>
  );
}

export default DeleteComment;

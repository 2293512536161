import { gql } from '@apollo/client';

export const DELETE_NODES = gql`
  mutation DeleteNodes($nodes: [NodeInput], $projectId: ID) {
    deleteNodes(nodes: $nodes, projectId: $projectId)
  }
`;

export const RENAME_NODES = gql`
  mutation ($node: NodeInput, $projectId: ID, $name: String!, $policy: WritePolicy) {
    renameNode(node: $node, projectId: $projectId, name: $name, policy: $policy) {
      name
      id
    }
  }
`;

export const ZIP_NODES = gql`
  mutation ($nodes: [ID]) {
    zipNodes(nodes: $nodes)
  }
`;

export const GET_NODE_SIGNED_URL = gql`
  query getNodeSignedUrl($fileId: ID!) {
    currentUser {
      id
      drive {
        nodeByID(id: $fileId) {
          ...on File {
            signedUrl
          }
        }
      }
    }
  }
`;


export const UPDATE_NODES_PERMISSIONS = gql`
  mutation ($nodes: [NodeInput], $projectId: ID) {
    updateNodesPermissions(nodes: $nodes, projectId: $projectId) {
      whitelist
      name
      id
    }
  }
`;
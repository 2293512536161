import React from 'react';

interface CheckboxProps {
  name: string;
  title: string;
  checked?: boolean;
  onChange: (arg: React.ChangeEvent<HTMLInputElement>) => void;
  labelClassName?: string;
}

const Checkbox = ({ name, title, checked, onChange, labelClassName }: CheckboxProps) => (
  <div className='flex items-center gap-1 text-sm'>
    <input
      id={name}
      name={name}
      value={name}
      checked={checked}
      onChange={onChange}
      type="checkbox"
      className='w-2 h-2 accent-primary'
    />
    <label className={labelClassName} htmlFor={name}>{title}</label>
  </div>
);

export default Checkbox;

const preferenceRoles = [
  {
    value: 'VIEWER',
    title: 'Viewer',
    subtitle: 'Limited to reading project information, viewing and commenting on project drive assets.',
  },
  {
    value: 'EDITOR',
    title: 'Editor',
    subtitle: 'Ability to invite editors and viewers, launch the 3D applications and make changes to the primary file.',
  },
  {
    value: 'ADMIN',
    title: 'Admin',
    subtitle: 'Full administration rights on the project, including managing project members, setting project info and the primary file.',
  },
];

export default preferenceRoles;

import { Tooltip } from 'components';
import React from 'react';
import Button, { ButtonProps } from '../BaseButton';

interface TooltipBtnProps extends ButtonProps {
  selected: boolean;
  description?: React.ReactNode;
  ref?: any;
}

const TooltipButton = ({ description, selected, children, ...props }: TooltipBtnProps) => (
  <Tooltip
    placement='bottom-start'
    content={description}
  >
    <Button
      size='sm'
      padding='unset'
      className={`w-5 rounded-sm mb-0.5 border-0 [&_svg]:hover:opacity-50 hover:bg-primary-lightest ${selected ? 'sm:bg-primary-lightest' : ''}`}
      {...props}
    >
      {children}
    </Button>
  </Tooltip>
);

export default TooltipButton;

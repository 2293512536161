import { Avatar } from '@material-ui/core';
import { ProfileIcon } from '@vucity/design_system';
import { useProjectMembers } from 'hooks';
import React, { useMemo } from 'react';
import { Mention, MentionsInput, MentionsInputProps } from 'react-mentions';
import theme from 'theme/theme';
import { ProjectMember } from 'types/graphql';
import { MentionsInputWrapper } from './Mentions.style';

const Mentions = (props: Omit<MentionsInputProps, 'children'>) => {
  const [members] = useProjectMembers();

  const membersFormatted = useMemo(
    () => members.map((mem: ProjectMember) => ({
      id: mem?.id,
      display: mem.profile?.name || mem.email,
      avatar: mem.profile?.picture,
    })), [members],
  );

  return (
    <MentionsInputWrapper>
      <MentionsInput
        a11ySuggestionsListLabel={'Suggested mentions'}
        allowSuggestionsAboveCursor
        className='mentions'
        {...props}
      >
        <Mention
          markup='@@{__id__}|{__display__}##'
          trigger="@"
          className='user'
          appendSpaceOnAdd
          displayTransform={(id, display) => `@${display}`}
          renderSuggestion={(entry: typeof membersFormatted) => (
            <div className='min-w-[214px] flex items-center'>
              <Avatar src={entry.avatar} alt='avatar' style={{ width: '24px', height: '24px' }}>
                {!entry.avatar && <ProfileIcon fontSize="inherit" htmlColor={theme.palette.text.primary} />}
              </Avatar>
              <div className='pl-1'>{entry.display}</div>
            </div>
          )}
          data={membersFormatted}
        />
      </MentionsInput>
    </MentionsInputWrapper>
  );
};
export default Mentions;

import { scale } from '@cloudinary/url-gen/actions/resize';
import { useCloudinary } from 'hooks';
import React from 'react';
import { OrgMember } from 'types/graphql';
import { isLogo } from 'utils/helpers';
import BuildingLogo from '../assets/icons/BuildingLogo.svg';
import { StyledImg } from '../OrganisationInvitation.styles';
import AcceptInvite from './AcceptInvitation';
import CancelInvite from './CancelInvitation';

type InvitationProps = {
  handleInvite: (event: string, orgId: string) => void;
} & OrgMember;

const OrganisationActions = (props: InvitationProps) => {
  const { organisation, role, invitedBy, handleInvite } = props;

  const [cld, token] = useCloudinary();
  const logo = `${cld.image(organisation.logo).resize(scale().width(240)).toURL()}&${token}`;

  const checkLogo = isLogo(logo);

  if (!organisation || !role) return <></>;

  return (
    <div className='p-1 pr-2 flex flex-wrap flex-row w-[940px] max-w-full gap-2 justify-between border border-primary-lightest rounded'>
      <div className='flex flex-row items-center gap-2'>
        <StyledImg src={checkLogo ? logo : BuildingLogo} alt="Organisation Image" objectfit={checkLogo ? 'cover' : 'contain'} />

        <div>
          <h4 className='text-content'>{organisation.name}</h4>
          <p className='text-grey'>
            <span className='font-medium'>{`${invitedBy?.name || invitedBy?.email} `}</span>
            has invited you as {role === 'ADMIN' ? 'an' : 'a'} {role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()}
          </p>
        </div>
      </div>

      <div className='flex items-center gap-2'>
        <AcceptInvite
          handleSubmit={() => handleInvite('ACCEPT', organisation.id)}
          org={organisation}
          info={{
            name: organisation?.name || '',
            inviter: invitedBy?.name,
          }}
        />
        <CancelInvite
          handleSubmit={() => handleInvite('DECLINE', organisation.id)}
          org={organisation}
        />
      </div>
    </div>
  );
};

export default OrganisationActions;

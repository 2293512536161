import { Tooltip as MTooltip, TooltipProps, Typography } from '@material-ui/core';
import React from 'react';

import { Card, Content, Popper } from './Tooltip.style';

export interface ITooltipProps {
  content?: React.ReactNode;
  children: React.ReactElement<any, any>;
  isVisible?: string | boolean;
  header?: React.ReactNode;
  placement?: TooltipProps['placement'];
  enterDelay?: number;
  id?: string;
  contentClassName?: string;
}

const Tooltip: React.FC<React.PropsWithChildren<ITooltipProps>> = ({
  enterDelay = 0,
  children,
  header,
  content,
  placement,
  isVisible = true,
  id,
  contentClassName,
}) => (
  <MTooltip
    id={id}
    placement={placement}
    enterDelay={enterDelay}
    PopperComponent={Popper}
    title={isVisible
      ? <Card elevation={1}>
        {header && (
          <Typography variant="subtitle1" gutterBottom={!!content}>
            {header}
          </Typography>
        )}
        {content && <Content className={contentClassName}>{content}</Content>}
      </Card> : ''}
    {...id === 'help-button' && { TransitionProps: { timeout: 0 } }}
  >
    {children}
  </MTooltip>
);

export default Tooltip;

import { useLazyQuery } from '@apollo/client';
import { Dialog } from '@material-ui/core';
import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { useHandleError, useMixpanel } from 'hooks';
import { File } from 'types/graphql';
import { getExt, isSupportedImageFile, nameSort } from 'utils/helpers';
import { GET_PROJECT_STATUS } from 'views/project/apollo';

import { TextureLoader } from 'three';
import Comments from './Comments/Comments';
import FileMeta from './FileMeta';
import FileViewer from './FileViewer';
import { Paper } from './Preview.style';
import QuickActions from './QuickActions/QuickActions';
import GET_FILE from './apollo';

interface IPreviewProps {
  fileId: string;
  commentId: string;
  onClose: () => void;
  showGallery?: boolean;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AnnotationsContext = createContext({
  isAnnotating: false as boolean,
  imageUrl: '' as string,
  setImageUrl: (() => { }) as (React.Dispatch<React.SetStateAction<string>>),
  setIsAnnotationg: (() => { }) as (React.Dispatch<React.SetStateAction<boolean>>),
});

const Preview: React.FC<IPreviewProps> = (
  { fileId, commentId, onClose, setLoading, showGallery = true },
) => {
  const { mixpanelTrack } = useMixpanel();
  const { projectId } = useParams<{ projectId: string; }>();
  const [getProjectStatus, { data: result, called }] = useLazyQuery(GET_PROJECT_STATUS, {
    variables: { id: projectId },
    fetchPolicy: 'cache-first', nextFetchPolicy: 'cache-only',
  });
  const history = useHistory();
  const location = useLocation();
  const route = location.pathname.slice(0, location.pathname.lastIndexOf('/drive/') + 7);
  const onCreation = useRouteMatch().path.includes('/create/:projectId?/drive');

  const [handleError, enqueueSnackbar] = useHandleError();
  const siblingsRef = useRef([]);
  const textureLoaderRef = useRef(new TextureLoader());

  const [imageUrl, setImageUrl] = useState('');
  const [isAnnotating, setIsAnnotationg] = useState(false);
  const [isPano, setIsPano] = useState(false);

  const [fetchSiblings, { data }] = useLazyQuery(GET_FILE, {
    initialFetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    onCompleted: ({ currentUser }) => {
      const files = currentUser?.drive?.nodeByID?.siblingFiles;
      const previewedFileId = files?.find(({ id }: { id: string; }) => id === fileId);

      if (setLoading) setLoading(false);

      if (!previewedFileId) {
        enqueueSnackbar('This asset no longer exists. Please refresh the drive.', { variant: 'error' });
        history.push(projectId ? `/project/${projectId}/drive` : '/drive');
      }
    },
    onError: (error) => {
      if (setLoading) setLoading(false);
      handleError(error);
      history.goBack();
    },
  });

  const siblings: File[] = useMemo(
    () => {
      const sibs = data?.currentUser.drive?.nodeByID?.siblingFiles;
      if (!sibs) return siblingsRef.current;
      siblingsRef.current = sibs.slice().sort((a: File, b: File) => nameSort(a.name, b.name));
      return siblingsRef.current;
    },
    [data],
  );

  const [file, nextFile, previousFile] = useMemo(
    () => {
      const i = siblings.findIndex(({ id }) => id === fileId);
      return [
        siblings[i],
        siblings[i + 1] || siblings[0],
        siblings[i - 1] || siblings[siblings.length - 1],
      ];
    },
    [siblings, fileId],
  );

  const fileType = file ? getExt(file.name) : '';

  useEffect(() => {
    if (projectId && !called) getProjectStatus();
  }, [called, getProjectStatus, projectId]);

  useEffect(
    () => {
      if (file) return;
      fetchSiblings({
        variables: {
          fileId,
        },
      });
    },
    [file, fetchSiblings, fileId],
  );

  useEffect(() => {
    (async function () {
      textureLoaderRef.current.crossOrigin = '';

      try {
        const texture = await textureLoaderRef.current.loadAsync(file?.signedUrl!);
        if (!texture) return;

        const { width, height } = texture.image;
        const _isPano = (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') && Math.abs((width / height) - 2) <= 0.01;
        setIsPano(_isPano);
      } catch { }
    })();
  }, [fileType, file]);

  const handleNextClick = showGallery && nextFile
    ? () => history.push(`${route}${nextFile.id}`)
    : undefined;

  const handlePreviousClick = showGallery && previousFile
    ? () => history.push(`${route}${previousFile.id}`)
    : undefined;

  const onClosePreview = () => {
    mixpanelTrack('Asset preview - Close');
    onClose();
  };

  if (!file) return null;

  const isImage = isSupportedImageFile(fileType) && fileType !== 'tiff' && !isPano;

  return (
    <AnnotationsContext.Provider value={{ isAnnotating, setIsAnnotationg, imageUrl, setImageUrl }}>
      <Dialog
        open
        onClose={onClose}
        PaperComponent={Paper}
        maxWidth={false}
        id='file--preview_window'
      >
        <div className='flex flex-row w-full m-2 mr-1'>
          {file && <FileViewer
            file={file}
            isPano={isPano}
            handleNextClick={handleNextClick}
            handlePreviousClick={handlePreviousClick}
          />}
          <div className='max-w-[380px] w-full flex flex-col h-full'>
            <FileMeta
              id={projectId}
              file={file}
              onClosePreview={onClosePreview}
              role={result?.project?.me?.role}
            />
            {!onCreation && (
              <>
                <QuickActions
                  imageUrl={imageUrl}
                  file={file}
                  onAnnotation={() => setIsAnnotationg(prev => !prev)}
                  isAnnotateVisible={isImage}
                />
                {projectId && <Comments fileId={fileId} commentId={commentId} />}
              </>
            )}
          </div>
        </div>
      </Dialog>
    </AnnotationsContext.Provider>
  );
};

export default Preview;

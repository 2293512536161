import { ReactComponent as LogoSVG } from 'assets/VU.CITY-Dark.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import Mixpanel from 'utils/mixpanel';

const Logo = () => (
  <Link to="/" onClick={() => Mixpanel.track('Logo Button', {})}>
    <LogoSVG className='flex object-contain w-[147px] h-4' />
  </Link>
);

export default Logo;

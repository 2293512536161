import React from 'react';
import { Project, UserTier } from 'types/graphql';
import GridView from 'views/projects/grid/Grid';
import ListView from 'views/projects/list/List';
import MapView from 'views/projects/map/Map';

import { ReactComponent as SadFace } from 'assets/icons/SadFace.svg';

interface IToggleViewProps {
  view: string;
  projects: Project[];
  filteredProjects: Project[];
  tier: UserTier;
  refetch: () => any;
  setVisiblityPreference: (preference: string) => void;
  visiblityPreference: string;
}

const ToggleView: React.FC<IToggleViewProps> = ({ view, 
  projects, 
  filteredProjects, 
  tier, 
  refetch, 
  setVisiblityPreference,
  visiblityPreference }) => {

  const renderSection = (content: string) => <>{content}</>;
  const props = { projects: filteredProjects, 
    tier, refetch, view, setVisiblityPreference, 
    visiblityPreference };

  if (!filteredProjects.length && projects.length !== filteredProjects.length && view !== 'map') {
    return (
      <div className='flex items-center gap-1 text-grey'>
        <SadFace />
        <p className='text-lg'>No results match your search</p>
      </div>
    );
  }

  switch (view) {
    case 'list':
      return <ListView {...props} />;
    case 'grid':
      return <GridView {...props} />;
    case 'map':
      return <div className='relative w-full h-[90%]'>
        <MapView {...props} />
      </div>;

    default:
      return renderSection('list');
  }
};

export default ToggleView;

import { EditIcon } from '@vucity/design_system';
import React from 'react';
import { IconButton } from '../QuickActions.style';

const Annotate = ({ onClick }: { onClick: () => void; }) => (
  <IconButton onClick={onClick} className='items-center mb-2'>
    <EditIcon />
    Annotate
  </IconButton>
);

export default Annotate;
import { useMutation } from '@apollo/client';
import { CancelProcessModal } from 'components';
import { useCreateProjectMembers, useHandleError, useMixpanel } from 'hooks';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { BackButton, Button, FullwidthLayout, Stepper } from 'tailwind';
import { ProjectInput } from 'types/graphql';
import { CREATE_PROJECT } from './apollo';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const stepsLabel = [
  { title: 'Project setup', button: 'Set location' },
  { title: 'Set location', button: 'Continue' },
  { title: 'Invite members', button: 'Create project' },
];
const stepsView = [Step1, Step2, Step3];

const CreateProject = () => {
  const history = useHistory();
  const [isDuplicateName, setIsDuplicateName] = useState(false);
  const [isDuplicateReference, setIsDuplicateReference] = useState(false);

  const { createProjectMembers } = useCreateProjectMembers();
  const { mixpanelTrack } = useMixpanel();
  const [handleError] = useHandleError();

  const { handleSubmit, formState: { errors }, register, setError, clearErrors, setValue, watch } = useForm<ProjectInput>();

  const [activeStep, setActiveStep] = useState(0);

  const isFirst = activeStep === 0;
  const isLast = activeStep === stepsLabel.length - 1;

  const StepView = stepsView[activeStep];

  const [createProject, { loading }] = useMutation(CREATE_PROJECT);

  const onCreateProject = (values: ProjectInput) => {
    const { description, name, reference, members = [], location, access = 'INVITE_ONLY', defaultRole = 'VIEWER' } = values;
    const { licenseId, coords } = location || {};
    const { x, y } = coords || {};

    const finalMembers = createProjectMembers(members);

    if (finalMembers.length > 0)
      mixpanelTrack('Invited members to a Project Successfully via ‘Add Project’ - Action', {
        'Members invited to the project': finalMembers.map(({ email }) => email).join(', '),
      });

    createProject({
      variables: {
        project: {
          name,
          description,
          reference,
          location: { licenseId, coords: { x, y } },
          members: finalMembers,
          access,
          defaultRole,
        },
      },
      onError: handleError,
      onCompleted: ({ createProject: { id } }) => {
        history.push(`/project/${id}`);
      },
    });
  };

  const isDisabled = (isFirst && !watch('name'))
    || (isFirst && Object.keys(errors).length > 0)
    || (activeStep === 1 && !watch('location'))
    || (isLast && Object.keys(errors).length > 0);

  return (
    <FullwidthLayout>
      <>
        <div className='absolute right-3 top-3'>
          <CancelProcessModal />
        </div>

        <Stepper activeStep={activeStep} setActiveStep={setActiveStep} steps={stepsLabel} />

        <div className='max-w-[1150px] w-full mx-auto'>
          <div className='flex justify-between w-full mb-1'>
            <BackButton
              onClick={() => {
                if (isFirst) return history.push('/');
                return setActiveStep(prev => prev - 1);
              }}
            />

            <Button
              variant='contained'
              disabled={isDisabled || loading}
              onClick={() => {
                if (isLast) return handleSubmit(onCreateProject)();
                return setActiveStep(prev => prev + 1);
              }}
            >
              {stepsLabel[activeStep].button}
            </Button>
          </div>

          <StepView {...{ watch, setValue, errors, register, setError, clearErrors, isDuplicateName, setIsDuplicateName, isDuplicateReference, setIsDuplicateReference }} />
        </div>
      </>
    </FullwidthLayout>
  );

};

export default CreateProject;

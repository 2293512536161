import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { SelectButton } from 'tailwind';
import { ISingleOverlay } from 'views/coverage/types';

import { ReactComponent as KmFilled } from '../../icons/1kmFilled.svg';
import { ReactComponent as KmOutlined } from '../../icons/1kmOutlined.svg';

const Tiles1km = ({ selected, disabled, tiles, onClick }: ISingleOverlay) => (
  <>
    <SelectButton
      fullWidth
      selected={selected}
      onClick={() => onClick('1km')}
      startIcon={selected || disabled ? <KmFilled /> : <KmOutlined />}
      disabled={disabled}
    >
      <span>
        <span>1km<sup>2</sup></span> tiles
      </span>
    </SelectButton>

    {selected && tiles && (
      <>
        <Source id='1km_tiles' type='vector' tiles={[tiles[0]]}>
          <Layer
            id='1km_tiles'
            source-layer='default'
            type='line'
            minzoom={8.5}
            layout={{
              visibility: selected ? 'visible' : 'none',
            }}
            paint={{
              'line-color': '#80A6B1',
              'line-width': 2.7,
              'line-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                8.5, 0.1, 13, 0.65,
              ],
            }}
          />
        </Source>

        <Source id='1Km_tiles_centroid' type='vector' tiles={[tiles[1]]}>
          <Layer
            id='1Km_tiles_centroid'
            source-layer='default'
            type='symbol'
            minzoom={11}
            layout={{
              'text-allow-overlap': true,
              'text-field': ['get', 'id'],
              'text-size': 12,
              'text-letter-spacing': 0.02,
              visibility: selected ? 'visible' : 'none',
            }}
            paint={{
              'text-color': '#004e63',
              'text-halo-color': '#e6edef',
              'text-halo-width': 10,
            }}
          />
        </Source>
      </>
    )}
  </>
);

export default Tiles1km;

import React from 'react';
import { Button } from 'tailwind';
import { BillingInterval } from 'types/graphql';

interface BillingPeriodToggleProps {
  allowTrial?: boolean;
  annualSavingsPercentage?: number;
  value: BillingInterval | 'trial';
  handleChange: (value: BillingInterval | 'trial') => void;
  getLabelByPeriod: (value: BillingInterval | 'trial') => string;
  isCompactMode?: boolean;
}

const BillingPeriodToggle = ({
  allowTrial,
  annualSavingsPercentage,
  value,
  handleChange,
  getLabelByPeriod,
  isCompactMode,
}: BillingPeriodToggleProps): JSX.Element => {
  const options = {
    ...(allowTrial ? {
      trial: {
        label: 'Free Trial',
        subtext: '7 Days',
      },
    } : {}),
    month: {
      label: getLabelByPeriod('month'),
      subtext: 'Full Price',
    },
    year: {
      label: getLabelByPeriod('year'),
      subtext: `Saving ${Number.isNaN(annualSavingsPercentage) ? 0 : (annualSavingsPercentage ?? 0).toFixed()}%`,
    },
  };

  return (
    <div className={`flex items-center bg-primary-lightest border border-primary rounded p-0.25 ${isCompactMode ? 'w-[170px]' : 'w-full'}`}>
      {Object.entries(options).map(([option, payload]) => (
        <Button
          size={isCompactMode ? 'unset' : 'lg'}
          padding='unset'
          key={option}
          role='tab'
          variant='text'
          aria-selected={value === option}
          onClick={() => handleChange(option as BillingInterval | 'trial')}
          className={`${value === option ? 'bg-primary' : 'bg-none'} w-full py-1 flex flex-col hover:no-underline`}
        >
          <div className={`capitalize text-sm font-normal ${value === option ? 'text-white' : 'text-primary'}`}>
            {payload.label}

            {!isCompactMode && (option === 'trial' || !!annualSavingsPercentage) && (
              <p className={`font-medium text-xs ${value === option ? 'text-primary-lighter' : 'text-primary'}`}>
                {payload.subtext}
              </p>
            )}
          </div>
        </Button>
      ))}
    </div>
  );
};

export default BillingPeriodToggle;

import { createTheme } from '@material-ui/core/styles';
import { palette, typography } from './utils';
import * as c from './components';

export const theme = {
  palette,
  typography,
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 1000,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiInput: {
      input: {
        '&::placeholder': {
          color: 'gray',
        },
      },
    },
    MuiButton: c.button,
    MuiAutocomplete: c.autocomplete,
    MuiIconButton: c.iconButton,
    MuiInputLabel: c.inputLabel,
    MuiInputBase: c.inputBase,
    MuiFormHelperText: c.formHelperText,
    MuiOutlinedInput: c.outlinedInput,
    MuiSvgIcon: c.svgIcon,
    MuiStep: c.step,
    MuiStepConnector: c.stepConnector,
    MuiStepLabel: c.stepLabel,
    MuiStepButton: c.stepButton,
    MuiCard: c.card,
    MuiCardActionArea: c.cardActionArea,
    MuiCardMedia: c.cardMedia,
    MuiSelect: c.select,
    MuiList: c.list,
    MuiListItem: c.listItem,
    MuiTreeItem: c.treeItem,
    MuiTreeView: c.treeView,
    MuiCheckbox: c.checkbox,
    MuiAlert: c.alert,
    MuiAlertTitle: c.alertTitle,
    MuiDialog: c.dialog,
    MuiDataGrid: c.dataGrid,
    MuiAvatar: c.avatar,
    MuiTableRow: c.tableRow,
    MuiTableCell: c.tableCell,
    MuiPopover: c.popover,
    MuiPaper: c.paper,
    MuiGrid: c.grid,
  },
};

export default createTheme(theme);

import React, { useState } from 'react';
import Loader from '../Loader';

const VideoViewer: React.FC<{ filePath: string; fileType: string }> = ({ filePath, fileType }) => {
  const [loading, setLoading] = useState(true);

  const handleOnCanPlay = () => {
    setLoading(false);
  };
  const visibility = !loading ? 'visible' : 'hidden';
  return (
    <>
      <div className='flex flex-col w-full h-full justify-center items-center'>
        {loading && <Loader />}
        <video
          onCanPlay={handleOnCanPlay}
          controls
          style={{ visibility }}
        >
          <source src={filePath} type={`video/${fileType}`} />
          Video playback is not supported by your browser.
        </video>
      </div>
    </>
  );
};

export default VideoViewer;

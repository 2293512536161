import { TextModal } from 'components';
import { useMixpanel } from 'hooks';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { BackButton } from 'tailwind';

const CancelInvite = ({ returnText = 'Return to home' }: { returnText: string; }) => {
  const { mixpanelTrack } = useMixpanel();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  return (
    <>
      <BackButton
        text={returnText}
        onClick={() => {
          setOpen(true);
          mixpanelTrack('Return to home');
        }}
      />
      <TextModal
        open={open}
        setOpen={setOpen}
        mixpanelPath="Invitations cancelled"
        closeBtnLabel="Leave"
        closeBtnHandle={() => history.push('/account/organisation/members')}
        mainBtnLabel="I'll stay"
        mainBtnHandle={() => setOpen(false)}
        headerText="Are you sure you want to leave?"
      >
        If you leave now, your invitations won't be sent.
      </TextModal>
    </>
  );
};

export default CancelInvite;

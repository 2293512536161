import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useCopyNodes } from '../../../../../hooks';
import GQLWrapper from '../../../../GQLWrapper/GQLWrapper';
import TransferContextMenu from '../../../TransferContextMenu/TransferContextMenu';
import { IFileRow } from '../../../types';
import { GET_PERSONAL_DRIVE_FOLDERS } from './apollo';

interface IUploadProps {
  projectId?: string;
  files: IFileRow[];
  closeContextMenu: () => void;
}

const ToPersonalContextMenu: React.FC<IUploadProps> = ({
  files, closeContextMenu,
}) => {
  const { loading, error, data } = useQuery(GET_PERSONAL_DRIVE_FOLDERS);
  const treeData = useMemo(() => data?.currentUser?.drive.nodeByPath, [data]);

  return (
    <GQLWrapper loading={loading} error={error} renderChildren={!!data}>
      <TransferContextMenu
        treeData={treeData}
        files={files.map((file: IFileRow) => ({ id: file.id }))}
        closeContextMenu={closeContextMenu}
        useCopyOrMoveNodes={useCopyNodes}
        refetchQuery={{ query: GET_PERSONAL_DRIVE_FOLDERS }}
        rootFolder="Personal drive"
        initialQueryLoading={loading}
      />
    </GQLWrapper>
  );
};

export default ToPersonalContextMenu;

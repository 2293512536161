import { Popper } from '@material-ui/core';
import styled from 'styled-components';

export const NotificationItemContainer = styled.div`
  margin: 8px !important;
  padding: 16px 8px !important;
  font-family: Roboto !important;
`;

export const NotificationState = styled.div<{ isRead: boolean; }>`
  ${({ theme, isRead }) => `
    height: auto;
    width: auto;
    display: flex;
    align-items: flex-start !important;
    visibility: ${isRead ? 'hidden' : 'visible'};
    color: ${theme.palette.primary.main};
  `}  
`;

export const NotificationTitle = styled.p`
  cursor: inherit;
  margin-top: 5px !important;
  font-size: 14px !important;
  word-break: break-word !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
`;

export const NotificationArticle = styled.article`
  cursor: inherit;
  margin-top: 8px !important;
  line-height: 1.5;
`;

export const OptionsMenu = styled(Popper)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: auto;
  min-width: 8em;
  min-height: 40px;
  height: auto;
  padding: 16px !important;
  margin-left: auto !important;
  box-sizing: content-box;
  border-radius: 8px;
  z-index: 9999;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px;
  text-align: left;
  background: rgb(255, 255, 255) !important;

  button {
    text-align: left;
  }
`;

export const Badge = styled.span`
  width: 12px;
  height: 18px;
  background: #4C7BAA;
  border-radius: 2px;
  padding: 2px 3px;
`;

export const jobTitles = [
  'Architect',
  'Architectural Assistant',
  'Architectural Technologist',
  'Architectural Visualiser',
  'BIM Coordinator',
  'BIM Manager',
  'Chief Executive',
  'Design Officer',
  'Designer',
  'Developer',
  'GIS Technician',
  'Head of Design',
  'Head of IT',
  'Head of Planning',
  'IT Manager',
  'Landscape Architect',
  'Planning Director',
  'Planning Director',
  'Planning Officer',
  'Police Officer',
  'Practice Manager',
  'Planner',
  'Project Manager',
  'Senior Architect',
  'Statutory Consultee',
  'Student',
  'Surveyor',
  'Urban Designer',
  'Urbanist',
];

export default jobTitles;

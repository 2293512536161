import { LocationIcon } from '@vucity/design_system';
import React, { useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { Coords, Project } from 'types/graphql';

interface IMarkersProps {
  project: Project;
  coords: Coords;
  skipPopup?: boolean;
  children?: React.ReactNode;
}

const markerInitialState = {
  center: null,
  marker: null,
};

const Markers: React.FC<React.PropsWithChildren<IMarkersProps>> = ({
  project, coords, skipPopup, children,
}) => {
  const [clickedMarker, setClickedMarker] = useState<{
    center: Coords | null,
    marker: Project | null,
  }>(markerInitialState);

  const onMarkerClick = (marker: Project) => {
    setClickedMarker({
      center: coords,
      marker,
    });
  };

  const renderMarkerColor = (clickedMarker.marker ? 'fill-primary' : 'fill-white');

  return (
    <>
      <Marker
        anchor="bottom"
        longitude={coords.x}
        latitude={coords.y}
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          if (!skipPopup)
            onMarkerClick(project);
        }}
        style={{ cursor: 'pointer' }}
      >
        <LocationIcon
          size="large"
          className={`w-5 h-5 stroke-primary ${renderMarkerColor}`}
        />
      </Marker>

      {clickedMarker.marker && !skipPopup && (
        <Popup
          key={coords.x}
          longitude={coords.x}
          latitude={coords.y}
          anchor='bottom'
          className='mapbox-custom-popup'
          style={{ zIndex: 5, paddingBottom: '45px' }}
          onClose={() => setClickedMarker(markerInitialState)}
        >
          {children}
        </Popup>
      )}
    </>
  );
};

export default Markers;

import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import copy from 'copy';
import { parseGraphqlError } from 'utils/helpers';

interface ICopyProps {
  [index: string]: string;
}

/*
  Picks up copy for HUB_CODE if one is present falling back to default if passed one
  or the error message itself if not.
*/
const useHandleError = (customCodes: object = {}): any => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleError = useCallback(
    (error: any) => {
      const errorMessages: ICopyProps = { ...copy.errors.errorMessages };
      const codes: ICopyProps = {
        ...copy.errors.codes,
        ...customCodes,
      };

      const [code] = parseGraphqlError(error.graphQLErrors);

      if (code === 'EMAIL_NOT_VERIFIED') return code;
      if (code === 'CANNOT_JOIN_ORG') return code;
      if (code === 'SEAT_UNAVAILABLE') return code;

      const message: string = codes[code] || error.default || error.message;
      const messageToShow = errorMessages[message] || message;

      enqueueSnackbar(messageToShow, { preventDuplicate: true, variant: 'error' });

      if (code === 'PROJECT_ACCESS_REMOVED' || code === 'NO_PROJECT_ACCESS') history.push('/');

      if (message === 'Failed to fetch') return message;

      return code;
    },
    [customCodes, enqueueSnackbar, history],
  );

  // Returning enqueueSnackbar here as convenience
  return [handleError, enqueueSnackbar];
};

export default useHandleError;

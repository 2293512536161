import { ClickAwayListener } from '@material-ui/core';
import { Info, InfoOutlined } from '@material-ui/icons';
import { ReactComponent as FolderIcon } from 'assets/icons/FolderIcon.svg';
import { ReactComponent as DocThumb } from 'assets/thumbnails/Doc.svg';
import { ReactComponent as ImageThumb } from 'assets/thumbnails/Image.svg';
import { ReactComponent as VideoThumb } from 'assets/thumbnails/Video.svg';
import { ReactComponent as VUThumb } from 'assets/thumbnails/VUFile.svg';
import { DriveSelectedFilesContext } from 'components/Drive/DriveProvider/DriveProvider';
import { IFileRow } from 'components/Drive/types';
import { useMixpanel } from 'hooks';
import React, { useContext, useState } from 'react';
import { extraExtensions, getExt, imgExtensions, modelExtensions } from 'utils/helpers';

interface SelectedFilesLabelProps {
  files: IFileRow[];
  erroredFiles?: string[];
  projectId?: string;
}

type ActionType = { action: 'Viewed'; 'Items selected': number; } | { action: 'Asset Selected'; 'Item Selected': string; };

const SelectedFilesLabel = ({ files, erroredFiles = [], projectId }: SelectedFilesLabelProps) => {
  const [open, setOpen] = useState(false);
  const { vtRef } = useContext(DriveSelectedFilesContext);
  const { mixpanelTrack } = useMixpanel();
  const extensionThumbs = [...modelExtensions, ...extraExtensions, 'pdf'];

  const renderIcon = (file: IFileRow) => {
    const { mimeType } = file;
    const extension = getExt(file.name.filename).toLowerCase();
    if (extensionThumbs.includes(extension)) {
      return <div
        className='bg-primary 
        flex
        items-center
        justify-center
        w-2
        h-2
        text-[10px]
        text-primary-lightest
        rounded-xs'
      >
        {extension.substring(0, 3)}
      </div>;
    }

    if (extension.startsWith('doc')) {
      return <DocThumb className='w-2 h-2' />;
    }

    if (mimeType?.startsWith('image') || imgExtensions.includes(extension)) {
      return <ImageThumb className='w-2 h-2' />;
    }

    if (mimeType?.startsWith('vucity/project') || extension === 'vu') {
      return <VUThumb className='w-2 h-2' />;
    }

    if (mimeType?.startsWith('video')) {
      return <VideoThumb className='w-2 h-2' />;
    }

    return <ImageThumb className='w-2 h-2' />;
  };
  const hasError = React.useCallback((fileId: string) => erroredFiles.findIndex((id) => id === fileId) !== -1, [erroredFiles]);

  const logToMixpanel = (action: ActionType) => {
    const driveType = !projectId ? 'Personal' : 'Project';
    mixpanelTrack(`${driveType} Drive > Asset Label > ${action.action}`, {
      ...action,
      'Label State': erroredFiles.length ? 'Error' : 'Normal',
    });
  };
  const handleItemClick = (file: IFileRow) => {
    vtRef.current.scrollToRow(file.id);
    logToMixpanel({ action: 'Asset Selected', 'Item Selected': file.name.filename });
  };

  const handleLabelClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
    logToMixpanel({
      action: 'Viewed',
      'Items selected': files.length,
    });
  };

  if (!files.length) return null;
  return (
    <div className='relative'>
      <div className={`h-3 
          text-xs 
          ${erroredFiles.length ? 'text-red-500' : 'text-primary'}
          ${erroredFiles.length ? 'bg-error-light' : ' bg-primary-lightest'}
          rounded 
          border-primary 
          flex items-center
          justify-end
          cursor-pointer
          gap-1
          p-1
          group
        `}
        aria-haspopup="true"
        onClick={handleLabelClick}
      >
        <span><b>{files.length}</b> selected</span>
        {open && <Info className="h-[13.33px] w-[13.33px]" />}
        {!open && (
          <>
            <InfoOutlined className='block group-hover:hidden h-[13.33px] w-[13.33px]' />
            <Info className='hidden group-hover:block h-[13.33px] w-[13.33px]' />
          </>
        )}
      </div>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div className={`${open ? 'flex' : 'hidden'}
          w-[20rem]
          max-h-[228px]
          overflow-y-auto
          p-2
          right-0
          rounded
          flex-col bg-white drop-shadow-lg absolute z-[999]`}
        >
          <p className="pb-1 text-sm text-content">
            <b>Asset(s) selected</b>
          </p>
          {files.map((file) => (
            <div
              className={`px-0.25
                py-[6px] 
                flex gap-0.5
                h-min[28px]
                ${hasError(file.id) ? 'bg-error-light' : 'hover:bg-primary-lightest'}
                cursor-pointer items-center border-t
                `
              }
              onClick={(e) => {
                e.stopPropagation();
                handleItemClick(file);
              }}
            >
              {!file.folder && renderIcon(file)}
              {file.folder && <FolderIcon className="w-2 h-2" />}
              <p className="w-full h-full overflow-hidden text-sm text-ellipsis whitespace-nowrap text-grey">
                {file.name.filename}
              </p>
            </div>
          ))}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default SelectedFilesLabel;

import MuiIconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

export const IconButton = styled(MuiIconButton)`
  ${({ theme }) => `
    width: auto;
    height: 24px;
    border-radius: 4px;
    border: 1px solid ${theme.palette.primary.light};
    color: #333;
    padding: 4px;
    transition: 0.1s ease-in;

    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;

    & .MuiIconButton-label {
      align-items: center;
    }

    & .MuiIconButton-label .MuiSvgIcon-root {
      height: 11px;
      width: auto;
      padding: 0 4px;
      transition: 0.1s ease-in;
    }

    &:hover {
      cursor: pointer;
      background-color: ${theme.palette.primary.main};
      color: #fff;
      & .MuiIconButton-label .MuiSvgIcon-root {
        color: #fff;
      }
    }
  `}
`;

export const Progress = styled.span`
  padding: 0 20px;
`;

import React, { useCallback } from 'react';
import reactStringReplace from 'react-string-replace';
import regexifyString from 'regexify-string';
import { useParams } from 'react-router-dom';
import startsWith from 'lodash/startsWith';
import flow from 'lodash/flow';

import { useMixpanel } from 'hooks';
import { mentionRegex, urlOrEmailRegex } from 'utils/helpers';
import { Mention } from 'components/Preview/Comments/Mentions/Mentions.style';
import { ProfileCard } from 'components';
import { UrlLink } from 'components/UI';

const useRenderComment = ({ textOnly = false } = {}) => {
  const { projectId } = useParams<{ projectId?: string }>();
  const { mixpanelTrack } = useMixpanel();

  const renderLinks = useCallback(
    (message: string) => regexifyString({
      pattern: urlOrEmailRegex,
      decorator: (match, i) => {
        let url = match.includes('@') ? `mailto:${match}` : match;
        if (!url.includes('@') && !startsWith(url, 'http')) url = `https://${url}`;

        return textOnly ? match : (
          <UrlLink
            onClick={() => mixpanelTrack('ClickCommentLink')}
            target='_blank'
            key={i}
            href={url}
          >
            {match}
          </UrlLink>
        );
      },
      input: message,
    }),
    [mixpanelTrack, textOnly],
  );

  const renderMentions = useCallback(
    (content: string) => reactStringReplace(content, mentionRegex, (match, i) => {
      const [userId, name] = match.split('}|{');

      return textOnly ? `@${name}` : (
        <ProfileCard
          key={i + userId}
          redirect
          userId={userId}
          leaveDelay={800}
          projectId={projectId}
        >
          <Mention>{`@${name}`}</Mention>
        </ProfileCard>
      );
    }),
    [projectId, textOnly],
  );

  return flow(renderLinks, renderMentions);
};

export default useRenderComment;

import { CloseIcon } from '@vucity/design_system';
import React from 'react';

interface IOrderItemProps {
  id?: string;
  title: string;
  area: number;
  tiles?: string[];
  onClick: (id: string, isTile?: boolean) => void;
  onDelete: (id: string, isTile?: boolean) => void;
}

const OrderItem = ({ id, area, tiles, title, onClick, onDelete }: IOrderItemProps) => {
  const titleStyle = 'flex items-center justify-between transition duration-300 rounded-sm group';
  const iconStyle = 'cursor-pointer text-grey w-1.2 h-1.2 mx-0.25 pr-0.25 invisible';

  return (
    <section className='flex flex-col mr-0.25'>
      <article className={`${titleStyle} ${id ? 'hover:bg-primary-lightest' : 'cursor-default'}`}>
        <button className='flex justify-between w-full text-sm p-0.5' onClick={() => id && onClick(id)}>
          <span className='font-medium'>{title}</span>
          <span>{area} km<sup>2</sup></span>
        </button>

        <CloseIcon
          onClick={() => id && onDelete(id)}
          className={`${iconStyle} ${id ? 'group-hover:visible' : ''}`}
        />
      </article>

      <hr className='border-b border-b-grey-light my-0.25' />

      {tiles && tiles.map((tile: string) => (
        <article key={tile} className={`${titleStyle} ${!id ? 'hover:bg-primary-lightest' : 'cursor-default'}`}>
          <button
            onClick={() => onClick(tile, true)}
            className='p-0.5 flex w-full justify-between text-sm'
          >
            <span>{tile}</span>
            {!id && <span>0.06 km<sup>2</sup></span>}
          </button>

          <CloseIcon
            onClick={() => onDelete(tile, true)}
            className={`${iconStyle} ${!id ? 'group-hover:visible' : ''}`}
          />
        </article>
      ))}
    </section>
  );
};

export default OrderItem;

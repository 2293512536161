import { KeyIcon, LockIcon, UpgradeIcon } from '@vucity/design_system';
import { ReactComponent as FileIcon } from 'assets/thumbnails/Doc.svg';
import React from 'react';
import { Label } from 'tailwind';
import { ProjectAccess } from 'types/graphql';

const ToggleStatus = ({ status, access, isSameOrg }: { status?: string; access?: ProjectAccess; isSameOrg?: boolean; }) => {
  const isAvailableToAll = access === 'AVAILABLE_TO_ALL' && isSameOrg;
  const isAvailableToOrgAdmins = status === 'AVAILABLE_TO_ORG_ADMINS' && isSameOrg;

  const renderAccess = () => {
    const variant = status === 'LOCKED' || status === 'LIMITED' ? 'inactive' : 'primary';

    if (isAvailableToAll && status !== 'ACCEPTED') return (
      <Label variant={variant} icon={<FileIcon />}>
        Available to all
      </Label>
    );
    if (isAvailableToOrgAdmins) return (
      <Label variant={variant} icon={<FileIcon />}>
        Available to org admins
      </Label>
    );
  };

  return (
    <div className='absolute flex flex-wrap gap-1 top-2 left-2'>
      {renderAccess()}

      {(() => {
        switch (status) {
          case 'INVITED': return !isAvailableToAll
            ? <Label variant='inactive' icon={<KeyIcon />}>Invite Pending</Label>
            : null;
          case 'LOCKED': return (
            <>
              <Label variant='inactive' icon={<LockIcon />}>Locked</Label>
              <Label icon={<UpgradeIcon />}>Upgrade</Label>
            </>
          );
          case 'LIMITED': return (
            <>
              <Label variant='inactive' icon={<LockIcon />}>Limited</Label>
              <Label icon={<UpgradeIcon />}>Upgrade</Label>
            </>
          );
          default: return null;
        }
      })()}
    </div>
  );

};

export default ToggleStatus;

import React, { useMemo } from 'react';

import { File } from 'types/graphql';

import { useCheckPermissions, useCurrentUser, useMixpanel } from 'hooks';
import { useParams } from 'react-router-dom';
import Annotate from './Annotate';
import Download from './Download/Download';
import ShowInDrive from './ShowInDrive/ShowInDrive';

interface QuickActionsTypes {
  file?: File;
  imageUrl: string;
  isAnnotateVisible: boolean;
  onAnnotation: () => void;
}

const QuickActions = ({ file, imageUrl, isAnnotateVisible, onAnnotation }: QuickActionsTypes) => {
  const { currentUser } = useCurrentUser();
  const { mixpanelTrack } = useMixpanel();
  const { projectId, fileId } = useParams<{ projectId?: string; fileId?: string; }>();
  const [hasPermission] = useCheckPermissions(['PROJECT_ASSET_DOWNLOAD']);

  const isStarter = useMemo(() => currentUser?.tier?.name === 'Starter', [currentUser]);

  const canDownload = projectId ? hasPermission && projectId : true;
  const canAnnotate = projectId ? (hasPermission && !isStarter) : !isStarter;

  if (!file) return null;

  const onAnnotate = () => {
    onAnnotation();
    mixpanelTrack('File annotations / Annotate', { fileId });
  };

  return (
    <div className='pl-3 pr-1'>
      <div className='flex justify-between'>
        <div className='flex items-center gap-1'>
          {canDownload ? (
            <Download file={{ signedUrl: imageUrl || file?.signedUrl, name: file.name, mimeType: file.mimeType }} />
          ) : <span />}

          {canAnnotate && isAnnotateVisible && <Annotate onClick={onAnnotate} />}
        </div>

        {!fileId && <ShowInDrive fileId={file.id} />}

      </div>
      {(canDownload || !fileId) && <hr className='mb-2' />}
    </div>
  );
};

export default QuickActions;

import React from 'react';
import { capitalizeWord } from 'utils/helpers';

interface Subs {
  type: string;
  total?: number;
  assigned: string;
}

interface ISubsTableProps {
  firstColumnTitle: string | React.ReactNode;
  subs: Subs[];
}

const SubscriptionsTable = ({ firstColumnTitle, subs }: ISubsTableProps) => (
  <section className='flex flex-col px-2 py-1 mt-1 border rounded-sm border-primary-lightest'>
    <article className='flex justify-between pb-1 text-sm text-grey'>
      <span className='flex items-start w-1/3 gap-1.2'>{firstColumnTitle}</span>
      <span className='w-1/3 text-right'>Total</span>
      <span className='w-1/3 text-right'>Used</span>
    </article>

    <article className='flex flex-col gap-1'>
      {subs.map((sub, index) => (
        <div className='flex justify-between pt-1 border-t border-t-primary-lightest' key={index}>
          <span className='w-1/3'>{capitalizeWord(sub.type)}</span>
          <span className='w-1/3 text-right'>{sub.total}</span>
          <span className='w-1/3 text-right'>{sub.assigned}</span>
        </div>
      ))}
    </article>
  </section>
);

export default SubscriptionsTable;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { bytesToSize, capitalizeWord } from '../../../../utils/helpers';
import TextModal from '../../TextModal/TextModal';

interface IStorageLimitProps {
  projectId?: string;
  mainBtn?: string;
  spaceNeeded: number;
  closeModal: () => void;
  closeBtnHandle?: () => void;
}

const StorageLimit: React.FC<IStorageLimitProps> = ({
  projectId, spaceNeeded, mainBtn, closeModal, closeBtnHandle,
}) => {
  const history = useHistory();

  const url = projectId ? `/project/${projectId}/drive` : '/drive';
  const type = projectId ? 'project' : 'personal';

  const closeBtn = mainBtn
    ? { label: `Go to ${type} drive`, handle: () => (closeBtnHandle ? closeBtnHandle() : history.push(url)) }
    : { label: 'Review selection', handle: () => closeModal() };

  return (
    <TextModal
      headerText={`${capitalizeWord(type)} drive storage limit reached`}
      open={true}
      setOpen={closeModal}
      mainBtnLabel={mainBtn}
      mainBtnHandle={closeModal}
      closeBtnLabel={closeBtn.label}
      closeBtnHandle={closeBtn.handle}
      mixpanelPath='Storage limit reached'
      component='div'
    >
      You do not have enough storage available in your {type} drive.
      <br />
      Please clear {bytesToSize(spaceNeeded)} from your {type} drive.
    </TextModal>
  );
};

export default StorageLimit;

import { StripeSubscription } from 'types/graphql';

export const fakeMonthlySubscription: StripeSubscription = {
  __typename: 'StripeSubscription',
  created: 0,
  id: '',
  current_period_end: 0,
  start_date: 0,
  seats: [],
  status: 'unpaid',
  interval: 'month',
  cityLicenses: [],
  siteLicenses: [],
  stats: {
    __typename: 'SeatStats',
    floating: {
      __typename: 'TierStats',
      assigned: 0,
      assignedAnnually: 0,
      assignedMonthly: 0,
      available: 0,
      availableAnnually: 0,
      availableMonthly: 0,
      total: 0,
      totalAnnual: 0,
      totalMonthly: 0,
    },
    plus: {
      __typename: 'TierStats',
      assigned: 0,
      total: 0,
      assignedAnnually: 0,
      assignedMonthly: 0,
      available: 0,
      availableAnnually: 0,
      availableMonthly: 0,
      totalAnnual: 0,
      totalMonthly: 0,
    },
    pro: {
      __typename: 'TierStats',
      assigned: 0,
      total: 0,
      assignedAnnually: 0,
      assignedMonthly: 0,
      available: 0,
      availableAnnually: 0,
      availableMonthly: 0,
      totalAnnual: 0,
      totalMonthly: 0,
    },
  },
};

import { gql } from '@apollo/client';

const GET_PERMISSIONS = gql`
  query GetPermissions($projectId: ID!) {
    project(id: $projectId) {
      id
      me {
        id
        permissions
      }
    }
  }
`;

export default GET_PERMISSIONS;

import { Column, IntegratedSelection, SelectionState } from '@devexpress/dx-react-grid';
import { TableColumnResizing, TableColumnVisibility, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { MenuItem } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useMixpanel } from 'hooks';
import { File, Folder, ProjectMemberRole, User, UserPermissions } from 'types/graphql';
import { nameSort, recurseSearch } from 'utils/helpers';
import { IDriveFilesData } from 'views/types';

import { DriveGrid, IfPermissions, LoadingBar } from 'components';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import { DriveBackdrop, MoveToContextMenu, RenderAction, ToPersonalContextMenu } from 'components/Drive';
import { Copy, CreateProject, Delete, Download, MoveTo, OpenApp, Paste, Preview, Rename, UpdateAccess, UploadToPersonal } from '../DriveContextMenu/DriveActions';
import { DriveSelectedFilesContext } from '../DriveProvider/DriveProvider';
import { IFileRow, IRefetchQuery } from '../types';

interface IDriveFiles {
  data: IDriveFilesData;
  project: { id: string; location: string; isPublic: boolean; };
  onFolderClick: (folder: Folder) => void;
  currentFolder: Folder;
  driveType: 'personal' | 'project';
  user?: User;
  refetchQueryAfterUpdate: IRefetchQuery;
  useSearch: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
  isUpdated: boolean,
  setIsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  onTransfer?: (files: IFileRow[]) => void;
  onCreation?: { location: string; hasMultipleLocations: string[]; };
  role?: ProjectMemberRole;
}

const initialMouse = {
  mouseX: null,
  mouseY: null,
};

const columns: Column[] = [
  { name: 'name', title: 'Name' },
  { name: 'creator', title: 'Creator' },
  { name: 'location', title: 'Location' },
  { name: 'size', title: 'Size' },
  { name: 'createdAt', title: 'Created on' },
  { name: 'type', title: 'Type' },
];

const defaultColumnWidths = [
  { columnName: 'name', width: 300 },
  { columnName: 'creator', width: 180 },
  { columnName: 'location', width: 150 },
  { columnName: 'size', width: 130 },
  { columnName: 'type', width: 150 },
  { columnName: 'createdAt', width: 180 },
];

const DriveFiles: React.FC<IDriveFiles> = ({
  project, data, onFolderClick, currentFolder, refetchQueryAfterUpdate,
  driveType, useSearch, isUpdated, setIsUpdated, onTransfer, onCreation, user,
  role,
}) => {
  const { hasMultipleLocations, location } = onCreation || {};
  const { selectedFiles, setSelectedFiles } = useContext(DriveSelectedFilesContext);

  const { folderId } = useParams<{ folderId?: string; }>();
  const history = useHistory();
  const { pathname } = useLocation();

  const { mixpanelTrack } = useMixpanel();

  const [downloadingInProgress, setDownloadingInProgress] = useState(false);
  const [selection, setSelection] = useState<(string | number)[]>([]);
  const [copiedFiles, setCopiedFiles] = useState<IFileRow[]>([]);
  const [searched, setSearched] = useSearch;

  const [loading, setLoading] = useState<boolean>(false);
  const [isTransferring, setIsTransferring] = useState({
    selected: false,
    toPersonal: false,
  });
  const [mousePos, setMousePos] = useState<{
    mouseX: number | null;
    mouseY: number | null;
  }>(initialMouse);

  useEffect(() => {
    if (selectedFiles) {
      setSelection(selectedFiles.map(({ id }) => id));
    }
  }, [selectedFiles]);

  const setLoadingCallback = useCallback((state: React.SetStateAction<boolean>) => {
    setLoading(state);
  }, []);

  const naturalSort = (array: Folder[] | File[]) => array
    .sort((a: Folder | File, b: Folder | File) => nameSort(a.name, b.name));

  const customNameSort = (
    a: { filename: string; }, b: { filename: string; },
  ) => nameSort(a.filename, b.filename);

  const allFiles = [...naturalSort(data.folders), ...naturalSort(data.files)];

  const isRowError = (file: any) => {
    const isFolder = !!file?.files;
    const hasMismatchedVu = isFolder ? recurseSearch(file).files : [];

    if (location) {
      if (!file.metadata?.licenseName && !isFolder) return false;

      return isFolder
        ? hasMismatchedVu.filter(({ metadata }) => metadata?.licenseName !== location).length > 0
        : (location !== file.metadata?.licenseName) && file.mimeType === 'vucity/project';
    }

    return !!hasMultipleLocations?.find((id) => id === file.id);
  };

  const isRowDisabled = (file: any) => isRowError(file)
    && location
    && !selection.find((id) => id === file.id);

  const rows: IFileRow[] = allFiles.map((file: (any)) => ({
    ...file,
    name: {
      filename: file?.name,
      locked: file?.locked,
      thumbnail: file,
    },
    creator: file?.creator?.isUserRemoved ? 'Deleted User' : file?.creator?.name,
    createdAt: file?.createdAt && new Date(file?.createdAt),
    location: file?.metadata?.licenseName || '-',
    type: file?.mimeType,
    folder: !!file?.files,
    signedUrl: file?.signedUrl ?? null,
    files: file.files ?? [],
    whitelist: file?.whitelist,
    ...(onCreation && {
      isError: isRowError(file),
      isDisabled: isRowDisabled(file),
    }),
    creatorId: file?.creator?.id,
  } as IFileRow));

  const resetFilesAfterUpdate = useCallback(() => {
    setSelectedFiles([]);
    setSelection([]);
  }, [setSelectedFiles, setSelection]);

  const onSelectRows = (selectedRows: (string | number)[]) => {
    setSelection(selectedRows);
    setSelectedFiles(rows.filter(({ id }) => selectedRows.some((row) => id === row)));
  };

  const onShiftSelect = (lastId: string, sortedRows: IFileRow[]) => {
    const start = sortedRows.findIndex(row => row.id === selection[selection.length - 1]);
    const end = sortedRows.findIndex(row => row.id === lastId);

    const startIndex = start < end ? start : end;
    const endIndex = start > end ? start : end;
    const inBetween = sortedRows.slice(startIndex, endIndex + 1).map(row => row.id);

    const newSelection = selection.concat(inBetween);
    const uniqueSelection = Array.from(new Set([...newSelection]));

    onSelectRows(uniqueSelection);
  };

  const handleMouseClick = (event: React.MouseEvent<HTMLElement>, row: IFileRow) => {
    event.preventDefault();
    mixpanelTrack('Clicked on file', { afterSearch: searched });
    setSearched(false);
    setMousePos({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    const isSelected = selection.filter((element) => element === row.id);
    if (isSelected.length === 0) onSelectRows([row.id]);
  };

  const handleClose = useCallback(() => {
    if (mousePos.mouseX) {
      setIsTransferring({ selected: false, toPersonal: false });
      setMousePos(initialMouse);
      resetFilesAfterUpdate();
    }
  }, [mousePos, resetFilesAfterUpdate]);

  const handleFolderClick = (row: any) => {
    mixpanelTrack('Clicked on folder', { afterSearch: searched });
    setSearched(false);
    const selectedFolder = data.folders.filter((folder: Folder) => folder.id === row.id)[0];
    if (row.folder) onFolderClick(selectedFolder);
  };

  const TableRow: React.FC<any> = ({ row, ...restProps }) => (
    <VirtualTable.Row
      className={`
        cursor_pointer
        ${row.isError && 'row--error'}
        ${row.isDisabled && 'row--disabled'}
        `}
      role="row"
      onClick={() => {
        if (!onCreation) handleClose();

        if (loading) return;

        if (row.folder) {
          handleFolderClick(row);
        } else if (!folderId) {
          setLoading(true);
          mixpanelTrack('Asset preview - Left click');
          history.push(`${pathname}/${row.id}`);
        }
      }}
      onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        // prevent root table element from receiving onContextMenu event when row is clicked
        e.stopPropagation();
        if (!onTransfer) handleMouseClick(e, row);
      }}
      {...restProps}
    />
  );

  const TableRoot = ({ children, ...props }: any) => (
    <VirtualTable.Table
      onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (!onTransfer || !onCreation) handleMouseClick(e, currentFolder as unknown as IFileRow);
      }}
      className='h-full'
      {...props}>
      {children}

      <DriveBackdrop
        handleMouseClick={(e: any) => {
          e.preventDefault();
          handleMouseClick(e, currentFolder as unknown as IFileRow);
        }}
      />
    </VirtualTable.Table>
  );

  const projectId = driveType === 'project' ? project.id : undefined;

  const contextMenuProps = {
    projectId,
    isUpdated,
    setIsUpdated,
    files: selectedFiles,
    closeContextMenu: handleClose,
    resetFiles: resetFilesAfterUpdate,
    copiedFiles,
    setCopiedFiles,
    currentFolder,
    refetchQueryAfterUpdate,
    setDownloadingInProgress,
    downloadingInProgress,
  };

  const PERMISSIONS: UserPermissions[] = ['PROJECT_ASSET_CREATE', 'PROJECT_ASSET_DOWNLOAD', 'PROJECT_ASSET_EDIT', 'PROJECT_ASSET_DELETE', 'PROJECT_3D_LAUNCH'];

  return (
    <>
      {loading && <LoadingBar />}
      <DriveGrid
        rows={rows}
        columns={columns}
        rowComponent={TableRow}
        tableComponent={TableRoot}
        noDataRowComponent={() => <></>}
        customNameSort={customNameSort}
        getRowId={(row: IFileRow) => row.id}
        isVirtualised={true}
        virtualHeight='calc(100vh - 253px)'
        canBeSelected
        selectAllDisabled={!!onCreation && rows.filter(({ isDisabled }) => isDisabled).length > 0}
        projectId={projectId}
        setIsUpdated={setIsUpdated}
        driveType={driveType}
        role={role}
        onShiftSelect={onShiftSelect}
      >
        <SelectionState selection={selection} onSelectionChange={onSelectRows} />
        <IntegratedSelection />

        <TableColumnResizing minColumnWidth={70} defaultColumnWidths={defaultColumnWidths} />

        <TableColumnVisibility defaultHiddenColumnNames={projectId ? ['location'] : []} />
      </DriveGrid>

      {!folderId && <Preview setLoading={setLoadingCallback} />}

      {!onTransfer && !onCreation
        && (selectedFiles.length > 0 || copiedFiles.length > 0)
        && mousePos.mouseX
        && !isTransferring.selected
        && (
          <ContextMenu mousePosition={mousePos} onClose={handleClose} >
            {downloadingInProgress && <LoadingBar />}
            <IfPermissions scopes={PERMISSIONS} skipCheck={!projectId}>
              {selectedFiles.length < 1
                ? <Paste key='Paste' {...contextMenuProps} />
                : [
                  <CreateProject key='Create' {...contextMenuProps} />,
                  <RenderAction key="Launch" condition={
                    driveType === 'project'
                    && selectedFiles.length === 1
                  }
                  >
                    {user && <OpenApp project={project} {...contextMenuProps} />}
                  </RenderAction>,

                  <RenderAction key="Preview" condition={
                    !selectedFiles[0]?.folder
                    && selectedFiles.length === 1
                    && selectedFiles[0]?.type !== 'vucity/project'}
                  >
                    <MenuItem onClick={() => {
                      mixpanelTrack('Asset preview - Context menu');
                      history.push(`${pathname}/${selectedFiles[0].id}`);
                    }}>
                      Preview
                    </MenuItem>
                  </RenderAction>,

                  <Download key='Download' {...contextMenuProps} />,

                  <RenderAction key='UpdateAccess' condition={
                    role !== 'VIEWER' && selectedFiles.filter((file) => !file.folder).length > 0 && !!projectId
                  }>
                    <UpdateAccess
                      role={role!}
                      selectedFiles={selectedFiles}
                      {...contextMenuProps}
                    />
                  </RenderAction>,

                  <UploadToPersonal key="UploadToPersonal" projectId={projectId} setIsTransferring={setIsTransferring} />,

                  <RenderAction key="Rename" condition={selectedFiles.length === 1}>
                    <Rename {...contextMenuProps} />
                  </RenderAction>,

                  <Copy key='Copy' {...contextMenuProps} />,
                  <Paste key='Paste' {...contextMenuProps} />,
                  <MoveTo key='Move' setIsTransferring={setIsTransferring} />,
                  <Delete key='Delete' {...contextMenuProps} />,
                ]
              }
            </IfPermissions>
          </ContextMenu>
        )
      }

      {isTransferring.selected && (
        <ContextMenu mousePosition={mousePos} onClose={handleClose}>
          {isTransferring.toPersonal ? (
            <ToPersonalContextMenu
              projectId={projectId}
              files={selectedFiles}
              closeContextMenu={handleClose}
            />
          ) : (
            <MoveToContextMenu
              projectId={projectId}
              files={selectedFiles}
              closeContextMenu={handleClose}
              setIsUpdated={setIsUpdated}
            />
          )}
        </ContextMenu>
      )}
    </>
  );
};

export default DriveFiles;

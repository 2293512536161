import { MenuItem } from '@material-ui/core';
import { useMixpanel, useSignedUrl } from 'hooks';
import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import LoadingBar from '../../../../Loading/LoadingBar';
import { IFileRow } from '../../../types';
import { downloadDrive } from 'utils/drive';

interface IDownloadProps {
  files: IFileRow[];
  closeContextMenu: () => void;
  setDownloadingInProgress: (inProgress: boolean) => void;
  downloadingInProgress: boolean;
}

const Download: React.FC<IDownloadProps> = ({ files, closeContextMenu }) => {
  const client = useApolloClient();
  const { mixpanelTrack } = useMixpanel();
  const { getSignedUrl } = useSignedUrl(closeContextMenu, closeContextMenu);
  const [downloadingInProgress, setDownloadingInProgress] = useState(false);

  const isSingleFile = files.length === 1 && !files[0].folder;

  const onDownload = async () => {
    await downloadDrive({ files, isSingleFile, setDownloadingInProgress, client, mixpanelTrack, getSignedUrl });
  };

  return (
    <>
      {downloadingInProgress && <LoadingBar />}
      <MenuItem onClick={onDownload} disabled={downloadingInProgress}>
        {isSingleFile ? 'Download' : 'Download as .zip'}
      </MenuItem>

      <hr className='my-1 border-grey-border' />
    </>
  );
};

export default Download;

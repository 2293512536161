import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import { Constants } from '../utils/constants';
import { createSupplementaryPointsForCircle } from '../utils/createSupplementaryPointsForCircle';
import { constrainFeatureMovement } from '../utils/moveFeatures/featureMovement';
import { moveFeatures } from '../utils/moveFeatures/moveFeatures';
import { createSupplementaryPoints } from '../utils/supplementaryPoints/createSupplementaryPoints';

const DirectMode = MapboxDraw.modes.direct_select;


DirectMode.dragFeature = function (state, e, delta) {
  moveFeatures(this.getSelected(), delta);
  this.getSelected()
    .filter(feature => feature.properties.isCircle)
    .map(circle => circle.properties.center)
    .forEach(center => {
      center[0] += delta.lng;
      center[1] += delta.lat;
    });
  state.dragMoveLocation = e.lngLat;
};

DirectMode.dragVertex = function (state, e, delta) {
  if (state.feature.properties.isCircle) {
    const center = state.feature.properties.center;
    const movedVertex = [e.lngLat.lng, e.lngLat.lat];
    const radius = turf.distance(turf.point(center), turf.point(movedVertex), { units: 'kilometers' });
    const circleFeature = turf.circle(center, radius);
    state.feature.incomingCoords(circleFeature.geometry.coordinates);
    state.feature.properties.circleRadius = radius;
  } else {
    const selectedCoords = state.selectedCoordPaths.map(coord_path => state.feature.getCoordinate(coord_path));
    if (state.feature.properties.square) {
      switch (state.selectedCoordPaths[0]) {
        case '0.0': {
          state.feature.updateCoordinate('0.1', state.feature.getCoordinate('0.1')[0], selectedCoords[0][1]);
          state.feature.updateCoordinate('0.3', selectedCoords[0][0], state.feature.getCoordinate('0.3')[1]);
          break;
        }
        case '0.1': {
          state.feature.updateCoordinate('0.0', state.feature.getCoordinate('0.0')[0], selectedCoords[0][1]);
          state.feature.updateCoordinate('0.2', selectedCoords[0][0], state.feature.getCoordinate('0.2')[1]);
          break;
        }
        case '0.2': {
          state.feature.updateCoordinate('0.3', state.feature.getCoordinate('0.3')[0], selectedCoords[0][1]);
          state.feature.updateCoordinate('0.1', selectedCoords[0][0], state.feature.getCoordinate('0.1')[1]);
          break;
        }
        case '0.3': {
          state.feature.updateCoordinate('0.2', state.feature.getCoordinate('0.2')[0], selectedCoords[0][1]);
          state.feature.updateCoordinate('0.0', selectedCoords[0][0], state.feature.getCoordinate('0.0')[1]);
          break;
        }
        default: break;
      }
    }
    const selectedCoordPoints = selectedCoords.map(coords => ({
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.POINT,
        coordinates: coords,
      },
    }));

    const constrainedDelta = constrainFeatureMovement(selectedCoordPoints, delta);
    for (let i = 0; i < selectedCoords.length; i++) {
      const coord = selectedCoords[i];
      state.feature.updateCoordinate(state.selectedCoordPaths[i], coord[0] + constrainedDelta.lng, coord[1] + constrainedDelta.lat);
    }
  }
};

DirectMode.toDisplayFeatures = function (state, geojson, push) {
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = Constants.activeStates.ACTIVE;
    push(geojson);
    const supplementaryPoints = geojson.properties.user_isCircle ? createSupplementaryPointsForCircle(geojson)
      : createSupplementaryPoints(geojson, {
        map: this.map,
        midpoints: true,
        selectedPaths: state.selectedCoordPaths,
      });
    supplementaryPoints.forEach(push);
  } else {
    geojson.properties.active = Constants.activeStates.INACTIVE;
    push(geojson);
  }
  this.fireActionable(state);

};
export default DirectMode;
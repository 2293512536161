import { AddIcon } from '@vucity/design_system';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TextModal } from 'components/Modal';
import Tooltip from 'components/Tooltip/Tooltip';
import { useIsVerified, useMixpanel } from 'hooks';
import { Button } from 'tailwind';
import { UserTier } from 'types/graphql';

interface AddProjectBtnProps {
  tier: UserTier;
  compactMode?: boolean;
}

const AddProjectBtn = ({ tier, compactMode = false }: AddProjectBtnProps) => {
  const [isVerified, showVerifyModal] = useIsVerified();
  const [open, setOpen] = useState(false);

  const isDisabled: number | boolean | undefined = tier && tier.slots > 0 && tier?.slotsUsed && typeof tier?.slotsUsed === 'number' && tier?.slotsUsed >= tier.slots;

  const history = useHistory();
  const { mixpanelTrack } = useMixpanel();

  const clickHandle = () => {
    if (!isVerified) return showVerifyModal(true);
    if (isDisabled) {
      mixpanelTrack('Add Project', { status: 'disabled' });
      return setOpen(true);
    }
    mixpanelTrack('Add Project', { status: 'active' });
    return history.push('/projects/create');
  };

  return (
    <>
      {!compactMode && (
        <Button
          size='unset'
          fullWidth
          className='create-project h-full text-lg max-h-[220px] min-h-[200px] flex-col gap-2'
          fakeDisabled={isDisabled}
          startIcon={<AddIcon />}
          onClick={clickHandle}
        >
          Create project
        </Button>
      )}
      {compactMode && (
        <Tooltip
          content="Create project"
          placement="bottom-start"
          enterDelay={500}
        >
          <Button
            size='unset'
            padding='unset'
            onClick={clickHandle}
            className='create-project h-5 py-1 px-[14px] min-[100px]:rounded-sm [&>svg]:h-1'
            fakeDisabled={isDisabled}
          >
            <AddIcon />
          </Button>
        </Tooltip>
      )}
      <TextModal
        open={open}
        setOpen={setOpen}
        mixpanelPath="No spaces available"
        closeBtnLabel="Not now"
        mainBtnLabel="See organisation admins"
        onClose={() => mixpanelTrack('Close on "No spaces available"')}
        mainBtnHandle={() => {
          mixpanelTrack('See organisation admins on "No spaces available"');
          history.push({
            pathname: '/account/organisation/members',
            state: { fromDashboard: true },
          });
        }}
        headerText="Looks like you've no spaces available"
      >
        You can unlock unlimited project spaces by upgrading your account's
        subscription. Contact an organisation admin to upgrade your account.
      </TextModal>
    </>
  );
};

export default AddProjectBtn;

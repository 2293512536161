import { useQuery, gql, TypedDocumentNode } from '@apollo/client';
import { User } from 'types/graphql';

const GET_ACTIVE_SUBSCRIPTIONS: TypedDocumentNode<{ currentUser: User }> = gql`
  query Subscriptions {
    currentUser {
      organisation {
        subscriptions(status: active) {
          id
          interval
          status
          stats {
            plus {
              total
            }
            pro {
              total
            }
          }
          cityLicenses {
            id
            status
            license {
              name
              id
              location {
                id
                name
                displayName
              }
              stripeProduct {
                id
                name
                prices{
                  id
                  currency
                  recurring {
                    interval
                  }
                  unit_amount
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetActiveSubscriptions = () => {
  const { data } = useQuery(GET_ACTIVE_SUBSCRIPTIONS);

  return data?.currentUser.organisation.subscriptions;
};

import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider as Provider,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import {
  getMainDefinition,
  relayStylePagination,
} from '@apollo/client/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';
import LoadingScreen from 'components/Loading/LoadingScreen/LoadingScreen';
import { InvalidFiles } from 'components/Modal';
import { createClient } from 'graphql-ws';
import { useHandleError } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parseGraphqlError } from 'utils/helpers';
import VerifyEmailModal from './VerifyEmailModal';

export const VerifyEmailContext = React.createContext(
  (() => null) as React.Dispatch<React.SetStateAction<boolean>>,
);

const DOMAIN = (() => {
  if (!import.meta.env.VITE_GRAPHQL_URI) return '';
  return import.meta.env.VITE_GRAPHQL_URI.split('https://')[1];
})();

const ApolloProvider = ({ children }: { children: JSX.Element; }) => {
  const [handleError] = useHandleError();
  const location = useLocation();
  const {
    isLoading,
    user,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
  } = useAuth0();
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [error, setError] = useState<{
    code: string;
    files: { [key: string]: any; } | undefined;
  }>({ code: '', files: {} });

  useEffect(() => {
    localStorage.setItem(location.pathname, JSON.stringify(0));
  }, [location]);

  const apolloClient = useMemo(() => {
    const httpLink: any = createUploadLink({
      uri: `${import.meta.env.VITE_GRAPHQL_URI}/graphql`,
    });
    const errorLink = onError((err) => {
      const { graphQLErrors, networkError, operation } = err;
      Sentry.withScope((scope) => {
        const { headers } = operation.getContext();
        scope.setTag('kind', 'apollo-client');
        scope.setTag('transaction_id', headers['x-transaction-id']);

        if (graphQLErrors) {
          const [code] = parseGraphqlError(graphQLErrors);
          scope.setExtra('graphql_errors', graphQLErrors);
          scope.setExtra('hub_code', code);

          if (code === 'DOWNLOAD_INCLUDES_VU_FILES') { setError({ code, files: graphQLErrors[0].extensions }); }

          const count = Number(localStorage.getItem(location.pathname) as string);
          if (code === 'EMAIL_NOT_VERIFIED' && count === 0) {
            localStorage.setItem(location.pathname, JSON.stringify(count + 1));
            setShowVerifyEmail(true);
          }
          if (code === 'PROJECT_ACCESS_REMOVED' || code === 'NO_PROJECT_ACCESS') handleError(err);

          if (graphQLErrors.some((e) => e?.extensions?.code === 'UNAUTHENTICATED')) {
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
          }

          graphQLErrors.map((e) => Sentry.captureException(e.message, {
            extra: { error: e },
          }));
        }

        if (networkError) {
          // this will group all network errors under a single event
          // instead of creating separate issues for similar events
          scope.setFingerprint(['network-error']);
          Sentry.captureException(networkError);
        }
      });
    });

    // Pass the auth0 id token as authorization header so BFF can verify
    const authLink = setContext(async () => {
      if (!user) return {};
      let token = '';
      try {
        token = await getAccessTokenSilently({
          authorizationParams: {
            audience: import.meta.env.VITE_GRAPHQL_URI,
          },
        });
      } catch (err) {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }

      return {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-transaction-id': Math.random().toString(36).substr(2, 9),
        },
      };
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: `wss://${DOMAIN}/graphql`,
        lazy: true,
        connectionParams: async () => {
          let token;
          try {
            token = await getAccessTokenSilently({
              authorizationParams: {
                audience: import.meta.env.VITE_GRAPHQL_URI,
              },
            });
            // eslint-disable-next-line no-empty
          } catch (err) { }
          return { authorization: `Bearer ${token}` };
        },
      }),
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition'
          && definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );

    return new ApolloClient({
      link: ApolloLink.from([
        authLink,
        new SentryLink({
          attachBreadcrumbs: {
            includeQuery: true,
            includeVariables: true,
            includeError: true,
          },
        }),
        errorLink,
        splitLink,
      ]),
      cache: new InMemoryCache({
        typePolicies: {
          Project: {
            fields: {
              eventsPaged: relayStylePagination(),
            },
          },
          File: {
            fields: {
              eventsPaged: relayStylePagination(),
            },
          },
        },
      }),
      connectToDevTools: true,
    });
  }, [user, getAccessTokenSilently, logout]);

  if (!isLoading && !user && !window.location.search.includes('invite')) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname + window.location?.hash + window.location.search,
      },
    });
    return null;
  }
  return (
    <Provider client={apolloClient}>
      <VerifyEmailContext.Provider value={setShowVerifyEmail}>
        {showVerifyEmail && <VerifyEmailModal setOpen={setShowVerifyEmail} />}
        {isLoading ? <LoadingScreen /> : children}
        {error.code === 'DOWNLOAD_INCLUDES_VU_FILES' && (
          <InvalidFiles
            files={error.files?.vuFiles || []}
            title="Cannot download .VU files"
            message="This is a VU.CITY .VU file and cannot be used outside of VU.CITY."
            closeModal={() => setError({ code: '', files: {} })}
          />
        )}
      </VerifyEmailContext.Provider>
    </Provider>
  );
};
export default ApolloProvider;

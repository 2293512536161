import { useMutation } from '@apollo/client';
import { useMixpanel } from 'hooks';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'tailwind';
import { GET_CURRENT_USER, UPDATE_WELCOME_TOUR } from 'utils/apollo/user';
import { StepProps } from '../types';
import { MembersIcon, PresentationIcon, ProjectIcon } from './icons';
import LogoDark from './step3.svg';

const Step3: FC<StepProps> = ({ handleBack }) => {
  const history = useHistory();
  const { mixpanelTrack } = useMixpanel();

  const [updateWelcomeTour] = useMutation(UPDATE_WELCOME_TOUR, {
    onCompleted: () => {
      mixpanelTrack('Welcome journey completed');
      history.push('/');
    },
    refetchQueries: [
      { query: GET_CURRENT_USER },
    ],
    fetchPolicy: 'network-only',
    awaitRefetchQueries: true,
  });

  const handleComplete = () => {
    updateWelcomeTour();
  };

  return (
    <>
      <img
        width='208'
        height='208'
        src={LogoDark}
        className='block mx-auto mb-3'
        alt="Thumbnail placeholder"
      />
      <h3 className='mb-3 font-normal'>Jump into VU.CITY</h3>

      <p className='text-lg mb-2.5 text-center'>
        Jump into VU.CITY and follow our onboarding guide to get
        started collaborating with your colleagues.
      </p>

      <div className='flex justify-between w-full'>
        <div className='flex flex-col items-center justify-center w-1/3 mb-5 text-xl font-medium text-center text-primary'>
          <div className='flex items-center justify-center mb-2 rounded-full w-7 h-7 bg-primary-lightest'>
            <ProjectIcon className='w-[30px] h-[30px] fill-primary' />
          </div>
          Create a project
        </div>

        <div className='flex flex-col items-center justify-center w-1/3 mb-5 text-xl font-medium text-center text-primary'>
          <div className='flex items-center justify-center mb-2 rounded-full w-7 h-7 bg-primary-lightest'>
            <MembersIcon className='w-[30px] h-[30px] fill-primary' />
          </div>
          Invite project members
        </div>

        <div className='flex flex-col items-center justify-center w-1/3 mb-5 text-xl font-medium text-center text-primary'>
          <div className='flex items-center justify-center mb-2 rounded-full w-7 h-7 bg-primary-lightest'>
            <PresentationIcon className='w-[30px] h-[30px] fill-primary' />
          </div>
          Visualise your story
        </div>

      </div>

      <div className='flex justify-between w-full'>
        <Button
          variant='text'
          padding='none'
          onClick={() => { mixpanelTrack('Go back'); handleBack(); }}
        >
          Go back
        </Button>
        <Button
          variant="contained"
          onClick={handleComplete}
        >
          Start using VU.CITY
        </Button>
      </div>
    </>
  );
};

export default Step3;

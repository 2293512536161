import { useCurrentUser } from 'hooks';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import Logo from './Logo/Logo';
import ReturnHome from './ReturnHome/ReturnHome';
import TopNavButtons from './TopNavButtons/TopNavButtons';

const TopNav = ({ showButtons = true }: { showButtons?: boolean; }) => {
  const { currentUser } = useCurrentUser();
  const { projectId: id } = useParams<{ projectId: string; }>();

  return (
    <div className='flex items-center justify-between w-full h-10 border-b border-b-primary'>
      <div className='flex items-center justify-between h-full pl-3 min-w-[280px] xl:min-w-280'>
        <Logo />
        {id && <ReturnHome />}
      </div>

      {showButtons && (
        <div className='pr-3'>
          {currentUser && <TopNavButtons user={currentUser} />}
        </div>
      )}
    </div>
  );
};

export default TopNav;

const typography = {
  fontFamily: [
    '"Roboto"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontSize: 16,
  fontWeight: 'normal',
  fontStyle: 'normal',
  color: '#333333',
  body1: {
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.2px',
  },
  body2: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.2px',
  },
  subtitle1: {
    fontSize: '14px',
    lineHeight: 'inherit',
  },
  subtitle2: {
    fontSize: '12px',
    fontWeight: 'normal',
  },
  h1: {
    fontSize: '48px',
    lineHeight: '64px',
    letterSpacing: '-0.1px',
  },
  h2: {
    fontSize: '36px',
    lineHeight: '48px',
    fontWeight: 'normal',
  },
  h3: {
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '0.2px',
    fontWeight: '500',
  },
  h4: {
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    fontWeight: '500',
  },
  h5: {
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.2px',
    fontWeight: '500',
  },
  h6: {
    fontSize: '18px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    fontWeight: '500',
  },
};

export default typography;

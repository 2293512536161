import React, { useCallback, useState } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useMutation } from '@apollo/client';
import { useCurrentUser, useHandleError, useMixpanel } from 'hooks';
import DetailedSelect from 'components/DetailedSelect';
import { DetailedSelectItem } from 'views/types';
import { UPDATE_ORG_MEMBER } from 'views/account/apollo';
import GET_ORG_MEMBERS from 'views/organisation/members/data';

const options = [
  {
    value: 'admin',
    title: 'Admin',
    subtitle:
      'Full administration rights for your organisation including managing members, subscriptions, billing and organisation details.',
  },
  {
    value: 'member',
    title: 'Member',
    subtitle:
      'No special permissions. View access to your organisation\'s details and members.',
  },
];

const OrgRoleDropdown = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
  const { currentUser } = useCurrentUser();
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();

  const [dropdownValue, setDropdownValue] = useState<string | undefined>(
    (value as string).toLowerCase(),
  );

  const [updateOrgMemberRole] = useMutation(UPDATE_ORG_MEMBER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ORG_MEMBERS }],
    onCompleted: ({ updateOrgMember }) => {
      mixpanelTrack('Role updated', { OrgRole: updateOrgMember.role });
      enqueueSnackbar('Organisation member role updated', {
        variant: 'success',
      });
    },
    onError: (message) => {
      setDropdownValue(value);
      handleError(message);
    },
  });

  const canNotPerformUpdate = useCallback(() => {
    const [, email] = row.name;
    return currentUser?.organisation?.me?.role !== 'ADMIN' || email === currentUser?.email;
  }, [currentUser, row.name]);

  const handleChange = (item: DetailedSelectItem | null | undefined) => {
    const { id: memberId } = row;
    setDropdownValue(item?.value);
    updateOrgMemberRole({
      variables: {
        memberId,
        role: item?.value.toUpperCase(),
      },
    });
  };

  return (
    <DetailedSelect
      handleChange={handleChange}
      value={dropdownValue}
      items={options}
      isReadOnly={canNotPerformUpdate()}
      optionWidth={332}
    />
  );
};

export default OrgRoleDropdown;

import { InputLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, TextModal } from 'components';
import InputGroupBox from 'components/InputGroupBox/InputGroupBox';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { jobTitles, organisationTypes } from 'utils/configs';

interface DetailsProps {
  onSendQuote: () => void;
  onClose: () => void;
  loading: boolean;
}

const SubmitDetailsModal = ({ onSendQuote, onClose, loading }: DetailsProps) => {
  const [openAuto, setOpenAuto] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const { firstName, lastName, organisation, email } = watch();

  const isDisabled = () => {
    if (!firstName || !lastName || !organisation || !email) return true;
    if (Object.keys(errors).length > 0) return true;

    return false;
  };

  return (
    <TextModal
      headerText='Submit your request'
      component='div'
      closeBtnLabel='Go back'
      mainBtnLabel='Submit request'
      mainBtnDisabled={isDisabled()}
      mainBtnHandle={handleSubmit(onSendQuote)}
      open={true}
      loading={loading}
      setOpen={onClose}
    >
      <p className='text-base'>
        Please provide the following details to complete your quote request:
      </p>

      <div className='flex mt-2 text-left gap-1 flex-wrap justify-between flex-grow [&>*]:w-[49%]'>
        <InputGroupBox
          register={register}
          field='firstName'
          placeholder='First name'
          errors={errors}
          labelText='First name*'
          isRequired
          onKeyDown={(e) => e.stopPropagation()}
        />
        <InputGroupBox
          register={register}
          field='lastName'
          placeholder='Last name'
          errors={errors}
          labelText='Last name*'
          isRequired
          onKeyDown={(e) => e.stopPropagation()}
        />
        <InputGroupBox
          register={register}
          field='email'
          placeholder='Email'
          errors={errors}
          labelText='Email*'
          type='email'
          isRequired
          onKeyDown={(e) => e.stopPropagation()}
        />
        <div>
          <InputLabel htmlFor="type" error={!!errors.jobTitle}>Job title</InputLabel>
          <Controller
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <Autocomplete
                id="jobTitle"
                freeSolo
                options={jobTitles.sort()}
                onOpen={() => setOpenAuto(true)}
                onClose={() => setOpenAuto(false)}
                className={openAuto ? 'openedAutocomplete' : ''}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(_, data) => onChange(data)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    id='jobTitle'
                    error={!!errors.jobTitle}
                    aria-label="Job title"
                    variant="outlined"
                    placeholder="Select title"
                  />
                )}
              />
            )}
            name="jobTitle"
            control={control}
          />
        </div>

        <InputGroupBox
          register={register}
          field='organisation'
          placeholder='Company name'
          errors={errors}
          labelText='Organisation*'
          isRequired
          onKeyDown={(e) => e.stopPropagation()}
        />
        <div>
          <InputLabel htmlFor="type" error={!!errors.type}>Organisation type*</InputLabel>
          <Controller
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <Autocomplete
                id="type"
                freeSolo
                className={openAuto ? 'openedAutocomplete' : ''}
                options={organisationTypes.sort()}
                onOpen={() => setOpenAuto(true)}
                onClose={() => setOpenAuto(false)}
                onChange={(_, data) => onChange(data)}
                onKeyDown={(e) => e.stopPropagation()}
                renderInput={(props: any) => (
                  <TextField
                    {...props}
                    aria-label="Organisation Type"
                    id="type"
                    error={!!errors.type}
                    variant="outlined"
                    placeholder="Select Organisation Type"
                  />
                )}
              />
            )}
            name="type"
            control={control}
          />
        </div>
      </div>
    </TextModal>
  );
};

export default SubmitDetailsModal;

import { useSelect, UseSelectStateChange } from 'downshift';
import { useMixpanel } from 'hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DetailedSelectItem, DetailedSelectProps } from 'views/types';

import { ReactComponent as ExpandCloseSvgOpen } from 'assets/icons/ExpandClose.svg';
import { ReactComponent as ExpandOpenSvg } from 'assets/icons/ExpandOpen.svg';
import Tooltip from 'components/Tooltip/Tooltip';
import SelectOption from './SelectOption';

const DetailedSelect: React.FC<DetailedSelectProps> = (props) => {
  const {
    id,
    items,
    handleChange,
    value,
    isReadOnly,
    variant,
    mixpanelEvent,
    optionalHeader,
    optionalFooter,
    alternateLabel,
    formatTitle,
    optionWidth,
    showTooltip = false,
    className,
  } = props;

  const { mixpanelTrack } = useMixpanel();
  const [selected, setSelected] = useState<DetailedSelectItem | undefined | null>();

  useEffect(() => {
    setSelected(items.find((item) => item.value === value));
  }, [items, value]);

  const ref = useRef<HTMLDivElement>(null);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items,
    itemToString: (item: DetailedSelectItem | null) => item?.value ?? '',
    onSelectedItemChange: (
      changes: UseSelectStateChange<DetailedSelectItem> | null,
    ) => {
      if (handleChange) handleChange(changes?.selectedItem);
      setSelected(changes?.selectedItem);
    },
  });

  const [_optionWidth, setOptionWidth] = useState(!optionWidth ? ref.current?.offsetWidth : optionWidth);
  useEffect(() => {
    const resizeHandler = () => setOptionWidth(!optionWidth ? ref.current?.offsetWidth : optionWidth);
    window.addEventListener('resize', () => resizeHandler());
    return window.removeEventListener('resize', () => resizeHandler());
  }, []);
  const _optionHeight = ref.current?.offsetHeight;

  const triggerMixpanel = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isOpen && mixpanelEvent) {
      mixpanelTrack(mixpanelEvent);
    }
  };

  const nearBottom = useCallback(() => {
    const rect = ref?.current?.getBoundingClientRect();
    return isOpen && rect && rect.top > 510;
  }, [isOpen]);

  const selectedValueRef = useRef<HTMLParagraphElement>(null);
  const [isSelectHovered, setIsSelectHovered] = useState(false);
  const isOverflowing = () =>
    isSelectHovered && selectedValueRef.current
      ? selectedValueRef.current.scrollWidth >
        selectedValueRef.current.offsetWidth
      : false;

  return (
    <div
      id={id}
      data-testid={id}
      role='combobox'
      ref={ref}
      className={`
        font-sans
        ${
          variant === 'outlined'
            ? `border border-placeholder max-h-6 relative bg-white p-1.2 ${
              isOpen ? 'rounded-t rounded-b-none' : 'rounded'
            }`
            : ''
        }
        ${nearBottom() && isOpen ? 'rounded-t-none rounded-b' : ''}
        ${className}
      `}
      onClick={triggerMixpanel}
    >
      <div
        onMouseEnter={() => setIsSelectHovered(true)}
        onMouseLeave={() => setIsSelectHovered(false)}
        role='button'
        className={`cursor-pointer flex justify-between items-center ${
          variant === 'outlined' && 'w-full'
        }`}
        {...getToggleButtonProps({ disabled: isReadOnly })}
      >
        <Tooltip
          isVisible={showTooltip && selected?.customTooltipValue || showTooltip && isOverflowing()}
          placement='top-start'
          content={selected?.customTooltipValue ? selected.customTooltipValue : (formatTitle ? formatTitle(selected) : selected?.title)}
        >
          {alternateLabel ? (
            alternateLabel(getLabelProps())
          ) : (
            <p
              ref={selectedValueRef}
              className='w-full min-w-[65px] overflow-hidden text-ellipsis whitespace-nowrap'
              {...getLabelProps()}
            >
              {formatTitle ? formatTitle(selected) : selected?.title}
            </p>
          )}
        </Tooltip>
        {!isReadOnly && (
          <>
            {isOpen ? (
              <ExpandCloseSvgOpen className='min-w-[24px] min-h-[24px] fill-none' />
            ) : (
              <ExpandOpenSvg className='min-w-[24px] min-h-[24px] fill-none' />
            )}
          </>
        )}
      </div>

      <ul
        className={`
          absolute flex-col items-start border border-placeholder list-none m-0 p-0 overflow-x-hidden overflow-y-auto bg-white z-[999] outline-none w-full
          ${
            nearBottom()
              ? 'rounded-t rounded-b-none'
              : 'rounded-t-none rounded-b'
          }
          ${variant === 'outlined' && '-left-[1px]'}
          ${variant === 'outlined' && !nearBottom() ? 'top-full' : ''}
          ${isOpen ? 'flex' : 'hidden'}
        `}
        style={{
          width: _optionWidth ? `${_optionWidth}px` : '100%',
          bottom:
            variant === 'outlined' && nearBottom()
              ? `calc(${_optionHeight}px - 2px)`
              : 'unset',
        }}
        {...getMenuProps()}
      >
        {optionalHeader?.()}
        {isOpen &&
          items.map((item, index) => (
            <li
              className={`
                transition duration-300
                relative block m-0 cursor-pointer rounded-none w-full border-b border-b-primary-lightest last-of-type:border-none 
                hover:bg-primary-lightest
                ${!!item.subtitle ? 'p-2' : 'py-1 px-2'}
                ${item.value === selected?.value ? 'bg-primary-lightest' : ''}
                ${item.disabled ? 'opacity-30 pointer-events-none' : ''}
              `}
              {...getItemProps({
                item,
                index,
                ...{ disabled: item?.disabled },
              })}
              key={index}
            >
              <SelectOption
                item={item}
                isActive={item.value === selected?.value}
                showTooltip={showTooltip}
              />

              <div className='text-xs whitespace-pre-line text-grey float-right w-full'>
                {item.subtitle}
              </div>
            </li>
          ))}

        {optionalFooter?.()}
      </ul>
    </div>
  );
};

export default DetailedSelect;

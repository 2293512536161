import { Tooltip } from 'components';
import { useMixpanel } from 'hooks';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export type MenuItems = {
  icon: JSX.Element;
  title: string;
  url: string;
  isLimited?: boolean;
  activeIcon?: JSX.Element;
  dataAttributes?: {
    [x: string]: string;
  };
}[];

export interface IMenuProps {
  onClose?: () => void;
  items: MenuItems;
  collapsed?: boolean;
}

const Menu = ({ items, onClose, collapsed }: IMenuProps) => {
  const location = useLocation();
  const { mixpanelTrack } = useMixpanel();

  const getActiveState = React.useCallback((url: string) => location.pathname === url, [location.pathname]);

  const navItemClick = (e: React.SyntheticEvent, item: MenuItems[0]) => {
    if (item.isLimited) e.preventDefault();
    mixpanelTrack(`${item.title} page clicked`, {
      'Menu Collapsed': collapsed,
    });
  };

  return (
    <div className='w-full rounded-r-none'>
      <div className='flex flex-col w-full gap-1'>
        {items && items.map(({ title, icon, url, isLimited, activeIcon, dataAttributes }) => (
          <Tooltip
            key={title}
            placement="right"
            content={<>{title}</>}
            isVisible={collapsed}
          >
            <NavLink
              exact={true}
              to={url}
              key={title}
              onClick={(e) => navItemClick(e, { title, icon, url, isLimited, activeIcon })}
              activeClassName="text-content font-medium bg-primary-lightest"
              className='no-underline transition duration-300 rounded text-grey'
              {...dataAttributes}
            >
              <button
                onClick={onClose}
                disabled={isLimited}
                className={`px-2 py-1 w-full flex items-center ${collapsed && 'justify-center'} rounded gap-[10px] [&>svg]:text-primary
              disabled:opacity-20 hover:bg-grey-lighter disabled:hover:bg-transparent`}
              >
                {React.cloneElement(getActiveState(url) && activeIcon ? activeIcon : icon, { className: 'min-h-[20px] min-w-[20px] w-[20px] h-[20px]' })}
                {!collapsed ? title : null}
              </button>
            </NavLink>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default Menu;

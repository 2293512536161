import { useEffect, useState } from 'react';
import useHandleError from 'hooks/useHandleError';
import useCurrentUser from 'hooks/useCurrentUser';

const useUserRoleCheck = () => {
  const { currentUser } = useCurrentUser();

  const [, enqueueSnackbar] = useHandleError();
  const [isAllowed, setIsAllowed] = useState(true);

  useEffect(() => {
    if (currentUser && currentUser.organisation?.me?.role === 'MEMBER') {
      enqueueSnackbar('You are not authorized to access this page', { variant: 'error' });
      setIsAllowed(false);
    }
  }, [currentUser, enqueueSnackbar]);

  return { isAllowed };
};

export default useUserRoleCheck;

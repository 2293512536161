import { gql } from '@apollo/client';

const COPY_NODES = gql`
  mutation ($nodes: [NodeInput], $projectId: ID, $folder: NodeInput!) {
   copyNodes(nodes: $nodes, projectId: $projectId, folder: $folder) {
      id
      name
    }
  }
`;

export default COPY_NODES;

import { useQuery } from '@apollo/client';
import { FormHelperText, InputLabel, Link, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import { Help, Info, PhoneIphone } from '@material-ui/icons';
import { Autocomplete, LoadingBar } from 'components';
import InputGroupBox from 'components/InputGroupBox/InputGroupBox';
import { useHandleError, useMixpanel } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { BackButton, Button, FullwidthLayout } from 'tailwind';
import { License } from 'types/graphql';
import { jobTitles, organisationTypes } from 'utils/configs';
import { sortLicences, splitName } from 'utils/helpers';
import { LocationState } from 'views/types';
import { GET_FORM_DATA } from './data/data';
import { Values } from './types';

const HUB_CONTACT_FORM_TRIAL = import.meta.env.VITE_CONTACT_FORM_TRIAL as string;
const HUB_CONTACT_FORM_SUPPORT = import.meta.env.VITE_CONTACT_FORM_SUPPORT as string;

const ContactUsForm = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [, enqueueSnackbar] = useHandleError();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { mixpanelTrack } = useMixpanel();

  const isTrial = location.state?.trial || false;

  const { data: formData } = useQuery(GET_FORM_DATA);

  const { handleSubmit, register, formState, reset, control, getValues } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      jobTitle: '',
      organisation: '',
      organisationType: '',
      cities: [],
      message: '',
    },
  });

  const { errors, dirtyFields } = formState;

  useEffect(() => {
    if (formData) {
      const { currentUser } = formData;
      const [firstName, lastName] = splitName(currentUser.name);

      const defaults = {
        firstName,
        lastName,
        email: currentUser.email,
        phone: currentUser.contactNumber,
        jobTitle: currentUser.jobTitle,
        organisation: currentUser.organisation.name,
        organisationType: currentUser.organisation.type,
      };

      reset(defaults);
    }
  }, [formData, reset]);

  const onSendMessage = async (values: Values) => {
    setLoading(true);


    const contactUrl = isTrial ? HUB_CONTACT_FORM_TRIAL : HUB_CONTACT_FORM_SUPPORT;
    mixpanelTrack('Send Message');

    const finalValues = {
      ...values,
      leadSource: isTrial ? 'HUB Trial - Get in touch' : 'HUB - Get in touch',
      ...(isTrial && { message: `I want to trial VU.CITY Hub. ${values.message}` }),
    };

    try {
      await fetch(contactUrl, {
        method: 'POST',
        body: JSON.stringify(finalValues),
      });

      enqueueSnackbar('Form submitted. We\'ll be in touch soon.', { variant: 'success' });
      history.push('/');
    } catch {
      enqueueSnackbar('Something went wrong. Try again.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const backUrl = location.state?.prevPath || '/';

  return (
    <FullwidthLayout>
      <div className='pb-2'>
        {loading && <LoadingBar />}

        <BackButton onClick={() => {
          mixpanelTrack('Returned to subscription overview');
          history.push(backUrl);
        }} />

        <h2 className='text-center'>
          Get in touch
        </h2>

        <section className='flex justify-between gap-2 mt-4'>
          <div className='w-1/2'>
            <h3>Let's talk</h3>

            <p className='mt-1 mb-2 text-lg'>
              Whatever your question, we're here to help.
            </p>

            <div className='flex items-start gap-2 py-1'>
              <PhoneIphone className='h-2.5 w-2.5 mt-0.5 text-primary' />
              <div>
                <p>General Enquiries: <Link href="tel:00442038897030">+44 (0)203 889 7030</Link></p>
                <p>Technical Support: <Link href="tel:08081683931">0808 168 3931</Link></p>
              </div>
            </div>

            <div className='flex items-center gap-2 py-1'>
              <Info className='h-2.5 w-2.5 text-primary' />
              <Link href="mailto:info@vu.city">info@vu.city</Link>
            </div>

            <div className='flex items-center gap-2 py-1'>
              <Help className='h-2.5 w-2.5 text-primary' />
              <Link target='_blank' rel='noreferrer noopener' href="https://kb.vu.city/home/">Help and support</Link>
            </div>
          </div>

          <div className='flex flex-col w-1/2 max-w-[600px] gap-1'>
            <InputGroupBox
              register={register}
              isRequired
              field='firstName'
              labelText='First Name*'
              placeholder='First Name'
              errors={errors}
            />

            <InputGroupBox
              register={register}
              isRequired
              field='lastName'
              labelText='Last Name*'
              placeholder='Last Name'
              errors={errors}
            />

            <InputGroupBox
              register={register}
              isRequired
              field='email'
              type='email'
              labelText='Email*'
              placeholder='Email address'
              errors={errors}
            />

            <InputGroupBox
              register={register}
              isRequired
              field='phone'
              labelText='Phone*'
              placeholder='Phone'
              errors={errors}
            />

            <InputLabel htmlFor="jobTitle" className='pb-0'>
              Job title
            </InputLabel>

            <Controller
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  id="jobTitle"
                  freeSolo
                  options={jobTitles}
                  onChange={(_, data) => onChange(data)}
                  value={value}
                  renderInput={(props: any) => (
                    <TextField
                      {...props}
                      aria-label="Job title"
                      name="jobTitle"
                      id="jobTitle"
                      variant="outlined"
                      placeholder="Enter title"
                      onChange={(e) => onChange(e.target.value)}
                    />
                  )}
                />
              )}
              name="jobTitle"
              control={control}
              defaultValue=''
            />

            <InputGroupBox
              register={register}
              isRequired
              field='organisation'
              labelText='Organisation name*'
              placeholder='Organisation name'
              errors={errors}
            />

            <InputLabel htmlFor="organisationType" error={!!errors.organisationType} className='pb-0'>
              Organisation type
            </InputLabel>

            <Controller
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  id="organisationType"
                  freeSolo
                  options={organisationTypes}
                  onChange={(_, data) => onChange(data)}
                  value={value}
                  renderInput={(props: any) => (
                    <TextField
                      {...props}
                      aria-label="Organisation Type"
                      id="organisationType"
                      variant="outlined"
                      placeholder="Select Organisation Type"
                    />
                  )}
                />
              )}
              name="organisationType"
              control={control}
              defaultValue={getValues('organisationType')}
            />
            {errors.organisationType && (
              <FormHelperText error>{errors.organisationType.message}</FormHelperText>
            )}

            <InputLabel htmlFor="cities" error={!!errors.cities} className='pb-0'>
              What cities are you interested in?
            </InputLabel>

            <Controller
              name="cities"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className='[&>div]:w-full'
                  multiple={true}
                  input={<OutlinedInput />}
                  error={!!errors.cities}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    disablePortal: true,
                  }}
                >
                  {formData && sortLicences(formData.cityLicenses).map((licence: License) => (
                    <MenuItem key={licence.id} value={licence.name}>
                      {licence.name}
                    </MenuItem>
                  ))
                  }
                </Select>
              )}
            />
            {errors.cities && (
              <FormHelperText error>{errors.cities.message}</FormHelperText>
            )}

            <InputLabel htmlFor="message" className='pb-0'>
              Message
            </InputLabel>

            <OutlinedInput
              multiline
              rows={3}
              id="message"
              labelWidth={0}
              placeholder="Message"
              {...register('message')}
            />

            <Button
              fullWidth
              variant='contained'
              disabled={!Object.keys(dirtyFields).length || loading}
              onClick={handleSubmit(onSendMessage)}
            >
              Send message
            </Button>
          </div>
        </section>
      </div>
    </FullwidthLayout>
  );
};

export default ContactUsForm;

import React, { ReactElement } from 'react';
import { GQLWrapper } from '../..';

interface Props {
  searchLoading: boolean;
  searchResults: boolean;
  children: React.ReactNode;
}

function SearchResults({ children, searchLoading, searchResults }: Props): ReactElement {
  return (
    <GQLWrapper loading={searchLoading}>
      {searchResults ? (
        children
      ) : (
        <div className='p-3 bg-white h-[calc(100vh-253px)] flex justify-center items-center flex-col text-center'>
          <p>
            None of your files or folders match your search
          </p>
        </div>
      )}
    </GQLWrapper>
  );
}

export default SearchResults;

import { useEventEmitter } from 'ahooks';
import flagsmith from 'flagsmith';
import React, { useEffect } from 'react';

// const defaultFlags = {
//   preview_3d_size_limit: {
//     enabled: true,
//     value: 0,
//   },
//   organisations: {
//     enabled: true,
//   },
//   download_desktop: {
//     enabled: true,
//   },
// };

export const FlagsmithContext = React.createContext({ flagsmith });

let isInitialised = false;

const FlagsmithProvider = ({ children }) => {
  const { useSubscription } = useEventEmitter();

  useEffect(
    () => {
      if (isInitialised) return;
      if (import.meta.env.VITE_ENV !== 'PROD') return;
      // flagsmith.init({
      //   environmentID: import.meta.env.VITE_FLAGSMITH_ENV_ID,
      //   defaultFlags,
      //   onError: (error) => {
      //     Sentry.captureException(error?.message);
      //   },
      //   onChange: async (prev, { flagsChanged }) => {
      //     if (!flagsChanged) return;
      //     await flagsmith.getFlags();
      //     // TODO: Consider emitting specific events for just the changed flags
      //     emit('FLAGS_UPDATED');
      //   },
      // });
      // flagsmith.startListening(8.64e+7);
      isInitialised = true;
    },
  );

  return (
    <FlagsmithContext.Provider value={{ flagsmith, useSubscription }}>
      {children}
    </FlagsmithContext.Provider>
  );
};

export default FlagsmithProvider;

import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { auth0Credentials } from 'utils/configs';

const ApolloProviderNoLogin = ({ children }: { children: React.ReactNode; }) => {
  const { user, getAccessTokenSilently, logout } = useAuth0();

  const apolloClient = useMemo(() => {
    const httpLink: any = createUploadLink({
      uri: `${import.meta.env.VITE_GRAPHQL_URI}/graphql`,
    });

    const authLink = setContext(async () => {
      if (!user) return {};
      let token = '';
      try {
        token = await getAccessTokenSilently({
          authorizationParams: {
            audience: import.meta.env.VITE_GRAPHQL_URI,
          },
        });
      } catch (err) {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }

      return {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-transaction-id': Math.random().toString(36).substr(2, 9),
        },
      };
    });

    return new ApolloClient({
      link: ApolloLink.from([
        authLink,
        new SentryLink({
          attachBreadcrumbs: {
            includeQuery: true,
            includeVariables: true,
            includeError: true,
          },
        }),
        httpLink,
      ]),
      cache: new InMemoryCache(),
    });
  }, [getAccessTokenSilently, logout, user]);

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};

const Auth0ProviderNoLogin = ({ children }: { children: React.ReactNode; }) => {
  const { domain, clientId } = auth0Credentials;
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: import.meta.env.VITE_GRAPHQL_URI,
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <ApolloProviderNoLogin>
        {children}
      </ApolloProviderNoLogin>
    </Auth0Provider>
  );
};

export default Auth0ProviderNoLogin;

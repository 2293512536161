const credentials = {
  LOCAL: {
    domain: 'vucity-vudev.eu.auth0.com',
    clientId: 'Qkuk6S3HVd1TDqEJDWRwiHWhvXt6KxP2',
    frame: 'https://vustage-frame.web.app',
    frameClientId: 'DfcMBVPIEwQy2Dm1tjyYNjkISdOipFav',
    desktopClientId: 'xU4Pc17t4pghzGx64IDgKmszUwaOyBgp',
    frameBackend: 'vudev',
    sitesolveClientId: 'ljQD4A0UF3Lugs9dAdZSuEjRvTmuKBKv',
  },
  DEVELOP: {
    domain: 'vucity-vudev.eu.auth0.com',
    clientId: 'Qkuk6S3HVd1TDqEJDWRwiHWhvXt6KxP2',
    frame: 'https://vustage-frame.web.app',
    frameClientId: 'DfcMBVPIEwQy2Dm1tjyYNjkISdOipFav',
    desktopClientId: 'xU4Pc17t4pghzGx64IDgKmszUwaOyBgp',
    frameBackend: 'vudev',
    sitesolveClientId: 'ljQD4A0UF3Lugs9dAdZSuEjRvTmuKBKv',
  },
  STAGING: {
    domain: 'vucity-vustage.eu.auth0.com',
    clientId: '8afnDsbjM0d8531bMjg69yHkk9laQrKJ',
    frame: 'https://vustage-frame.web.app',
    frameClientId: 'xCHZ11DExLrD7nqe0LHMQqPSaWtWFF8i',
    desktopClientId: 'SagemLbtyZJNxd67q7bMOuqv25xobQYM',
    frameBackend: 'vustage',
    sitesolveClientId: '5LpBkusHrfjehKd9AgPjnNXgZKiKqRJZ',
  },
  PROD: {
    domain: 'vucity.eu.auth0.com',
    clientId: 'OouYCD8d5ldmgm2TQLdEtLZm1uLMsZTN',
    frame: 'https://vucity-frame.web.app',
    frameClientId: 'dxG6Wi5ATOMBKC3ncSaGuQFhQjvS2zJ0',
    desktopClientId: 'Kqe2ci4K3QCD7MOmApjM05yobtyNYMDn',
    frameBackend: 'vu',
    sitesolveClientId: 'hQC1OA3jHFzitJhbDHcRSydF8qC5i9mq',
  },
};

// @ts-ignore
export const auth0Credentials = credentials[import.meta.env.VITE_ENV];

// === LAUNCH 3D APP ===

// Double encoding the email, mainly because of auth0
// not decoding correctly "+" signs e.g. email+anything@vu.city
// turning it into a space instead
// This hack makes sure the + gets decoded correctly
export const encodeEmail = (email: string) => email.replace(/\+/g, '%2B');

const { domain, frameClientId, desktopClientId, sitesolveClientId } = auth0Credentials;

export const desktopAppUri = `vucity://hub.${auth0Credentials.frameBackend}/launch`;
export const auth0Authorize = (uri: string) => `https://${domain}/authorize?audience=${import.meta.env.VITE_GRAPHQL_URI}&scope=offline_access&response_type=code&client_id=${frameClientId}&redirect_uri=${encodeURIComponent(uri)}`;

export const auth0SiteSolveAuthorize = (uri: string) => `https://${domain}/authorize?audience=${import.meta.env.VITE_GRAPHQL_URI}&scope=offline_access&response_type=code&client_id=${sitesolveClientId}&redirect_uri=${encodeURIComponent(uri)}`;

export const auth0DesktopAuthorize = (uri: string) => `https://${domain}/authorize?audience=${import.meta.env.VITE_GRAPHQL_URI}&scope=offline_access&response_type=code&client_id=${desktopClientId}&redirect_uri=${encodeURIComponent(uri)}`;


import { gql, TypedDocumentNode } from '@apollo/client';
import { User } from 'types/graphql';

export const GET_ORG_MEMBERS: TypedDocumentNode<{ currentUser: User; }> = gql`
  query getOrgMembers {
    currentUser {
      id
      organisation {
        id
        members {
          id
          role
          status
          email
          profile {
            id
            name
            email
            picture
          }
        }
        me {
          role
        }
      }
    }
  }
`;

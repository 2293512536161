import { TextModal } from 'components';
import { useMixpanel } from 'hooks';
import * as React from 'react';
import { Button } from 'tailwind';
import { Organisation } from 'types/graphql';
import { ReactComponent as InfoSvg } from '../assets/icons/Info.svg';
import {
  invitationConfirmation,
  invitationInstructions,
  invitationWarning,
} from '../text';

interface AcceptInviteProps {
  handleSubmit: any;
  org: Organisation;
  info: {
    name: string;
    inviter: string;
  };
}

const AcceptInvite: React.FC<AcceptInviteProps> = ({
  handleSubmit,
  org,
  info,
}) => {
  const { mixpanelTrack } = useMixpanel();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    mixpanelTrack('cancelled Decline org invite');
    setOpen(false);
  };

  return (
    <>
      <Button
        size='md'
        variant="contained"
        onClick={() => {
          setOpen(true);
          mixpanelTrack('Join Org invite', {
            OrgID: org.id,
            OrgName: org.name,
          });
        }}
      >
        Join
      </Button>
      <TextModal
        open={open}
        setOpen={setOpen}
        mixpanelPath="Invitation accepted"
        closeBtnLabel="Cancel"
        closeBtnHandle={handleClose}
        mainBtnLabel="Join organisation"
        mainBtnHandle={() => {
          setOpen(false);
          mixpanelTrack('Accept Org invite', {
            OrgID: org.id,
            OrgName: org.name,
          });
          handleSubmit();
        }}
        headerText="Join organisation"
      >
        <div className='px-2 text-left'>
          <p className='text-lg' dangerouslySetInnerHTML={{ __html: invitationInstructions(info) }} />
          <div className='flex items-center p-2 pl-1.2 my-2 text-sm border border-l-8 rounded bg-primary-lightest border-primary-light'>
            <InfoSvg />
            <div className='ml-1.2'>
              {invitationWarning()}
            </div>
          </div>
          <p
            className='text-lg text-center'
            dangerouslySetInnerHTML={{ __html: invitationConfirmation(info) }}
          />
        </div>
      </TextModal>
    </>
  );
};

export default AcceptInvite;

import { useMutation, useQuery } from '@apollo/client';
import { useHandleError, useMixpanel } from 'hooks';
import React, { useState } from 'react';
import { AccountLayout, Label, Row } from 'tailwind';
import { GET_CURRENT_USER } from 'utils/apollo/user';
import { getCurrentLauchOption, isWindowsPlatform } from 'utils/helpers';
import { LaunchOption } from 'views/types';
import { Link, RadioOption } from './Desktop.style';
import GET_DOWNLOAD_URL, { UPDATE_LAUNCH_OPTION } from './data';

const requirements = [
  '<span class="font-medium">Windows</span> 8.1 64-bit or later;',
  'NVIDIA GeForce GTX 1070 or better;',
  'Intel 6th generation or later quad-core i7;',
  'SSD storage greater than 128GB;',
  '16GB RAM.',
];

const Desktop = () => {
  const [launchOption, setLaunchOption] = useState<LaunchOption>('CLOUD');

  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();
  
  const { loading } = useQuery(GET_CURRENT_USER, {
    onCompleted: ({ currentUser }) => {
      setLaunchOption(currentUser.appEnvironment);
    },
    onError: (e) => {
      handleError(e);
    },
  });

  const [updateLaunchOption, { loading: showLoading }] = useMutation(UPDATE_LAUNCH_OPTION, {
    onCompleted: () => {
      const currentOption = getCurrentLauchOption(launchOption === 'CLOUD');
      enqueueSnackbar(`You will now open your file via the VU.CITY ${currentOption}`, {
        variant: 'success',
      });
    },
    onError: (e) => {
      const currentOption = getCurrentLauchOption(!(launchOption === 'CLOUD'));
      handleError(e);
      setLaunchOption(currentOption.toUpperCase() as LaunchOption);
    },
  });

  const [getDownloadUrl] = useMutation(GET_DOWNLOAD_URL, {
    onError: () => {
      enqueueSnackbar('Download error, please try again', { variant: 'error' });
    },
    onCompleted: ({ createDesktopRelease }) => {
      if (createDesktopRelease)
        window.location.href = createDesktopRelease;
    },
  });

  const onDownloadDesktop = () => {
    getDownloadUrl();
    mixpanelTrack('Clicked the Download VU.CITY Desktop button');
  };

  const handleClick = (option: LaunchOption) => {
    if (option === 'DESKTOP' && !isWindowsPlatform) return;
    if (option === launchOption) return;

    mixpanelTrack(`Selecting the VU.CITY ${getCurrentLauchOption(option === 'CLOUD')} option`);
    setLaunchOption(option);
    updateLaunchOption({ variables: { environment: option } });
  };

  return (
    <AccountLayout
      gql={{ loading: loading || showLoading }}
      title='VU.CITY Desktop'
      subtitle='With Pro, access the VU.CITY 3D environment via Cloud or Desktop.'
      button={{
        text: 'Download VU.CITY Desktop',
        onClick: onDownloadDesktop,
        variant: 'contained',
      }}
    >
      <Row column1='VU.CITY 3D environment'>
        <RadioOption onClick={() => handleClick('CLOUD')}>
          <input type="radio" name='version' value='CLOUD' checked={launchOption === 'CLOUD'} />
          <label htmlFor="3D">VU.CITY Cloud <Label className='ml-1'>Default</Label></label>
        </RadioOption>
        <RadioOption
          onClick={() => handleClick('DESKTOP')}
          disabled={!isWindowsPlatform}
        >
          <input type="radio"
            name='version'
            value='DESKTOP'
            checked={launchOption === 'DESKTOP'}
            disabled={!isWindowsPlatform}
          />
          <label htmlFor="desktop">VU.CITY Desktop</label>
        </RadioOption>
      </Row>

      <Row column1='VU.CITY Desktop requirements'>
        You can find our minimum and recommended options <Link
          target='_blank'
          rel='noopener noreferer'
          href='https://kb.vu.city/home/install-and-setup/system-requirements/'
        >here</Link>.
        For the smoothest experience, the ideal spec are as follows:

        <ul className='pl-3 my-2 leading-tight list-disc'>
          {requirements.map((req) => (
            <li key={req} dangerouslySetInnerHTML={{ __html: req }} />
          ))}
        </ul>

        We do not currently support macOS for VU.CITY Desktop.
      </Row>

      <Row column1='Technical information'>
        For more information on technical requirements, please visit <Link
          target='_blank'
          rel='noopener noreferer'
          href="https://kb.vu.city/home/install-and-setup/"
        >Knowledge Base</Link>.
      </Row>
    </AccountLayout>
  );
};

export default Desktop;

import { InfoInactive } from '@vucity/design_system';
import styled from 'styled-components';

export const InfoIcon = styled(InfoInactive)`
  padding-left: 0px;
  width: fit-content;
  height: fit-content;

  & svg {
    color: #5C5C5C;
    height: 13.33px;
    width: 13.33px !important;
  }
`;

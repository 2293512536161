import React from 'react';
import { AdminProjectAccess } from 'types/graphql';
import AdminOversightOption from './AdminOversightOption';
import { InfoIcon } from './AdminOversight.style';

interface AdminOversightProps {
  isReadOnly: boolean;
  oversight: AdminProjectAccess;
  onOversightChange: (oversight: AdminProjectAccess) => void;
}

const AdminOversight: React.FC<AdminOversightProps> = (props: AdminOversightProps) => {
  const { onOversightChange, isReadOnly, oversight } = props;

  const handleClick = (access: AdminProjectAccess) => {
    if (access === oversight) return;
    onOversightChange(access);
  };

  return (
    <div className='py-2 mt-2 border-t border-b border-b-grey-light border-t-grey-light'>
      <p className='text-lg font-medium'>
        Your organisation admin's oversight preferences
      </p>

      <div className='flex items-center mb-2'>
        <InfoIcon />
        <p className='text-grey'>
          This oversight setting applies to all admins within your organisation.
        </p>
      </div>

      <div className='flex flex-col gap-2'>
        <AdminOversightOption
          setting="INVITE_ONLY_OVERSIGHT"
          isDefault
          isChecked={oversight === 'INVITE_ONLY_OVERSIGHT'}
          handleClick={handleClick}
          heading="Invite only oversight"
          subHeading="Organisation admins must be invited to have visibility of 'invite only' projects created by other members of their organisation."
          isReadonly={isReadOnly}
        />
        <AdminOversightOption
          setting="FULL_OVERSIGHT"
          isChecked={oversight === 'FULL_OVERSIGHT'}
          handleClick={handleClick}
          heading="Full oversight with manual access"
          subHeading="Organisation admins have full visibility of all projects created by members of the organisation, but they must manually join projects to gain access when needed."
          isReadonly={isReadOnly}
        />
      </div>
    </div>
  );
};

export default AdminOversight;

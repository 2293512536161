import * as React from 'react';

interface RowProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  column1: any;
  children: React.ReactNode;
}

const Row = ({ column1, children, ...rest }: RowProps) => (
  <div className={`flex flex-grow py-3 border-t border-t-grey-light text-content ${rest.className}`}>
    <div className='max-w-[210px] w-full flex-shrink-0 text-grey'>
      {column1}
    </div>
    <div className='flex-col flex-wrap flex-grow-0 w-full'>
      {children}
    </div>
  </div>
);

export default Row;

import {
  FormHelperText,
  InputLabel,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { Autocomplete, ConfirmChangesModal, UploadImageModal } from 'components';
import CountrySelect from 'components/CountrySelect/CountrySelect';
import InputGroupBox from 'components/InputGroupBox/InputGroupBox';
import { Picture } from 'components/Modal/UploadPhoto/UploadImage';
import { useCloudinary, useHandleError, useMixpanel } from 'hooks';
import { Organisation } from 'types/graphql';
import organisationTypes from 'utils/configs/organisationTypes';
import { UPDATE_ORGANISATION_DETAILS } from 'views/account/apollo';
import { IDataProps } from 'views/types';

import { scale } from '@cloudinary/url-gen/actions/resize';
import { Button, Row } from 'tailwind';
import { GET_CURRENT_USER } from 'utils/apollo/user';
import { OrgStyledAvatar, Phone } from './EditDetails.style';

interface IEditOrganisationProps {
  organisation: Organisation;
}

const EditDetails: React.FC<IEditOrganisationProps> = ({ organisation }) => {
  const defaultValues: IDataProps = {
    name: organisation.name,
    email: organisation.email,
    type: organisation.type,
    line1: organisation.address?.line1,
    line2: organisation.address?.line2,
    city: organisation.address?.city,
    postal_code: organisation.address?.postal_code,
    state: organisation.address?.state,
  };

  const {
    handleSubmit,
    register,
    trigger,
    control,
    formState: { errors, isDirty, dirtyFields },
    getValues,
  } = useForm({ mode: 'onBlur', defaultValues });

  const [cld, token] = useCloudinary();
  const history = useHistory();
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();

  const [phone, setPhone] = useState<string | undefined>(organisation.phone);
  const [dirty, setDirty] = useState(false);
  const [openAuto, setOpenAuto] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [picture, setPicture] = useState<Picture>({
    img: cld.image(organisation?.logo || ''),
  });
  const [country, setCountry] = useState(organisation.address?.country);

  const generateMixPanelProperties = () => {
    let extraProps = {};
    Object.keys(dirtyFields).forEach((key) => {
      if (
        ['city', 'country', 'line1', 'line2', 'postal_code', 'state'].includes(
          key,
        )
      ) {
        extraProps = {
          ...extraProps,
          [`$Org_Address_${key}`]: 'Has Changed/True',
        };
      } else {
        extraProps = {
          ...extraProps,
          [`Org_${key}`]: 'Has Changed/True',
        };
      }
    });

    return !dirty
      ? extraProps
      : { ...extraProps, Org_phone: 'Has Changed/True' };
  };

  const [updateOrganisationDetail] = useMutation(UPDATE_ORGANISATION_DETAILS, {
    onCompleted: () => {
      mixpanelTrack(
        'Update organisation Successful',
        generateMixPanelProperties(),
      );
      enqueueSnackbar('Organisation details updated', { variant: 'success' });
      history.push('/account/organisation/details');
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_CURRENT_USER }],
    onError: handleError,
  });

  const submitHandle = () => {
    const { name, email, type, ...address } = getValues();
    const { picturePublicId } = picture;
    const { access, defaultRole } = organisation.data || { access: 'INVITE_ONLY', defaultRole: 'VIEWER' };

    updateOrganisationDetail({
      variables: {
        id: organisation.id,
        details: {
          name: name?.trim(),
          phone,
          type: type?.trim(),
          email: email?.toLowerCase().trim(),
          logo: picturePublicId,
          access,
          defaultRole,
          address: {
            ...address,
            line1: address.line1 || '',
            country: country || '',
          },
        },
      },
    });
  };

  const handlePhoneInputChange = (value: any) => {
    setDirty(true);
    setPhone(value);
  };

  const handleModal = () => {
    mixpanelTrack('Opened set image modal.');
    setOpenModal(!openModal);
  };

  const handleSetPicture = (img: Picture) => {
    setPicture(img);
    setDirty(true);
  };

  const countryHandle = (event: object, value: any) => {
    setDirty(true);
    setCountry(value?.code);
  };

  return (
    <main className='flex-grow bg-white text-content'>
      <div className='flex flex-wrap items-start justify-between gap-2 mb-3'>
        <h4>Edit organisation</h4>

        <div>
          <ConfirmChangesModal
            headerText="Edit organisation"
            trigger={trigger}
            disabled={!(isDirty || dirty)}
            clickHandle={handleSubmit(submitHandle)}
            buttonLabel="Confirm"
          />
          <Button
            variant="text"
            onClick={() => {
              mixpanelTrack('Edit Organisation/Cancel');
              history.push('/account/organisation/details');
            }}
          >
            Cancel
          </Button>
        </div>
      </div>

      <Row column1="Name">
        <InputGroupBox
          register={register}
          isRequired
          labelText="Organisation name*"
          field="name"
          errors={errors}
          placeholder="Organisation name"
          width="88%"
        />
      </Row>

      <Row column1="Type">
        <InputLabel htmlFor="type" error={!!errors.type}>Organisation type*</InputLabel>

        <div className='w-[88%]'>
          <Controller
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id="type"
                freeSolo
                className={openAuto ? 'openedAutocomplete' : ''}
                options={organisationTypes.sort()}
                onOpen={() => setOpenAuto(true)}
                onClose={() => setOpenAuto(false)}
                onChange={(_, data) => onChange(data)}
                value={value}
                renderInput={(props: any) => (
                  <TextField
                    {...props}
                    aria-label="Organisation Type"
                    id="type"
                    error={!!errors.type}
                    variant="outlined"
                    placeholder="Select Organisation Type"
                  />
                )}
              />
            )}
            name="type"
            control={control}
            defaultValue={getValues('type')}
          />
        </div>
      </Row>

      <Row column1="Logo">
        <OrgStyledAvatar
          src={`${picture.img.resize(scale().width(240)).toURL()}&${token}`}
          alt={`${organisation.name} logo`}
          variant="square"
          onClick={handleModal}
        >
          Upload your logo here
        </OrgStyledAvatar>
        <UploadImageModal
          openModal={openModal}
          handleModal={handleModal}
          handleSetPicture={handleSetPicture}
          type="organisation"
        />
      </Row>

      <Row column1="Address">
        <div className='flex flex-grow flex-wrap justify-between w-[88%] [&>*]:w-[47%]'>
          <InputGroupBox
            register={register}
            isRequired={false}
            field="line1"
            placeholder="Enter address"
            errors={errors}
            labelText="Address line 1"
          />
          <InputGroupBox
            register={register}
            isRequired={false}
            field="line2"
            errors={errors}
            labelText="Address line 2"
          />
          <InputGroupBox
            register={register}
            isRequired={false}
            field="city"
            errors={errors}
            labelText="Town/City"
          />
          <InputGroupBox
            register={register}
            isRequired={false}
            field="state"
            errors={errors}
            labelText="County/Region"
          />
          <InputGroupBox
            register={register}
            isRequired={false}
            field="postal_code"
            errors={errors}
            labelText="Postcode"
            placeholder="Enter postcode"
          />
          <CountrySelect value={country} handleChange={countryHandle} />
        </div>
      </Row>

      <Row column1="Contact Info">
        <div className='flex flex-grow flex-wrap justify-between w-[88%] [&>*]:w-[47%]'>
          <InputGroupBox
            register={register}
            field="email"
            placeholder="@"
            errors={errors}
            labelText="Organisation email"
            type="email"
          />
          <div>
            <InputLabel htmlFor="phone" error={!!errors.phone}>
              Organisation contact number
            </InputLabel>
            <Phone
              initialCountry='gb'
              value={phone || ''}
              onChange={handlePhoneInputChange}
              className='flex items-center'
              inputClassName='border border-placeholder rounded rounded-l-none w-full py-2 px-1.2 h-6 hover:border-primary focus:shadow-input focus:outline-0 focus:border-primary'
            />
            {errors.phone && (
              <FormHelperText error>{errors.phone.message}</FormHelperText>
            )}
          </div>
        </div>
      </Row>
    </main>
  );
};

export default EditDetails;

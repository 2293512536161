import { useMixpanel } from 'hooks';
import React from 'react';
import { Button } from 'tailwind';
import CustomModal from '../Modal';

interface InviteNotAllowedProps {
  open: boolean;
  handleCloseModal: () => void;
}
const UnableToJoin: React.FC<InviteNotAllowedProps> = ({ open, handleCloseModal }) => {
  const { mixpanelTrack } = useMixpanel();
  return (
    <CustomModal open={open}
      headerText="You cannot join this organisation"
      close={handleCloseModal}
    >
      <div className='w-full text-left'>
        <p className='my-2 text-lg'>This is due to one of the following reasons: </p>
        <ul className='pl-3 m-0 list-disc'>
          <li className='text-lg'>You are already part of a multi-member organisation</li>
          <li className='text-lg'>Your organisation has an active paid subscription</li>
        </ul>
      </div>

      <div className='m-2'>
        <Button
          variant="contained"
          onClick={() => {
            mixpanelTrack('CannotJoinOrganisation/Close');
            handleCloseModal();
          }}
        >
          Close
        </Button>
      </div>
    </CustomModal>
  );
};

export default UnableToJoin;

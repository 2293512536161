import { Avatar } from '@material-ui/core';
import styled from 'styled-components';

const OrgAvatar = styled(Avatar)`
  width: 240px;
  height: 138px;
  font-size: 14pt;
  background: #fff;
  color: #004e63;
  justify-content: center;
  border-radius: 4px;
`;

export default OrgAvatar;

import { Box, BoxProps, FormHelperText, InputLabel } from '@material-ui/core';
import copy from 'copy';
import React from 'react';
import { DeepMap, FieldError, FieldValues, UseFormRegister } from 'react-hook-form';
import { TextField } from 'tailwind';
import { emailRegex } from 'utils/helpers';
import { IDataProps } from 'views/types';

interface InputGroupBoxProp extends BoxProps {
  register: UseFormRegister<any>;
  field: Extract<keyof IDataProps, string>;
  errors: DeepMap<FieldValues, FieldError>;
  labelText: string;
  isRequired?: boolean;
  type?: string;
}

const { emailErrorMsg } = copy;

const InputGroupBox: React.FC<React.PropsWithChildren<InputGroupBoxProp>> = (props) => {
  const { register, errors, field, labelText, isRequired,
    placeholder, type, ...boxProps } = props;

  const generateErrorMessage = () => {
    if (type === 'email' && errors[field].type === 'pattern') {
      return emailErrorMsg.INVALID;
    }
    if (isRequired) return 'Required';
    return '';
  };

  return (
    <Box {...boxProps}>
      <InputLabel htmlFor={field} error={!!errors[field]}>{labelText}</InputLabel>
      <TextField
        id={field}
        error={!!errors[field]}
        placeholder={placeholder}
        {...register(field, {
          required: isRequired,
          pattern: type === 'email' ? emailRegex : undefined,
          ...(isRequired && {
            validate: value => !!value.trim(),
          }),
        })}
        type={type}
      />
      {errors[field]
        && <FormHelperText error style={{ paddingBottom: '15px' }}>{generateErrorMessage()}</FormHelperText>}
    </Box>
  );
};

export default InputGroupBox;

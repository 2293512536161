import { fill } from '@cloudinary/url-gen/actions/resize';
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';
import { FocusOn } from '@cloudinary/url-gen/qualifiers/focusOn';
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity';
import { CircularProgress, SvgIcon } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { useCloudinary, useMixpanel } from 'hooks';
import React, { useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import {
  BoxIconWrapperOrg, ImgWrapper,
} from './UploadImage.styles';

import { Button } from 'tailwind';
import CustomModal from '../Modal';
import { ReactComponent as Face } from './assets/Face.svg';

export type Picture = { img: any; picturePublicId?: string; };

const FaceIcon = (props: any) => <SvgIcon {...props} viewBox="0 0 28 28" component={Face} />;

const baseUrl = `https://api.cloudinary.com/v1_1/${import.meta.env.VITE_CLOUDINARY_DOMAIN}`;

type UploadPhotoProps = {
  handleModal: () => void;
  handleSetPicture: (value: Picture) => void;
  openModal: boolean;
  projectID?: string;
  type?: string;
};

interface IModalTypes {
  [key: string]: {
    header: string;
    extraText?: string;
    dropzoneType: JSX.Element;
    uploadFolder: string;
    uploadPreset: string;
    buttonText: string;
  };
}

const modalTypes: IModalTypes = {
  project: {
    header: 'Project cover image',
    extraText:
      'Only the following file types are supported JPG, PNG, BMP, TIFF, ICO, SVG and WebP.',
    dropzoneType: <BoxIconWrapperOrg />,
    uploadFolder: '/hub/projects/',
    uploadPreset: 'project_cover',
    buttonText: 'Set project image',
  },
  profile: {
    header: 'Profile photo',
    dropzoneType: (
      <div className='border-dotted border-[3px] border-primary-light w-[128px] h-[128px] my-2 mx-auto flex justify-center items-center rounded-full'>
        <FaceIcon />
      </div>
    ),
    uploadFolder: '/hub/users/avatars',
    uploadPreset: 'profile_image',
    buttonText: 'Set as profile photo',
  },
  organisation: {
    header: 'Organisation image',
    extraText:
      'Only the following file types are supported JPG, PNG, BMP, TIFF, ICO, SVG and WebP.',
    dropzoneType: <BoxIconWrapperOrg />,
    uploadFolder: '/hub/organizations/images',
    uploadPreset: 'organization_image',
    buttonText: 'Set as organisation image',
  },
};

const handleDropzoneErrors = (code: any, index: number) => {
  switch (code) {
    case 'file-too-large':
      return (
        <div className='pb-3'>
          <Alert key={`${code}-${index}`} severity="error">
            <span key={code}>File must be under 10MB!</span>
          </Alert>
        </div>
      );
    case 'file-invalid-type':
      return (
        <div className='pb-3'>
          <Alert key={`${code}-${index}`} severity="error">
            <span key={code}>
              The image you selected is not the correct format. Please use one of the following:
              JPG, PNG, BMP, TIFF, ICO, SVG and WebP
            </span>
          </Alert>
        </div>
      );
    case 'too-many-files':
      return (
        <div className='pb-3'>
          <Alert key={`${code}-${index}`} severity="error">
            <span key={code}>Select only one file to upload!</span>
          </Alert>
        </div>
      );
    default:
      break;
  }

  return <></>;
};

const initMetadata = {
  id: '',
  delete_token: '',
  secure_url: '',
};

type Files = { name: string; preview: string | undefined; };

const UploadImageModal: React.FC<UploadPhotoProps> = ({
  handleModal,
  handleSetPicture,
  openModal,
  type,
  projectID,
}) => {
  const [cld] = useCloudinary();
  const { mixpanelTrack } = useMixpanel();
  const [isUploaded, setIsUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [imageMeta, setImageMeta] = useState(initMetadata);
  const [image, setImage] = useState(cld.image());
  const [fileRejectionItems, setFileRejectionItems] = useState<JSX.Element[]>();
  const { header, extraText, dropzoneType, uploadFolder, uploadPreset, buttonText } = modalTypes[
    type || 'profile'
  ];

  const fetchData = (acceptedFiles: any) => {
    setLoading(true);
    if (imageMeta?.delete_token) {
      const formData = new FormData();
      formData.append('token', `${imageMeta.delete_token}`);

      axios
        .post(`${baseUrl}/delete_by_token`, `token=${imageMeta.delete_token}`)
        .then(() => {
          mixpanelTrack('Previous photo deleted.');
        });
    }

    const formData = new FormData();
    const upload: string = type === 'project' ? `${uploadFolder}${projectID}/cover` : uploadFolder;
    formData.append('file', acceptedFiles[0]);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', import.meta.env.VITE_CLOUDINARY_API_KEY || '');
    formData.append('folder', upload);

    axios
      .post(`${baseUrl}/image/upload`, formData, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
      .then((response) => {
        const {
          data: { public_id, delete_token, secure_url, ...rest },
        } = response;
        setImageMeta({ id: public_id, secure_url, delete_token });

        setImage(
          type === 'profile'
            ? cld
              .image(public_id)
              .resize(fill().width(134).height(134).gravity(focusOn(FocusOn.face())))
              .roundCorners(byRadius(100))
            : cld.image(public_id).quality('auto'),
        );
        mixpanelTrack('Photo uploaded.', { metadata: rest });
        setLoading(false);
      });
    setIsUploaded(true);
  };

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      setFileRejectionItems([]);
      setFiles(
        acceptedFiles.map((file: any) => Object.assign(file, {
          preview: URL.createObjectURL(file),
        })),
      );
      fetchData(acceptedFiles);
    }
  };

  const handleReject = (fileRejections: FileRejection[]) => {
    const errorsSet = new Set();
    fileRejections.map(({ errors }) => errors.map(({ code }) => errorsSet.add(code)));
    const filteredErrors = Array.from(errorsSet);
    setFileRejectionItems(filteredErrors.map((value, index) => handleDropzoneErrors(value, index)));
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      'image/webp': [],
      'image/bmp': [],
      'image/tiff': [],
      'image/ico': [],
      'image/svg': [],
    },
    noClick: true,
    noKeyboard: true,
    onDrop: handleDrop,
    onDropRejected: handleReject,
    maxSize: 1e+7, // 10mb max file size for Cloudinary
  });

  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: Files) => file.preview && URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  const handleAccept = () => {
    setFileRejectionItems([]);
    setImageMeta(initMetadata);
    setIsUploaded(false);
    handleSetPicture({ img: image, picturePublicId: imageMeta?.id }); handleModal();
  };

  const handleClose = () => {
    setFileRejectionItems([]);
    setImageMeta(initMetadata);
    setIsUploaded(false);
    handleModal();
  };

  const handleUploadImage = () => {
    mixpanelTrack('Open upload image modal.');
    open();
  };

  const handleChooseAnother = () => {
    mixpanelTrack('Open choose another image.');
    open();
  };

  return (
    <>
      <CustomModal open={openModal} close={handleClose} headerText={header}>
        {loading ? (
          <div className='mb-[10px] mt-2.5'>
            <CircularProgress size="200px" />
          </div>
        ) : (
          <>
            <div className='flex flex-col justify-center pt-3 pb-2'>
              <div {...getRootProps({ className: `dropzone text-center ${!isUploaded ? ' block' : 'hidden'}` })}>
                <input {...getInputProps()} placeholder="Photo" />
                {extraText && (
                  <p className='mb-1'>
                    {extraText}
                  </p>
                )}
                <p className='mb-1'>
                  Drop your image here
                </p>
                {dropzoneType}
                or
              </div>
              {imageMeta?.id && (
                <>
                  {type === 'organisation' || type === 'project' ? (
                    <>
                      {`This image will be visible to anyone who can see this ${type}.`}
                      <div className='mx-auto outline-offset-[-3px] w-[275px] h-[138px] mt-2.5 mb-7'>
                        <ImgWrapper cldImg={image} />
                      </div>
                    </>
                  ) : (
                    <ImgWrapper cldImg={image} />
                  )}
                </>
              )}
            </div>

            {fileRejectionItems}

            <div className='flex justify-center w-full gap-3'>
              {isUploaded ? (
                <>
                  <Button variant="contained" onClick={handleAccept}>
                    {buttonText}
                  </Button>
                  <Button onClick={handleChooseAnother}>
                    Choose another
                  </Button>
                </>
              ) : (
                <Button variant="contained" onClick={handleUploadImage}>
                  Upload an image
                </Button>
              )}
            </div>
          </>
        )}
      </CustomModal>
    </>
  );
};

export default UploadImageModal;

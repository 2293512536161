import { palette, typography } from '../utils';

const renderStyles = (mainColor, bgColor) => ({
  border: `1px solid ${mainColor}`,
  borderLeft: `8px solid ${mainColor}`,
  color: palette.text.primary,
  backgroundColor: bgColor,
});

export const alert = {
  root: {
    borderRadius: '8px',
    minWidth: '250px',
  },
  standardSuccess: renderStyles(palette.success.main, palette.success.light),
  standardWarning: renderStyles(palette.warning.main, palette.warning.light),
  standardError: renderStyles(palette.error.main, palette.error.light),
  icon: {
    alignItems: 'center',
    fontSize: '30px',
  },
  message: {
    fontSize: typography.subtitle1.fontSize,
  },
  action: {
    alignItems: 'flex-start',

    '& .MuiSvgIcon-root': {
      paddingRight: '0',
    },
  },
};

export const alertTitle = {
  root: {
    marginBottom: '0',
    fontSize: typography.body1.fontSize,
  },
};

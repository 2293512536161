import { ReactComponent as Info } from 'assets/icons/Info.svg';
import { TextModal, Tooltip } from 'components';
import { useMixpanel } from 'hooks';
import React from 'react';
import { TextField } from 'tailwind';

interface IAdditionalProps {
  data: { comment: string; projectName: string; };
  setComment: (arg: string) => void;
  setProjectName: (arg: string) => void;
  loading: boolean;
  mainBtnLabel: string;
  mainBtnDisabled: boolean;
  onContinue: () => void;
  onBack: () => void;
}

const AdditionalDetails = ({
  data, loading, mainBtnLabel, mainBtnDisabled, setProjectName, setComment, onContinue, onBack,
}: IAdditionalProps) => {
  const { projectName, comment } = data;
  const { mixpanelTrack } = useMixpanel();

  const isDisabled = mainBtnDisabled && !comment;

  return (
    <TextModal
      headerText='Add any additional details'
      component='div'
      closeBtnLabel='Back'
      open={true}
      loading={loading}
      setOpen={onBack}
      mainBtnLabel={mainBtnLabel}
      mainBtnHandle={onContinue}
      mainBtnDisabled={isDisabled}
      closeBtnHandle={onBack}
    >
      <section className='flex flex-col gap-1 text-left w-500'>
        <div className='flex items-center gap-0.5 relative'>
          <p className='mb-0 text-base'>Internal reference name</p>
          <span className='absolute bottom-[-5px] right-1 text-xxs'>
            (optional)
          </span>
          <Tooltip placement="bottom-start" header='Internal reference name' content='Give your request a name that is recognised internally by you and your team'>
            <div><Info className='[&_path]:fill-primary' /></div>
          </Tooltip>
        </div>

        <TextField
          type='text'
          value={projectName}
          onChange={e => setProjectName(e.target.value)}
          onBlur={(e) => mixpanelTrack('Input text in the Project name box', {
            'Total length of input text': e.target.value.length,
          })}
          onKeyDown={(e) => e.stopPropagation()}
          placeholder="Add your internal reference name"
        />

        <p className='relative text-base'>
          Additional comments
          {!mainBtnDisabled && (
            <span className='absolute bottom-[-5px] right-1 text-xxs'>
              (optional)
            </span>
          )}
        </p>

        <TextField
          type='text'
          required={isDisabled}
          value={comment}
          onChange={e => setComment(e.target.value)}
          onBlur={(e) => mixpanelTrack('Input text in the additional comments box', {
            'Total length of input text': e.target.value.length,
          })}
          error={isDisabled}
          onKeyDown={(e) => e.stopPropagation()}
          placeholder="Add any additional comments and requests here"
        />
      </section>
    </TextModal>
  );
};

export default AdditionalDetails;

import React from 'react';
import { MemberFilter, RoleFilter } from 'views/types';

interface FilterButtonProps {
  text: string;
  value?: MemberFilter | RoleFilter;
  active?: boolean;
  onClick?: (value?:  MemberFilter) => void;
}

export default function FilterButton({ text, active, onClick, value } : FilterButtonProps) {
  return (
    <button 
      className={`py-1 
      flex
      items-center
      px-2
      text-base
      h-5
      min-h-40
      ${!active ? 'bg-white' : 'bg-primary-lightest text-primary border-none font-medium' }
      border
    border-primary-lightest
      rounded-sm
      w-fit
      min-w-fit
    `}
    onClick={() => onClick?.(value)}
    >
      {text}
    </button>
  );
}
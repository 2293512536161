export const avatar = {
  root: {
    '&.large': {
      width: '194px',
      height: '194px',
    },
  },
};

export default avatar;

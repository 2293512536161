import axios from 'axios';
import { useHandleError, useMixpanel } from 'hooks';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { generateDownloadWindow } from 'utils/helpers';

import { Progress } from '../QuickActions.style';

let percentCompleted: number = 0;

const useDownloadFile = ({
  signedUrl,
  name,
  mimeType,
}: {
  signedUrl: string | undefined;
  name: string;
  mimeType?: string;
}) => {
  let key: SnackbarKey;
  const ref = useRef<HTMLSpanElement>(null);

  const { closeSnackbar } = useSnackbar();
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();

  const downloadFile = () => {
    key = enqueueSnackbar('Downloading in progress', {
      variant: 'info',
      persist: true,
      action: () => <Progress ref={ref} />,
    });
    axios
      .get(signedUrl || '', {
        onDownloadProgress: (progressEvent) => {
          if (!progressEvent.total) return null;

          percentCompleted = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100,
          );
          if (ref.current) {
            ref.current.innerHTML = `${percentCompleted}%`;
          }
        },
        responseType: 'blob',
      })
      .then(({ data }) => {
        closeSnackbar(key);
        mixpanelTrack('Download Successful');

        generateDownloadWindow(window.URL.createObjectURL(data), name, mimeType);
      })
      .catch((error) => handleError(error));
  };
  return { downloadFile };
};

export default useDownloadFile;

import React, { memo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { LocationState } from 'views/types';

import FilePreview from './Preview';

const PreviewRoute = memo((
  { setLoading }: { setLoading?: React.Dispatch<React.SetStateAction<boolean>> },
) => {
  const { fileId } = useParams<{ fileId: string; }>();
  const { pathname, hash } = useLocation<LocationState>();
  const history = useHistory();

  const previousPath = pathname.slice(0, pathname.lastIndexOf('/drive/') + 6);

  const returnPath = () => history.push(previousPath);

  return <FilePreview
    fileId={fileId}
    commentId={hash.substring(1)}
    onClose={returnPath}
    setLoading={setLoading}
  />;
});

export default PreviewRoute;

import { createStyles, Switch, Theme, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import { SelectVariant } from 'views/types';

const SelectWrap = styled.div<{ isOpen: boolean; variant?: SelectVariant; }>`
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
`;

export const OptionList = styled.div<{
  isOpen: boolean;
  variant?: 'outlined';
  nearBottom?: boolean,
  width?: number;
}>`
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  max-height: 215px;
  width: 272px;
  border: 1px solid #a6afb9;
  border-radius: ${({ nearBottom }) => (nearBottom ? '8px 8px 0px 0px' : '0px 0px 8px 8px')};
  display: flex;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  margin: 0px;
  padding: 0px;
  overflow: hidden auto;
  outline: none;
  background: #fff;
  z-index: 100000000;
  box-sizing: border-box;

  ${({ variant }) => variant === 'outlined'
    && `
      top: 100%;
      left: -1px;
  `}

  ${({ nearBottom, variant }) => nearBottom
    && `
    transform: translateY(-100%) !important;
    margin-top: ${variant === 'outlined' ? '-35px' : '-30px'}
  `}
`;

export const OptionItem = styled.div<{ assigned?: boolean; }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: center;
  padding: 12px 16px;
  background: #FFFFFF;
  border-bottom: 1px solid #A6AFB9;
  cursor: pointer;
  box-sizing: border-box;
  color: #333333;

  :hover {
    background: #E6EDEF;
  }

  :last-of-type {
    border-bottom: none;
  }

  span {
    margin: 0px !important; //overrides scope creep from MUI
  }

  ${({ assigned }) => assigned && `
    background: #E6EDEF;

    .__item-header {
      color: #375675;
      font-weight: 500;
    }
  `};
`;

export const OptionItemHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
`;

export const ToggleSwitch = withStyles((theme: Theme) => createStyles({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: 'none',
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#A6AFB9',
  },
  checked: {},
}))(Switch);

export default SelectWrap;

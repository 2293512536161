import React from 'react';
import Typography from '@material-ui/core/Typography';
import { CircularProgressWithLabel } from 'components/UI';
import { SpinnerBox } from '../Viewer3D.style';

const Downloading = ({ loading }: { loading: number }): JSX.Element => (
  <SpinnerBox>
    <Typography variant="body2">Downloading...</Typography>
    <CircularProgressWithLabel value={loading} />
  </SpinnerBox>
);
export default Downloading;

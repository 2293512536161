import { gql } from '@apollo/client';

const CREATE_FOLDER = gql`
  mutation CreateFolder($folder: NodeInput, $name: String!, $projectId: ID) {
    createFolder(folder: $folder, name: $name, projectId: $projectId) {
      id
    }
  }
`;

export default CREATE_FOLDER;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { BrokenImageIcon } from '@vucity/design_system';
import { SpinnerBox } from '../Viewer3D.style';

const Error = ({ text = 'Rendering 3D asset failed' }): JSX.Element => (
  <SpinnerBox>
    <Box maxWidth="408px" textAlign="center">
      <BrokenImageIcon htmlColor='#5C5C5C' />
      <Typography variant="body2">{text}</Typography>
    </Box>
  </SpinnerBox>
);

export default Error;

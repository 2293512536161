import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useSize } from 'ahooks';
import { useMixpanel } from 'hooks';
import React, { useEffect } from 'react';
import Mixpanel from 'utils/mixpanel';
import MobileFallback from './Fallback';

const MobileWarning = ({ children }: { children: JSX.Element; }) => {
  const { mixpanelTrack } = useMixpanel();
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user?.sub) {
      Mixpanel.identify(user.sub);
      Mixpanel.people.set({
        $name: user.name,
        $email: user.email,
        $avatar: user.picture,
        'Email Verified': user.email_verified,
      });
      mixpanelTrack('Signed In');
      Sentry.setUser({ id: user.sub });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const size = useSize(window.document.body);

  if (size && size.width && size.width < 800) return <MobileFallback />;

  return children;
};

export default MobileWarning;

import { Backdrop, Menu } from '@material-ui/core';
import React from 'react';

export interface IContextMenu {
  mousePosition: {
    mouseX: number | null;
    mouseY: number | null;
  };
  onClose: () => void;
  children: React.ReactNode;
}

const ContextMenu: React.FC<IContextMenu> = ({ mousePosition, onClose, children }) => {
  const disableBrowserContextMenu = (e: React.MouseEvent<HTMLElement>) => e.preventDefault();

  return (
    <>
      <Menu
        className="drive_context_menu"
        keepMounted
        open={mousePosition?.mouseY !== null}
        onClose={onClose}
        style={{ position: 'unset', zIndex: 2 }}
        onContextMenu={disableBrowserContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          mousePosition.mouseY !== null && mousePosition.mouseX !== null
            ? { top: mousePosition.mouseY, left: mousePosition.mouseX }
            : undefined
        }
      >
        {children}
      </Menu>
      <Backdrop
        open={!!mousePosition.mouseX}
        style={{ zIndex: 0, backgroundColor: 'transparent' }}
        onClick={onClose}
        onContextMenu={(event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          onClose();
        }}>
      </Backdrop>
    </>
  );
};

export default ContextMenu;

import { Avatar } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCurrentUser, useMixpanel, useQueryParam } from 'hooks';

import { AccountLayout, Button, Row } from 'tailwind';
import EditProfile from './edit/EditProfile';

const Profile: React.FC = () => {
  const history = useHistory();
  const query = useQueryParam();
  const { mixpanelTrack } = useMixpanel();

  const { currentUser } = useCurrentUser();

  if (!currentUser) return <></>;

  if (query.get('mode') === 'edit') {
    return (
      <EditProfile
        user={{
          avatar: currentUser.picture,
          data: currentUser,
        }}
      />
    );
  }

  return (
    <AccountLayout title='My Profile'>
      <div className='w-[104px] h-[104px] mb-3'>
        <Avatar
          className='w-full h-full'
          src={currentUser.picture}
          alt={`${currentUser.name} profile`}
        />
      </div>

      <Row column1="Name">{currentUser.name}</Row>
      <Row column1="Email address">{currentUser.email}</Row>
      <Row column1="Password">*********</Row>
      <Row column1="Job title">{currentUser.jobTitle}</Row>

      <div className='flex justify-end flex-grow'>
        <Button
          onClick={() => {
            mixpanelTrack('Edit Profile');
            history.push('/account/profile?mode=edit');
          }}
        >
          Edit Profile
        </Button>
      </div>
    </AccountLayout>
  );
};

export default Profile;

import { ProjectCard } from 'components';
import React from 'react';
import { AddProjectBtn } from 'tailwind';
import { Project, UserTier } from 'types/graphql';
import NoProjects from './NoProjects/NoProjects';

interface GridViewProps {
  projects: Project[];
  tier: UserTier;
  refetch: () => any;
  setVisiblityPreference: (preference: string) => void;
  view: string;
  visiblityPreference: string;
}

const GridView = ({ projects, tier, ...rest }: GridViewProps) => (
  <div className='grid gap-2 grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))]'>
    <AddProjectBtn tier={tier} />

    {projects?.length === 0
      ? <NoProjects />
      : projects?.map((project) => (
        <ProjectCard 
          key={project.id} 
          project={project} 
          tier={tier} 
          {...rest}
        />
      ))
    }
  </div>
);

export default GridView;

import { gql } from '@apollo/client';

export const GET_NODE_SIGNED_URL = gql`
  query getNodeSignedUrl($fileId: ID!) {
    currentUser {
      id
      drive {
        nodeByID(id: $fileId) {
          ...on File {
            signedUrl
            name
            mimeType
          }
        }
      }
    }
  }
`;

import { useMutation, useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { PersonOutlineRounded } from '@material-ui/icons';
import { IFileRow } from 'components/Drive/types';
import GQLWrapper from 'components/GQLWrapper/GQLWrapper';
import { useHandleError, useMixpanel, useProjectMembers } from 'hooks';
import { GET_ORG_MEMBERS } from 'hooks/useCreateProjectMembers/apollo';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Label, SelectWithFilter } from 'tailwind';
import MemberChip from 'tailwind/SelectWithFilter/SelectedMember';
import { OrgMember, ProjectMember, ProjectMemberRole } from 'types/graphql';
import { capitalizeWord, parseGraphqlError } from 'utils/helpers';
import { ADMIN_ROLE } from 'views/organisation/members/constants';
import { MemberFilter, SelectWithInputItem } from 'views/types';
import { UPDATE_NODES_PERMISSIONS } from '../apollo';
import OptionBox from './OptionBox';
import OptionHeader from './OptionHeader';
import QuickFilters from './QuickFilters';
import UpdateAccessModal from './UpdateAccessModal';
interface UpdateAccessProp {
  role: ProjectMemberRole;
  selectedFiles: IFileRow[];
  projectId?: string;
  closeContextMenu: () => void;
}

export const mapMembers = (member: OrgMember | ProjectMember) => ({
  displayText: member.profile?.name,
  value: member.id,
  tag: capitalizeWord(member.role),
  imgUrl: member.profile?.picture,
  extra: member.profile?.id,
} as SelectWithInputItem);

const UpdateAccess: React.FC<UpdateAccessProp> = ({ role, selectedFiles,
  projectId, closeContextMenu }) => {
  const [openModal, setOpenModal] = useState(false);
  const [,enqueueSnackbar] = useHandleError();
  const [loading, setLoading] = useState(true);
  const [currentFilters, setCurrentFilter] = useState<MemberFilter>();
  const [roleFilters, setRoleFilters] = useState(new Set<MemberFilter>());
  const [members] = useProjectMembers();
  const { data, loading: fetching } = useQuery(GET_ORG_MEMBERS);
  const [selectedMembers, setSelectedMembers] = useState<SelectWithInputItem[]>([]);
  const [filterResult, setFilterResult] = useState<Array<SelectWithInputItem>>();
  const { mixpanelTrack } = useMixpanel();
  const [updateNodesPermissions, { loading: updating }] = useMutation(UPDATE_NODES_PERMISSIONS);

  const selectedList = useMemo(() => selectedFiles.map(({ whitelist }) => whitelist), [selectedFiles]);
  const orgMembers = useMemo(() => data?.currentUser?.organisation?.members || [], [data]);
  
  const hasOverlappingPermissions = useMemo(() => {
    const selected = selectedList.map(w => JSON.stringify(w));
    if (selected.length === 1) return false;
    return selected.filter(s => s === selected[0]).length !== selected.length;
  }, [selectedList]);
  
  const whitelist = useMemo(() => {
    return hasOverlappingPermissions ? [] : _.union(...selectedList);
  }, [hasOverlappingPermissions, selectedList]);

  const options: SelectWithInputItem[] = useMemo(() => {
    return members?.map((member: ProjectMember) => ({
      ...mapMembers(member),
      sameOrg: !!orgMembers.find((orgMember) => orgMember.profile?.id === member.profile?.id),
    })) || [];
  }, [members, orgMembers]);

  useEffect(() => {
    if (!hasOverlappingPermissions && members.length > 0) {
      setSelectedMembers(options.filter((m) => whitelist.some(s => s === m.extra)));
    }
    if ((members.length > 0 && hasOverlappingPermissions) || (whitelist.length === 0)) {
      setSelectedMembers(options);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members.length, hasOverlappingPermissions, orgMembers]);

  useEffect(() => {
    if (whitelist.length === 0) {
      setCurrentFilter('All');
    }
  }, [whitelist]);

  const handleMenuItemClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setOpenModal(true);
    mixpanelTrack('Clicked Update access on specific assets from context menu', {
      'member role': role,
      'Number of files': selectedFiles.length,
      'File IDs': selectedFiles.map(({ id }) => id),
    });
  };

  const logMemberDetails = (key: string, option: SelectWithInputItem) => {
    mixpanelTrack(key, {
      'Member Name': option.displayText,
      'Member UID': option.value,
    });
  };

  const getMemberLabel = useCallback((userId: string) => {
    return selectedFiles.find((file) => file.creatorId === userId) && 'Creator';
  }, [selectedFiles]);

  const handleOnItemClick = (option: SelectWithInputItem) => {
    const keys = Array.from(roleFilters.keys());
    const _selected = [...selectedMembers, option];
    const filterOptions = _selected.filter((item) => keys.some(val => item.tag === val.substring(0, val.length - 1)));
    logMemberDetails('Added a member to the asset’s access list', option);
    setSelectedMembers(_selected);
    setFilterResult(filterOptions);

  };

  const handleFilter = (val: string, option: SelectWithInputItem) => {
    mixpanelTrack('Searched for project members', {
      'Search character count': val.length,
    });
    const { value, displayText } = option;
    if (selectedMembers.some((m) => m.value === value)) return;
    return displayText?.toLowerCase().includes(val);
  };

  const handleOnRemove = (index: number, option: SelectWithInputItem) => {
    logMemberDetails('Removed a member from the asset’s access list', option);
    const arr = [...selectedMembers];
    
    if (roleFilters.size > 0) arr.splice(arr.findIndex(o => o.extra === option.extra), 1);
    else arr.splice(index, 1);

    filterResult?.splice(index, 1);
    setSelectedMembers(arr);
    setFilterResult(filterResult);
  };

  const handleReturnToDrive = () => {
    mixpanelTrack('Clicked return to drive');
    setOpenModal(false);
    closeContextMenu();
  };

  const showRemoveButton = (item: SelectWithInputItem) => {
    if (getMemberLabel(item.extra)) return false;
    return item.tag?.toUpperCase() !== ADMIN_ROLE;
  };

  const handleUpdateAccess = () => {
    mixpanelTrack('Updated asset access', {
      'No of files selected': selectedFiles.length,
      'Project member role': role,
      'Selected Files': selectedFiles.map((f) => f.id),
      'UserIDs': selectedMembers.map(m => m.extra),
    });
    updateNodesPermissions({
      variables: {
        nodes: selectedFiles.map(file => ({ 
          id: file.id,
          whitelist: selectedMembers.map(m => m.extra),
        })),
        projectId,
      },
      onCompleted: () => {
        enqueueSnackbar(`Member access to ${selectedFiles.length} project asset have been updated`, {
          variant: 'success',
        });
        closeContextMenu();
      },
      onError: (error) => {
        const [, message] = parseGraphqlError(error.graphQLErrors);
        enqueueSnackbar(message || 'Request payload too large', {
          variant: 'error',
        });
      },
    });
  };

  const renderResults = (selecetd: SelectWithInputItem []) => {
    return (selecetd.map((item, index) => (
      <MemberChip
        key={index}
        index={index}
        showBorder
        onRemove={showRemoveButton(item) ? handleOnRemove : undefined}
        label={getMemberLabel(item.extra)}
        {...item}
      />
    )));
  };

  return (
    <>
      <MenuItem
        onClick={handleMenuItemClick}
      >
        Update access
      </MenuItem>
      <hr className='my-1 border-grey-border' />
      <UpdateAccessModal
        hasOverlappingPermissions={hasOverlappingPermissions}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <GQLWrapper loading={loading || fetching || updating}>
          <div className='flex w-full gap-2'>
            <QuickFilters
              currentFilters={currentFilters}
              setMemberFilters={setCurrentFilter}
              options={selectedMembers}
              roleFilters={roleFilters}
              setRoleFilters={setRoleFilters}
              setFilterResult={setFilterResult}
            />
            <OptionBox>
              <OptionHeader
                icon={<PersonOutlineRounded />}
                title="Add specific members"
                label={selectedMembers.length > 0 ? <Label>
                  <div className='flex gap-0.25'>
                    <b>{selectedMembers.length}&nbsp;</b>
                    {selectedMembers.length > 1 ? 'members' : 'member'}
                  </div>
                </Label> : undefined}
              />
              <div className='relative w-full'>
                <SelectWithFilter
                  options={options || []}
                  onItemClick={handleOnItemClick}
                  filterOptions={handleFilter}
                  placeholder="Search project members"
                  onKeyDown={(e) => e.stopPropagation()}
                />
              </div>
              <div className='w-full p-1 flex flex-col gap-0.5 max-h-full overflow-y-auto'>
                {renderResults(filterResult || selectedMembers)}
              </div>
            </OptionBox>
          </div>
          <div className='flex justify-center gap-1 mt-auto'>
            <Button 
              disabled={updating} 
                className='bg-primary' 
                onClick={handleUpdateAccess}
                variant="contained" 
                color="primary"
              >
              Update asset access
            </Button>
            <Button color="primary" onClick={handleReturnToDrive}>
              Return to drive
            </Button>
          </div>
        </GQLWrapper>
      </UpdateAccessModal>
    </>
  );
};


export default UpdateAccess;

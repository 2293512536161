import { useQueryParam } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from 'tailwind';
import { File, Folder, User } from 'types/graphql';
import { checkUpdatedFolder, generateFilePath } from 'utils/helpers';
import SelectedFilesLabel from 'views/createFromDrive/CreateDrive/SelectedFilesLabel';
import { VIEWER_ROLE } from 'views/organisation/members/constants';
import { IDriveFilesData } from 'views/types';
import { DriveBreadcrumbs, FolderTree, ProgressBar, Upload } from '.';
import { DriveGrid } from './Drive.style';
import DriveFiles from './DriveFiles/DriveFiles';
import { DriveSelectedFilesContext } from './DriveProvider/DriveProvider';
import Search from './Search/Search';
import SearchResults from './Search/SearchResults';
import { IFileRow, IRefetchData, IRefetchQuery } from './types';

interface IDriveProps {
  data: any;
  refetchQueryAfterUpdate: IRefetchQuery;
  driveType: 'personal' | 'project';
  refetch: () => Promise<IRefetchData>;
  user?: User;
  onTransfer?: (files: IFileRow[] | Folder[]) => void;
  onCreation?: { location: string; hasMultipleLocations: string[]; };
}

const Drive: React.FC<IDriveProps> = ({
  data, user, driveType, onTransfer, onCreation, refetchQueryAfterUpdate, refetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const fileId = useQueryParam().get('id');

  const { selectedFiles, setSelectedFiles } = useContext(DriveSelectedFilesContext);

  const [treeData, setTreeData] = useState<{ folders: Folder[]; }>({
    folders: [],
  });
  const [drive, setDrive] = useState<IDriveFilesData>({
    files: [],
    folders: [],
  });

  const [selected, setSelected] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);

  const [selectedFolder, setSelectedFolder] = useState<Folder>();
  const [breadcrumbs, setBreadcrumbs] = useState<any>([]);

  // State to update selected folder after an update
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [updatedFolder, setUpdatedFolder] = useState<Folder>();

  // search
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(true);
  const [searched, setSearched] = useState(false);

  const onFolderClick = (f: Folder, skipReset: boolean = true) => {
    setSelected([`${f.id}`]);
    setSelectedFolder(f);
    setDrive({
      files: f?.files ? f?.files?.map((file: File) => file) : [],
      folders: f?.folders ? f?.folders.map((folder: Folder) => folder) : [],
    });

    if (!skipReset && selectedFiles.length > 0) {
      setSelectedFiles([]);
    }
  };

  const initFolders = useCallback((folder: Folder) => {
    onFolderClick(folder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBreadcrumbs = useCallback(() => {
    const selectedPath = selectedFolder?.path?.split('/') || [];
    const paths = treeData?.folders.filter((folder: Folder) => folder.path === `/${selectedPath[0]}`) || [];

    const { newPath, finalNodes } = generateFilePath(paths, { p: [], e: [] }, selectedFolder?.path);

    setBreadcrumbs(newPath);
    setExpanded((e) => ([...e, ...finalNodes]));
  }, [selectedFolder, treeData]);

  const recurseFindFolder = useCallback((f: Folder, id: string | string[]) => {
    // Search through tree and find file path
    if (f.folders?.length > 0) {
      f.folders.forEach((folder) => {
        recurseFindFolder(folder, id);
      });
    }

    const files = f.files.filter((file: File) => file.id === id);
    const folders = f.folders?.filter((folder: Folder) => folder.id === id) || [];
    const result = folders[0] || files[0];
    if (!result) return;
    const findFilePath = result.__typename === 'Folder' ? result.path : result.path.split('/').slice(1, -1).join('/');
    if (!findFilePath) return;

    // Get folder path from file and handle breacrumbs
    const { newPath, finalNodes } = generateFilePath(
      [f],
      {
        p: [{ name: f.name, id: f.id, folder: { ...f } }],
        e: [{ id: f.id, folders: f.folders }],
      },
      `/${findFilePath}`,
    );

    const findFolderPath: Folder = folders[0] ? folders[0] : newPath[newPath.length - 1]?.folder;

    setBreadcrumbs(newPath);
    setExpanded((e) => ([...e, ...finalNodes]));
    if (findFolderPath) { initFolders(findFolderPath); }
  }, [initFolders]);

  useEffect(() => {
    if (data?.drive?.nodeByPath) {
      setTreeData({ folders: [data.drive.nodeByPath] });

      if (selectedFolder?.id) {
        const initialFolder = { ...data.drive.nodeByPath, folders: [data.drive.nodeByPath], files: [] };
        recurseFindFolder(initialFolder, selectedFolder?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    handleBreadcrumbs();
    setUpdatedFolder(selectedFolder);
  }, [selectedFolder, handleBreadcrumbs]);

  useEffect(() => {
    if (!selectedFolder?.id && treeData && treeData.folders[0]) {
      initFolders(treeData.folders[0]);
    }
  }, [treeData, initFolders, selectedFolder]);

  const onRefreshHandle = useCallback(() => {
    setLoading(true);
    refetch().then((res) => {
      if (!selectedFolder) return;
      const { nodeByPath } = res.data.project ? res.data.project.drive : res.data.currentUser.drive;
      const newFolder = checkUpdatedFolder({ folders: [nodeByPath] }, selectedFolder.id);

      if (newFolder) {
        onFolderClick(newFolder);
      } else {
        onFolderClick(nodeByPath as unknown as Folder);
        enqueueSnackbar('This folder no longer exists', { variant: 'error' });
      }
      setLoading(false);
    });
  }, [enqueueSnackbar, refetch, selectedFolder]);

  useEffect(() => {
    if (selectedFolder?.id && updatedFolder?.id && isUpdated) {
      setIsUpdated(false);
      onRefreshHandle();
    }
  }, [treeData, onRefreshHandle, updatedFolder, isUpdated, selectedFolder?.id]);

  useEffect(() => {
    if (onCreation && selectedFiles.length > 0) {
      const findParentId = selectedFiles[0].parentId;
      if (!findParentId) return;

      recurseFindFolder(data.drive.nodeByPath, findParentId);
    }
  }, []);

  useEffect(() => {
    if (fileId) recurseFindFolder(data.drive.nodeByPath, fileId);
  }, []);

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => setExpanded(nodeIds);
  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => setSelected(nodeIds);

  const renderRootFolderName = () => {
    if (driveType === 'personal') return 'Personal drive';
    return 'Project drive';
  };

  const toUpdateProps = { isUpdated, setIsUpdated, refetchQueryAfterUpdate };

  if (!data?.drive || !selectedFolder) return null;

  return (
    <>
      {!onTransfer && (
        <div className='flex flex-wrap items-start justify-between mb-1'>
          <Search
            setDrive={setDrive}
            data={[data.drive.nodeByPath, selectedFolder]}
            driveType={renderRootFolderName()}
            setSearch={[setLoading, setSearchResults, setSearched]}
          />
          {onCreation && <SelectedFilesLabel files={selectedFiles} erroredFiles={onCreation.hasMultipleLocations || []} projectId={data?.id} />}
          {!onCreation
            && <ProgressBar total={data.drive.info.total} used={data.drive.info.used} />
          }
        </div>
      )}

      <div className='bg-white border rounded-project border-placeholder'>
        <div className='flex w-full overflow-hidden'>
          {!onCreation && (
            <div className='relative overflow-x-hidden flex-shrink-0 max-w-[60vw] min-w-[160px] w-[280px] border-b border-b-placeholder'>
              {!onTransfer && (
                <Upload
                  projectId={driveType === 'project' ? data?.id : undefined}
                  currentFolder={selectedFolder}
                  type={driveType}
                  driveSize={[data.drive.info.used, data.drive.info.total]}
                  onRefreshHandle={onRefreshHandle}
                  {...toUpdateProps}
                />
              )}
            </div>
          )}
          <div className='border-b border-b-placeholder w-full min-h-[55px]'>
            <div className='flex items-center justify-between h-full px-2 py-1'>
              <DriveBreadcrumbs
                paths={breadcrumbs}
                rootFolder={renderRootFolderName()}
                onFolderClick={(f) => onFolderClick(f, false)}
              />

              {onTransfer && (
                <Button
                  variant="contained"
                  disabled={selectedFiles.length === 0 && selectedFolder.path === '/'}
                  onClick={() => {
                    if (selectedFiles.length !== 0) { return onTransfer(selectedFiles); }

                    return onTransfer([selectedFolder]);
                  }}
                >
                  Upload to project
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className='flex w-full overflow-hidden'>
          <div className='relative overflow-x-hidden flex-shrink-0 max-w-[60vw] min-w-[160px] w-[280px] border-r border-r-placeholder' id='resizable'>
            <FolderTree
              treeData={treeData?.folders}
              rootFolder={renderRootFolderName()}
              folderControls={{ selected, expanded, handleToggle, handleSelect }}
              onFolderClick={(f) => onFolderClick(f, false)}
              driveType={driveType}
              projectId={driveType === 'project' ? data?.id : undefined}
              exclude={driveType === 'project' ? data?.me?.role === VIEWER_ROLE : false}
            />
          </div>
          <DriveGrid>
            <SearchResults searchLoading={loading} searchResults={searchResults}>
              <DriveFiles
                data={{ files: drive.files, folders: drive.folders }}
                project={{
                  id: data?.id,
                  location: data?.location?.license?.name,
                  isPublic: data?.location?.license?.public,
                }}
                onFolderClick={onFolderClick}
                currentFolder={selectedFolder}
                driveType={driveType}
                role={data?.me?.role}
                useSearch={[searched, setSearched]}
                user={user}
                onTransfer={onTransfer}
                onCreation={onCreation}
                {...toUpdateProps}
              />
            </SearchResults>
          </DriveGrid>
        </div>
      </div>
    </>
  );
};

export default Drive;

import copy from 'copy';

const useLimitedProjectAccess = () => {
  const { project: { roles: { ADMIN: admin }, status: { LOCKED, INVITED, LIMITED } } } = copy;
  const ADMIN = admin.toUpperCase();

  const checkStatus = (id: string, role?: string, status?: string) => {
    const limitedUrl = `/project/${id}/members?mode=invite`;
    const isLimited = status === LOCKED && role === ADMIN;

    return { limitedUrl, isLimited };
  };

  return { ADMIN, LOCKED, INVITED, LIMITED, checkStatus };
};

export default useLimitedProjectAccess;

import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import { GET_ORG_FLOATING } from './apollo';

const useFloating = () => {
  const [getFloating, { data, called }] = useLazyQuery(GET_ORG_FLOATING, { fetchPolicy: 'cache-first', nextFetchPolicy: 'cache-only' });

  useEffect(() => {
    if (!called) getFloating();
  }, [called, getFloating]);

  const floatingTotal = useMemo(() =>
    data?.currentUser?.organisation?.seatStats?.floating?.total || 0, [data]);

  const defaultTier = useCallback((initTier?: string) => {
    const tier = initTier?.toLowerCase();
    const isPro = tier === 'pro';

    if (!floatingTotal) return initTier || 'Starter';
    if (isPro) return 'Pro';
    return 'Floating';
  }, [floatingTotal]);

  return {
    hasFloating: floatingTotal > 0 || false,
    floatingTotal,
    defaultTier,
  };
};

export default useFloating;
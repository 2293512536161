import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreIcon } from '@vucity/design_system';
import theme from 'theme/theme';
import { useMixpanel } from 'hooks';
import { IfPermissions } from 'components';
import { useAuth0 } from '@auth0/auth0-react';

const ActionsMenu: React.FC<{
  creator: string,
  eventId:string,
  setAction: React.Dispatch<string>,
  setLoading: React.Dispatch<boolean>,
  setCurrentId: React.Dispatch<string>,
}> = ({ creator, eventId, setAction, setLoading, setCurrentId }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { mixpanelTrack } = useMixpanel();
  const { user } = useAuth0();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAction('');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action: string) => {
    mixpanelTrack(`Actions menu ${action === '' ? 'close' : action}`);
    setCurrentId(eventId);
    setAction(action);
    setLoading(true);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="More" aria-haspopup="true" onClick={handleClick}>
        <MoreIcon htmlColor={theme.palette.text.primary} style={{ width: '16px', height: '16px' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose('')}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        style={{ fontSize: '16px' }}
      >
        {user?.sub === creator
        && <MenuItem onClick={() => handleClose('edit')}>
          Edit comment
        </MenuItem>
        }
        <IfPermissions scopes={['PROJECT_COMMENT_DELETE']} skipCheck={user?.sub === creator} key='delete'>
          <MenuItem onClick={() => handleClose('delete')}>
            Delete comment
          </MenuItem>
        </IfPermissions>

        <MenuItem key='copy' onClick={() => handleClose('copy')}>
          Copy link to comment
        </MenuItem>
      </Menu>
    </>
  );
};

export default ActionsMenu;

import { ApolloError } from '@apollo/client';
import axios from 'axios';
import { format, formatDistanceStrict, formatDistanceToNowStrict, formatRelative, parseISO } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { GraphQLError } from 'graphql';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

import { IFilesProps } from 'components/Drive/Upload/types';
import { IFileRow, IRecurseNodes, IRecursePaths } from 'components/Drive/types';
import copy from 'copy';
import { ActivityEvent, GQL } from 'types';
import { BillingInterval, Folder, License, OrgLicense, ProjectMemberRole, UserSiteLicense } from 'types/graphql';
import { mapboxCrendetials } from './configs';
import countries from './configs/countries';

export const bytesToSize = (bytes: number, dp: number = 2) => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 B';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / (1024 ** i)).toFixed(dp)} ${sizes[i]}`;
};

export function hasChildren(item: any) {
  const { items: children } = item;

  if (children === undefined) return false;
  if (children.constructor !== Array) return false;
  if (children.length === 0) return false;

  return true;
}

export const checkUpdatedFolder = (root: any, val: string) => {
  const stack = []; let node; let ii;
  stack.push(root);

  while (stack.length > 0) {
    node = stack.pop();
    if (node.id === val) return node;
    if (node.folders && node.folders.length) {
      for (ii = 0; ii < node.folders.length; ii += 1) {
        stack.push(node.folders[ii]);
      }
    }
  }

  return null;
};

export const capitalizeWord = (w: string | undefined) => (
  w ? w?.charAt(0).toUpperCase() + w?.slice(1).toLowerCase() : ''
);

export const getNameFromEmail = (email: String): string => {
  let name = email.split('@').slice(0, -1)[0];
  name = name?.replaceAll('.', '_');
  const nameArr = name?.split('_').map((el) => capitalizeWord(el));
  return nameArr?.join(' ') || capitalizeWord(name);
};

export const nameSort = (a: string, b: string) => a?.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
export const sortByDate = (a: { createdAt: string; }, b: { createdAt: string; }) => (
  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
);

export const sortLicences = (licences: License[]) => licences
  .slice()
  .sort((a, b) => nameSort(a.name, b.name));

export const sortSiteLicences = (siteLicences: UserSiteLicense[]) => siteLicences
  .slice()
  .sort();

export const lowerCaseFilter = (
  a: string, b: string,
) => a.toLowerCase().indexOf(b.toLowerCase()) >= 0;

export const getAddress = async (coords: { lng: number; lat: number; }, types = 'address') => {
  const { data } = await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${coords.lng},${coords.lat}.json?access_token=${mapboxCrendetials}&types=${types}`,
  );

  return data.features.length > 0
    ? data.features[0].place_name
    : 'Address not available';
};

export const getInitials = (name: string) => (name.indexOf(' ') > -1
  ? name.split(' ').map((item: string) => item[0]).join('')
  : name[0]);

export const getCompactName = (name: string) => {
  if (!name) return '';
  const str = name.replace(/\s\s+/g, ' ');
  return name.indexOf(' ') > -1 ? `${str.split(' ')[0]} ${str.split(' ')[1][0]}.` : name;
};

export const splitName = (name: string) => {
  const trim = (str: string) => str.trim();

  const index = trim(name).lastIndexOf(' ');
  return [trim(name.slice(0, index)), trim(name.slice(index + 1))];
};

// TODO: Will need to be revisited
export const bodyHeight = 'calc(100vh - 80px)';
export const bodyH = 'h-[calc(100vh-80px)]';

export const checkUserLicences = (projectLocation: string, user: GQL.User) => {
  if (user?.cityLicenses && user.tier.name !== 'Starter') {
    const hasLicense = user?.cityLicenses
      .filter(({ license, status }: OrgLicense) => (status === 'active' || status === 'trialing') && license.name === projectLocation && license.public)[0];

    const siteLicenceLocation = projectLocation.replace(/(Quarter|Licence|Test|Sandbox)/gi, '').trim();
    const hasSiteLicense = user?.siteLicenses?.filter(({ name }) =>
      name?.includes(siteLicenceLocation)
      || name?.includes(siteLicenceLocation.replace(/\s+/g, ''))
      || name?.includes(siteLicenceLocation.replace(/\(.*?\)/g, '').trim()),
    )[0];

    return hasLicense || hasSiteLicense;
  }

  return null;
};

export const isLogo = (url: string) => url.includes('https://');

// === GQL ERRORS ===

export const parseGraphqlError = (graphQLErrors: readonly GraphQLError[]): [string, string] => {
  const message: string = get(graphQLErrors, [0, 'message']);
  const code: any = get(graphQLErrors, [0, 'extensions', 'HUB_CODE']);
  return [code, message];
};

export const getApolloErrorData = (error: ApolloError | undefined, initializeData: any) => get(
  error,
  ['graphQLErrors', 0, 'extensions'],
  initializeData,
);

// === File utils ===

export const getExt = (str: string) => {
  if (!str) return '';
  const list = str.toLowerCase().split('.');
  return list[list.length - 1];
};

export const generateDownloadWindow = (url: string, name: string, mimeType?: string) => {
  const fileName = mimeType === 'vucity/project' && getExt(name) !== 'vu' ? `${name}.vu` : name;
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(url);
};

// === Comments ===

export const urlOrEmailRegex = /(\b(?:http|https):\/\/)?([^\s@:/,?']+@)?([-a-zA-Zа-яА-ЯЁё\u00C0-\u017F0-9.]{2,}\.[a-zа-яА-ЯЁё\u00C0-\u017F]{2,})\b(?:\/[-a-zA-Zа-яА-ЯЁё\u00C0-\u017F0-9@:%_+.~#?&e//=]*)?/gi;
export const mentionRegex = /@@\{(.{10}\}\|\{[\w\s\d+.@а-яА-ЯЁё\u00C0-\u017F>()!#&$\\/"'`,_-]*)\}##/g;
export const getUniqueMentionIds = (message: string) => {
  const match = message.match(mentionRegex);
  if (!match) return [];
  const userIds: string[] = match.map((id) => id.slice(3, -3).split('}|{')[0]);
  return [...(Array.from(new Set(userIds)))];
};

// === DATE RELATIVE FORMATTING ===

export const formatRelativeDate = (date: string) => {
  const formatRelativeLocale: { [key: string]: string; } = {
    lastWeek: "dd MMMM yyyy 'at' HH:mm",
    yesterday: "'Yesterday at' p",
    today: "'Today at' p",
    tomorrow: "'Tomorrow at' p",
    nextWeek: "eeee 'at' p",
    other: "dd MMMM yyyy 'at' HH:mm",
  };

  const locale = Object.assign(enGB, {
    formatRelative: (token: string) => formatRelativeLocale[token],
  });

  return formatRelative(new Date(date), new Date(), { locale });
};

export const formatTime = (date: string) => format(new Date(date), 'HH:mm');

export const formatRelativeToNow = (date: string) => {
  const relativeTime = formatDistanceToNowStrict(parseISO(date));
  if (relativeTime.indexOf('seconds') !== -1) return 'Just now';
  return `${relativeTime} ago`;
};

// Get country flag code
export const countryToFlag = (isoCode: string) => (typeof String.fromCodePoint !== 'undefined'
  ? isoCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
  : isoCode);

// Get country from countries array
export function getCountry(country: string | null | undefined) {
  return country ? countries.find((val) => val.code === country) : null;
}

// Group projects by file vs folder
export const groupFiles = (files: IFileRow[]) => files.reduce(
  (prev: { folders: IFileRow[], files: IFileRow[]; }, curr) => {
    const { folder } = curr;
    const key = folder ? 'folders' : 'files';
    prev[key].push(curr);

    return prev;
  }, { folders: [], files: [] },
);

// Recurse search through folders and find vu files of different locations
export const recurseSearch = (searchData: {
  folders: IFileRow[], files: IFileRow[]; id?: string;
}) => {
  let parentFolder = [] as string[];
  let result = [] as IFileRow[];

  if (searchData.folders?.length > 0) {
    searchData.folders.forEach((folder: any) => {
      const res = recurseSearch(folder);
      result = [...res.files, ...result];
      parentFolder = [...res.parentFolder, ...parentFolder];
    });
  }

  const files = searchData.files
    .filter(({ metadata, mimeType }) => metadata.licenseName && mimeType === 'vucity/project');

  if (files.length > 0 && searchData.id) {
    parentFolder = [...parentFolder, searchData.id];
  }

  return { files: [...files, ...result], parentFolder: [...parentFolder] };
};

// Groups Events by Date
export const groupEventsByDate = (data: ActivityEvent[]) => {
  const todayStr = new Date().toLocaleDateString('en-GB');
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayStr = yesterday.toLocaleDateString('en-GB');

  const checkDate = (date: string) => {
    if (date === todayStr) return 'Today';
    if (date === yesterdayStr) return 'Yesterday';
    return date;
  };

  const events = data.reduce((groups: any, e: ActivityEvent) => {
    const date = new Date(e.createdAt).toLocaleDateString('en-GB');
    if (!groups[date]) {
      // eslint-disable-next-line no-param-reassign
      groups[date] = [];
    }
    groups[date].push(e);
    return groups;
  }, {});

  return Object.keys(events).map((date) => ({
    date: checkDate(date),
    events: events[date],
  }));
};

// Find and generate file path
export const generateFilePath = (
  paths: Folder[],
  initializePaths: { p: IRecursePaths[], e: IRecurseNodes[]; } = { p: [], e: [] },
  selectedPath?: string,
) => {
  let newPath: IRecursePaths[] = [];
  let expandedNodes: IRecurseNodes[] = [];

  const iter = (p: IRecursePaths[], nodes: IRecurseNodes[]) => (a: any) => {
    if (a.path === selectedPath) {
      newPath = p.concat({ name: a.name, id: a.id, folder: { ...a } });
      expandedNodes = nodes.concat({ id: a.id, folders: a.folders });
      return true;
    }
    return a.folders
      && a.folders.some(iter(p.concat({ name: a.name, id: a.id, folder: { ...a } }),
        nodes.concat({ id: a.id, folders: a.folders })));
  };

  const { p, e } = initializePaths;
  paths.some(iter(p, e));

  const finalNodes = expandedNodes
    .filter((node: IRecurseNodes) => node.folders && node.id)
    .map((node: IRecurseNodes) => node.id);

  return { newPath, finalNodes };
};

// String interpolation for dynamic text
export const interpolateParams = (
  template: string,
  params: { [key: string]: string; },
) => Object.entries(params).reduce(
  (acc, [key, value]) => acc.replace(`#{${key}}`, value),
  template,
);

export const isSupportedImageFile = (fileType: string) => ['png', 'jpg', 'jpeg', 'gif', 'jpeg', 'webp', 'ico', 'svg', 'tiff'].indexOf(fileType.toLowerCase()) !== -1;
export const isSupported3DFile = (fileType: string) => ['obj', 'fbx'].indexOf(fileType.toLowerCase()) !== -1;
export const emailRegex = /^[a-zA-Zа-яА-ЯЁё\u00C0-\u017F0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Zа-яА-ЯЁё\u00C0-\u017F0-9-]+(?:\.[a-zA-Zа-яА-ЯЁё\u00C0-\u017F0-9-]+)*$/;

// Zoho lead status

export const defineLeadStatus = (email: string) => {
  let leadStatus = '';

  if (email.includes('.ac.')) {
    leadStatus = 'Student';
  } else if (email.includes('hotmail')
    || email.includes('icloud')
    || email.includes('ymail')
    || email.includes('yahoo')
    || email.includes('gmail')
    || email.includes('outlook')
    || email.includes('live')) {
    leadStatus = 'Not Qualified';
  }

  return leadStatus;
};

// Calculate trial day

export const getTrialDays = (date: number | undefined): [number, string] => {
  if (!date) return [0, ''];

  const [number, time] = formatDistanceStrict(
    date ? date * 1000 : Date.now(),
    Date.now(),
    { unit: 'day' },
  ).split(' ');

  return [parseInt(number, 10), time];
};

// Tyoe to String formatting

export const formatBillingPeriod = (billingPeriod: BillingInterval | 'trial') => {
  if (billingPeriod === 'year') {
    return {
      adverb: 'annually',
      adjective: 'annual',
      abbreviation: 'an',
    };
  }

  if (billingPeriod === 'month') {
    return {
      adverb: 'monthly',
      adjective: 'monthly',
      abbreviation: 'mo',
    };
  }

  if (billingPeriod === 'trial') {
    return {
      adverb: 'trial',
      abbreviation: '',
      adjective: '',
    };
  }

  throw new Error('Invalid billing interval');
};

// check if user is running Windows OS

export const isWindowsPlatform = navigator.userAgent.includes('Win');

// Generate project member roles based on currentUser role

export const roleOptions = (myRole?: ProjectMemberRole) => {
  const options = Object.keys(copy.project.roles).map((role) => ({
    value: role,
    title: capitalizeWord(role),
    subtitle: copy.project.roleSubtitles[role],
  }));

  return myRole === 'EDITOR' ? options.filter(({ value }) => value !== 'ADMIN') : options;
};

export const getCurrentLauchOption = (isTruthy: boolean) => (isTruthy ? 'Cloud' : 'Desktop');
// model file extensions

export const modelExtensions = [
  'stl', 'sat', 'ifc',
  'dae', 'c4d', 'abc',
  'abc', '3ds', '3dm',
  'dwg', 'rvt', 'skp',
  'dxf', 'fbx', 'obj',
  'bak', 'max', 'log',
  'mtl', 'dfk',
];

export const docExtensions = [
  'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'xlsm',
  'odt', 'ods', 'epub', 'mobi',
];

export const mediaExtensions = [
  'mp4', 'mp3', 'avi', 'wav', 'wmv', 'wms', 'mov', 'wma',
  'ogg', 'wpl', 'mpa', 'mpg', 'mpeg', 'webm', 'video',
];

export const extraExtensions = [
  'txt', '3d', 'xla', 'dkf', 'obk', 'rtf', 'sslv',
  'zip', 'rar', 'xml', 'jar', 'psd', 'eps',
  '7z', 'arj', 'pkg',
];
export const imgExtensions = [
  'jpg', 'jpeg', 'gif', 'png', 'svg',
  'tiff', 'tif', 'eps', 'bmp', 'webp', 'ico',
];

// gis file extensions (given by david)

export const gisExtensions = [
  'json', 'geojson', 'gml', 'shp', 'csv',
  'kml', 'kmz', 'gpx', 'gpkg', 'vu',
];

// generate mime type

export const getMimeType = (file: IFilesProps) => {
  const extension = getExt(file.name);
  const index = modelExtensions.indexOf(extension.toLowerCase());
  if (index !== -1) return `model/${modelExtensions[index]}`;

  return file.type || 'application/octet-stream';
};

export const invalidCharsRegEx = /[!@#$%^&*\\,?":{}|<>/;]/i;

// Classes 

export const cls = (input: string) =>
  input
    .replace(/\s+/gm, ' ')
    .replace(/undefined/gm, '')
    .split(' ')
    .filter((cond) => typeof cond === 'string')
    .join(' ')
    .trim();

const allowedExtensions = [...modelExtensions, ...gisExtensions, ...extraExtensions, ...imgExtensions, ...docExtensions, ...mediaExtensions];

export function isValidExtension(extension: string = ''): boolean {
  return allowedExtensions.includes(extension.toLowerCase()); // checks if extension exists in the array
}

export function invalidFileExtensions(files: IFilesProps[]): string[] {
  const invalidExtensionsNames: string[] = [];
  files.forEach(({ name }: IFilesProps) => {
    const extension: string | undefined = name.split('.').pop()?.toLowerCase();
    if (extension && !isValidExtension(extension)) {
      invalidExtensionsNames.push(extension);
    }
  });

  return uniq(invalidExtensionsNames);
}

export const durationToMilliseconds = (duration: string) => {
  if (!duration) return '';
  const regex = /(\d+)\s*(second|seconds|minute|minutes|hour|hours|day|days)/i;
  const match = duration.match(regex);

  if (!match) return 0;

  const value = parseInt(match[1], 10);
  const unit = match[2].toLowerCase();

  const multiplier: { [key: string]: number; } = {
    second: 1000,
    seconds: 1000,
    minute: 60000,
    minutes: 60000,
    hour: 3600000,
    hours: 3600000,
    day: 86400000,
    days: 86400000,
  };

  return value * multiplier[unit];
};

// Extract city name from stripeProduct

export const extractCityName = (city?: string) => {
  if (!city) return '';

  return city.replace(/(International|City licence|Site Licence)/gi, '').trim();
};
import { gql } from '@apollo/client';

const FILES = gql`
  fragment fileFields on File {
    id
    path
    name
    creator {
      id
      name
    }
    createdAt
    size
    mimeType
    metadata {
      licenseName
    }
  }
`;

const FOLDERS = gql`
  fragment folderFields on Folder {
    id
    name
    path
    creator {
      id
      name
    }
    createdAt
    files {
      ...fileFields
    }
  }
`;

const GET_PERSONAL_DRIVE = gql`
  ${FILES}
  ${FOLDERS}
  query getPersonalDrive($id: ID!) {
    project(id: $id) {
      id
      name
      drive {
        info {
          total
          used
          remaining
        }
      }
    }
    currentUser {
      id
      drive {
        nodeByPath {
          id
          path
          name
          createdAt
          ... on Folder {
            ...folderFields
            folders {
              ...folderFields
              folders {
                ...folderFields
                folders {
                  ...folderFields
                  folders {
                    ...folderFields
                    folders {
                      ...folderFields
                      folders {
                        ...folderFields
                      }
                    }
                  }
                }
              }
            }
          }
          ... on File {
            ...fileFields
          }
        }
      }
    }
  }
`;

export default GET_PERSONAL_DRIVE;

import { interpolateParams } from 'utils/helpers';
import content from './content.json';

export const invitationInstructions = ({
  inviter,
  name,
}: {
  [key: string]: string;
}) => interpolateParams(content.invitationInstructions, { inviter, name });
export const invitationWarning = () => content.invitationWarning;
export const invitationConfirmation = ({ name }:
{ [key: string]: string }) => interpolateParams(content.invitationConfirmation, { name });

import { useMutation } from '@apollo/client';
import { useHandleError, useMixpanel } from 'hooks';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Project, UserTier } from 'types/graphql';
import { ACCEPT_AVAILABLE_PROJECT, RSVP_PROJECT } from 'views/project/apollo';
import GET_PROJECTS from 'views/projects/apollo';
import TextModal from '../Modal/TextModal/TextModal';

export interface IProjectActionsProps {
  project: Project;
  tier: UserTier;
  upgradeModal: boolean;
  inviteModal: boolean;
  availableToAll: boolean;
  setUpgradeModal: React.Dispatch<React.SetStateAction<boolean>>;
  setInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setAvailableToAll: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProjectActions: React.FC<IProjectActionsProps> = ({
  project,
  tier,
  upgradeModal,
  inviteModal,
  availableToAll,
  setInviteModal,
  setUpgradeModal,
  setAvailableToAll,
}) => {
  const history = useHistory();
  const [handleError, enqueueSnackbar] = useHandleError();
  const { mixpanelTrack } = useMixpanel();

  const [rsvpProject] = useMutation(RSVP_PROJECT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PROJECTS }],
    onCompleted: ({ rsvpProject: { status } }) => {
      enqueueSnackbar(
        `Project invite ${status === 'ACCEPTED' ? 'accepted' : 'declined'}`,
        { variant: 'success' },
      );
      if (status === 'ACCEPTED')
        return history.push(`/project/${project.id}`);

      return setInviteModal(false);
    },
    onError: handleError,
  });

  const [acceptAvailableToAll] = useMutation(ACCEPT_AVAILABLE_PROJECT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PROJECTS }],
    onError: handleError,
    onCompleted: ({ addUserToProject: { status } }) => {
      mixpanelTrack('Joined project successfully', { status, access: project.access });
      enqueueSnackbar('Project joined successfully', { variant: 'success' });
      history.push(`/project/${project.id}`);
    },
  });

  const handleInvite = (response: string) => {
    rsvpProject({
      variables: {
        projectId: project.id,
        response,
      },
    });
  };

  const handleAcceptAvailable = () =>
    acceptAvailableToAll({ variables: { id: project.id } });

  return (
    <>
      <TextModal
        open={upgradeModal}
        setOpen={setUpgradeModal}
        mixpanelPath="No spaces available"
        headerText="Looks like you’ve no spaces available"
        closeBtnLabel="Close"
        mainBtnLabel="See organisation admins"
        onClose={() => mixpanelTrack('Close on "No spaces available"')}
        mainBtnHandle={() => {
          mixpanelTrack('See organisation admins on "No spaces available"');
          history.push({
            pathname: '/account/organisation/members',
            state: { fromDashboard: true },
          });
        }}
      >
        You can unlock unlimited project spaces by upgrading your account’s
        subscription. Contact an organisation admin to upgrade your account.
      </TextModal>

      <TextModal
        headerText="Join project?"
        open={inviteModal}
        setOpen={setInviteModal}
        mixpanelPath="Join project"
        closeBtnLabel="Decline invite"
        closeBtnHandle={() => handleInvite('DECLINE')}
        mainBtnLabel="Accept invite"
        mainBtnHandle={() => handleInvite('ACCEPT')}
        component='div'
      >
        {tier.slotsRemaining && (
          <div className='pb-2'>
            <p>You will use <span className='font-medium'>one</span> of your free project spaces.</p>
            <p>If you accept this invite you will have {tier.slotsRemaining - 1} free left.</p>
          </div>
        )}
        <div className='flex flex-col space-y-2 !text-start'>
          <p>
            If you join this project you will have the <b>{project?.me?.role}</b> role.
          </p>
          <p>
            If you decline this invite you will need to be re-invited.
          </p>
        </div>
      </TextModal>

      <TextModal
        headerText="Join project?"
        open={availableToAll}
        setOpen={setAvailableToAll}
        mixpanelPath="Join project"
        closeBtnLabel="Cancel"
        closeBtnHandle={() => setAvailableToAll(false)}
        mainBtnLabel="Join project"
        mainBtnHandle={handleAcceptAvailable}
      >
        If you join this project, you will use one of your project spaces.
      </TextModal>
    </>
  );
};

import React from 'react';
import { ReactComponent as DoubleArrow } from 'assets/icons/DoubleArrow.svg';

interface CollapseButtonProps {
  onClick: () => void;
  collapsed: boolean;
  className?: string;
}

const CollapseButton: React.FC<CollapseButtonProps> = ({
  onClick,
  collapsed,
  className,
}) => {
  return (
      <button
        className={` 
        absolute
        top-[calc(50%-24px)]
        -right-1
        z-50  
        flex
        justify-center 
        items-center
        w-[28px] h-6
        bg-white 
        border
        border-grey-border
        hover:border-primary
        transition-all
        duration-150
        rounded
        group/btn
        ${className ? className : ''}
      `}
        onClick={onClick}
      >
        <DoubleArrow
          className={`fill-grey-border group-hover/btn:fill-primary ${
            collapsed ? 'rotate-180' : ''
          }`}
        />
      </button>
  );
};

export default CollapseButton;

import { useQuery } from '@apollo/client';
import {
  Collapse, List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  ArrowDropDownIcon,
  ArrowDropUpIcon,
} from '@vucity/design_system';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { useMixpanel } from 'hooks';
import { GET_INVITATIONS } from 'views/invitations/apollo';

import { Badge, TopNav } from 'tailwind';
import Fallback from './Fallback';
import { GET_ORG_ROLE } from './apollo';
import { Sublinks, routes } from './routes';

const Account = () => {
  const [open, setOpen] = useState([true, true, true]);
  const [invites, setInvites] = useState<number>(0);

  const { data } = useQuery(GET_ORG_ROLE);

  useQuery(GET_INVITATIONS, {
    onCompleted: ({ currentUser: user }) => {
      setInvites(user.orgInvites.length);
    },
  });

  const { mixpanelTrack } = useMixpanel();
  const history = useHistory();
  const location = useLocation();

  const { role, tier } = useMemo(() => ({
    role: data?.currentUser?.organisation?.me?.role || '',
    tier: data?.currentUser?.tier.name || '',
  }), [data]);

  const allowedRoutes = useMemo(() => routes(true)
    .filter((route) => !route.isHidden)
    .map((route) => ({
      ...route,
      sublinks: route.sublinks.filter((sublink) => !sublink.isHidden
        && (role === 'MEMBER' && sublink.hideFromMembers ? '' : sublink)
        && (tier !== 'Pro' && tier !== 'Floating' ? sublink.title !== 'VU.CITY Desktop' : sublink)),
    })), [tier, role]);

  const sublinks = useMemo(() => allowedRoutes.reduce((links: Sublinks[], arr) => {
    links.push(...arr.sublinks);
    return links;
  }, []), [allowedRoutes]);

  useEffect(() => {
    const title = sublinks.filter(({ url }) => url === location.pathname)[0]?.title;
    const pathname = location.pathname.split('/').splice(2).join('/');

    if (title) { mixpanelTrack(`${pathname}/Visited ${title} page`); }
  }, [location, mixpanelTrack, sublinks]);

  return (
    <>
      <TopNav />
      <div className='flex gap-3 p-3 bg-primary-lightest h-content'>
        <div className='bg-white rounded-[11px] p-2 pt-5 w-330 flex-shrink-0'>
          {allowedRoutes.map((link, i) => (
            <List component="nav" key={i} disablePadding className="account-menu">
              <ListItem
                button
                dense
                color="primary"
                onClick={() => {
                  const temp = [...open];
                  temp[i] = !open[i];
                  setOpen(temp);
                }}
              >
                <ListItemIcon>{link.icon}</ListItemIcon>
                <ListItemText primary={link.title} />
                {open[i] ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </ListItem>
              <Collapse in={open[i]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {link.sublinks?.map((sublink, j) => (
                    <ListItem
                      button
                      dense
                      color="primary"
                      onClick={() => {
                        mixpanelTrack(`Clicked ${sublink.title} page`);
                        history.push(sublink.url);
                      }}
                      key={j}
                      selected={sublink.url === location.pathname}
                    >
                      <ListItemText inset primary={sublink.title} />
                      {sublink.badge && invites > 0 &&
                        <Badge num={invites} className='absolute left-[140px]' />
                      }
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </List>
          ))}
        </div>

        <div className='relative flex-grow p-6 pt-4 overflow-auto bg-white rounded-[11px]'>
          <Switch>
            {allowedRoutes.map((link) => link.sublinks?.map((sublink, j) => (
              <Route
                key={j}
                path={sublink.url}
                component={() => sublink.component}
              />
            )))}
            <Route path="" component={Fallback} />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Account;

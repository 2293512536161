import React from 'react';
import Loader from '../../Loader';

interface IBtnProps {
  disabled: boolean;
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSave: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const AnnotationButtons = ({ disabled, onClose, onSave }: IBtnProps) => {
  const btnCls = 'h-5 w-5 p-1 fixed top-[30px] z-[999998] fill-white hover:bg-slate-700 transition';

  return (
    <>
      {disabled && <section className='fixed top-0 left-0 flex z-[999999] items-center justify-center h-annotation w-annotation m-[30px] bg-[rgba(255,255,255,0.3)]'>
        <Loader />
      </section>}

      <button className={`${btnCls} right-[30px]`} onClick={onClose}>
        <svg viewBox="0 0 24 24">
          <path d="M20 6.91L17.09 4 12 9.09 6.91 4 4 6.91 9.09 12 4 17.09 6.91 20 12 14.91 17.09 20 20 17.09 14.91 12 20 6.91z"></path>
        </svg>
      </button>

      <button className={`${btnCls} right-[70px]`} disabled={disabled} onClick={onSave}>
        <svg viewBox="0 0 24 24">
          <path d="M9 20.42l-6.21-6.21 2.83-2.83L9 14.77l9.88-9.89 2.83 2.83L9 20.42z"></path>
        </svg>
      </button>
    </>
  );
};

export default AnnotationButtons;
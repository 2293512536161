import { IconButton } from '@material-ui/core';
import { LockIcon } from '@vucity/design_system';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import TextModal from '../TextModal/TextModal';

const UpgradeModal: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleModal = () => setOpen(!open);

  const iconStyle = {
    width: '20px',
    height: '20px',
  };

  const iconButtonStyle = {
    padding: '0',
    marginRight: '10px',
  };

  return (
    <>
      <IconButton onClick={handleModal} style={iconButtonStyle} aria-label="Locked">
        <LockIcon style={iconStyle} />
      </IconButton>
      <TextModal
        open={open}
        setOpen={handleModal}
        mixpanelPath='No spaces available'
        headerText='Upgrade?'
        closeBtnLabel='No'
        mainBtnLabel='Yes'
        mainBtnHandle={() => history.push('/upgrade')}
        component='div'
      >
        You cannot access this project.
        <p className='mb-1'>
          Do you wish to upgrade?
        </p>
      </TextModal>
    </>
  );
};

export default UpgradeModal;

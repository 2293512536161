import { RasterSource } from 'mapbox-gl';

const envMap = {
  LOCAL: 'vudev.city',
  DEVELOP: 'vudev.city',
  STAGING: 'vustage.city',
  PROD: 'vu.city',
};

const domain = envMap[import.meta.env.VITE_ENV as keyof typeof envMap];

export const GET_MODELLED_CITIES = `https://ingress.${domain}/hub-coverage-map-support/config/cities.json`;
export const GET_VECTOR_SOURCES = `https://ingress.${domain}/hub-coverage-map-support/config/vectorsources.json`;
export const GET_COVERAGE_AREA = `https://ingress.${domain}/hub-coverage-map-support/FetchCoverageArea.geojson`;
export const SEND_MODELLING_ORDER = `https://ingress.${domain}/hub-coverage-map-support/PlaceOrder`;
export const FETCH_250_TILES = `https://ingress.${domain}/hub-coverage-map-support/Fetch250mTiles`;

export const rasterSource: RasterSource = {
  id: 'VU.CITY',
  type: 'raster',
  tiles: [
    `https://ingress.${domain}/hub-coverage-map-support/FetchRasterTile/VU.CITY/{z}/{x}/{y}.png`,
  ],
  tileSize: 256,
  attribution: 'Map tiles by VU.CITY',
};
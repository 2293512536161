import { useMixpanel } from 'hooks';
import * as React from 'react';
import { Button } from 'tailwind';
import { capitalizeWord } from 'utils/helpers';
import TextModal from '../TextModal/TextModal';

type ConfirmChangesProps = {
  headerText: string;
  clickHandle: () => {};
  disabled?: boolean;
  buttonLabel?: string;
  invitesLength?: number;
  subscriptionCounts: { [key: string]: number; };
};

const ConfirmInvitationsModal: React.FC<ConfirmChangesProps> = ({
  headerText,
  clickHandle,
  disabled = false,
  buttonLabel,
  invitesLength,
  subscriptionCounts,
}) => {
  const { mixpanelTrack } = useMixpanel();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button
        variant='contained'
        disabled={disabled}
        onClick={() => {
          setOpen(!open);
          mixpanelTrack(`${headerText}/${buttonLabel || 'Save changes'}`);
        }}
      >
        {buttonLabel || 'Save changes'}
      </Button>

      <TextModal
        open={open}
        setOpen={setOpen}
        mixpanelPath="Confirm invitations"
        headerText="Confirm invitations"
        closeBtnLabel="Review Invites"
        mainBtnLabel="Send Invites"
        loading={disabled}
        mainBtnHandle={clickHandle}
        component='div'
      >
        <p className='mb-3 text-left'>
          Total number of invitations to your organisation: <b>{invitesLength}</b>
        </p>
        <p className='text-left'>
          Assigned subscriptions:
          {subscriptionCounts && Object.keys(subscriptionCounts).map(((sub, i) => (
            <li key={i}><strong>{subscriptionCounts[sub]}{' '}{capitalizeWord(sub)}</strong> </li>
          )))}
        </p>
      </TextModal>
    </>
  );
};

export default ConfirmInvitationsModal;

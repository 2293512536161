import React, { useEffect } from 'react';
import { useHandleError } from 'hooks';

interface IScrollProps {
  elementId: string;
  children: React.ReactNode;
  hasNextPage: boolean;
  loading: boolean;
  called: boolean;
  fetchMore: () => void;
  setScrolled: (element: HTMLElement) => void;
  scrolled: boolean;
}

const ScrollIntoView: React.FC<React.PropsWithChildren<IScrollProps>> = ({
  elementId, children,
  hasNextPage, loading, fetchMore, called, scrolled, setScrolled,
}) => {
  const [, enqueueSnackbar] = useHandleError();
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const element = document.getElementById(elementId);
    if (elementId && hasNextPage && !element) return fetchMore();
    setTimeout(() => {
      if (!element && !hasNextPage && !loading && elementId && called) {
        enqueueSnackbar('This comment no longer exists.', { variant: 'error' });
      } else if (!scrolled && element && !hasNextPage && !loading && called) {
        element?.scrollIntoView();
        setScrolled(element);
      }
    }, 0);
  }, [elementId, enqueueSnackbar, called, loading, hasNextPage, fetchMore, scrolled, setScrolled]);

  return <>{children}</>;
};

export default ScrollIntoView;

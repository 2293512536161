import { MagicBellProvider } from '@magicbell/magicbell-react';
import { useLocalStorageState } from 'ahooks';
import { useCurrentUser, useMixpanel } from 'hooks';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CollapseButton, Ellipsis, TopNav } from 'tailwind';
import { magicBellTheme } from 'tailwind/TopNav/TopNavButtons/TopNavButtons.style';
import { User } from 'types/graphql';
import Menu, { MenuItems } from './Menu/Menu';
import { Resizable } from 're-resizable';
import { useQuery } from '@apollo/client';
import { ReactComponent as IdeaSvg } from 'assets/icons/Idea.svg';
import { ReactComponent as IdeaOutline } from 'assets/icons/IdeaOutline.svg';
import { ReactComponent as RoadmapOutline } from 'assets/icons/RoadMapOutline.svg';
import { ReactComponent as RoadmapSvg } from 'assets/icons/Roadmap.svg';
import GET_PROJECT_NAME from './apollo';
import { Direction } from 're-resizable/lib/resizer';

interface LayoutProps {
  children: React.ReactNode;
  items: MenuItems[];
}

const MainLayout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  items,
  children,
}) => {
  const { projectId: id } = useParams<{ projectId: string }>();
  const { currentUser } = useCurrentUser();
  const { pathname } = useLocation();
  const { mixpanelTrack } = useMixpanel();

  const { data } = useQuery(GET_PROJECT_NAME, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    variables: { id },
    skip: !id,
  });

  const renderProjectName = () =>
    id && (
      <div className='w-full mb-2 min-h-4 text-grey overflow-hidden whitespace-normal'>
        {data?.project?.id && (
          <>
            <Ellipsis
              rows={3}
              className='text-lg font-medium text-grey'
              applyTooltip
            >
              {data?.project?.name}
            </Ellipsis>
          </>
        )}
      </div>
    );

  const bottomMenu = [
    {
      icon: <IdeaOutline color='primary' />,
      title: 'Submit an Idea',
      url: '/roadmap-submit-idea',
      activeIcon: <IdeaSvg color='primary' />,
    },
    {
      icon: <RoadmapOutline color='primary' />,
      title: 'View roadmap',
      url: '/roadmap',
      activeIcon: <RoadmapSvg color='primary' />,
    },
  ];

  const SIDEBAR_MIN_WIDTH = 84;
  const SIDEBAR_MAX_WIDTH = 280;
  const SIDEBAR_COLLAPSED_THRESHOLD = SIDEBAR_MIN_WIDTH * 2;

  const [sidebarSizeState, setSidebarSize] = useLocalStorageState(
    'sidebarSize',
    { defaultValue: SIDEBAR_MAX_WIDTH },
  );
  const sidebarSize = sidebarSizeState ?? SIDEBAR_MAX_WIDTH;
  const isSidebarCollapsed = sidebarSize <= SIDEBAR_COLLAPSED_THRESHOLD;

  const handleResize = (
    e: MouseEvent | TouchEvent,
    direction: Direction,
    elementRef: HTMLElement,
  ) => {
    setSidebarSize(elementRef.offsetWidth);
  };

  const handleCollapseClick = () => {
    const action = isSidebarCollapsed ? 'expand' : 'collapse';
    setSidebarSize(isSidebarCollapsed ? SIDEBAR_MAX_WIDTH : SIDEBAR_MIN_WIDTH);
    mixpanelTrack(`Click to ${action} menu`, {
      Page: pathname,
    });
  };

  const sidebarCursor = sidebarSize === SIDEBAR_MIN_WIDTH ? 'e-resize' : sidebarSize === SIDEBAR_MAX_WIDTH ? 'w-resize' : 'col-resize';

  return (
    <MagicBellProvider
      apiKey={(currentUser as User)?.magicbellKey || ''}
      userEmail={currentUser?.email || ''}
      theme={magicBellTheme}
    >
      <TopNav />
      <div className='flex w-full overflow-hidden h-content'>
        <Resizable
          defaultSize={{
            width: SIDEBAR_MAX_WIDTH,
          }}
          minWidth={SIDEBAR_MIN_WIDTH}
          maxWidth={SIDEBAR_MAX_WIDTH}
          size={{ width: sidebarSize, height: '100%' }}
          enable={{ top: false, right: true, bottom: false, left: false }}
          onResize={(e, direction, elementRef) =>
            handleResize(e, direction, elementRef)
          }
          style={{ height: '100%', display: 'flex' }}
          handleStyles={{ right: { width: '6px', cursor: sidebarCursor } }}
          handleClasses={{ right: 'bg-[#CACCD7] hover:!w-[8px] active:bg-[#94A9BC] hover:bg-[#94A9BC] transition-[background] duration-75' }}
          className='group/sidebar'
        >
          <div
            className={`flex flex-col whitespace-nowrap w-full transition-[max-width] min-w-[84px] duration-300
            justify-between p-3 relative ${
              isSidebarCollapsed ? 'max-w-full' : 'max-w-[280px]'
            }`}
          >
            <CollapseButton
              onClick={handleCollapseClick}
              collapsed={isSidebarCollapsed || false}
              className='group-hover/sidebar:flex hidden'
            />
            <div className='flex flex-col overflow-hidden'>
              {renderProjectName()}
              <Menu items={items[0]} collapsed={isSidebarCollapsed} />
            </div>
            <div className='flex flex-col p-0'>
              {!isSidebarCollapsed && (
                <p className='pl-2 my-1 text-lg text-content'>
                  Give your feedback
                </p>
              )}
              <Menu items={bottomMenu} collapsed={isSidebarCollapsed} />
            </div>
          </div>
        </Resizable>
        <div
          className={`relative flex flex-col w-full h-full ${
            pathname === '/coveragemap' ? 'p-0' : 'p-3'
          } overflow-auto overflow-y-scroll text-content bg-primary-lightest`}
        >
          {children}
        </div>
      </div>
    </MagicBellProvider>
  );
};

export default MainLayout;

import React, { useCallback, useMemo } from 'react';
import { extraExtensions, getExt, imgExtensions, modelExtensions } from 'utils/helpers';

import { ReactComponent as DocThumb } from 'assets/thumbnails/Doc.svg';
import { ReactComponent as ImageThumb } from 'assets/thumbnails/Image.svg';
import { ReactComponent as VideoThumb } from 'assets/thumbnails/Video.svg';
import { ReactComponent as VUThumb } from 'assets/thumbnails/VUFile.svg';

import { useAuth0 } from '@auth0/auth0-react';
import { defaultImage } from '@cloudinary/url-gen/actions/delivery';
import { getPage } from '@cloudinary/url-gen/actions/extract';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { useCloudinary } from 'hooks';
import { useParams } from 'react-router-dom';
import { File } from 'types/graphql';
import { Extension, Image } from './Thumbnails.style';

const Thumbnails = ({ file }: { file: File; }) => {
  const { projectId } = useParams<{ projectId?: string; }>();
  const { user } = useAuth0();
  const [cld] = useCloudinary();

  const extension = getExt(file.name).toLowerCase();
  const extensionThumbs = [...modelExtensions, ...extraExtensions];

  const cldPath = useMemo(() => (projectId
    ? `hub/projects/${projectId}/drive/${file.id}`
    : `hub/drive/${user?.sub?.split('|')[1]}/${file.id}`), [projectId, user?.sub, file]);

  const generateImage = useCallback((placeholder: string) => (
    `${cld.image(`${cldPath}.jpg`)
      .resize(scale().height(100))
      .extract(getPage().byNumber(1))
      .delivery(defaultImage(`public:placeholder:${placeholder}`))
      .toURL()}`
  ), [cld, cldPath]);

  const renderThumbnail = () => {
    const { mimeType } = file;

    if (extensionThumbs.includes(extension)) {
      return <Extension>{extension.substring(0, 3)}</Extension>;
    }

    if (extension.startsWith('doc')) {
      return <DocThumb />;
    }

    if (extension === 'pdf') {
      return <Image bgImg={generateImage('pdf_plc.svg')} />;
    }

    if (mimeType?.startsWith('image') || imgExtensions.includes(extension)) {
      return <Image bgImg={generateImage('image_placeholder.svg')} />;
    }

    if (mimeType?.startsWith('vucity/project') || extension === 'vu') {
      return <VUThumb />;
    }

    if (mimeType?.startsWith('video')) {
      return <VideoThumb />;
    }

    return <ImageThumb />;
  };

  return (
    <div className='min-w-[22px] max-w-[22px] h-[22px] mr-1 [&_svg]:w-full [&_svg]:h-full [&_svg]:rounded-xs'>
      {renderThumbnail()}
    </div>
  );
};

export default Thumbnails;

import { gql, TypedDocumentNode } from '@apollo/client';
import { User } from 'types/graphql';

const GET_ORG_MEMBERS: TypedDocumentNode<{ currentUser: User; }> = gql`
  query getOrgMembers($skipData: Boolean! = false) {
    currentUser {
      id
      lastLogin
      jobTitle
      siteLicenses @skip(if: $skipData) {
        licenseId
      }
      organisation {
        name
        id
        subscriptions @skip(if: $skipData) {
          interval
          trial_end
          stats {
            plus {
              total
              assigned
              available
            }
            pro {
              total
              assigned
              available
            }
          }
          status
        }
        trialActive @skip(if: $skipData) 
        trialAvailable @skip(if: $skipData) 
        seatStats @skip(if: $skipData) {
          plus {
            available
            availableMonthly
            availableAnnually
          }
          pro {
            available
            availableMonthly
            availableAnnually
          }
        }
        members {
          id
          role
          status
          email
          isUserRemoved
          seat @skip(if: $skipData) {
            status
            tier {
              name
              interval
            }
          }
          siteLicenses {
            userId
            licenseId
            name
          }
          profile {
            id
            name
            lastLogin
            email
            picture
          }
        }
        me {
          role
        }
      }
    }
  }
`;

export const GET_ORGANISATION_SEATSTATS: TypedDocumentNode<{ currentUser: User; }> = gql`
  query getOrgMembers {
    currentUser {
      id
      organisation {
        name
        id
        subscriptions {
          interval
          trial_end
          stats {
            plus {
              total
              assigned
              available
            }
            pro {
              total
              assigned
              available
            }
          }
          status
        }
        trialActive
        trialAvailable
        seatStats {
          plus {
            available
            availableMonthly
            availableAnnually
          }
          pro {
            available
            availableMonthly
            availableAnnually
          }
        }
      }
    }
  }
`;

export default GET_ORG_MEMBERS;

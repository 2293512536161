import { Avatar } from '@material-ui/core';
import styled from 'styled-components';

export const StyledImg = styled(Avatar) <{ objectfit?: string; }>`
  width: 88px;
  height: 88px;
  
  & .MuiAvatar-img {
    object-fit: ${({ objectfit }) => objectfit};
  }
`;

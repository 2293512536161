import { Column } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { useMixpanel } from 'hooks';
import React from 'react';
import { Button } from 'tailwind';
import { File } from 'types/graphql';
import DataGrid from '../../../DataGrid/DataGrid';
import CustomModal from '../../Modal';

interface IInvalidFilesProps {
  files: File[];
  title: string;
  message: string;
  closeModal: () => void;
}

interface IRows {
  id: string;
  name?: string;
  path?: string;
  type?: string;
}

const InvalidFiles: React.FC<IInvalidFilesProps> = ({ files, title, message, closeModal }) => {
  const { mixpanelTrack } = useMixpanel();
  const rows: IRows[] = files.map((file: File) => ({
    id: file.id,
    name: file.name,
    path: file.path,
    type: file?.mimeType,
  }));

  const columns: Column[] = [
    { name: 'path', title: 'Name' },
    { name: 'type', title: 'File type' },
  ];

  const columnWidth = [
    { columnName: 'name', width: 'auto' },
    { columnName: 'owner', width: 'auto' },
  ];

  return (
    <CustomModal open={true} close={closeModal} headerText={title}>
      <div>
        <div className='pt-3 pb-4'>
          <p className='text-lg text-center'>
            {message}
          </p>
        </div>

        <div className='border border-[#e0e0e0] rounded max-h-[250px] overflow-auto'>
          <DataGrid
            rows={rows}
            columns={columns}
            columnWidth={columnWidth}
            defaultSort={[{ columnName: 'path', direction: 'asc' }]}
            rowComponent={Table.Row}
          />
        </div>

        <div className='flex justify-center gap-2 mt-5'>
          <Button
            variant="contained"
            onClick={() => {
              mixpanelTrack('Invalid Files/Ok');
              closeModal();
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default InvalidFiles;

import { MenuItem } from '@material-ui/core';
import React from 'react';
import { useMixpanel } from 'hooks';
import { IFileRow } from 'components/Drive/types';

interface ICopyProps {
  files: IFileRow[];
  closeContextMenu: () => void;
  setCopiedFiles: React.Dispatch<React.SetStateAction<IFileRow[]>>;
}

const Copy: React.FC<ICopyProps> = ({ files, closeContextMenu, setCopiedFiles }) => {
  const { mixpanelTrack } = useMixpanel();

  const onCopy = () => {
    mixpanelTrack('Copy');
    setCopiedFiles(files);
    closeContextMenu();
  };

  return (
    <MenuItem onClick={onCopy}>Copy</MenuItem>
  );
};

export default Copy;

import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { OutlinedInput } from '@material-ui/core';
import { CloseIcon } from '@vucity/design_system';
import { DetailedSelect } from 'components';
import copy from 'copy';
import { useMixpanel } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { BillingInterval, OrgMemberInvite, ProjectMember } from 'types/graphql';
import organisationMemberRoles from 'utils/configs/organisationMemberRoles';
import { emailRegex } from 'utils/helpers';
import RemoveButton from 'views/organisation/invite/style';
import { GET_ORGANISATION_SEATSTATS } from 'views/organisation/members/data';
import { Errors } from 'views/types';
import SubscriptionDropdown from './SubscriptionDropdown';
import { invalidDomains } from '../../../utils/constants';

interface IIndividualProps {
  members: OrgMemberInvite[];
  invitedMembers: ProjectMember[];
  defaultTier: string;
  register: UseFormRegister<{ members: OrgMemberInvite[]; }>;
  hasError: Errors;
  onUpdate: any;
}

export interface Seats {
  pro: number;
  plus: number;
}

const { emailErrorMsg } = copy;
const initialSeats = {
  month: { pro: 0, plus: 0 },
  year: { pro: 0, plus: 0 },
};

const IndividualOrgInvites = ({
  members, invitedMembers, defaultTier, register, hasError, onUpdate,
}: IIndividualProps) => {
  const { user } = useAuth0();
  const { mixpanelTrack } = useMixpanel();

  const [seats, setSeats] = useState<{ [key: string]: Seats; }>(initialSeats);

  const { data } = useQuery(GET_ORGANISATION_SEATSTATS);
  const fallbackStats = [
    {
      interval: 'year',
      status: 'active',
      stats: {
        plus: {
          available: data?.currentUser?.organisation?.seatStats.plus.availableAnnually,
        },
        pro: {
          available: data?.currentUser?.organisation?.seatStats.pro.availableAnnually,
        },
      },
    },
    {
      interval: 'month',
      status: 'active',
      stats: {
        plus: {
          available: data?.currentUser?.organisation?.seatStats.plus.availableMonthly,
        },
        pro: {
          available: data?.currentUser?.organisation?.seatStats.pro.availableMonthly,
        },
      },
    },
  ];

  const subscriptions = useMemo(() => data && data?.currentUser?.organisation?.subscriptions?.length > 0 ? data?.currentUser?.organisation?.subscriptions : fallbackStats || [], [data]);
  const availableSubs: { [key: string]: Seats; } = useMemo(() => subscriptions.reduce((sub, acc) => ({
    ...initialSeats,
    [acc.interval]: { pro: acc.stats.pro.available, plus: acc.stats.plus.available },
  }), {}), [subscriptions]);
  const hasBoth = useMemo(() => {
    const { year = { pro: 0, plus: 0 }, month = { pro: 0, plus: 0 } } = availableSubs;
    if (isNaN(year?.plus)) return {};

    return { year: year.pro + year.plus, month: month.pro + month.plus };
  }, [availableSubs]);

  useEffect(() => {
    if (subscriptions.length) {
      setSeats(availableSubs);
    }
  }, [availableSubs, subscriptions.length]);

  const { errors, setError, clearErrors } = hasError;

  const validate = (value: string, index: number) => {
    const current = `members.${index}.email`;

    if (!value) {
      clearErrors(current);
      return true;
    }

    const alreadyInvited = invitedMembers?.some((val) => value === val?.email.toLowerCase() && val?.status !== 'REMOVED');
    const duplicate = members && members?.filter((member) => member.email === value).length > 1;
    const ownEmail = value === user?.email;
    const invalid = !emailRegex.test(value);

    if (ownEmail) return setError(current, { message: emailErrorMsg.OWN_EMAIL });
    if (invalid) return setError(current, { message: emailErrorMsg.INVALID });
    if (alreadyInvited) return setError(current, { message: emailErrorMsg.ALREADY_INVITED });
    if (duplicate) return setError(current, { message: emailErrorMsg.REPEATED });
    for (const domain of invalidDomains) {
      if (value.includes(domain)) {
        setError(current, { message: emailErrorMsg.UNAUTHORISED });
        return false;
      }
    }

    clearErrors(current);
    return true;
  };

  const onChange = (value: string, i: number) => {
    const nd: OrgMemberInvite[] = [...members];

    if (value.length > 0 || nd.length === 1) {
      nd[i] = { ...nd[i], email: value.toLowerCase() };
    } else nd.splice(i, 1);

    if (nd.length === nd.filter((el) => el.email.length >= 2).length) {
      nd.push({ email: '', role: 'MEMBER', tierId: defaultTier, interval: 'year' });
    }

    onUpdate(nd);
  };

  const onRemove = (index: number) => {
    const current = `members.${index}.email`;
    mixpanelTrack('Remove User Email via Invite Journey', {
      state: errors.members && errors.members[index] ? 'error' : 'Valid',
      role: members[index].role,
      subscription: members[index].tierId,
    });
    clearErrors(current);

    const { interval = 'year', tierId } = members[index];
    setSeats((prev) => ({
      ...prev,
      [interval]: {
        plus: tierId === 'plus' ? prev[interval].plus + 1 : prev[interval].plus,
        pro: tierId === 'pro' ? prev[interval].pro + 1 : prev[interval].pro,
      },
    }));

    const removedMember = members.filter((_, i) => i !== index);
    onUpdate(removedMember);
  };

  const onSubscriptionChange = (tierId: string, interval: BillingInterval | 'trial', index: number) => {
    const updatedMembers = members.map((member, i) => ({
      ...member,
      tierId: i === index ? tierId : member.tierId,
      interval: i === index ? interval : member.interval,
    }));
    onUpdate(updatedMembers);
  };

  return (
    <section className='flex items-center gap-3'>
      <div className='text-content min-h-[280px] w-full max-w-full pr-2 gap-2'>
        <div className='flex gap-2'>
          <p className='w-full mb-1 text-sm max-w-330'>Add email</p>
          <p className='w-full mb-1 text-sm max-w-330'>Organisation role</p>
          <p className='w-full mb-1 text-sm max-w-330'>Subscription</p>
          <p className='invisible'>Action</p>
        </div>

        <section className='flex flex-col gap-0.5'>
          {members.map((m, index) => (
            <article key={index} className='flex items-start gap-2 group'>
              <div className='flex flex-col w-330'>
                <OutlinedInput
                  autoComplete='off'
                  type='email'
                  value={m.email}
                  placeholder='Email'
                  className='mb-0.5'
                  {...register(`members.${index}.email`, {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid',
                    },
                    onChange: (e) => onChange(e.target.value.toLowerCase().trim(), index),
                    onBlur: (e) => validate(e.target.value, index),
                  })}
                />

                {errors.members && errors.members[index] && (
                  <div className='text-xs text-error'>{errors.members[index]?.email.message}</div>
                )}
              </div>

              <div className='flex flex-col w-330'>
                <DetailedSelect
                  items={organisationMemberRoles}
                  variant="outlined"
                  value={m.role?.toLowerCase() || 'member'}
                  handleChange={(option) => {
                    if (option?.value) {
                      const updatedMembers = members.map((member, i) => (
                        { ...member, role: i === index ? option.value.toUpperCase() : member.role }
                      ));
                      onUpdate(updatedMembers);
                    }
                  }}
                />
              </div>

              <div className='flex flex-col w-330'>
                <SubscriptionDropdown
                  hasBoth={hasBoth}
                  initialSeat={m.tierId}
                  seats={seats}
                  setSeats={setSeats}
                  onSubscriptionChange={(tierId: string, interval: BillingInterval | 'trial') =>
                    onSubscriptionChange(tierId, interval, index)
                  }
                />
              </div>

              <div className='flex items-center justify-center h-6'>
                <RemoveButton
                  className='invisible group-hover:visible'
                  disabled={members.length < 2 || !m.email}
                  aria-label="remove"
                  size="small"
                  onClick={() => onRemove(index)}
                >
                  <CloseIcon />
                </RemoveButton>
              </div>
            </article>
          ))}
        </section>
      </div>
    </section>
  );
};

export default IndividualOrgInvites;

import { Box, BoxProps, InputLabel, Popper, PopperProps, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Autocomplete } from 'components';
import React, { useState } from 'react';
import styled from 'styled-components';

export type Option = {
  code: string;
  label: string;
  phone?: string;
  suggested?: boolean;
};

interface SelectAutocompleteProp extends BoxProps {
  handleChange: (event: React.ChangeEvent<{}>, value: any) => void;
  renderOptions: (option: Option) => React.ReactNode;
  labelText: string;
  value?: any;
  boxProps?: object;
  options: Option[];
}

const BoxWrapper = styled(Box)`
  & option {
    font-size: 15px;
    & span {
      margin-right: 10px;
      font-size: 18px;
    }
  }
`;

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const PopperCustom = ({ style, ...props }: PopperProps) => (
  <Popper {...props} style={{ ...style, height: '0' }} placement="bottom" />
);

const SelectAutocomplete: React.FC<SelectAutocompleteProp> = (props) => {
  const { handleChange, renderOptions, labelText, value, placeholder, boxProps, options } = props;

  const [opened, setOpened] = useState(false);
  const classes = useStyles();

  return (
    <BoxWrapper {...boxProps}>
      <InputLabel htmlFor="combo-box-demo">{labelText}</InputLabel>
      <Autocomplete
        classes={{
          option: classes.option,
        }}
        onChange={handleChange}
        value={value}
        id="combo-box-demo"
        options={options}
        autoHighlight
        getOptionLabel={(option: Option) => option.label}
        PopperComponent={PopperCustom}
        fullWidth
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
        renderOption={renderOptions}
        className={opened ? 'openedAutocomplete' : ''}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
    </BoxWrapper>
  );
};

export default SelectAutocomplete;

import LicencePlaceholder from 'assets/icons/LicencePlaceholder.svg';
import { Tooltip } from 'components';
import { format } from 'date-fns';
import { useMixpanel } from 'hooks';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'tailwind';
import { OrgLicense } from 'types/graphql';
import { extractCityName } from 'utils/helpers';

interface ISiteLicenceProps {
  licence: OrgLicense;
  isDisabled: boolean;
  stats: {
    projects: number;
    members: number;
  };
}

const SiteLicence = ({ licence, isDisabled, stats }: ISiteLicenceProps) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { license: { stripeProduct, id }, subscription } = licence;
  const { mixpanelTrack } = useMixpanel();

  const formatDateTime = (
    unixTimeSeconds: number = 0,
  ) => format(new Date((unixTimeSeconds || 0) * 1000), 'do MMMMMM yyyy');

  const renewalDate = formatDateTime(subscription.current_period_end);
  const bgImg = stripeProduct.images?.[0] || LicencePlaceholder;

  return (
    <article className='w-full p-3 transition duration-300 ease-in-out bg-white border rounded border-placeholder hover:shadow hover:shadow-slate-400'>
      <div className='flex items-center gap-2'>
        <span
          className='w-[60px] h-[60px] rounded flex-shrink-0'
          style={{ background: `url(${bgImg}) center / cover no-repeat` }}
        />
        <h5>{extractCityName(stripeProduct.name)}</h5>
      </div>

      <p className='h-10 my-2 overflow-auto text-sm leading-5 text-grey'>
        {stripeProduct.description}
      </p>

      <div className='flex flex-wrap gap-2 py-2 mb-2 border-y border-y-primary-lighter'>
        <div className='flex justify-between w-full text-sm text-grey'>
          <Link
            to='/account/organisation/members'
            onClick={() => mixpanelTrack("Clicked on 'No. of assigned members'")}
          >
            <Button variant='text' padding='unset' className='py-0 h-fit' size='sm'>
              No. of assigned members
            </Button>
          </Link>
          <span className='font-medium'>{stats.members || 0}</span>
        </div>

        <div className='flex justify-between w-full text-sm text-grey'>
          <p className='text-sm'>Org projects using licence</p>
          <span className='font-medium'>{stats.projects || 0}</span>
        </div>

        <div className='flex justify-between w-full text-sm text-grey'>
          <p className='text-sm'>Renewal date</p>
          <span className='font-medium'>{renewalDate}</span>
        </div>
      </div>

      <Tooltip isVisible={isDisabled} header='Assign the site licence' content='You will need this site licence assigned to you to create a project with it. '>
        <div>
          <Button
            variant="contained"
            size='xs'
            padding='sm'
            disabled={isDisabled}
            onClick={() => {
              if (isDisabled) return null;
              history.push(`/projects/create?licence=${id}`, { prevPath: pathname });
              mixpanelTrack("Clicked on 'Create project' from site licences", {
                'Site licence': stripeProduct.name,
              });
            }}
          >
            Create project
          </Button>
        </div>
      </Tooltip>
    </article>
  );
};

export default SiteLicence;

import { gql, TypedDocumentNode } from '@apollo/client';
import { User } from 'types/graphql';

export const GET_ORG_SUBSCRIPTIONS: TypedDocumentNode<{ currentUser: User; }> = gql`
  query getSubscriptions {
    currentUser {
      id
      organisation {
        id
        trialAvailable
        trialActive
        siteLicenses {
          license {
            id
          }
        }
        seatStats {
          floating {
            assigned
            total
          }
        }
        subscriptions {
          id
          interval
          status
          trial_end
          cityLicenses {
            id
            status
            license {
              name
              id
              location {
                id
              }
              stripeProduct {
                name
              }
            }
          }
          stats {
            plus {
              assigned
              total
            }
            pro {
              assigned
              total
            }
            floating {
              assigned
              total
            }
          }
        }
      }
    }
  }
`;

export const GET_URL = gql`
  mutation getStripeUrl($return_url: String) {
    createStripePortal(return_url: $return_url)
  }
`;

import { TableHeaderRow } from '@devexpress/dx-react-grid';
import { Box } from '@material-ui/core';
import { NorthIcon, SouthIcon, SwitchIcon, theme } from '@vucity/design_system';
import React from 'react';

const iconStyle = {
  width: '20px',
  height: '20px',
  color: theme.palette.placeholder.main,
};
interface ISortIcon { direction: string; disabled: boolean }
interface ISortLabel extends React.PropsWithChildren<TableHeaderRow.SortLabelProps> { }

const SortingIcon = ({ direction, disabled }: ISortIcon) => {
  if (disabled) return <></>;

  return (
    direction === 'asc'
      ? <SouthIcon style={iconStyle} />
      : <NorthIcon style={iconStyle} />
  );
};

const SortLabel = ({ disabled, onSort, children, direction }: ISortLabel) => {
  if (disabled) {
    return (
      <Box display="flex" alignItems="center" width="90%" justifyContent="space-between" fontSize="14px" color="#696969" fontWeight="400">
        {children}
      </Box>
    );
  }

  return (
    <Box
      onClick={() => onSort(direction === 'desc' ? { direction: null } : {})}
      display="flex"
      alignItems="center"
      width="90%"
      fontSize="14px"
      color="#696969"
      fontWeight="400"
    >
      <Box pr={1} height={20}>
        {(direction
          ? <SortingIcon direction={direction} disabled={disabled} />
          : <SwitchIcon style={iconStyle} />
        )}
      </Box>
      {children}
    </Box>
  );
};

export default SortLabel;

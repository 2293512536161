import { useQuery } from '@apollo/client';
import { Cloudinary } from '@cloudinary/url-gen';
import { useMemo } from 'react';
import CLOUDINARY_TOKEN from './apollo';

const instance = new Cloudinary({
  cloud: {
    cloudName: import.meta.env.VITE_CLOUDINARY_DOMAIN,
  },
});

const useCloudinary = () => {
  const { data } = useQuery(CLOUDINARY_TOKEN, {
    fetchPolicy: 'cache-only',
  });

  const token = useMemo(() => data?.currentUser?.cloudinaryToken, [data]);
  return [instance, token];
};

export default useCloudinary;

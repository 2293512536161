import { useQuery } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';
import { License } from 'types/graphql';
import { sortLicences } from 'utils/helpers';
import { extractCityName } from './../../utils/helpers';
import GET_LICENCES from './apollo';

const useLicences = () => {
  const { data, loading, error } = useQuery(GET_LICENCES);

  const allLicences: License[] = useMemo(() => {
    const userSiteLicences = data?.currentUser?.siteLicenses || [];
    const cityLicenses = data?.cityLicenses?.map((license: License) => ({
      ...license,
      name: extractCityName(license.name),
      stripeProduct: {
        ...license.stripeProduct,
        name: extractCityName(license.stripeProduct.name),
      },
    })) || [];
    const allSiteLicences = (data?.currentUser.organisation.siteLicenses || [])
      .map(({ license }) => license);

    const siteLicences = allSiteLicences
      .filter((siteL) => userSiteLicences
        .some((userL) => siteL.id === userL.licenseId));
    const concatLicences = [...cityLicenses, ...siteLicences];

    return sortLicences(uniqBy(concatLicences, 'id') || []);
  }, [data]);

  return { allLicences, loading, error };
};

export default useLicences;

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import GET_PERMISSIONS from './apollo';

const useProjectPermissions = () => {
  const { projectId } = useParams<{ projectId?: string; }>();

  const { data, loading } = useQuery(
    GET_PERMISSIONS,
    {
      variables: { projectId },
      skip: !projectId,
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-only',
    },
  );

  return { permissions: data?.project?.me.permissions || [], loading };
};

export default useProjectPermissions;

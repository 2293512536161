import { MenuItem } from '@material-ui/core';
import { useCopyNodes, useMixpanel } from 'hooks';
import React from 'react';
import { SimpleLoadingModal, StorageLimit } from 'components/Modal';
import { Folder } from 'types/graphql';
import { IContextMenuBase, IFileRow } from '../../../types';

interface IPasteProps extends IContextMenuBase {
  copiedFiles: IFileRow[];
  currentFolder: Folder;
}

const Paste: React.FC<IPasteProps> = ({
  projectId, files, closeContextMenu, copiedFiles, currentFolder,
  setIsUpdated,
}) => {
  const { mixpanelTrack } = useMixpanel();
  const { copyNodes, loading, errorCode, errorData: { spaceNeeded } } = useCopyNodes(
    () => {
      setIsUpdated(true);
      closeContextMenu();
    },
    (e: string) => {
      if (e !== 'INSUFFICIENT_DRIVE_SPACE') { closeContextMenu(); }
    },
  );

  const onPaste = () => {
    const filesToPaste = copiedFiles.map((file: IFileRow) => ({ id: file.id }));
    const folderId = files[0]?.folder ? files[0]?.id : currentFolder.id;
    mixpanelTrack('Paste');

    copyNodes(filesToPaste, folderId as string, projectId);
  };

  return (
    <>
      {copiedFiles.length > 0 && <MenuItem onClick={onPaste}>
        Paste
      </MenuItem>}

      {errorCode === 'INSUFFICIENT_DRIVE_SPACE'
        && <StorageLimit
          projectId={projectId}
          spaceNeeded={spaceNeeded}
          closeModal={closeContextMenu}
        />
      }

      {loading && (
        <SimpleLoadingModal
          title="Copying files"
          message="Please wait... files are being copied"
        />
      )}
    </>
  );
};

export default Paste;

import { useMutation } from '@apollo/client';
import MultiToggleDropdown from 'components/MultiToggleDropdown';
import { useHandleError, useMixpanel } from 'hooks';
import { uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { OrgMemberRole, UserSiteLicense } from 'types/graphql';
import { ASSIGN_SITE_LICENSE, REMOVE_SITE_LICENSE } from 'utils/apollo/user';
import { MEMBER_ROLE } from 'views/organisation/members/constants';
import GET_ORG_MEMBERS from 'views/organisation/members/data';
import { MultiToggleOption } from 'views/types';

interface Props {
  row: any;
  licenses?: MultiToggleOption[];
  userAssigned?: boolean;
  currentUser?: {
    id: string;
    role?: OrgMemberRole;
  };
  siteLicenses?: {
    memberId: string;
    siteLicenses: UserSiteLicense[];
  }[];
}

export default function ToggleDropdown({ row, licenses, userAssigned, siteLicenses, currentUser }: Props) {
  const { userId, name, status } = row;

  const [memberName] = name;
  const { mixpanelTrack } = useMixpanel();
  const [values, setValues] = useState<string[]>([]);
  const [handleError, enqueueSnackbar] = useHandleError();

  useEffect(() => {
    if (userId) {
      const orgSiteLicenses = siteLicenses?.find((licence) => licence.memberId === userId)?.siteLicenses || [];
      if (orgSiteLicenses.length > 0) {
        setValues(orgSiteLicenses.map(license => license.licenseId));
      }
    }
  }, [siteLicenses, userId]);

  const [assignSiteLicense] = useMutation(ASSIGN_SITE_LICENSE);
  const [removeSiteLicense] = useMutation(REMOVE_SITE_LICENSE);

  const onCaretClick = (open: boolean) => {
    if (!open) return;
    mixpanelTrack('Site licence dropdown opened', {
      'Affected Member': memberName,
    });
  };

  const handleOnSwitchChange = (_: number, option: MultiToggleOption) => {
    const assign = values.indexOf(option.value) === -1;
    const extraProps = {
      'Site License Name': option.header,
      'Affected Member': memberName,
    };
    mixpanelTrack('Site licence toggled', {
      Assigned: assign,
      ...extraProps,
    });

    if (!assign) {
      return removeSiteLicense({
        variables: { licenseId: option.value, userId },
        onCompleted: () => {
          mixpanelTrack('Site licence unassigned successfully', extraProps);
          enqueueSnackbar(`${option.header} successfully removed`, { variant: 'success' });
        },
        update: (cache) => {
          const data = cache.readQuery({ query: GET_ORG_MEMBERS });

          if (data && data.currentUser.organisation && data.currentUser.organisation.members) {
            const memberIndex = data.currentUser.organisation.members.findIndex(member => member.profile?.id === userId);
            if (memberIndex > -1) {
              const member = data.currentUser.organisation.members[memberIndex];
              const updatedSiteLicenses = member.siteLicenses?.filter(license => license.licenseId !== option.value) || [];
              const updatedMember = {
                ...member,
                siteLicenses: updatedSiteLicenses,
              };

              const updatedMembers = [
                ...data.currentUser.organisation.members.slice(0, memberIndex),
                updatedMember,
                ...data.currentUser.organisation.members.slice(memberIndex + 1),
              ];

              cache.writeQuery({
                query: GET_ORG_MEMBERS,
                data: {
                  ...data,
                  currentUser: {
                    ...data.currentUser,
                    organisation: {
                      ...data.currentUser.organisation,
                      members: updatedMembers,
                    },
                  },
                },
              });
            }
          }
        },
        onError: handleError,
      });
    }

    return assignSiteLicense({
      variables: { licenseId: option.value, userId },
      onCompleted: () => {
        mixpanelTrack('Site Licence Assigned successfully', extraProps);
        enqueueSnackbar(`${option.header} successfully assigned`, { variant: 'success' });
      },
      update: (cache, { data: { addUserSiteLicense } }) => {
        const data = cache.readQuery({ query: GET_ORG_MEMBERS });

        if (data && data.currentUser.organisation && data.currentUser.organisation.members) {
          const memberIndex = data.currentUser.organisation.members.findIndex(member => member.profile?.id === userId);
          if (memberIndex > -1) {
            const member = data.currentUser.organisation.members[memberIndex];
            const updatedMember = {
              ...member,
              siteLicenses: member.siteLicenses ? uniqBy([...member.siteLicenses, ...addUserSiteLicense], 'licenseId') : addUserSiteLicense,
            };

            const updatedMembers = [
              ...data.currentUser.organisation.members.slice(0, memberIndex),
              updatedMember,
              ...data.currentUser.organisation.members.slice(memberIndex + 1),
            ];

            cache.writeQuery({
              query: GET_ORG_MEMBERS,
              data: {
                ...data,
                currentUser: {
                  ...data.currentUser,
                  organisation: {
                    ...data.currentUser.organisation,
                    members: updatedMembers,
                  },
                },
              },
            });
          }
        }
      },
      onError: handleError,
    });
  };

  if (status === 'Removed' || status === 'Declined' || !userId) return null;

  return (
    <MultiToggleDropdown
      options={licenses || []}
      values={values}
      onSwitchChange={handleOnSwitchChange}
      onCaretClick={onCaretClick}
      readOnly={currentUser?.role === MEMBER_ROLE}
      defaultAssign={userAssigned && userId === currentUser?.id}
    />
  );
}

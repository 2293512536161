/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

import { useMixpanel } from 'hooks';
import { bytesToSize } from 'utils/helpers';

import { Downloading, Error, Rendering } from './states';
import { Scene } from './Viewer3D.style';

interface IViewer3D {
  filePath: string,
  fileType: string,
  fileSize: number,
}

function disposeNode(parentObject: any) {
  parentObject.traverse((node: any) => {
    if (node instanceof THREE.Mesh) {
      node.geometry?.dispose();

      if (node.material) {
        try {
          node.material.map?.dispose();
          node.material.lightMap?.dispose();
          node.material.bumpMap?.dispose();
          node.material.normalMap?.dispose();
          node.material.specularMap?.dispose();
          node.material.envMap?.dispose();

          node.material.dispose(); // disposes any programs associated with the material
        } catch (err) {
          // console.log(error);
        }
      }
    }
  });
} // disposeNode

function disposeHierarchy(node: any, callback: any) {
  if (node?.children) {
    for (let i = node.children.length - 1; i >= 0; i = -1) {
      const child = node.children[i];
      disposeHierarchy(child, callback);
      callback(child);
    }
  }
}

const Viewer3D = ({ filePath, fileType, fileSize }: IViewer3D) => {
  const fileSizeLimit = 94370000;
  const { enqueueSnackbar } = useSnackbar();
  const { mixpanelTrack } = useMixpanel();

  const [rerender, setRerender] = useState(false); // used for loading new models
  const [finished, setFinished] = useState(false); // used for "Rendering" screen
  const [error, setError] = useState({ state: false, message: '' }); // used for "Error" screen
  const [loading, setLoading] = useState(0); // used for "Downloading" screen

  const mountRef = useRef<any>(null); // the dom element used for attaching the scene

  const prevFilePathRef: React.MutableRefObject<string | undefined> = useRef();

  useEffect(() => {
    prevFilePathRef.current = filePath;
  });

  const prevFilePath = prevFilePathRef.current;

  useEffect(() => {
    // rerender only when file is different - using filePath
    if (prevFilePath && prevFilePath !== filePath) {
      setRerender((r) => !r); // each switch triggers rerender no matter true or false
      // reset the states
      setFinished(false);
      setError({ state: false, message: '' });
      setLoading(0);
    }
  }, [filePath, prevFilePath]);

  const fitCameraToSelection = useCallback(
    (scene: any, camera: any, controls: any, selection: any, fitOffset = 1.2) => {
      const box = new THREE.Box3();

      selection.forEach((object: THREE.Object3D) => box.expandByObject(object));

      const size = box.getSize(new THREE.Vector3());
      const center = box.getCenter(new THREE.Vector3());

      const maxSize = Math.max(size.x, size.y, size.z);
      const fitHeightDistance = maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
      const fitWidthDistance = fitHeightDistance / camera.aspect;
      const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

      const direction = controls.target.clone()
        .sub(camera.position)
        .normalize()
        .multiplyScalar(distance);

      controls.maxDistance = distance * 10;
      controls.target.copy(center);

      camera.near = distance / 100;
      camera.far = distance * 100;
      camera.position.copy(controls.target).sub(direction);

      const ambientLight = new THREE.AmbientLight('#aaaaaa', 0.45);
      const pointLight = new THREE.PointLight('#ffffff', 0.5);

      camera.add(pointLight);

      scene.add(ambientLight);
      scene.add(camera);

      camera.updateProjectionMatrix();
    },
    [],
  );

  useEffect(() => {
    if (fileSizeLimit && typeof fileSizeLimit === 'number' && fileSize > fileSizeLimit) {
      const size = bytesToSize(fileSizeLimit, 0);
      setError({
        state: true,
        message: `We currently cannot preview 3D files over ${size}. 
        To see this 3D file, please launch the 3D app and import it`,
      });
      setFinished(true);

      return () => { };
    }

    const mount = mountRef.current;
    let height = mount ? mount.clientHeight : 0;
    let width = mount ? mount.clientWidth : 0;
    let frameId: number;
    let abort = false;

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xCAD6E1);
    const camera = new THREE.PerspectiveCamera(75, width / height);
    camera.position.z = 200;
    const controls = new OrbitControls(camera, mount);
    const renderer = new THREE.WebGLRenderer();
    const loader = fileType === 'obj' ? new OBJLoader() : new FBXLoader();
    // model is initialized here so it can be cleared from the scene on rerender
    let model: THREE.Group;

    const renderScene = () => {
      renderer.render(scene, camera);
    };

    const animate = () => {
      renderScene();
      frameId = requestAnimationFrame(() => animate());
    };

    // rerenders the scene,fixes the aspect ratio and updates the camera
    const handleWindowResize = () => {
      width = mount.clientWidth;
      height = mount.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderScene();
    };

    const loadModel = () => {
      loader.load(
        filePath,
        (res) => {
          model = res;
          model.position.set(0, 0, 0);
          setFinished(true); // stops the "Rendering..." screen
          fitCameraToSelection(scene, camera, controls, [model]);
          scene.add(model);
        },
        (xhr) => {
          if (abort) {
            // aborts the request while in progress with local variable
            // (each model should use different variable so state isnt an option)
            (xhr.target as XMLHttpRequest)?.abort();
          } else if (xhr.lengthComputable) {
            const percent = Math.ceil((xhr.loaded / xhr.total) * 100);
            if (percent % 5 === 0) {
              // updates the component on every 5% used in "Downloading.." screen
              // can be changed in the future for every x seconds for better UX
              setLoading(percent);
            }
          }
        },
        (e) => {
          if (!abort) {
            // when abort is true error is returned from the request
            // could be used for relevant snackbar if ever needed
            setError({ state: true, message: '' }); // calls the "Error" screen
            setFinished(true);
            Sentry.captureException(e);
            mixpanelTrack('3D_Render_failed');
            enqueueSnackbar('There is a problem rendering this asset', { variant: 'error' });
          }
        },
      );
    };

    loadModel();
    handleWindowResize();
    animate();
    mount.appendChild(renderer.domElement);
    window.addEventListener('resize', () => handleWindowResize());

    return () => {
      window.removeEventListener('resize', () => handleWindowResize());
      cancelAnimationFrame(frameId);
      abort = true; // helps with stopping the request
      disposeHierarchy(model, disposeNode);
      controls.dispose();
      mount.removeChild(renderer.domElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rerender]);

  return (
    <>
      {loading !== 100
        ? <Downloading loading={loading} />
        : !finished && <Rendering />
      }
      {error.state
        ? <Error text={error.message} />
        : <Scene ref={mountRef} />}
    </>
  );
};

export default Viewer3D;

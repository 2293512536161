import { gql } from '@apollo/client';

const project = `
  id
  name
  description
  imageId
  reference
  createdAt
  updatedAt
  hiddenFor
  access
  owner {
    id
    name
    email
    picture
  }
  me {
    status
    role
  }
  location {
    license {
      id
      name
      stripeProduct {
        name
      }
      location {
        id
        name
        displayName
      }
    }
    coords {
      x
      y
      convert (epsg: 4326) {
        x
        y
      }
    }
  }
`;
const GET_PROJECTS = gql`
  query UserProjects {
    currentUser {
      id
      emailVerified
      projects {
        ${project}
      }
      availableToAllProjects{
        ${project}
      }
      inviteOnlyProjects{
        ${project}
      }
      tier {
        name
        slotsRemaining
        slots
        slotsUsed
      }
    }
  }
`;

export const UPDATE_PROJECT_VISIBILITY = gql`
  mutation UpdateProjectVisibility($id: ID!,$project: UpdateProjectInput!) {
    updateProject(id: $id, project: $project) {
      id
    }
  }
`;

export default GET_PROJECTS;

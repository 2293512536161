import { ReactComponent as ExpandCloseSvg } from 'assets/icons/ExpandClose.svg';
import { ReactComponent as ExpandOpenSvg } from 'assets/icons/ExpandOpen.svg';
import { useSelect } from 'downshift';
import React, { useCallback, useRef } from 'react';
import { MultiToggleDropdownProps, MultiToggleOption } from 'views/types';
import SelectWrap, {
  OptionItem, OptionList, ToggleSwitch,
} from './MultiToggleDropdown.style';

const MultiToggleDropdown: React.FC<MultiToggleDropdownProps> = ({ variant = 'default', ...props }) => {
  const { options, readOnly, onSwitchChange, values, onCaretClick, defaultAssign } = props;
  const ref = useRef<HTMLDivElement>(null);
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
  } = useSelect({
    items: options,
    itemToString: () => '',
    onIsOpenChange: ({ isOpen: open }) => {
      onCaretClick?.(!!open);
    },
  });

  const nearBottom = useCallback(() => {
    const rect = ref?.current?.getBoundingClientRect();
    return isOpen && rect && rect.top > 500;
  }, [isOpen]);

  const handleChange = (e: React.MouseEvent, index: number, option: MultiToggleOption) => {
    e.stopPropagation();
    onSwitchChange(index, option);
  };

  const renderAssignmentLabel = () => {
    if (defaultAssign) return 'Assigned';
    return values?.some((val) => options.some((opt) => opt.value === val)) ? 'Assigned' : '-';
  };

  return (
    <SelectWrap
      ref={ref}
      isOpen={isOpen && options.length > 0}
      variant={variant}
      role="combobox"
      className='multi-toggle'
    >
      <div className='flex items-center justify-between w-full gap-1 cursor-pointer' {...getToggleButtonProps()}>
        <label className='min-w-[65px]' {...getLabelProps()}>
          {renderAssignmentLabel()}
        </label>

        {!readOnly &&
          isOpen ? <ExpandCloseSvg /> : <ExpandOpenSvg />
        }
      </div>

      {options.length > 0 && (
        <OptionList
          nearBottom={nearBottom()}
          isOpen={isOpen}
          {...getMenuProps()}
        >
          {options.map((option, index) => (
            <OptionItem
              assigned={values?.includes(option.value)}
              key={index} onClick={(e) => handleChange(e, index, option)}
            >
              <div className='flex w-full justify-between items-center mb-0.5'>
                <p className='whitespace-pre-line __item-header min-h-fit'>{option.header}</p>
                <ToggleSwitch
                  id={`switch-${option.value}`}
                  checked={values?.includes(option.value)}
                />
              </div>

              <p className='text-xs text-grey'>
                {option.subHeader}
              </p>
            </OptionItem>
          ))}
        </OptionList>
      )}
    </SelectWrap>
  );
};

export default MultiToggleDropdown;

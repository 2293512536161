import copy from 'copy';
import React from 'react';
import { Button, DeleteButton } from './EmailButton.style';

interface IEmailButtonProps {
  email: string;
  onDeleteEmail: (arg: string) => void;
  isError: {
    isValid: boolean;
    isSelf: boolean;
    isUnauthorised: boolean;
  };
}

const { emailErrorMsg } = copy;

const EmailButton = ({ email, isError, onDeleteEmail }: IEmailButtonProps) => {
  const error = isError.isValid || isError.isSelf || isError.isUnauthorised;

  return (
    <div className='flex flex-col items-start justify-start'>
      <Button
        isError={error}
        className={`
          ${error ? 'mb-0.5 border-error' : 'mb-1.2 border-primary-lightest'}
           flex gap-1 rounded-sm border bg-white py-0.5 px-1 items-center justify-between
        `}
      >
        {email}

        <DeleteButton
          className='w-3 h-3 m-0 transition duration-500 border-none cursor-pointer bg-grey'
          onClick={() => onDeleteEmail(email)}
        />
      </Button>

      <div className='mb-0'>
        {isError.isUnauthorised &&
          <div className='mb-1 text-xs whitespace-pre-line text-error'>
            {emailErrorMsg.UNAUTHORISED}
          </div>
        }
        {isError.isValid &&
          <div className='mb-1 text-xs whitespace-pre-line text-error'>
            {emailErrorMsg.INVALID}
          </div>
        }

        {isError.isSelf &&
          <div className='mb-1 text-xs whitespace-pre-line text-error'>
            {emailErrorMsg.OWN_EMAIL}
          </div>
        }
      </div>
    </div>
  );
};

export default EmailButton;

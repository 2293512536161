import React from 'react';
import { Button, TopNav } from 'tailwind';

interface IErrorLayoutProps {
  withHeader?: boolean;
  title: string | null;
  message?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
}

const ErrorLayout: React.FC<IErrorLayoutProps> = ({
  title, message, primaryButtonText, secondaryButtonText,
  onPrimaryClick, onSecondaryClick, withHeader = true,
}) => (
  <>
    {withHeader && <TopNav />}

    <div className='flex items-center justify-center h-content bg-primary-lightest'>
      <div className='p-5 rounded text-primary bg-white max-w-[660px] w-full text-center'>
        <h4>{title}</h4>

        {message && (
          <p className='mt-4'>{message}</p>
        )}

        <div className='p-2' />

        {primaryButtonText && (
          <Button
            variant="contained"
            className="mx-2"
            onClick={onPrimaryClick}
          >
            {primaryButtonText}
          </Button>
        )}

        {secondaryButtonText && (
          <Button onClick={onSecondaryClick}>
            {secondaryButtonText}
          </Button>
        )}
      </div>
    </div>
  </>
);

export default ErrorLayout;

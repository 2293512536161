import React from 'react';
import { cls } from 'utils/helpers';

export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: React.ReactNode;
  color?: 'primary' | 'secondary' | 'content' | 'error';
  size?: 'unset' | 'xs' | 'sm' | 'md' | 'lg' | 'base';
  padding?: 'unset' | 'none' | 'sm' | 'md';
  variant?: 'outlined' | 'contained' | 'text' | 'light';
  fakeDisabled?: number | boolean | undefined;
  href?: string;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  children,
  color = 'primary',
  size = 'lg',
  variant = 'outlined',
  padding = 'md',
  disabled,
  fakeDisabled,
  fullWidth,
  startIcon,
  endIcon,
  href,
  ...rest
}, ref) => {
  const classes = {
    base: 'inline-flex items-center justify-center gap-1 font-medium tracking-wide font-sans transition duration-300 ease-in-out rounded focus:outline-none',
    padding: {
      unset: '', // unset for custom padding
      none: 'px-0', // padding right/left 0px
      sm: 'px-3', // padding right/left 24px
      md: 'px-5', // padding right/left 40px
    },
    size: {
      unset: '', // unset for custom size
      xs: 'h-4 py-1 text-sm', // height 32px font-size 14px
      sm: 'h-5 py-1 text-sm', // height 40px font-size 14px
      base: 'h-5 py-1 text-base', // height 40px font-size 16px
      md: 'h-5 py-1 text-lg', // height 40px font-size 18px
      lg: 'h-6 py-1.2 text-lg', // height 48px font-size 18px
    },
    variant: {
      light: 'border sm:justify-start text-content border-primary-lightest hover:bg-primary-lightest disabled:font-medium disabled:bg-content-light disabled:text-grey [&_path]:disabled:fill-grey',
      outlined: {
        primary: 'bg-white border text-primary border-primary hover:text-primary-light hover:border-primary-light disabled:text-primary-lighter disabled:border-primary-lighter',
        secondary: 'bg-white border text-secondary border-secondary hover:text-secondary-light hover:border-secondary-light disabled:text-secondary-lighter disabled:border-secondary-lighter',
        content: 'bg-white border text-content border-content hover:opacity-40 disabled:text-content-light disabled:border-content-light',
        light: 'bg-white border text-content border-content',
        error: 'bg-white border text-error border-error hover:text-error-light hover:border-error-light disabled:text-error-light disabled:border-error-light',
      },
      contained: {
        primary: 'text-white border bg-primary border-primary hover:bg-white hover:text-primary disabled:bg-primary-lighter disabled:border-primary-lighter disabled:text-white',
        secondary: 'text-white border bg-secondary border-secondary hover:bg-white hover:text-secondary disabled:bg-secondary-lighter disabled:border-secondary-lighter disabled:text-white',
        content: 'text-white border bg-content border-content hover:opacity-40 disabled:bg-content-light disabled:border-content-light disabled:text-white',
        error: 'text-white border bg-error border-error hover:bg-white hover:text-error disabled:bg-error-light disabled:border-error-light disabled:text-white',
      },
      text: {
        primary: 'text-primary hover:underline disabled:text-primary-lighter disabled:hover:no-underline',
        secondary: 'text-secondary hover:underline disabled:text-secondary-lighter disabled:hover:no-underline',
        content: 'text-content hover:underline disabled:text-content-light disabled:hover:no-underline',
        error: 'text-error hover:underline disabled:text-error-light disabled:hover:no-underline',
      },
    },
    icon: variant === 'light' ? '[&>svg]:h-2 [&>svg]:w-2' : '[&>svg]:h-2.5 [&>svg]:w-2.5',
    fakeDisabled: 'text-primary-lighter border-primary-lighter',
  };

  const className = cls(`
    ${classes.base} ${classes.padding[padding]} ${classes.size[size]}
    ${variant === 'light' ? classes.variant.light : classes.variant[variant][color]}
    ${fullWidth && 'w-full'}
    ${fakeDisabled && classes.fakeDisabled}
    ${rest.className}
  `);

  if (href) {
    return (
      <a
        href={href}
        className={className}
        target="_blank"
      >
        {startIcon && <span className={classes.icon}>{startIcon}</span>}
        {children}
        {endIcon && <span className={classes.icon}>{endIcon}</span>}
      </a>
    );
  }

  return (
    <button
      ref={ref}
      {...rest}
      className={className}
      disabled={disabled}
    >
      {startIcon
        && <span className={classes.icon}>
          {startIcon}
        </span>
      }

      {children}

      {endIcon
        && <span className={classes.icon}>
          {endIcon}
        </span>
      }
    </button>
  );
});

export default Button;

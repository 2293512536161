import { DetailedSelect } from 'components';
import { useMixpanel } from 'hooks';
import React, { useState } from 'react';
import { Button, MultipleEmailBox } from 'tailwind';
import { OrgMemberInvite, OrgMemberRole } from 'types/graphql';
import organisationMemberRoles from 'utils/configs/organisationMemberRoles';

interface IMultipleProps {
  onUpdate: (members: OrgMemberInvite[]) => void;
  defaultTier: string;
  setView: (arg: string) => void;
  setCounter: (arg: number) => void;
}

const MultipleOrgInvites = ({ setView, onUpdate, setCounter, defaultTier }: IMultipleProps) => {
  const { mixpanelTrack } = useMixpanel();

  const [role, setRole] = useState<OrgMemberRole>('MEMBER');
  const [multiMembers, setMultiMembers] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const onReview = () => {
    const members: OrgMemberInvite[] = multiMembers.map(m => ({ email: m, role, tierId: defaultTier, interval: 'year' }));
    members.push({ email: '', role, tierId: defaultTier, interval: 'year' });

    mixpanelTrack('Review invites - Button Clicked', {
      'No. of emails for invite review': members.length - 1,
    });

    onUpdate(members);
    setView('review');
  };

  const onRoleChange = (updatedRole: OrgMemberRole) => {
    mixpanelTrack('Bulk Organisation Role - Update', { Role: updatedRole });
    setRole(updatedRole);
  };

  const onAddEmails = (emails: string[]) => {
    setMultiMembers(emails);
    setCounter(emails.length);
  };

  const counter = multiMembers.filter(m => m).length;

  return (
    <div className='flex items-start justify-between gap-2'>
      <div className='flex gap-2'>
        <MultipleEmailBox
          className='min-w-330'
          emails={multiMembers}
          setEmails={onAddEmails}
          setErrors={setErrors}
          label='Enter email separated by space'
        />

        <div className='flex flex-col w-330'>
          <label className='mb-1 text-sm'>Organisation role</label>
          <DetailedSelect
            items={organisationMemberRoles}
            variant="outlined"
            id="member-role"
            value={role.toLowerCase()}
            handleChange={(item) => onRoleChange(item?.value.toUpperCase() as OrgMemberRole || 'MEMBER')}
          />
        </div>
      </div>

      <div>
        <Button
          className='mt-3'
          padding='sm'
          variant='outlined'
          disabled={!counter || errors.length > 0}
          onClick={onReview}
        >
          Review invites
        </Button>
      </div>
    </div>
  );
};

export default MultipleOrgInvites;

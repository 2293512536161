import React from 'react';

export interface LabelProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  children: React.ReactNode | string;
  variant?: 'primary' | 'inactive' | 'error';
  icon?: React.ReactNode;
}

const Label = ({ children, variant = 'primary', icon, ...props }: LabelProps) => {
  const classes = {
    primary: 'bg-secondary-lightest text-primary',
    inactive: 'bg-grey-light text-base [&_path]:fill-content',
    error: 'bg-error-light text-error',
  };

  return (
    <span {...props} className={`${classes[variant]} inline-flex gap-1 h-3 px-1 items-center rounded w-fit [&_svg]:w-[14px] [&_svg]:h-[14px] ${props.className}`}>
      {icon}

      <span className='text-xs'>
        {children}
      </span>
    </span>
  );
};

export default Label;

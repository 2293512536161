import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { SelectButton } from 'tailwind';
import { ISingleOverlay } from 'views/coverage/types';

import { ReactComponent as BouroughFilled } from '../../icons/boroughsFilled.svg';
import { ReactComponent as BouroughOutlined } from '../../icons/boroughsOutlined.svg';

const Boroughs = ({ selected, disabled, tiles, onClick }: ISingleOverlay) => (
  <>
    <SelectButton
      fullWidth
      selected={selected}
      onClick={() => onClick('boroughs')}
      startIcon={selected || disabled ? <BouroughFilled /> : <BouroughOutlined />}
      disabled={disabled}
    >
      Boroughs
    </SelectButton>

    {selected && tiles && (
      <>
        <Source id='UK Boroughs' type='vector' tiles={[tiles[0]]}>
          <Layer
            id='UK Boroughs'
            source-layer='default'
            type='fill'
            layout={{
              visibility: 'none',
            }}
            minzoom={8}
          />
        </Source>

        <Source id='boroughs_line' type='vector' tiles={[tiles[1]]}>
          <Layer
            id='boroughs_line'
            source-layer='default'
            type='line'
            minzoom={8.5}
            paint={{
              'line-color': '#375675',
              'line-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                8, 0.1, 11, 1,
              ],
            }}
          />
        </Source>
      </>
    )}
  </>
);

export default Boroughs;

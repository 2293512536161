import { ApolloError, useMutation } from '@apollo/client';

import { IRefetchProps } from '../useCopyNodes';
import useHandleError from '../useHandleError';
import useMixpanel from '../useMixpanel';

import CREATE_FOLDER from './apollo';

export const useCreateFolder = (
  refetchQueries: IRefetchProps,
  onCompleted: ()=>void,
  onError?: (e: ApolloError)=>void,
) => {
  const [handleError, enqueueSnackbar] = useHandleError();
  const { mixpanelTrack } = useMixpanel();
  const [createFolder, { data, loading, error }] = useMutation(CREATE_FOLDER, {
    onError: (e) => {
      mixpanelTrack('Add new folder Failed');
      if (onError) return onError(e);
      return handleError(e);
    },
    awaitRefetchQueries: true,
    refetchQueries,
    onCompleted: () => {
      mixpanelTrack('Add new folder Successful');
      enqueueSnackbar('Folder created', { variant: 'success' });
      if (onCompleted) onCompleted();
    },
  });

  const handleCreateFolder = (
    folderId: string | string[], name: string, projectId?: string,
  ) => {
    createFolder({
      variables: {
        projectId,
        folder: { id: folderId },
        name: name?.trim(),
      },
    });
  };

  return {
    createFolder: handleCreateFolder,
    data,
    folderLoading: loading,
    folderError: error?.message,
  };
};

export default useCreateFolder;

import { useHandleError } from 'hooks';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const Fallback: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [, enqueueSnackbar] = useHandleError();

  useEffect(() => {
    enqueueSnackbar('You are not authorized to access this page', { preventDuplicate: true, variant: 'error' });

    if (pathname.includes('desktop')) history.push('/account/profile');
    else history.push('/account/organisation/members');
  }, [enqueueSnackbar, history, pathname]);

  return (
    <div className='flex items-center justify-center w-full h-full'>
      <p className='text-lg'>You are not authorized to access this page.</p>
    </div>
  );
};

export default Fallback;

import ConfirmInvitationsModal from 'components/Modal/ConfirmInvitations/ConfirmInvitations';
import React from 'react';
import { OrgMemberInvite } from 'types/graphql';
import CancelInvite from './components/CancelInvite';

interface IInviteBtnProps {
  members: OrgMemberInvite[];
  disabled: boolean;
  onSubmit: any;
}

const InviteButtons = ({ members, disabled, onSubmit }: IInviteBtnProps) => {
  const subscriptionCounts = members.reduce((result: { [key: string]: number; }, member) => {
    const { tierId = '' } = member;

    if (!result[tierId]) {
      result[tierId] = 0;
    }

    result[tierId]++;
    return result;
  }, {});

  return (
    <div className='flex items-center justify-between'>
      <CancelInvite returnText='Back' />

      <ConfirmInvitationsModal
        headerText='Invite new org members'
        buttonLabel='Invite new members'
        clickHandle={onSubmit}
        disabled={disabled}
        invitesLength={members.length}
        subscriptionCounts={subscriptionCounts}
      />
    </div>
  );
};

export default InviteButtons;

import { gql } from '@apollo/client';

export const GET_ROLE = gql`
  query getProjectRole($id: ID!) {
    project(id: $id) {
      id
      me {
        role
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject($project: ProjectInput!) {
    createProject(project: $project) {
      id
      drive {
        nodeByPath {
          id
        }
      }
    }
  }
`;

export const COPY_NODES = gql`
  mutation ($nodes: [NodeInput], $projectId: ID, $folder: NodeInput!) {
    copyNodes(nodes: $nodes, projectId: $projectId, folder: $folder) {
      id
      name
    }
  }
`;

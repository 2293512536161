import React from 'react';
import { File } from '../../../../types/graphql';
import { InvalidFiles, SimpleLoadingModal, StorageLimit } from '../../../Modal';

interface ITransferLoadingProps {
  projectId?: string;
  state: {
    loading: boolean;
    error?: string;
  };
  errorData: {
    invalidFiles: File[];
    spaceNeeded: number;
    primaryVuFile?: File;
  };
  isMoving?: boolean;
  onCancel: () => void;
  onCloseModal: () => void;
}

const TransferLoading: React.FC<ITransferLoadingProps> = ({
  projectId, state, isMoving, errorData, onCloseModal,
}) => {
  const { loading, error } = state;
  const { spaceNeeded, invalidFiles, primaryVuFile } = errorData;

  const handleError = (errorCode: string) => {
    switch (errorCode) {
      case 'INSUFFICIENT_DRIVE_SPACE':
        return <StorageLimit
          projectId={projectId}
          closeModal={onCloseModal}
          spaceNeeded={spaceNeeded}
          mainBtn="Review selection"
        />;
      case 'FILE_DRIVE_LOCATION_MISMATCH':
        return <InvalidFiles
          files={invalidFiles}
          title="3D file does not match project"
          message="The below files do not match your project’s location."
          closeModal={onCloseModal}
        />;
      case 'MOVE_PRIMARY_VU_FILE':
        return primaryVuFile?.id && <InvalidFiles
          files={[primaryVuFile]}
          title="Cannot move the primary file"
          message="This is the VU.CITY primary file and cannot be moved while it is still nominated as primary."
          closeModal={onCloseModal}
        />;
      default:
        return null;
    }
  };

  return (
    <>
      {loading
        && <SimpleLoadingModal
          title={`${isMoving ? 'Moving' : 'Uploading'} files`}
          message={`Please wait while your files are being ${isMoving ? 'moved' : 'uploaded'}.`}
        />
      }

      {error && handleError(error)}
    </>
  );
};

export default TransferLoading;

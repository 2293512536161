import { useAuth0 } from '@auth0/auth0-react';
import { CloudIcon, CloudOutlineIcon, HomeIcon } from '@vucity/design_system';
import { DriveProvider } from 'components';
import UserProvider from 'components/AuthProvider/UserProvider';
import { useIsVerified, useMixpanel } from 'hooks';
import React, { useEffect, useRef } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import PrivateRoute from 'utils/routes/PrivateRoute';

import { MainLayout } from 'tailwind';
import Roadmap from 'tailwind/PageLayout/MainLayout/Roadmap/Roadmap';
import {
  Account, ContactUsForm,
  CoverageMap,
  CreateFromDrive, CreateProject,
  ErrorPage, Invitations,
  OrgInvite,
  Page404,
  PersonalDrive,
  ProjectDriveTransfer,
  Projects,
} from './views';
import Project from './views/project';

import { MenuItems } from 'tailwind/PageLayout/MainLayout/Menu/Menu';
import { ReactComponent as CoverageMapIcon } from './assets/icons/CoverageMapIcon.svg';
import { ReactComponent as CoverageMapIconFilled } from './assets/icons/CoverageMapIconFilled.svg';
import { ReactComponent as HomeOutline } from './assets/icons/HomeOutline.svg';

const mainMenu: MenuItems = [
  { icon: <HomeOutline color="primary" />, title: 'Home', url: '/', activeIcon: <HomeIcon /> },
  { icon: <CloudOutlineIcon color="primary" />, title: 'Personal drive', url: '/drive', activeIcon: <CloudIcon /> },
  {
    icon: <CoverageMapIcon />,
    title: '3D model coverage',
    url: '/coveragemap',
    activeIcon: <CoverageMapIconFilled />,
    dataAttributes: {
      ['data-tour-key']: '3D-model-coverage',
    },
  },
];

interface IParams {
  projectId: string;
  folderId?: string;
}

const App: React.FC = () => {
  const [, showVerifyModal] = useIsVerified();
  const { user: auth0User, isAuthenticated } = useAuth0();
  const { mixpanelTrack } = useMixpanel();
  const location = useLocation();
  const match = useRouteMatch<IParams>([
    '/project/:projectId/transfer/:folderId',
    '/project/:projectId/drive/:fileId',
    '/drive/:fileId',
    '/project/:projectId',
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      if (!auth0User?.email_verified) showVerifyModal(true);
    }
  }, [isAuthenticated]);

  const prevLocationRef: React.MutableRefObject<string | undefined> = useRef();

  useEffect(() => {
    prevLocationRef.current = location.pathname;
  });

  const prevLocation = prevLocationRef.current;

  useEffect(() => {
    if (prevLocation !== location.pathname) {
      let mixpanelPath = location.pathname === '/' ? '' : location.pathname;
      if (match) {
        if (match.isExact) mixpanelPath = match.path;
        else if (match.params.projectId) {
          const list = location.pathname.split('/');
          list.splice(2, 1, ':projectId');
          mixpanelPath = list.join('/');
        }
      }
      mixpanelTrack(`${mixpanelPath.substring(1)}/Changed Page`);
    }
  }, [location]);

  return (
    <Invitations>
      <UserProvider>
        <DriveProvider>
          <Switch>
            <Route exact path="/error" component={ErrorPage} />
            <PrivateRoute
              exact
              path="/projects/create"
              component={CreateProject}
            />
            <PrivateRoute
              exact
              path="/organisation-invite"
              component={OrgInvite}
            />
            <PrivateRoute path="/account" component={Account} />
            {/* <PrivateRoute
              exact
              path="/subscriptions"
              component={Subscriptions}
            />
            <PrivateRoute
              exact
              path="/subscriptions/upgrade"
              component={UpgradeSubscriptions}
            />
            <PrivateRoute
              exact
              path="/subscriptions/city-licences"
              component={CityLicenses}
            /> */}
            <PrivateRoute
              exact
              path="/contact"
              component={ContactUsForm}
            />
            <PrivateRoute
              path="/project/:projectId/transfer/:folderId?"
              exact
              component={ProjectDriveTransfer}
            />
            <PrivateRoute
              path="/create/:projectId?/drive/:fileId?"
              component={CreateFromDrive}
            />
            <Route path="/project/:projectId" component={Project} />

            <MainLayout items={[mainMenu]}>
              <Switch>
                <PrivateRoute path="/drive" component={PersonalDrive} />
                <PrivateRoute exact path="/" component={Projects} />
                <PrivateRoute path="/roadmap" component={Roadmap} />
                <PrivateRoute path="/roadmap-submit-idea" component={Roadmap} />
                <PrivateRoute path="/coveragemap" component={CoverageMap} />
                <Route exact component={Page404} />
              </Switch>
            </MainLayout>
          </Switch>
        </DriveProvider>
      </UserProvider>
    </Invitations>
  );
};

export default App;

import { PhoneInput } from 'react-international-phone';
import styled from 'styled-components';
import { StyledAvatar } from 'views/account/profile/edit/EditProfile';

export const OrgStyledAvatar = styled(StyledAvatar)`
  width: 240px;
  height: 138px;
  font-size: 14pt;
  background: #fff;
  color: #004e63;
  justify-content: center;
  border-radius: 4px;

  &:hover {
    background: #ebebeb;
  }
`;

export const Phone = styled(PhoneInput)`
  & .react-international-phone-country-selector {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border: 1px solid #A6AFB9;
    border-right: 0;
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 48px;
  }

  & .react-international-phone-country-selector-dropdown {
    position: absolute;
    bottom: 48px;
    left: 0px;
    z-index: 999;
    height: 300px;
    width: 300px;
    overflow: auto;
    background: white;
    box-shadow: 0 0 5px rgb(0 0 0 / 50%);
    border-radius: 8px;

    li {
      cursor: pointer;
      display: flex;
      gap: 16px;
      padding: 8px 16px;

      &:hover {
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }
`;

export default OrgStyledAvatar;

import { GQLWrapper, Licenses, LoadingBar, Markers, ProjectCard, SwitchStyle } from 'components';
import { useLicences } from 'hooks';
import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AddProjectBtn } from 'tailwind';
import { Project, UserTier } from 'types/graphql';
import { ILicense } from '../../types';

const VuMap = lazy(() => import('components/MapProvider/VuMap'));

interface IMapViewProps {
  projects?: Project[];
  tier: UserTier;
  refetch: () => any;
  setVisiblityPreference: (preference: string) => void;
  view: string;
  visiblityPreference: string;
}

const initialCenter: [number, number] = [-2.13848, 52.7359403];

const MapView: React.FC<IMapViewProps> = ({ projects, tier, ...rest }) => {
  const mapRef: any = useRef();
  const history = useHistory();

  const [center, setCenter] = useState<[number, number]>(initialCenter);
  const [zoom, setZoom] = useState<number>(6);
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [selectedLicense, setSelectedLicense] = useState<ILicense | any>({});

  const { allLicences, loading, error } = useLicences();

  const onFlyTo = (coords: [number, number], z: number = 12) => {
    if (mapRef.current) {
      mapRef.current.flyTo({ center: coords, zoom: z });
    }
  };

  useEffect(() => {
    if (projects) {
      setFilteredProjects(projects.filter((project: Project) => project.location !== null));
    }
  }, [projects]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setCenter(initialCenter);
      setZoom(6);
      setSelectedLicense({});

      onFlyTo(initialCenter, 6);
    });

    return () => unlisten();
  }, [history]);

  const onLicenseChange = useCallback((id: string) => {
    const filteredLicense = allLicences.find((license) => license.id === id);
    setSelectedLicense(filteredLicense);

    const { x, y } = filteredLicense?.location?.centre.convert || { x: 0, y: 0 };
    setCenter([x, y]);
    setZoom(12);

    onFlyTo([x, y], 12);
  }, [allLicences]);

  return (
    <GQLWrapper loading={loading} error={error} renderChildren={!!allLicences}>
      <SwitchStyle showBtn>
        {(style: string) => (
          <Suspense fallback={<LoadingBar />}>
            <VuMap ref={mapRef} center={center} zoom={zoom} style={style} customControls controlsClass='m-3' controlsPosition='top-right'>
              <div className='relative z-10 flex w-1/2 gap-2 pt-3 pl-3'>
                <AddProjectBtn compactMode tier={tier} />
                <div className='w-[240px] text-base'>
                  <Licenses
                    compactMode
                    licenses={allLicences || []}
                    selectedLicense={selectedLicense?.id}
                    onLicenseChange={onLicenseChange}
                  />
                </div>
              </div>

              {filteredProjects && filteredProjects.map((project) => (
                project.location?.coords
                && <Markers coords={project.location.coords} key={project.id} project={project}>
                  <ProjectCard project={project} tier={tier} {...rest}/>
                </Markers>
              ))}
            </VuMap>
          </Suspense>
        )}
      </SwitchStyle>
    </GQLWrapper>
  );
};

export default MapView;

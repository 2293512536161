import { AdvancedImage } from '@cloudinary/react';
import styled from 'styled-components';

export const BoxIconWrapperOrg = styled.div`
  border-radius: 0;
  width: 270px;
  height: 133px;
  background-color: #e6edef;
  border: 3px dotted #80a6b1;
  margin: 16px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImgWrapper = styled(AdvancedImage)`
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
  object-fit: cover;
`;

export const OrganizationImage = styled(AdvancedImage)`
  width: 240px;
  height: 112px;
  z-index: 1;
  position: relative;
  & img {
    object-fit: contain;
  }
`;

import React from 'react';
import { Checkbox } from 'tailwind/Input';

const Filters = ({ filters, setFilters }: { filters: string[]; setFilters: React.Dispatch<React.SetStateAction<string[]>>; }) => {
  const onFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (filters.includes(value))
      setFilters(prev => prev.filter(p => p !== value));
    else setFilters(prev => [...prev, value]);
  };

  return (
    <section className='flex-shrink-0 h-full px-2 py-1 border-l border-l-placeholder'>
      <p className='text-xs text-grey mb-0.5'>Add filter</p>

      <main className='flex flex-col gap-1'>
        <Checkbox name='admin' title='Admins' checked={filters.includes('admin')} onChange={onFilter} />
        <Checkbox name='editor' title='Editors' checked={filters.includes('editor')} onChange={onFilter} />
        <Checkbox name='viewer' title='Viewers' checked={filters.includes('viewer')} onChange={onFilter} />
        <Checkbox name='organisation' title='My organisation' checked={filters.includes('organisation')} onChange={onFilter} />
      </main>
    </section>
  );
};

export default Filters;

import { gql } from '@apollo/client';

const COMMENT = gql`
  fragment eventData on Event {
    id
    type
    createdAt
    projectMember {
      id
      name
      email
      profile {
        id
        name
        email
        picture
      }
    }
  }
  fragment commentData on Comment {
    id
    editedAt
    message
    file {
      id
    }
  }
`;

export const COMMENTS_SUBSCRIPTION = gql`
  ${COMMENT}
  subscription getComments($projectId: ID, $fileId: ID) {
    eventAdded(projectId: $projectId, fileId: $fileId){
      ...eventData
      ...on CommentAddedEvent {
        comment {
          ...commentData
        }
      }
      ...on CommentDeletedEvent {
        comment {
          id
        }
      }
      ... on CommentEditedEvent {
        comment {
          ...commentData
        }
      }
    }
  }
`;

export const GET_COMMENTS = gql`
  query getListComments($projectId: ID!, $fileId: ID!,$sortOrder: SortOrder!, $cursor: ID) {
    project(id: $projectId) {
      id
      drive {
        id
        nodeByID(id: $fileId) {
          id
          eventsPaged(types: [COMMENT_ADDED],omitDeletedComments: true, after: $cursor, sortOrder: $sortOrder){
            __typename
            pageInfo{
              endCursor
              hasNextPage
            }
            edges{
              node{
                ...on CommentAddedEvent {
                  id
                  type
                  createdAt
                  projectMember {
                    id
                    name
                    email
                    isUserRemoved
                    profile {
                      id
                      name
                      email
                      picture
                    }
                  }
                  comment {
                    id
                    editedAt
                    message
                    file {
                      id
                    }
                  }
                }
              }
              cursor
            }
            pageInfo{
              hasNextPage
              startCursor
              endCursor
            }
          }
        }
      }
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation addComment($projectId: ID!, $fileId: ID, $message: String!, $mentions: [ID]) {
    addComment(projectId: $projectId, fileId: $fileId, message: $message, mentions: $mentions) {
      id
      file {
        id
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($commentId: ID!) {
    deleteComment(commentId: $commentId) 
  }
`;

export const EDIT_COMMENT = gql`
  mutation EditComment($commentId: ID!, $message: String!, $mentions: [ID]) {
    editComment(commentId: $commentId, message: $message, mentions: $mentions) {
      id
      message
    }
  }
`;

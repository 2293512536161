import { gql } from '@apollo/client';

const GET_PROJECT_MEMBER = gql`
  query getProjectMember($id: ID!) {
    project(id: $id) {
      id
      members {
        id
        role
        status
        invitedAt
        isUserRemoved
        invitedBy {
          name
        }
        email
        name
        hasCityLicense
        profile {
          id
          name
          nickname
          picture
          jobTitle
        }
        hasCityLicense
      }
    }
  }
`;

export default GET_PROJECT_MEMBER;

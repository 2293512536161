import { useQuery } from '@apollo/client';
import * as JsSearch from 'js-search';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { GQLWrapper, IconButton } from 'components';
import { useMixpanel } from 'hooks';
import { Project, UserTier } from 'types/graphql';

import { useAuth0 } from '@auth0/auth0-react';
import { useLocalStorageState } from 'ahooks';
import { ReactComponent as Divider } from 'assets/icons/Divider.svg';
import { ReactComponent as GridFilled } from 'assets/icons/GridFilled.svg';
import { ReactComponent as GridOutlined } from 'assets/icons/GridOutlined.svg';
import { ReactComponent as ListFilled } from 'assets/icons/ListFilled.svg';
import { ReactComponent as ListOutlined } from 'assets/icons/ListOutlined.svg';
import { ReactComponent as MapFilled } from 'assets/icons/MapFilled.svg';
import { ReactComponent as MapOutlined } from 'assets/icons/MapOutlined.svg';
import { capitalizeWord, nameSort, sortByDate } from 'utils/helpers';
import { IFilters } from 'views/types';
import ProjectsVisibilityMenu from './ProjectsVisibilityMenu/ProjectsVisibilityMenu';
import ToggleView from './ToggleView/ToggleView';
import GET_PROJECTS from './apollo';
import SearchProjects from './search/SearchProjects';

const searched = new JsSearch.Search('name');
searched.sanitizer = new JsSearch.LowerCaseSanitizer();
searched.addIndex('name');
searched.addIndex('reference');
searched.addIndex(['owner', 'name']);
searched.addIndex(['location', 'license', 'stripeProduct', 'name']);


interface GroupedProjects {
  invites: Project[];
  others: Project[];
}

const Projects = () => {

  const { mixpanelTrack } = useMixpanel();
  const [projectView = 'grid', setProjectView] = useLocalStorageState<string>('Home view');
  const [visiblityPreference = 'Visible Projects', setVisiblityPreference] = useLocalStorageState<string>('All Projects');
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const { user } = useAuth0();
  const [filters, setFilters] = useLocalStorageState<IFilters>('createdBy', {
    defaultValue: { query: '', createdBy: 'anyone' },
  });
  const [sort, setSort] = useState<string>('lastOpened');
  const [searchRecents, setSearchRecents] = useLocalStorageState<any[]>('searchRecents projects', {
    defaultValue: [],
  });

  const { loading, error, data, refetch } = useQuery(GET_PROJECTS, { fetchPolicy: 'cache-and-network' });

  const groupProjects = (projects: Project[]) => {
    const grouped = projects.reduce((prev, current) => {
      const status = current.me?.status;
      const key = status && status === 'INVITED' ? 'invites' : 'others';
      prev[key].push(current);

      return prev;
    }, { invites: [], others: [] } as GroupedProjects);

    return [
      ...grouped.invites,
      ...grouped.others,
    ];
  };

  const projects = useMemo(() => {
    const tier: UserTier = data?.currentUser.tier || {};
    const initialProjects: Project[] = data?.currentUser?.projects || [];

    const isStarter = tier.name === 'Starter' && (!tier.slotsRemaining || tier.slotsRemaining <= 0);

    const allAvailableProjects: Project[] = (data?.currentUser?.availableToAllProjects || []).map((project: Project) => ({
      ...project,
      isSameOrg: true,
      me: {
        ...project.me,
        role: isStarter ? 'EDITOR' : project.me?.role,
        status: isStarter ? 'LOCKED' : project.me?.status,
      },
    }));

    const inviteOnlyProjects: Project[] = (data?.currentUser?.inviteOnlyProjects || []).map((project: Project) => ({
      ...project,
      isSameOrg: true,
      me: {
        ...project.me,
        role: isStarter ? 'EDITOR' : project.me?.role,
        status: isStarter ? 'LOCKED' : project.me?.status,
      },
    }));
    return groupProjects([...inviteOnlyProjects, ...allAvailableProjects, ...initialProjects]);
  }, [data]);

  const applyFilter = useCallback((preference: string, userProjects: Project[]) => {
    const userId = data?.currentUser?.id;
    switch (preference) {
      case 'Hidden Projects': {
        return userProjects.filter((p) => p.hiddenFor?.some(h => h === userId));
      }
      case 'Visible Projects': {
        return userProjects.filter((p) => !p.hiddenFor?.some(h => h === userId));
      }
      default: return userProjects;
    }
  }, [data]);


  const sortProjects = (value: string, _filteredProjects: Project[]) => {
    const applySort = (
      callback: ((a: Project, b: Project) => number) | undefined,
    ) => _filteredProjects.slice().sort(callback);

    switch (value) {
      case 'recentlyCreated':
        return applySort((a, b) => sortByDate(a, b));
      case 'a-z':
        return applySort((a, b) => nameSort(a.name, b.name));
      case 'z-a':
        return applySort((a, b) => nameSort(b.name, a.name));
      default:
        return _filteredProjects;
    }
  };

  const filterProjects = useCallback((selectedFilters?: IFilters, sortOption?: string) => {
    setSearchLoading(true);

    const { query, createdBy } = selectedFilters || filters!;

    const searchResults = (query ? searched.search(query) : projects) as Project[];
    const _filteredProjects = createdBy === 'anyone'
      ? searchResults
      : searchResults.filter(({ owner }) => (createdBy === 'me' ? user?.sub === owner.id : user?.sub !== owner.id));

    const sortedProjects = sortProjects(sortOption || sort, applyFilter(visiblityPreference, _filteredProjects));

    setFilters({ ...filters!, ...selectedFilters });
    setTimeout(() => setSearchLoading(false), 700);
    return sortedProjects;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyFilter, filters, projects, sort, user?.sub, visiblityPreference]);

  useEffect(() => {
    setFilteredProjects(filterProjects());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, visiblityPreference, filters?.query]);

  useEffect(() => {
    if (localStorage.getItem('skipOrgInvites')) {
      localStorage.removeItem('skipOrgInvites');
    }
  }, []);

  const onProjectView = (view: string) => {
    setProjectView(view);
    mixpanelTrack(capitalizeWord(view));
  };

  return (
    <GQLWrapper loading={loading || searchLoading} error={error} renderChildren={!!data}>
      <div className='relative flex justify-between gap-1 mb-3'>
        <SearchProjects
          projects={applyFilter(visiblityPreference, projects)}
          view={projectView}
          setFilteredProjects={setFilteredProjects}
          setSearchLoading={setSearchLoading}
          filterProjects={filterProjects}
          filters={filters!}
          searchRecents={searchRecents!}
          searched={searched}
          setSearchRecents={setSearchRecents}
          setSort={setSort}
          sort={sort}
        />

        <div className='flex items-start gap-2' data-tour-key="projects-view">
          {projects?.length > 0 && (
            <div className='flex items-start gap-2'>
              <IconButton
                active={projectView === 'grid'}
                onClick={() => onProjectView('grid')}
                activeIcon={<GridFilled />}
                defaultIcon={<GridOutlined />}
                aria-label="Grid"
              />
              <IconButton
                active={projectView === 'list'}
                onClick={() => onProjectView('list')}
                activeIcon={<ListFilled />}
                defaultIcon={<ListOutlined />}
                aria-label="List"
              />
            </div>
          )}
          <IconButton
            active={projectView === 'map'}
            onClick={() => {
              const view = projectView !== 'map' ? 'map' : 'grid';
              onProjectView(view);
            }}
            aria-label="Map"
            activeIcon={<MapFilled />}
            defaultIcon={<MapOutlined />}
          />
          <Divider />
          <ProjectsVisibilityMenu
            visibilityPreference={visiblityPreference}
            setVisibilityPreference={setVisiblityPreference}
            projectView={projectView}
            projects={projects}
            setFilteredProjects={setFilteredProjects}
            applyFilter={applyFilter}
          />
        </div>
      </div>

      <ToggleView
        view={projectView}
        projects={projects}
        filteredProjects={filteredProjects}
        tier={data?.currentUser.tier}
        refetch={refetch}
        setVisiblityPreference={setVisiblityPreference}
        visiblityPreference={visiblityPreference}
      />
    </GQLWrapper>
  );
};

export default Projects;

import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 300px;
  height: 400px;
  text-align: center;
  color: #333;
  font-family: 'Roboto';
`;

export const Title = styled.h3`
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.2px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const Subtitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 30px;
`;

export const Spinner = styled.div`
  width: 145px;
  height: 145px;
  border: 15px solid #E6EDEF;
  border-bottom-color: #375675;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
`;

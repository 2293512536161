import { gql, TypedDocumentNode } from '@apollo/client';
import { Project, User } from 'types/graphql';

export const FILES = gql`
  fragment fileInfo on File {
    id
    path
    name
    creator {
      id
      name
      isUserRemoved
    }
    parentId
    createdAt
    size
    mimeType
    locked
    whitelist
    signedUrl
    metadata {
      licenseName
    }
  }
`;

export const FOLDERS = gql`
  fragment folderInfo on Folder {
    id
    name
    path
    parentId
    creator {
      id
      name
      isUserRemoved
    }
    createdAt
    files {
      ...fileInfo
    }
  }
`;

export const GET_PROJECT_DRIVE: TypedDocumentNode<{ project: Project; currentUser: User }> = gql`
  ${FILES}
  ${FOLDERS}
  query getProjectDrive($id: ID!) {
    project(id: $id) {
      id
      location {
        license {
          name
          public
          id
        }
      }
      me {
        role
      }
      drive {
        id
        info {
          total
          used
          remaining
        }
        nodeByPath {
          id
          path
          name
          createdAt
          ... on Folder {
            ...folderInfo
            folders {
              ...folderInfo
              folders {
                ...folderInfo
                folders {
                  ...folderInfo
                  folders {
                    ...folderInfo
                    folders {
                      ...folderInfo
                      folders {
                        ...folderInfo
                      }
                    }
                  }
                }
              }
            }
          }
          ... on File {
            ...fileInfo
          }
        }
      }
    }
    currentUser {
      id
      cityLicenses {
        status
        license {
          name
          id
          public
        }
      }
      siteLicenses {
        name
        licenseId
      }
      organisation {
        siteLicenses {
          license {
            id
          }
        }
      }
      tier {
        name
        slotsRemaining
      }
    }
  }
`;

import { palette, typography } from '../utils';

export const list = {
  root: {
    borderRadius: '8px',

    '&.select-menu': {
      color: palette.gray.main,
      maxHeight: '300px',

      '& .Mui-selected': {
        fontWeight: 600,
        color: palette.primary.main,
        backgroundColor: palette.primary.lightest,
      },

      '& .MuiListItem-root': {
        fontSize: typography.body2.fontSize,

        '&:hover': {
          backgroundColor: palette.primary.lightest,
        },
      },
    },

    '&.small-menu': {
      color: palette.gray.main,
      '& .Mui-selected': {
        fontWeight: 600,
        color: palette.primary.main,
        backgroundColor: palette.primary.lightest,
        '& .MuiListItemIcon-root': {
          color: palette.primary.main,
        },
      },
      '& .MuiListItemIcon-root': {
        minWidth: '30px',
        '& .MuiSvgIcon-root': {
          width: '20px',
        },
      },
    },

    '&.account-menu': {
      color: palette.text.primary,
      paddingBottom: '24px',

      '& .Mui-selected': {
        fontWeight: 600,
        color: palette.primary.main,
        backgroundColor: palette.primary.lightest,
        borderRadius: '8px',
      },

      '& .MuiSvgIcon-root': {
        width: '10px',
      },

      '& .MuiListItemIcon-root': {
        minWidth: '30px',
        '& .MuiSvgIcon-root': {
          width: '16px',
          color: palette.text.primary,
        },
      },

      '& .MuiListItemText-inset': {
        paddingLeft: '40px',
      },
    },
    '&.activity': {
      color: palette.text.primary,
      width: '100%',
      backgroundColor: palette.white.main,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 'calc(100vh - 800px)',
      minHeight: '400px',
      '&.fullview': {
        maxHeight: 'calc(100vh - 288px)',
      },
      '& ul': {
        backgroundColor: 'inherit',
        padding: 0,
      },
      '& li': {
        width: '100%',
      },
      '& .MuiListSubheader-root': {
        fontSize: '14px',
        backgroundColor: palette.white.main,
        padding: '8px 24px',
        color: '#5c5c5c',
        lineHeight: 'normal',
        fontWeight: 'normal',
        marginBottom: '-8px',
      },
    },
  },
};

export const listItem = {
  root: {
    '&.Mui-disabled': {
      opacity: '0.3',
    },
  },
  button: {
    '&:hover': {
      borderRadius: '8px',
    },
  },
};

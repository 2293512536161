import { gql, TypedDocumentNode } from '@apollo/client';
import { License, User, UserSiteLicense } from 'types/graphql';

export { UPDATE_ACCOUNT } from 'utils/apollo/user';

export const GET_ORG_ROLE = gql`
  query getRoleTier {
    currentUser {
      tier {
        name
      }
      organisation {
        me {
          role
        }
      }
    }
  }
`;

export const GET_ACCOUNT_OVERVIEW: TypedDocumentNode<{ currentUser: User; cityLicenses: License[]; siteLicenses: UserSiteLicense[]; }> = gql`
  query getAccountOverview {
    currentUser {
      id
      tier {
        id
        name
        slots
        slotsRemaining
        slotsUsed
        status
      }
      organisation {
        subscriptions {
          trial_end
        }
        trialActive
        siteLicenses {
          license {
            id
            name
            location {
              id
              name
            }
          }
        }
      }
      cityLicenses {
        status
        license {
          id
          name
          stripeProduct {
            name
          }
          location {
            id
          }
        }
      }
      siteLicenses {
        licenseId
        name
        userId
      }
    }
    cityLicenses {
      id
      name
      public
    }
  }
`;

export const UPDATE_ORGANISATION_DETAILS = gql`
  mutation($id: ID!, $details: OrganisationInput!) {
    updateOrganisation(id: $id, organisation: $details) {
      id
      name
      logo
      phone
      email
      type
      data {
        access
        defaultRole
        adminAccess
      }
      address {
        line1
        line2
        postal_code
        city
        country
        state
      }
    }
  }
`;

export const UPDATE_ORG_MEMBER = gql`
  mutation($memberId: ID!, $role: OrgMemberRole!) {
    updateOrgMember(memberId: $memberId, role: $role) {
      id
      role
    }
  }
`;

export const REMOVE_ORG_MEMBER = gql`
  mutation($memberId: ID!){
    removeOrgMember(memberId: $memberId){
      id
      role
      name
      email
      profile {
        name
      }
    }
  }
`;

export const RESEND_ORG_INVITE = gql`
  mutation($memberId: ID!) {
    reinviteOrgMember(memberId: $memberId) {
      id
      role
      name
    }
  }
`;

export const ASSIGN_TIER = gql`
  mutation($memberId: ID!, $tierId: ID!, $interval: BillingInterval) {
    assignTier(memberId: $memberId, tierId: $tierId, interval: $interval) {
      id
    }
  }
`;

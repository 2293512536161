import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/core';
import React from 'react';
import muiTheme from 'theme/theme';
import { ThemeProvider } from 'styled-components';

const ThemeProviders: React.FC<React.PropsWithChildren<{ }>> = ({ children }) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={muiTheme}>
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
);

export default ThemeProviders;

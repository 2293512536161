import React from 'react';
import { ControlPosition } from 'react-map-gl';

type Props = {
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  disableZoomIn?: boolean;
  disableZoomOut?: boolean;
  style?: React.CSSProperties;
  position?: ControlPosition;
  controlsClass?: string;
};

const CustomNavigationControl = ({ handleZoomIn, handleZoomOut, disableZoomIn, disableZoomOut, style = {}, position = 'top-right', controlsClass }: Props) => {
  switch (position) {
    case 'top-left':
      style.top = 0;
      style.left = 0;
      break;

    case 'top-right':
      style.top = 0;
      style.right = 0;
      break;

    case 'bottom-right':
      style.bottom = 0;
      style.right = 0;
      break;

    case 'bottom-left':
      style.top = 0;
      style.left = 0;
      break;
  }

  return (
    <div 
      style={style}
      className={`flex flex-col items-center absolute w-[44px] h-[84px] bg-white rounded shadow-sm text-2xl ${controlsClass ? controlsClass : 'm-1'}`}
      >
      <button 
        type="button" 
        disabled={disableZoomIn}
        aria-disabled={disableZoomIn}
        aria-label="Zoom in" 
        title="Zoom in" 
        onClick={handleZoomIn} 
        className="disabled:opacity-50 disabled:cursor-not-allowed w-full h-full flex justify-center items-center disabled:hover-bg-white hover:bg-grey-light rounded-t transition-all duration-150"
      >
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#333333"/>
        </svg>
      </button>
      <button 
        type="button" 
        disabled={disableZoomOut}
        aria-disabled={disableZoomOut}
        aria-label="Zoom out" 
        title="Zoom out" 
        onClick={handleZoomOut} 
        className="disabled:opacity-50 disabled:cursor-not-allowed w-full h-full flex justify-center items-center disabled:hover-bg-white hover:bg-grey-light rounded-b transition-all duration-150"
      >
        <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 2H0V0H14V2Z" fill="#333333"/>
        </svg>
      </button>
    </div>
  );
};

export default CustomNavigationControl;
import { useQueryParam } from 'hooks';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorLayout } from 'tailwind';

export default function ErrorPage() {
  const query = useQueryParam();
  const history = useHistory();

  return (
    <ErrorLayout
      title={query.get('msg')}
      primaryButtonText="Go back"
      onPrimaryClick={() => history.goBack()}
    />
  );
}

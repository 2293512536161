export const treeView = {};

export const treeItem = {
  root: {
    '& .drive_tree_item': {
      '& svg': {
        height: '15px',
        width: '30px',
        fontSize: 'unset',
      },

      '& h6': {
        paddingLeft: '8px',
      },
    },
  },
  label: {
    margin: '2px 0',
    paddingLeft: '0',
    borderRadius: '4px',
    overflow: 'hidden',
  },
};

import MuiBox from '@material-ui/core/Box';
import MuiCard from '@material-ui/core/Card';
import styled from 'styled-components';
import MuiPopper from '@material-ui/core/Popper';

export const Content = styled(MuiBox)`
  font-weight: normal;
  font-size: 16px;
`;

export const Card = styled(MuiCard)`
  padding: 16px;
  box-sizing: border-box;
`;

export const Popper = styled(MuiPopper)`
  box-sizing: border-box;
  max-width: 350px;
  width: 100%;

  &#help-button {
    left: 60px!important;
  }

  & .MuiTooltip-tooltip {
    margin: 0;
    background-color: transparent;
    box-sizing: border-box;
    padding: 0;
    max-width: max-content;
  }
`;

const organisationMemberRoles = [
  {
    value: 'admin',
    title: 'Admin',
    subtitle: 'Full administration rights for your organisation including managing members, subscriptions, billing and organisation details.',
  },
  {
    value: 'member',
    title: 'Member',
    subtitle: 'No special permissions. View access to your organisation\'s details and members.',
  }];

export default organisationMemberRoles;

import { palette, typography } from '../utils';

export const outlinedInput = {
  root: {
    marginBottom: '8px',
    borderRadius: '8px',
    maxWidth: '600px',
    width: '100%',

    '&$focused': {
      outline: '0',
      border: '0',
      boxShadow: `0px 0px 5px ${palette.primary.main}`,
    },
    '&$focused $notchedOutline': {
      borderColor: palette.primary.main,
      borderWidth: '1px',
    },
    '&:hover $notchedOutline': {
      borderColor: palette.primary.main,
    },
    '&$fullWidth': {
      maxWidth: '100%',
    },
    '&.small .MuiOutlinedInput-input': {
      minHeight: '40px',
      padding: '8px 12px',
    },
  },
  input: {
    '&': {
      boxSizing: 'border-box',
      minHeight: '48px',
      background: palette.white.main,
      padding: '12px 16px',
      fontSize: typography.body2.fontSize,
      lineHeight: typography.body2.lineHeight,
      color: palette.text.primary,
      borderRadius: '8px',
    },
    '&::placeholder': {
      color: palette.placeholder.main,
    },
  },
  adornedStart: {
    background: palette.white.main,
    color: palette.placeholder.main,
    '& .MuiSvgIcon-root': {
      width: '17px',
    },
  },
  multiline: {
    padding: '12px 16px',
    background: palette.white.main,
  },
  notchedOutline: {
    outline: '0',
    border: `1px solid ${palette.placeholder.main}`,
    borderColor: palette.placeholder.main,
  },
  fullWidth: {
    maxWidth: '100%',
  },
};

export default outlinedInput;

import { useAuth0 } from '@auth0/auth0-react';
import { ClickAwayListener, OutlinedInput, OutlinedInputProps } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { useMixpanel } from 'hooks';
import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Badge from 'tailwind/Badge';
import { SelectWithInputItem } from 'views/types';
import Filters from './Filters';
import MemberChip from './SelectedMember';

interface SelectWithFilterProps extends OutlinedInputProps {
  options: Array<SelectWithInputItem>;
  filterOptions?: (value: string, option: SelectWithInputItem) => boolean | undefined;
  onItemClick?: (option: SelectWithInputItem) => void;
}

const SelectWithFilter: React.FC<SelectWithFilterProps> = ({ options, filterOptions, onItemClick, ...outlineProps }) => {
  const { user } = useAuth0();
  const { mixpanelTrack } = useMixpanel();
  const { projectId } = useParams<{ projectId?: string; }>();
  const inputRef = useRef<HTMLInputElement>(null);

  const [filtered, setFiltered] = useState<Array<SelectWithInputItem>>([]);
  const [open, setOpen] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState<string[]>([]);

  const handleOnInput = (search: string) => {
    let results = filterOptions ?
      options.filter((option) => filterOptions(search, option)) :
      options.filter(({ value, displayText, tag }) =>
        value?.toLowerCase().includes(search)
        || displayText?.toLowerCase().includes(search)
        || tag?.toLowerCase().includes(search));

    setFiltered(results);
  };

  const handleInputClick = () => {
    mixpanelTrack('Clicked on project member search');
    if (open) return;
    setOpen(true);
    handleOnInput('');
  };

  const handleOnClick = (e: React.MouseEvent, option: SelectWithInputItem) => {
    e.stopPropagation();
    onItemClick?.(option);
    setOpen(false);
    inputRef.current!.value = '';
  };

  const toggleFilters = () => {
    if (!open && !showFilter) setShowFilter(true);
    if (open) setShowFilter(prev => !prev);

    const mixpanelEvent = !showFilter ? 'File permissions - Search - Clicked Filter' : 'File permissions - Search - Closed Filter';
    const mixpanelProps = {
      'Project ID': projectId,
      'Project role': options.find(({ extra }) => extra === user?.sub)?.tag,
      'Filter type': filters.join(', '),
    };

    mixpanelTrack(mixpanelEvent, mixpanelProps);
  };

  const selectedFilters = useMemo(() => filters.length, [filters]);
  const finalResults = useMemo(() => {
    if (!filters.length) return filtered;

    let finalFilters: string[] = filters;
    let orgMembers: SelectWithInputItem[] = filtered;

    if (filters.includes('organisation')) {
      orgMembers = filtered.filter(({ sameOrg }) => sameOrg);
      finalFilters = finalFilters.filter(f => f !== 'organisation');
    }

    return !finalFilters.length ? orgMembers : orgMembers.filter(({ tag = '' }) => finalFilters.includes(tag.toLowerCase()));
  }, [filtered, filters]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className='relative w-full h-auto bg-transparent'>
        <OutlinedInput
          className={`mb-0 ${open && 'rounded-b-none'}`}
          onClick={handleInputClick}
          onChange={(e) => handleOnInput(e.currentTarget.value.toLowerCase() || '')}
          fullWidth
          inputRef={inputRef}
          style={{ boxShadow: 'none' }}
          endAdornment={
            <>
              <FilterList
                className='text-primary cursor-pointer w-2.5 h-2.5 transition rounded-sm duration-300 hover:bg-primary-lightest'
                fontSize="inherit"
                onClick={toggleFilters}
              />
              {selectedFilters ? <Badge num={selectedFilters} className='ml-1' /> : <></>}
            </>
          }
          {...outlineProps}
        />
        <div className={`absolute flex
          h-150
          w-full
          z-[99]
          bg-white
          ${!open && 'hidden'}
          max-h-150 border border-primary rounded border-t-0 rounded-t-none`}
        >
          <div className='flex flex-col w-full gap-0.5 p-1 overflow-y-auto'>
            {!finalResults.length && selectedFilters > 0
              ? <p className='p-1 text-sm text-grey'>No results match your search</p>
              : finalResults.map((item) =>
                <MemberChip key={item.value} onClick={(e) => handleOnClick(e, item)} {...item} />,
              )}
          </div>

          {showFilter && <Filters filters={filters} setFilters={setFilters} />}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default SelectWithFilter;
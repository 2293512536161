import {
  Box as MuiBox, Link, Paper as MuiPaper, Typography,
} from '@material-ui/core';
import styled from 'styled-components';

export const Paper = styled(MuiPaper)`
  width: 100%;
  height: 100%;
  flex-direction: row-reverse;
  max-width: 100%;
  overflow: hidden;
`;

export const FileName = styled(Typography)`
  word-break: break-word;
  font-weight: bold;
  max-width: 90%;
`;

export const Creator = styled(MuiBox)`
  #creator {
    transition: 0.3s ease-in-out;
    padding: 4px;
  }

  & #creator:hover {
    cursor: default;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const CloseBtn = styled(Link)`
  position: absolute;
  right: 28px;
  top: 29px;
  cursor: pointer;
  svg {
    width: 14px;
    height: 14px;
  }
`;

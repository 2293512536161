import { KeyIcon, ProfileIcon, WorkIcon } from '@vucity/design_system';
import * as React from 'react';
import { Billing, CustomPurchases, OrgDetails, OrgInvitations, OrgMembers, OrgOverview } from 'views/organisation';
import Desktop from './desktop/Desktop';
import Overview from './overview/Overview';
import Profile from './profile/Profile';

export interface Routes {
  icon: React.ReactNode;
  title: string;
  isHidden?: string | number | boolean;
  sublinks: Sublinks[];
}

export interface Sublinks {
  title: string;
  url: string;
  component: React.ReactElement;
  isHidden?: string | number | boolean;
  badge?: boolean;
  hideFromMembers?: boolean;
}

export const routes = (
  showDesktop: string | number | boolean,
): Routes[] => [
  {
    icon: <ProfileIcon />,
    title: 'Profile',
    sublinks: [
      {
        title: 'My profile',
        url: '/account/profile',
        component: <Profile />,
      },
      {
        title: 'My account',
        url: '/account/overview',
        component: <Overview />,
      },
      {
        title: 'VU.CITY Desktop',
        url: '/account/desktop',
        component: <Desktop />,
        isHidden: !showDesktop,
      },
    ],
  },
  {
    icon: <WorkIcon />,
    title: 'Organisation',
    sublinks: [
      {
        title: 'Overview',
        url: '/account/organisation/overview',
        component: <OrgOverview />,
        hideFromMembers: true,
      },
      {
        title: 'Settings',
        url: '/account/organisation/details',
        component: <OrgDetails />,
      },
      {
        title: 'Billing and payments',
        url: '/account/organisation/billing',
        component: <Billing />,
        hideFromMembers: true,
      },
      {
        title: 'Custom purchases',
        url: '/account/organisation/purchases',
        component: <CustomPurchases />,
        hideFromMembers: true,
      },
      {
        title: 'Members',
        url: '/account/organisation/members',
        component: <OrgMembers />,
      },
      {
        title: 'Invitations',
        url: '/account/organisation/invitations',
        component: <OrgInvitations/>,
        badge: true,
      },
    ],
  },
];

export const toBeAddedLater = [
  {
    icon: <WorkIcon />,
    title: 'My company',
    sublinks: [
      {
        title: 'Company profile',
        url: '/account/company',
        component: <div>Company profile</div>,
      },
      {
        title: 'Tiers and cities',
        url: '/account/tiersandcities',
        component: <div>Tiers and cities</div>,
      },
    ],
  },
  {
    icon: <KeyIcon />,
    title: 'Master admin',
    sublinks: [
      {
        title: 'Analytics',
        url: '/account/analytics',
        component: <div>Analytics</div>,
      },
      {
        title: 'Licences',
        url: '/account/licences',
        component: <div>Licences</div>,
      },
      {
        title: 'Data',
        url: '/account/data',
        component: <div>Data</div>,
      },
      {
        title: 'Projects',
        url: '/account/projects',
        component: <div>Projects</div>,
      },
    ],
  },
];

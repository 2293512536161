import React from 'react';
import { cls } from 'utils/helpers';
import { ITabContentProps } from '../types';

const TabContent = ({
  onTabClick, active, index, label, icon, counter, iconPosition, counterPosition, disabled,
}: ITabContentProps) => {
  const classes = {
    base: 'py-1 px-2 border outline-0 rounded-sm cursor-pointer min-w-[176px] flex items-center justify-center gap-1',
    position: {
      start: 'order-first',
      end: 'order-last',
    },
    hasLabel: 'm-0',
    active: 'text-primary font-medium bg-primary-lightest',
    disabled: 'text-content-light bg-white cursor-default [&_svg]:text-content-light',
  };

  const counterClass = cls(`
    py-0.5 px-1 bg-primary rounded-sm text-white text-sm
    ${counterPosition && classes.position[counterPosition]}
    ${!!label && classes.hasLabel}
    ${disabled && 'bg-content-light'}
  `);

  const btnClass = cls(`
    ${classes.base}
    border-primary-lightest 
    ${active && classes.active}
    ${disabled ? `${classes.disabled} hover:bg-white` : 'hover:bg-primary-lightest'}
  `);

  const iconClass = cls(`
    [&>svg]:h-3 [&>svg]:w-3
    ${!!label && classes.hasLabel}
    ${disabled ? '[&_svg]:fill-content-light [&_path]:fill-content-light' : '[&_svg]:fill-primary [&_path]:fill-primary'}
    ${iconPosition && classes.position[iconPosition]}
  `);

  return (
    <li>
      <button className={btnClass} onClick={() => onTabClick(index)} disabled={disabled}>
        {label}

        {icon &&
          <span className={iconClass}>
            {icon}
          </span>
        }

        {counter
          ? <span className={counterClass}>
            {counter}
          </span>
          : <></>
        }
      </button>
    </li>
  );
};

export default TabContent;

import React from 'react';
import { Container, Content, Spinner, Subtitle, Title } from './LoadingScreen.style';

const LoadingScreen = () => (
  <Container>
    <Content>
      <Title>
        Loading VU.CITY
      </Title>

      <Subtitle>
        We'll be right with you
      </Subtitle>

      <Spinner />
    </Content>
  </Container>
);

export default LoadingScreen;

import styled from 'styled-components';

export const Link = styled.a`
  color: #375675;
  transition: 0.2 ease-in-out;

  :hover {
    text-decoration: underline;
  }
`;

// TODO: This needs to be written as a separate component for the Design System

export const RadioOption = styled.div<{ disabled?: boolean; }>`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;

  input[type=radio] {
    position: absolute;
    opacity: 0;
  }

  input + label:before {
    content: "";
    background: #EFEFEF;
    border-radius: 100%;
    border: 1px solid #375675;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    top: 0.1em;
    margin-right: 12px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
  }

  input[type=radio]:checked + label:before {
    background-color: ${({ disabled }) => (disabled ? '#D2D2D2' : '#375675')};
    box-shadow: inset 0 0 0 2px #EFEFEF;
  }

  input[type=radio]:focus + label:before {
    outline: none;
    border-color: #375675;
  }

  input[type=radio]:disabled + label:before {
    box-shadow: inset 0 0 0 2px #EFEFEF;
    border-color: #A6AFB9;
    cursor: default;
  }

  input[type=radio] + label:empty:before {
    margin-right: 0;
  }

  :first-of-type {
    margin-bottom: 8px;
  }

  ${({ disabled }) => disabled && `
    color: #333333;
  `}
`;

import { Tooltip } from 'components';
import React, { useState } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import { SelectButton } from 'tailwind';
import { rasterSource } from 'views/coverage/requests';

import { ReactComponent as InfoSvg } from 'assets/icons/Info.svg';
import { ReactComponent as ModelledAreaFilled } from '../../icons/modelledAreaFilled.svg';
import { ReactComponent as ModelledAreaOutlined } from '../../icons/modelledAreaOutlined.svg';

const { id = '', type, tiles, tileSize, attribution } = rasterSource;

const ImageryCoverage = ({ disabled, onMixpanel }: { disabled: boolean; onMixpanel: (arg1: boolean, arg2?: string) => void; }) => {
  const [coverage, setCoverage] = useState<boolean>(false);

  const { current: map } = useMap();

  const addSource = () => {
    if (!map) return;
    const getMap = map.getMap();

    if (!getMap.isStyleLoaded()) return;

    if (!getMap.getStyle().sources[id]) {
      getMap.addSource(id, rasterSource);
    }
  };

  const onButtonClick = () => {
    addSource();
    setCoverage(prev => !prev);
    onMixpanel(coverage, 'Imagery coverage');
  };

  return (
    <section className='flex flex-col w-full gap-1'>
      <SelectButton
        fullWidth
        selected={coverage}
        onClick={onButtonClick}
        startIcon={coverage ? <ModelledAreaFilled /> : <ModelledAreaOutlined />}
        disabled={disabled}
      >
        Imagery coverage
        <Tooltip
          placement="bottom-start"
          header='Tiles'
          content='VU.CITY tiles are an experimental feature and may not fully reflect the extent of the model.'
        >
          <div>
            <InfoSvg className='[&_path]:fill-primary' />
          </div>
        </Tooltip>
      </SelectButton>

      <Source id={id} type={type} tileSize={tileSize} attribution={attribution} tiles={tiles}>
        <Layer
          id='VU.CITY'
          type='raster'
          minzoom={6}
          layout={{
            visibility: coverage ? 'visible' : 'none',
          }}
        />
      </Source>
    </section>
  );
};

export default ImageryCoverage;

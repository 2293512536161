import React from 'react';
import { BoxProps } from '@material-ui/core';
import { countryToFlag, getCountry } from 'utils/helpers';
import countries from 'utils/configs/countries';
import SelectAutocomplete from 'components/SelectAutocomplete/SelectAutoComplete';

export type Option = {
  code: string;
  label: string;
  phone?: string;
  suggested?: boolean;
};

interface CountrySelectProp extends BoxProps {
  handleChange: (event: React.ChangeEvent<{}>, value: any) => void;
  value?: any;
}

const renderOptions = (option: Option) => (
  <>
    <span>{countryToFlag(option.code)}</span>
    {option.label} ({option.code})
  </>
);

const CountrySelect: React.FC<CountrySelectProp> = (props) => {
  const { value, handleChange } = props;

  return (
    <SelectAutocomplete
      value={getCountry(value) || null}
      handleChange={handleChange}
      renderOptions={renderOptions}
      options={countries}
      labelText="Country"
    />
  );
};

export default CountrySelect;

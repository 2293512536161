import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useControl } from 'react-map-gl';
import drawStyles from './style';

import { DirectMode, DragCircleMode, SimpleSelectMode, DragRectangleMode } from 'components/MapProvider/DrawModes';
import React from 'react';

interface MapEditorProps {
  onUpdate: (evt: { features: object[]; action: string; }) => void;
}

const MapEditor = React.forwardRef(({ onUpdate }: MapEditorProps, ref) => {
  const drawRef = useControl<MapboxDraw>(() => {
    const ctrl = new MapboxDraw({
      modes: {
        ...MapboxDraw.modes,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode,
        drag_circle: DragCircleMode,
        drag_rectangle: DragRectangleMode,
      },
      // controls: {
      //   trash: true,
      // },
      boxSelect: true,
      userProperties: true,
      styles: drawStyles,
      displayControlsDefault: false,
      clickBuffer: 10,
      touchBuffer: 10,
    });

    return ctrl;

  }, ({ map }) => {
    map.on('draw.create', onUpdate);
    map.on('draw.update', onUpdate);
  }, ({ map }) => {
    map.off('draw.create', onUpdate);
    map.off('draw.update', onUpdate);
  });

  React.useImperativeHandle(ref, () => drawRef, [drawRef]);

  return null;
});

export default MapEditor;

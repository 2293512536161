import axios from 'axios';
import { useMixpanel } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
import { GET_COVERAGE_AREA, GET_VECTOR_SOURCES } from '../requests';
import { Overlay, Vector } from '../types';

import Boroughs from './areas/Boroughs';
import ImageryCoverage from './areas/ImageryCoverage';
import ModelCoverage from './areas/ModelCoverage';
import Tiles1km from './areas/Tiles1km';
import Tiles250m from './areas/Tiles250m';

interface IOVerlaysProps {
  selectedCity: string;
  selectedOverlays: Overlay[];
  setSelectedOverlays: (arg: Overlay[]) => void;
  resetTiles: () => void;
}

const Overlays = ({ selectedCity, selectedOverlays, setSelectedOverlays, resetTiles }: IOVerlaysProps) => {
  const { current } = useMap();
  const { mixpanelTrack } = useMixpanel();

  const [vectors, setVectors] = useState<Vector>({});
  const [zoom, setZoom] = useState(6);

  const isSelected = useCallback((overlayId: Overlay['id']) => selectedOverlays.filter(({ id }) => id === overlayId)[0], [selectedOverlays]);

  const onMixpanel = (selected: boolean, title?: string) => {
    const event = `3D model coverage / Overlays / ${title} / ${!selected ? 'Selected' : 'De-selected'}`;
    const extra = {
      'Current location': current?.getCenter().toArray(),
      'Current zoom level': current?.getZoom(),
      'Current selected city': selectedCity || 'None',
    };
    mixpanelTrack(event, extra);
  };

  useEffect(() => {
    const getVectorSources = async () => {
      if (Object.keys(vectors).length > 0) return;
      const { data } = await axios.get(GET_VECTOR_SOURCES);
      setVectors(data);
    };

    getVectorSources();
  }, [vectors]);

  useEffect(() => {
    current?.on('zoomend', ({ viewState }) => {
      const mapZoom = viewState?.zoom;
      setZoom(mapZoom);
    });
  }, [current]);

  const onButtonClick = async (overlayId: Overlay['id']) => {
    const { selected, title, area } = isSelected(overlayId);
    const isCoverage = overlayId === 'coverage' && !area;

    const { data = area } = isCoverage ? await axios.get(GET_COVERAGE_AREA) : {};

    const newSelectedOverlays = selectedOverlays.map(item => {
      if (item.id === overlayId) return { ...item, selected: !selected, area: data };
      return item;
    });

    onMixpanel(selected, title);
    setSelectedOverlays(newSelectedOverlays);

    if (overlayId === '250m' && selected)
      resetTiles();
  };

  useEffect(() => {
    onButtonClick('coverage');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTiles = useCallback((overlayId: Overlay['id']) => {
    if (!overlayId) return [];

    return Object.keys(vectors)
      .filter(t => t.toLowerCase().includes(overlayId))
      .map(s => vectors[s].tiles)
      .flat();
  }, [vectors]);

  return (
    <section className='flex flex-col w-full gap-1 mt-2' data-tour-key="3D-coverage-overlays">
      <p className='text-base text-grey'>Overlays</p>

      <Tiles250m
        selected={isSelected('250m').selected}
        tiles={getTiles('250m')}
        onClick={onButtonClick}
        disabled={zoom < isSelected('250m').minzoom}
      />

      <Tiles1km
        selected={isSelected('1km').selected}
        tiles={getTiles('1km')}
        onClick={onButtonClick}
        disabled={zoom < isSelected('1km').minzoom}
      />

      <Boroughs
        selected={isSelected('boroughs').selected}
        tiles={getTiles('boroughs')}
        onClick={onButtonClick}
        disabled={zoom < isSelected('boroughs').minzoom}
      />

      <ModelCoverage
        selected={isSelected('coverage').selected}
        area={isSelected('coverage').area}
        onClick={onButtonClick}
        disabled={zoom < isSelected('coverage').minzoom}
      />

      <ImageryCoverage disabled={zoom < 8} onMixpanel={onMixpanel} />
    </section>
  );
};

export default Overlays;

import React from 'react';

export default function OptionBox({ children }: { children?: React.ReactNode }) {
  return (
    <div className='min-h-[370px] flex
      min-w-[470px]
      max-h-[370px]
      border
      border-primary-lightest
      flex-col
      p-2
      pb-0
      gap-1
      rounded'
    >
      {children}
    </div>
  );
}
import { useMixpanel } from 'hooks';
import * as React from 'react';
import { UseFormTrigger } from 'react-hook-form';
import { Button } from 'tailwind';
import TextModal from '../TextModal/TextModal';

type ConfirmChangesProps = {
  headerText: string;
  clickHandle: () => {},
  disabled?: boolean,
  trigger: UseFormTrigger<any>;
  buttonLabel?: string;
};

const ConfirmChangesModal: React.FC<ConfirmChangesProps> = ({
  headerText, clickHandle, trigger, disabled = false, buttonLabel,
}) => {
  const { mixpanelTrack } = useMixpanel();
  const [open, setOpen] = React.useState(false);

  const handleModal = () => {
    trigger().then((res) => {
      if (res) setOpen(!open);
    });
  };

  return (
    <>
      <Button
        variant="contained"
        disabled={disabled}
        onClick={() => {
          handleModal();
          mixpanelTrack(`${headerText}/${buttonLabel || 'Save changes'}`);
        }}
      >
        {buttonLabel || 'Save changes'}
      </Button>
      <TextModal
        open={open}
        setOpen={setOpen}
        mixpanelPath='Confirm changes'
        headerText='Confirm changes'
        closeBtnLabel='No'
        mainBtnLabel='Yes'
        loading={disabled}
        mainBtnHandle={clickHandle}
      >
        These changes will overwrite your current details.
        You can update these again at anytime. Do you want to proceed?
      </TextModal>
    </>
  );
};

export default ConfirmChangesModal;

const organisationTypes = [
  'Architecture and Design',
  'Central Government',
  'Planning Consultancy',
  'Community Group',
  'Construction',
  'Building Information Management (BIM)',
  'Property development',
  'Engineering',
  'Facilities / Operations',
  'Local Government',
  'Government Agency',
  'Law',
  'Real Estate',
  'Statutory Consultee',
  'Non-Statutory Consultee',
  'University',
];

export default organisationTypes;

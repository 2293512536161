import { gql, TypedDocumentNode } from '@apollo/client';
import { Project, User } from 'types/graphql';

export const GET_PROJECT: TypedDocumentNode<{ project: Project; currentUser: User; }> = gql`
  query getProject($id: ID!) {
    project(id: $id) {
      id
      name
      description
      reference
      createdAt
      masterVuFile {
        id
        path
        name
      }
      location {
        license {
          id
          name
          public
          location {
            centre {
              convert (epsg: 4326) {
                x
                y
              }
            }
          }
        }
        coords {
          x
          y
          z
          convert (epsg: 4326) {
            x
            y
          }
        }
      }
      drive {
        allFiles(mimeType: "vucity/project") {
          id
          name
          path
          metadata {
            licenseName
          }
          creator {
            name
          }
        }
      }
      members {
        id
        role
        status
        invitedAt
        respondedAt
        invitedBy {
          name
        }
        email
        name
        hasCityLicense     
        profile {
          id
          name
          nickname
          picture
        }
        hasCityLicense
      }
      owner {
        id
        name
        email
      }
    }
    currentUser {
      cityLicenses {
        status
        license {
          name
        }
      }
      tier {
        name
        slotsRemaining
      }
    }
  }
`;

export const GET_PROJECT_STATUS = gql`
  query getProjectStatus($id: ID!) {
    project(id: $id) {
      id
      me {
        status
        role
      }
      location {
        license {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation ($id: ID!, $project: UpdateProjectInput!) {
    updateProject(id: $id, project: $project){
      id
      name
      description
      reference
      imageId
    }
  }
`;

export const RSVP_PROJECT = gql`
  mutation ($projectId: ID!, $response: RSVP!) {
    rsvpProject(projectId: $projectId, response: $response){
      status
    }
  }
`;

export const ACCEPT_AVAILABLE_PROJECT = gql`
  mutation AcceptAvailableToAllProject($id: ID!) {
    addUserToProject(projectId: $id) {
      id
      status
    }
  }
`;

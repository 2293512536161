import styled from 'styled-components';
import MuiCard from '@material-ui/core/Card';
import MuiPopper from '@material-ui/core/Popper';

export const Card = styled(MuiCard)`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  `}
`;

export const Popper = styled(MuiPopper)`
  box-sizing: border-box;
  max-width: 345px;
  width: 100%;

  & .MuiTooltip-tooltip {
    margin: 0;
    background-color: transparent;
    box-sizing: border-box;
    padding: 9px 0 0 0;
    max-width: 345px;
  }

  & .MuiTooltip-tooltipPlacementLeft {
    padding: 0 9px;
  }
`;

import React from 'react';
import { OrgMemberRole, ProjectAccess, ProjectMemberRole } from 'types/graphql';
import PreferenceOption from './PreferenceOption';
import { InfoIcon } from './ProjectPreference.style';

interface ProjectPreferenceProps {
  isReadOnly: boolean;
  preference: ProjectAccess;
  defaultRole?: ProjectMemberRole;
  onAccessChange: (preference: ProjectAccess) => void;
  onRoleChange: (role: ProjectMemberRole) => void;
  projectRole?: ProjectMemberRole | OrgMemberRole;
}

const ProjectPreference: React.FC<ProjectPreferenceProps> = (props: ProjectPreferenceProps) => {
  const { onAccessChange, isReadOnly, preference,
    onRoleChange, defaultRole, projectRole } = props;

  const handleClick = (access: ProjectAccess) => {
    if (access === preference) return;
    onAccessChange(access);
  };

  return (
    <div className='py-2 mt-2 border-t border-b border-b-grey-light border-t-grey-light'>
      <p className='text-lg font-medium'>
        Your organisation's default project access setting
      </p>

      <div className='flex items-center mb-2'>
        <InfoIcon />
        <p className='text-grey'>
          This can be changed on a project by project basis by a project admin.
        </p>
      </div>

      <div className='flex flex-col gap-2'>
        <PreferenceOption
          setting="INVITE_ONLY"
          isDefault
          isChecked={preference === 'INVITE_ONLY'}
          handleClick={handleClick}
          heading="Invite only"
          subHeading="Members can only join the project if they are invited."
          isReadonly={isReadOnly}
          projectRole={projectRole}
        />
        <PreferenceOption
          setting="AVAILABLE_TO_ALL"
          isChecked={preference === 'AVAILABLE_TO_ALL'}
          handleClick={handleClick}
          heading="Available to all (open door policy)"
          subHeading="The project is visible to everyone in the organisation, and any member can join it.
          Anyone outside of the organisation would still require an invite."
          hasDropdown={preference === 'AVAILABLE_TO_ALL'}
          onRoleChange={onRoleChange}
          defaultRole={defaultRole}
          isReadonly={isReadOnly}
          projectRole={projectRole}
        />
      </div>
    </div>
  );
};

export default ProjectPreference;

import { gql } from '@apollo/client';

export const INVITE_ORG_MEMBERS = gql`
  mutation inviteOrgMembers($invites: [OrgMemberInvite]!) {
    inviteOrgMembers(invites: $invites) {
      role
      email
    }
  }
`;

export const GET_INVITED_MEMBERS = gql`
  query getInvitedUsers {
    currentUser {
      id
      organisation {
        id
        members {
          id
          email
          status
          profile {
            id
            email
          }
        }
      }
    }
  }
`;

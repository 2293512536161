import { HomeIcon } from '@vucity/design_system';
import React from 'react';
import { Link } from 'react-router-dom';
import Mixpanel from 'utils/mixpanel';

const ReturnHome: React.FC = () => (
  <Link
    to="/"
    onClick={() => Mixpanel.track('Clicked Return home', {})}
    aria-label="Hub Home"
    className='flex items-center h-full p-2 border-l border-r border-[#CACCD7] ml-[108px]'
  >
    <HomeIcon color="primary" alt="Hub Home" />
  </Link>
);

export default ReturnHome;

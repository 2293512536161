import { ApolloError, useLazyQuery } from '@apollo/client';
import axios from 'axios';
import useHandleError from 'hooks/useHandleError';
import useMixpanel from 'hooks/useMixpanel';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { generateDownloadWindow } from 'utils/helpers';

import { Progress } from 'components/Preview/QuickActions/QuickActions.style';
import { GET_NODE_SIGNED_URL } from './apollo';

let percentCompleted: number = 0;

const useSignedUrl = (onCompleted?: () => void, onError?: (e?: ApolloError) => void) => {
  let key: SnackbarKey;
  const ref = useRef<HTMLSpanElement>(null);

  const { closeSnackbar } = useSnackbar();
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();

  const [signedUrlQuery] = useLazyQuery(GET_NODE_SIGNED_URL, {
    onError: (err: ApolloError) => {
      handleError(err);
      closeSnackbar(key);

      if (onError) onError(err);
    },
    onCompleted: ({ currentUser }) => {
      const { nodeByID } = currentUser.drive;

      if (!nodeByID) {
        mixpanelTrack('Download Failed');
        if (onError) onError();
        return enqueueSnackbar('This asset no longer exists. Refresh the drive', { variant: 'error' });
      }

      const { signedUrl, name, mimeType } = nodeByID;
      mixpanelTrack('Download Successful');

      if (onCompleted) onCompleted();

      key = enqueueSnackbar('Downloading in progress', {
        variant: 'info',
        persist: true,
        action: () => <Progress ref={ref} />,
      });

      return axios.get(signedUrl, {
        onDownloadProgress: (progressEvent) => {
          if (!progressEvent.total) return null;
          percentCompleted = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
          if (ref.current) {
            ref.current.innerHTML = `${percentCompleted}%`;
          }
        },
        responseType: 'blob',
      })
        .then(({ data }) => {
          closeSnackbar(key);
          generateDownloadWindow(window.URL.createObjectURL(data), name, mimeType);
        });
    },
  });

  const getSignedUrl = (fileId: string) => signedUrlQuery({ variables: { fileId } });

  return { getSignedUrl };
};

export default useSignedUrl;

const warningSymbol = String.fromCharCode(0x26a0);

const copy = {
  errors: {
    codes: {
      NO_SLOTS_REMAINING: 'No project slots remaining',
      NODE_NOT_FOUND: 'This asset has been deleted. Please refresh the drive',
      TARGET_NODE_NOT_FOUND: 'The target folder no longer exists, please refresh the drive',
      INSUFFICIENT_DRIVE_SPACE: 'Drive storage limit exceeded',
      FILE_DRIVE_LOCATION_MISMATCH: 'File licence does not match project licence',
      MOVE_PRIMARY_VU_FILE: 'Cannot move primary .vu file',
      MOVE_TO_SELF_OR_CHILD: 'Cannot move a folder to itself or its child folders',
      DOWNLOAD_INCLUDES_VU_FILES: 'Cannot download .vu file',
      INVALID_NODE_NAME: 'The specified name is not allowed. Avoid: <, >, :, ", /, \\, |, ?, *',
      NODE_ALREADY_EXISTS: 'Asset with same name already exists in this folder',
      NO_PROJECT_ACCESS: 'You don\'t have access to this project',
      PROJECT_ACCESS_REMOVED: 'You no longer have access to this project',
      INSUFFICIENT_PERMISSIONS: 'You do not have correct permissions to do that',
      EMAIL_NOT_VERIFIED: 'You need to validate your email before doing that',
    },
    errorMessages: {
      'Failed to fetch': 'Failed to connect. Please check your connection and try again',
    },
  },
  project: {
    status: {
      LOCKED: 'LOCKED',
      ACCEPTED: 'ACCEPTED',
      INVITED: 'INVITED',
      DECLINED: 'DECLINED',
      REMOVED: 'REMOVED',
      LIMITED: 'LIMITED',
    },
    roles: {
      ADMIN: 'Admin',
      EDITOR: 'Editor',
      VIEWER: 'Viewer',
    },
    roleSubtitles: {
      ADMIN: 'Full administration rights on the project, including managing project members, setting project info and the primary file.',
      EDITOR: 'Ability to invite editors and viewers, launch the 3D applications and make changes to the primary file.',
      VIEWER: 'Limited to reading project information, viewing and commenting on project drive assets.',
    } as { [key: string]: string },
    roleBenefits: {
      ADMIN: [
        'Adding & removing members',
        'Changing member\'s roles',
        'Editing project info',
        'Nominating a primary .VU file',
        'All Editor abilities',
      ],
      EDITOR: [
        'Adding & removing editors & viewers',
        'Uploading and downloading from the project drive',
        'Launching the 3D application',
        'Editing or deleting project assets',
        'Making changes to the primary file',
      ],
      VIEWER: [
        'Reading project info',
        'Viewing project drive assets',
      ],
    },
  },
  emailErrorMsg: {
    UNAUTHORISED: `${warningSymbol} Please enter a valid business email address`,
    INVALID: `${warningSymbol} Not a valid email address`,
    REPEATED: `${warningSymbol} Email has already been entered`,
    OWN_EMAIL: `${warningSymbol} You can't invite yourself. \n Please enter another email address to invite someone`,
    ALREADY_INVITED: `${warningSymbol} Member has already been invited`,
  },
};

export default copy;

import React from 'react';
import { useMutation, gql } from '@apollo/client';

import { useHandleError } from 'hooks';

import { TextModal } from '../Modal';

export const SEND_EMAIL_VERIFICATION = gql`
  mutation SendEmailVerification {
    sendEmailVerification
  }
`;

type VerifyEmailProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function VerifyEmailModal({ setOpen }: VerifyEmailProps) {
  const [handleError, enqueueSnackbar] = useHandleError();
  const [sendEmailVerification, { loading }] = useMutation(
    SEND_EMAIL_VERIFICATION,
    {
      onCompleted: () => enqueueSnackbar('Email sent', { variant: 'success' }),
      onError: handleError,
    },
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TextModal
      open
      setOpen={setOpen}
      loading={loading}
      mixpanelPath='Email not verified'
      closeBtnLabel='Not now'
      mainBtnLabel='Resend email'
      mainBtnHandle={sendEmailVerification}
      headerText="Email not yet verified"
      onClose={handleClose}
      closeBtnHandle={handleClose}
    >
      You need to verify your email address to unlock all the benefits of VU.CITY.
      <br /><br />
      Click the link in the email we sent you when you registered.<br />
      If you’ve already verified your email, refresh this page to continue.<br />
      Didn't receive one, no problem, click on the button below.
    </TextModal>
  );
}

import { useAuth0 } from '@auth0/auth0-react';
import { useMixpanel } from 'hooks';
import React, { useCallback, useState } from 'react';
import EmailButton from './EmailButton/EmailButton';
import { invalidDomains } from '../../utils/constants';

declare global {
  interface Window {
    clipboardData?: any;
  }
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const separatorRegex = /[, \n ; \r]+/;

interface IEmailBoxProps {
  emails: string[];
  setEmails: (arg: string[]) => void;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  isFullwidth?: boolean;
  label?: string;
  className?: string;
}

const cleanArray = (arr: string[]) => {
  const removeSpaces = arr.filter((item) => item !== '');
  return Array.from(new Set([...removeSpaces]));
};

const MultipleEmailBox = ({ emails, setEmails, setErrors, isFullwidth, label, className }: IEmailBoxProps) => {
  const { user: auth0User } = useAuth0();

  const [value, setValue] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(false);
  const { mixpanelTrack } = useMixpanel();

  const isEmailUnauthorised = (email: string) => invalidDomains.map(val => email.includes(val)).includes(true);

  const handleErrors = useCallback((val: string) => {
    if (!val) return;

    const ownEmail = val === auth0User?.email;
    const invalid = !emailRegex.test(val);

    if (ownEmail) return setErrors((prev) => ([...prev, val]));
    if (invalid) return setErrors((prev) => ([...prev, val]));
    if (isEmailUnauthorised(val)) return setErrors((prev) => ([...prev, val]));
    
    if (!emailRegex.test(val) || (val === auth0User?.email)) {
      setErrors((prev) => ([...prev, val]));
    }
  }, [setErrors, auth0User?.email]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);

    const lastCharTyped = e.target.value[e.target.value.length - 1];

    if (separatorRegex.test(lastCharTyped)) {
      const addedEmail = value.trim().toLowerCase();
      emails.unshift(addedEmail);
      handleErrors(addedEmail);
      setEmails(cleanArray(emails));
      setValue('');
    }
  }, [value, emails, setEmails, handleErrors]);

  const onPaste = useCallback((e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedString = clipboardData.getData('Text').toLowerCase();

    const splitString: string[] = pastedString
      .trim().split(separatorRegex)
      .filter((initial) => emails.indexOf(initial) === -1);
    splitString.forEach((str: string) => handleErrors(str));

    if (splitString.length > 0) {
      emails.unshift(...splitString);
      setEmails(cleanArray(emails));
      setValue('');
    }
    mixpanelTrack('Add multiple - Emails Successfully Added');
  }, [mixpanelTrack, handleErrors, setEmails, emails]);

  const onDeleteEmail = useCallback((email: string) => {
    const filterOutEmail = (arr: string[]) => arr.filter((em) => em !== email);
    setEmails(filterOutEmail(cleanArray(emails)));
    setErrors((prev) => filterOutEmail(prev));
  }, [emails, setEmails, setErrors]);

  return (
    <div className={`flex flex-col text-content ${className}`}>
      {label
        && <label className='mb-1 text-sm' id='emails'>{label}</label>
      }

      <div
        className={
          `relative w-full min-h-[200px] border  rounded bg-white py-1.2 px-2 overflow-auto 
            ${focused ? 'shadow-box border-primary' : 'border-placeholder'}
            ${isFullwidth ? 'max-w-full' : 'max-w-[400px]'}
          `}
        onFocus={() => !focused && setFocused(true)}
        onBlur={(e: any) => {
          if (e.currentTarget.contains(e.relatedTarget)) return false;
          return focused && setFocused(false);
        }}
      >
        <textarea
          className='w-full border-none rounded resize-none focus:outline-0 focus:border-none'
          name="emails"
          id="emails"
          value={value}
          onPaste={onPaste}
          onChange={handleChange}
          rows={!emails.length ? 7 : 1}
          placeholder={!emails.length ? label : ''}
          aria-label={'List of emails separated by space or semicolon' || label}
        />

        {emails.length > 0
          && emails.map((email, i) => <EmailButton
            key={email + i}
            onDeleteEmail={onDeleteEmail}
            email={email}
            isError={{
              isValid: !emailRegex.test(email) && !isEmailUnauthorised(email),
              isSelf: email === auth0User?.email,
              isUnauthorised: emailRegex.test(email) && isEmailUnauthorised(email),
            }}
          />)}
      </div>
    </div>
  );
};

export default MultipleEmailBox;

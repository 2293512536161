import { Breadcrumbs } from '@material-ui/core';
import { ArrowRight, theme } from '@vucity/design_system';
import React from 'react';
import { Folder } from 'types/graphql';

interface IPath {
  name: string;
  id: string;
  folder: Folder;
}

interface IBreadcrumbsProps {
  paths?: IPath[];
  onFolderClick: (folder: Folder) => void;
  rootFolder: string;
}

const DriveBreadcrumbs: React.FC<IBreadcrumbsProps> = ({ paths, rootFolder, onFolderClick }) => {
  const gray = theme.palette.gray.main;

  return (
    <Breadcrumbs
      separator={
        <ArrowRight htmlColor={gray} style={{ height: '12px', width: '12px' }} />
      }
      aria-label="Drive breadcrumbs"
      maxItems={4}
    >
      {paths?.map((path: IPath) => (
        <div className='text-base cursor-pointer text-content' key={path.id} onClick={() => onFolderClick(path.folder)}>
          <p>
            {path.name === '/' ? rootFolder : path.name}
          </p>
        </div>
      ))}
    </Breadcrumbs>
  );
};

export default DriveBreadcrumbs;

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { useCurrentUser, useHandleError, useMixpanel, useUserRoleCheck } from 'hooks';

import { FullwidthLayout, Tab, Tabs } from 'tailwind';
import { IndividualOrgInvites, MultipleOrgInvites } from 'tailwind/OrgInvites';
import { OrgMemberInvite } from 'types/graphql';
import { ADMIN_ROLE, MEMBER_ROLE } from '../members/constants';
import InviteButtons from './InviteButtons';
import { GET_INVITED_MEMBERS, INVITE_ORG_MEMBERS } from './apollo';

const InviteOrganisationMembers = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setError,
    setValue,
    watch,
  } = useForm<{ members: OrgMemberInvite[]; }>();

  const history = useHistory();

  const { mixpanelTrack } = useMixpanel();
  const { isAllowed } = useUserRoleCheck();

  const [handleError, enqueueSnackbar] = useHandleError();
  const { floating } = useCurrentUser();

  const defaultTier: string = useMemo(() => floating?.hasFloating ? 'floating' : 'starter', [floating?.hasFloating]);
  const initialValue: OrgMemberInvite[] = useMemo(() => [{ email: '', role: 'MEMBER', tierId: defaultTier, interval: 'year' }], [defaultTier]);

  const [members, setMembers] = useState<OrgMemberInvite[]>([]);
  const [counter, setCounter] = useState<number>(0);
  const [view, setView] = useState<string>('normal');
  const [activeTab, setActiveTab] = useState(0);

  const { data } = useQuery(GET_INVITED_MEMBERS);
  const invitedMembers = useMemo(() => data?.currentUser?.organisation?.members || [], [data]);

  useEffect(() => {
    setMembers(initialValue);
  }, [initialValue, watch]);

  useEffect(() => {
    if (!isAllowed) {
      history.push('/account/organisation/members');
    }
  }, [isAllowed, history]);

  const onUpdateMembers = (updatedMembers: OrgMemberInvite[]) => {
    setMembers(updatedMembers);
    setValue('members', updatedMembers);

    setCounter(updatedMembers.length - 1);

    if (activeTab === 1 && !updatedMembers[0].email && updatedMembers.length === 1)
      setView('normal');
  };

  const finalMembers = useMemo(() => members.filter(m => m.email), [members]);

  const createSeatMappings = (mapped: OrgMemberInvite[]) => ({
    MonthlyProSeats: mapped.filter((m) => m.tierId === 'pro' && m.interval === 'month').length,
    MonthlyPlusSeats: mapped.filter((m) => m.tierId === 'plus' && m.interval === 'month').length,
    AnnualPlusSeats: mapped.filter((m) => m.tierId === 'plus' && m.interval === 'year').length,
    AnnualProSeats: mapped.filter((m) => m.tierId === 'pro' && m.interval === 'year').length,
  });

  const [inviteUsers, { loading }] = useMutation(INVITE_ORG_MEMBERS, {
    refetchQueries: ['getInvitedUsers'],
    awaitRefetchQueries: true,
    onError: (e: ApolloError) => {
      const code = handleError(e);
      if (code === 'SEAT_UNAVAILABLE') {
        const extensions: any = e.graphQLErrors[0]?.extensions;
        mixpanelTrack('Seats Not assigned', {
          SeatsNotAssigned: extensions?.unassignable.length,
          ...createSeatMappings(extensions?.unassignable || []),
        });
        enqueueSnackbar(
          `${extensions?.unassignable.length} number of subscriptions were not assigned, please review in the members table`,
          { variant: 'warning' },
        );
        setTimeout(() => history.goBack(), 200);
      }
      mixpanelTrack('Invite Organisation Members Failed');
    },
    onCompleted: () => {
      mixpanelTrack('Invite Organisation Members Successful');
      enqueueSnackbar(
        'Organisation members have been invited to join VU.CITY.',
        { variant: 'success' },
      );
      history.goBack();
    },
  });

  const onInviteMembers = (values: { members: OrgMemberInvite[]; }): any => {
    const finalValues = values.members?.filter(val => !!val.email);

    mixpanelTrack('Send invites', {
      invitedEmails: finalValues.length,
      invitedSubscriptions: finalValues.length,
      OrgAdmins: finalValues.filter((m) => m.role === ADMIN_ROLE).length,
      OrgMembers: finalValues.filter((m) => m.role === MEMBER_ROLE).length,
      StarterSeats: finalValues.filter((m) => !m.tierId && !m.interval).length,
      ...createSeatMappings(finalValues),
    });

    return inviteUsers({
      variables: { invites: finalValues },
    });
  };

  return (
    <FullwidthLayout>
      <InviteButtons
        members={finalMembers}
        disabled={!finalMembers.length || !!Object.values(errors).length || loading}
        onSubmit={handleSubmit(onInviteMembers)}
      />
      <h3 className='mb-1'>Invite members</h3>

      <p className='text-lg'>
        Add new members to your VU.CITY organisation.
      </p>

      <Tabs onClick={(tabIndex) => setActiveTab(tabIndex)}>
        <Tab
          label="Add individually"
          counter={activeTab === 0 ? counter : undefined}
          disabled={activeTab === 1 && counter > 0}
        >
          <div className='mt-4'>
            <IndividualOrgInvites
              members={members}
              invitedMembers={invitedMembers}
              defaultTier={defaultTier}
              onUpdate={onUpdateMembers}
              register={register}
              hasError={{ errors, setError, clearErrors }}
            />
          </div>
        </Tab>
        <Tab
          label="Add multiple"
          counter={activeTab === 1 ? counter : undefined}
          disabled={activeTab === 0 && counter > 0}
        >
          <div className='flex flex-col gap-1 mt-4'>
            {view === 'normal'
              ? <MultipleOrgInvites
                onUpdate={onUpdateMembers}
                setView={setView}
                defaultTier={defaultTier}
                setCounter={setCounter}
              />
              : <IndividualOrgInvites
                members={members}
                invitedMembers={invitedMembers}
                defaultTier={defaultTier}
                onUpdate={onUpdateMembers}
                register={register}
                hasError={{ errors, setError, clearErrors }}
              />
            }
          </div>
        </Tab>
      </Tabs>
    </FullwidthLayout>
  );
};

export default InviteOrganisationMembers;

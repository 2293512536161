import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import {
  Card,
  CardActionArea,
  CardContent,
} from '@material-ui/core';
import { UndoIcon } from '@vucity/design_system';
import LogoDark from 'assets/VU.CITY-Dark.svg';
import { useCloudinary, useHandleError, useLimitedProjectAccess, useMixpanel } from 'hooks';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Ellipsis, Label } from 'tailwind';
import { Project, UserTier } from 'types/graphql';
import { UPDATE_PROJECT_VISIBILITY } from 'views/projects/apollo';
import ProjectDescription from 'views/projects/description/ProjectDescription';
import { ProjectActions } from '../ProjectActions/ProjectActions';
import { CardProjectCover } from './ProjectCard.style';
import ToggleStatus from './ToggleStatus/ToggleStatus';

import { ReactComponent as HiddenSvg } from 'assets/icons/VisibilityCrossed.svg';
import { ReactComponent as VisibleSvg } from 'assets/icons/VisibleFilled.svg';
import { extractCityName } from 'utils/helpers';

export interface ProjectWithSameOrg extends Project {
  isSameOrg?: boolean;
}

export interface ProjectCardProps {
  project: ProjectWithSameOrg;
  tier: UserTier;
  refetch: () => any;
  setVisiblityPreference: (preference: string) => void;
  view: string;
  visiblityPreference: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project,
  tier,
  refetch,
  setVisiblityPreference,
  visiblityPreference,
  view }) => {
  const { LOCKED, INVITED, LIMITED, checkStatus } = useLimitedProjectAccess();
  const history = useHistory();
  const { mixpanelTrack } = useMixpanel();
  const [cld, token] = useCloudinary();
  const { user } = useAuth0();

  const [upgradeModal, setUpgradeModal] = React.useState(false);
  const [inviteModal, setInviteModal] = React.useState(false);
  const [availableToAll, setAvailableToAll] = React.useState(false);
  const [updateProjectVisibility] = useMutation(UPDATE_PROJECT_VISIBILITY);
  const [handleError, enqueueSnackbar] = useHandleError();

  const { userStatus, access, userRole, location, isSameOrg, hidden } = {
    access: project?.access,
    userStatus: project.me?.status,
    userRole: project.me?.role,
    location: extractCityName(project.location?.license?.stripeProduct?.name),
    isSameOrg: project.isSameOrg,
    hidden: project.hiddenFor?.includes(user?.sub!),
  };

  const { limitedUrl, isLimited } = checkStatus(
    project.id, userRole, userStatus,
  );

  const disabled = userStatus === INVITED || userStatus === LOCKED || userStatus === 'AVAILABLE_TO_ORG_ADMINS';

  const clickHandle = (e: React.SyntheticEvent) => {
    const isAvailableToAll = isSameOrg && access === 'AVAILABLE_TO_ALL';
    const isAvailableToOrgAdmins = isSameOrg && userStatus === 'AVAILABLE_TO_ORG_ADMINS';
    const mixpanelProps = { status: userStatus, projectId: project.id, access: project.access ?? 'INVITE_ONLY' };
    const mixpanel = {
      AVAILABLE_TO_ORG_ADMINS: 'Project card -> available to org admins - Clicked',
      AVAILABLE_TO_ALL: 'Project card -> available to all - Clicked',
      LIMITED: 'Clicked Project card - Limited access',
      default: 'Clicked Project card',
    };

    if (userStatus === INVITED || userStatus === 'AVAILABLE_TO_ORG_ADMINS') {
      e.preventDefault();
      if (isAvailableToAll || isAvailableToOrgAdmins) setAvailableToAll(true);
      else {
        return setInviteModal(true);
      }
    }

    if (userStatus === LOCKED) {
      e.preventDefault();

      if (!isLimited) setUpgradeModal(true);
      else {
        history.push(limitedUrl);
        return mixpanelTrack(mixpanel.LIMITED, mixpanelProps);
      }
    }

    if (isAvailableToAll) return mixpanelTrack(mixpanel.AVAILABLE_TO_ALL, mixpanelProps);
    if (isAvailableToOrgAdmins) return mixpanelTrack(mixpanel.AVAILABLE_TO_ORG_ADMINS, mixpanelProps);
    return mixpanelTrack(mixpanel.default, mixpanelProps);
  };

  const viewProjects = (visible: boolean) => {
    if (visible) return setVisiblityPreference('Visible Projects');
    setVisiblityPreference('Hidden Projects');
  };

  const logToMixpanel = () => {
    const mixpanelProps = {
      'Hub view': view,
      'Visibility view': visiblityPreference,
      'Project ID': project.id,
    };
    mixpanelTrack('Project Card > Show Button > Selected', mixpanelProps);
  };

  const addMeToHiddenFor = () => [...project.hiddenFor!, user?.sub!];
  const removeMeFromHiddenFor = () => project.hiddenFor!?.filter(id => id !== user?.sub!);

  const updateProjectState = (customMessage: JSX.Element, undo?: boolean) => {
    let list: string[] = [];
    if (undo && hidden) list = addMeToHiddenFor();
    if (undo && !hidden) list = removeMeFromHiddenFor();
    if (!undo) {
      list = hidden ? removeMeFromHiddenFor() : addMeToHiddenFor();
      logToMixpanel();
    }
    updateProjectVisibility({
      variables: {
        id: project.id,
        project: {
          hiddenFor: list,
        },
      },
      onCompleted: async () => {
        if (!undo) enqueueSnackbar(customMessage, { variant: 'success' });
        await refetch();
      },
      onError: handleError,
    });
  };

  const renderCustomMessage = () => {
    const handleUndo = () => {
      updateProjectState(renderCustomMessage(), true);
    };
    return (
      <>
        {!hidden && (<>
          Your project has been hidden,&nbsp;
          <span className='underline cursor-pointer' onClick={() => viewProjects(false)}>
            view hidden projects
          </span>
        </>)}
        {hidden && (<>
          Your project is visible,&nbsp;
          <span
            className='underline cursor-pointer'
            onClick={() => viewProjects(true)}
          >
            view visible projects
          </span>
        </>)}&nbsp;&nbsp;
        <UndoIcon className='cursor-pointer' onClick={handleUndo} />
      </>
    );
  };

  const toggleProjectVisibility = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    updateProjectState(renderCustomMessage());
  };

  return (
    <Card elevation={0} className='relative min-h-[220px] group'>
      <Link
        to={`/project/${project.id}`}
        onClick={(e) => clickHandle(e)}
      >
        <CardActionArea disabled={disabled}>
          <CardProjectCover
            imageid={project.imageId}
            image={
              project.imageId
                ? `${cld
                  .image(project.imageId)
                  .resize(scale().width(400))
                  .toURL()}&${token}`
                : LogoDark
            }
            className="custom"
          />
          <CardContent>
            {project.location && (
              <Label className='absolute bottom-11'>{location}</Label>
            )}

            <Ellipsis rows={1} className={`${isLimited && 'text-placeholder'} mb-1 text-base`}>
              {project.name}
            </Ellipsis>

            <Ellipsis rows={1} className={`${(userStatus === 'LOCKED' || isLimited) && 'text-placeholder'} text-xs`}>
              <span>{project.owner?.name}</span>
            </Ellipsis>
          </CardContent>
        </CardActionArea>
      </Link>

      <ToggleStatus access={access} status={isLimited ? LIMITED : userStatus} isSameOrg={isSameOrg} />
      <button
        className={`absolute bottom-11 right-1 w-4 h-3 p-0.5 
                  bg-white rounded justify-center items-center
                  hidden group-hover:flex
                  hover:opacity-90`}
        onClick={toggleProjectVisibility}
      >
        {!hidden ? <VisibleSvg className='text-primary' /> : <HiddenSvg className='text-primary' />}
      </button>
      <ProjectDescription
        project={project}
        isLimited={isLimited}
        hidden={hidden!}
        handleOnClick={clickHandle}
        inactive={disabled}
        refetch={refetch}
        visiblityPreference={visiblityPreference}
        setVisiblityPreference={setVisiblityPreference}
      />

      <ProjectActions
        tier={tier}
        project={project}
        upgradeModal={upgradeModal}
        inviteModal={inviteModal}
        availableToAll={availableToAll}
        setInviteModal={setInviteModal}
        setUpgradeModal={setUpgradeModal}
        setAvailableToAll={setAvailableToAll}
      />
    </Card>
  );
};

export default ProjectCard;

import imageCompression, { Options } from 'browser-image-compression';

export const compressImages = async (image: File) => {
  let compressedImage: File = image;

  const options: Options = {
    alwaysKeepResolution: true,
    useWebWorker: true,
    maxSizeMB: 1,
  };

  if (image.type.startsWith('image/')) {
    try {
      const compressedFile = await imageCompression(image, options);
      compressedImage = compressedFile;
    } catch (compressionError) {
      console.error('Image compression failed:', compressionError);
      return compressedImage;
    }
  }

  return compressedImage;
};
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useMixpanel } from 'hooks';
import { useEffect } from 'react';
import { useControl } from 'react-map-gl';
import { mapboxCrendetials } from 'utils/configs';

interface IGeocoderProps {
  selectedLocation?: {
    bbox: MapboxGeocoder.Bbox | undefined;
    coords: { lng: number; lat: number; };
  };
  setCoordsAndAddress?: (coords: { lng: number; lat: number; }, address: string) => {};
}

const Geocoder = ({ selectedLocation, setCoordsAndAddress }: IGeocoderProps) => {
  const { bbox, coords } = selectedLocation || {};
  const { lng, lat } = coords || {};
  const { mixpanelTrack } = useMixpanel();

  const geocoder = useControl<MapboxGeocoder>(
    () => {
      const ctrl = new MapboxGeocoder({
        placeholder: 'Search location or postcode',
        accessToken: mapboxCrendetials,
        marker: false,
        flyTo: true,
        language: 'en-GB',
        bbox,
      });

      ctrl.on('result', evt => {
        mixpanelTrack('Completing a search input');
        const { result } = evt;
        const location = result && result.center;

        if (location && setCoordsAndAddress) {
          setCoordsAndAddress({
            lng: result.center[0],
            lat: result.center[1],
          }, result.place_name);
        }
      });

      return ctrl;
    },
    {
      position: 'top-left',
    },
  );

  useEffect(() => {
    if (geocoder && lat && lng && bbox) {
      geocoder.setBbox(bbox);
      geocoder.setProximity({
        longitude: lng,
        latitude: lat,
      });
    }
  }, [bbox, geocoder, lat, lng]);

  return null;
};

export default Geocoder;

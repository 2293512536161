import { lazy } from 'react';
import OfficeDocsViewer from './Drivers/OfficeDocsViewer';
import PdfViewer from './Drivers/PdfViewer';
import PhotoViewer from './Drivers/PhotoViewer/PhotoViewer';
import VideoViewer from './Drivers/VideoViewer';
import UnsupportedComponent from './UnsupportedComponent/UnsupportedComponent';

export default function (fileType: string) {

  switch (fileType) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'webp':
    case 'png':
    case 'svg':
    case 'ico':
    case 'tiff': {
      return PhotoViewer;
    }
    case 'mp4':
    case 'webm': {
      return VideoViewer;
    }
    case 'pdf': {
      return PdfViewer;
    }
    case 'docx':
    case 'xlsx':
    case 'xls':
    case 'ppt':
    case 'pptx': {
      return OfficeDocsViewer;
    }
    case 'txt':
    case 'csv': {
      return lazy(() => import('./Drivers/DocViewerWrapper'));
    }
    default: {
      return UnsupportedComponent;
    }
  }
}
import { useMutation } from '@apollo/client';
import {
  TextField,
} from '@material-ui/core';
import { Autocomplete } from 'components';
import { useMixpanel } from 'hooks';
import React, { ChangeEvent, FC, useState } from 'react';
import { Button } from 'tailwind';
import { UPDATE_ACCOUNT } from 'utils/apollo/user';
import { jobTitles } from 'utils/configs';
import { StepProps } from '../types';
import LogoDark from './step2.svg';

let jobTitlePersist = '';

const Step2: FC<StepProps> = ({ handleNext }) => {
  const [openAuto, setOpenAuto] = useState(false);
  const [jobTitle, setJobTitle] = useState(jobTitlePersist);
  const { mixpanelTrack } = useMixpanel();

  const changeHandle = (value: any) => {
    const trimmedValue = value?.trim();
    const str = trimmedValue?.length > 3 ? trimmedValue : '';
    jobTitlePersist = str;
    setJobTitle(str);
  };

  const [updateAccount, { loading }] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: () => {
      handleNext();
      mixpanelTrack('Update account Successful');
    },
  });

  const updateJobTitle = () => {
    updateAccount({ variables: { jobTitle: jobTitle?.trim() } });
    mixpanelTrack('Job title updated');
  };

  return (
    <>
      <img
        width='208'
        height='208'
        src={LogoDark}
        className='block mx-auto mb-3'
        alt="Thumbnail placeholder"
      />
      <h3 className='mb-3 font-normal'>Tell us about yourself</h3>

      <p className='text-lg mb-2.5 text-center'>
        Add your job title/role so other project members understand what you do.
        You can change this at any time in your profile settings.
      </p>

      <div className='w-full mb-4'>
        <label className='block mb-1 text-sm'>Job title</label>
        <Autocomplete
          id="jobTitle"
          freeSolo
          className={openAuto ? 'openedAutocomplete' : ''}
          open={openAuto}
          onOpen={() => setOpenAuto(true)}
          onClose={() => setOpenAuto(false)}
          options={jobTitles.filter((job) => job.toLowerCase().includes(jobTitle.toLowerCase()))}
          value={jobTitle}
          onKeyDown={(e) => {
            if (e.key === 'Enter') setOpenAuto(false);
          }}
          onChange={(e: ChangeEvent<{}>, value: string | null) => changeHandle(value as string)}
          renderInput={(props: any) => (
            <TextField
              {...props}
              value={jobTitle}
              onChange={(e) => changeHandle(e.target.value)}
              aria-label="Job title"
              variant="outlined"
              placeholder="Enter title"
            />
          )}
        />
      </div>

      <div className='flex justify-between w-full'>
        <Button
          size='md'
          padding='none'
          variant='text'
          onClick={() => { mixpanelTrack('Skip this step'); handleNext(); }}
        >
          Skip this step
        </Button>
        <Button
          variant="contained"
          disabled={!jobTitle || loading}
          onClick={updateJobTitle}
        >
          Confirm job title
        </Button>
      </div>
    </>
  );
};
export default Step2;

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { Auth0ProviderNoLogin, AuthProvider, MobileWarning, ThemeProviders } from 'components';
import { CoverageMap, Welcome } from 'views';

import App from './App';
import ErrorFallback from './components/Errors/Boundary/Boundary';
import GlobalErrorFallback from './components/Errors/Boundary/Global';
import './index.css';
import * as serviceWorker from './serviceWorker';

const envMap = {
  LOCAL: 'localhost',
  DEVELOP: 'vudev.city',
  STAGING: 'vustage.city',
  PROD: 'vu.city',
};

Sentry.init({
  dsn:
    'https://e15e630ca00b4c20b41f7ee988f11ee0@o877526.ingest.sentry.io/5828227',
  integrations: [new Integrations.BrowserTracing()],
  environment: envMap[import.meta.env.VITE_ENV as keyof typeof envMap],
  release: import.meta.env.VITE_COMMIT_REF,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed',
    'Non-Error promise rejection captured with',
    'Context creation failed',
    'Failed to fetch dynamically',
    'error loading dynamically imported module',
    'Failed to execute \'insertBefore\' on \'Node\'',
    'Failed to execute \'removeChild\' on \'Node\'',
    'Properties can only be defined on Objects',
    'The object can not be found here',
    'aborted',
    'Abstract type "Event" must resolve to an Object type at runtime',
    'Minified Material-UI error',
    'Unknown angle "NaN"',
  ],
  denyUrls: [
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /^ingress/i,
  ],
  beforeSend: (event: any) => {
    const env = envMap[import.meta.env.VITE_ENV as keyof typeof envMap];

    if (window.location.hostname === 'localhost' || env === 'vudev.city') {
      return null;
    }

    return event;
  },
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={(props) => <GlobalErrorFallback {...props} />}>
      <ThemeProviders>
        <SnackbarProvider classes={{ containerRoot: 'snackbar__container' }}>
          <Router>
            <Switch>
              <Route exact path="/non/coveragemap" component={() => (
                <Auth0ProviderNoLogin>
                  <CoverageMap />
                </Auth0ProviderNoLogin>
              )} />

              <AuthProvider>
                <MobileWarning>
                  <Welcome>
                    <Sentry.ErrorBoundary fallback={(props) => <ErrorFallback {...props} />}>
                      <App />
                    </Sentry.ErrorBoundary>
                  </Welcome>
                </MobileWarning>
              </AuthProvider>
            </Switch>
          </Router>
        </SnackbarProvider>
      </ThemeProviders>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

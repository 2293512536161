import { DetailedSelect } from 'components';
import { useCurrentUser, useMixpanel } from 'hooks';
import React, { useEffect, useState } from 'react';
import { IndividualInvites, MultipleInvites, Tab, Tabs } from 'tailwind';
import { ProjectAccess, ProjectMemberInput, ProjectMemberRole } from 'types/graphql';
import { roleOptions } from 'utils/helpers';

import { ReactComponent as PeopleFilled } from './people-filled.svg';
import { ReactComponent as People } from './people.svg';
import { ReactComponent as PersonFilled } from './person-filled.svg';
import { ReactComponent as Person } from './person.svg';

const options = [
  { value: 'INVITE_ONLY', title: 'Invite only', subtitle: 'Members can only join the project if they are invited. ' },
  { value: 'AVAILABLE_TO_ALL', title: 'Available to all', subtitle: 'The project is open to everyone within your organisation. Anyone outside of your organisation would still require an invitation.' },
];

const Step3 = ({ watch, setValue, errors, setError, clearErrors }: any) => {
  const { currentUser } = useCurrentUser();
  const { mixpanelTrack } = useMixpanel();

  const { orgAccess, orgDefaultRole } = {
    orgAccess: currentUser?.organisation?.data?.access || 'INVITE_ONLY',
    orgDefaultRole: currentUser?.organisation?.data?.defaultRole || 'VIEWER',
  };

  const [multiCounter, setMultiCounter] = useState<number>(0);
  const [access, setAccess] = useState<ProjectAccess>(watch('access') || orgAccess);
  const [defaultRole, setDefaultRole] = useState<ProjectMemberRole>(watch('defaultRole') || orgDefaultRole);
  const [activeTab, setActiveTab] = useState(0);
  const [view, setView] = useState('normal');

  const initialValue = watch('members') || [{ email: '', role: defaultRole }];
  const [members, setMembers] = useState<ProjectMemberInput[]>(initialValue);

  useEffect(() => {
    if (!watch('access') || !watch('defaultRole')) {
      setValue('access', orgAccess);
      setValue('defaultRole', orgDefaultRole);
    }
  }, [watch, setValue, orgAccess, orgDefaultRole]);

  const onUpdateMembers = (updatedMembers: ProjectMemberInput[]) => {
    setMembers(updatedMembers);
    setValue('members', updatedMembers);
  };

  const resetView = () => {
    setMultiCounter(0);
    const formMembers = watch('members');
    if (formMembers.length === 1 && !formMembers[0].email && activeTab === 1)
      setView('normal');
  };

  const counter = members.filter(m => m.email).length;

  return (
    <section>
      <h3>Set project settings and add your team members</h3>
      <p className='mt-1 text-lg'>
        Add your project members and assign them a project role.
      </p>

      <article className='flex w-full gap-3 mt-3'>
        <div className='flex flex-col flex-grow-0 flex-shrink-0 w-full gap-1 px-2 pt-3 pb-2 border rounded h-fit max-w-350 bg-primary-lightest border-placeholder'>
          <p className='text-lg font-medium'>
            Set your project's access setting
          </p>

          <p className='text-sm'>Set how members can access your project</p>
          <DetailedSelect
            variant='outlined'
            value={access}
            items={options}
            handleChange={(option) => {
              if (option?.value) {
                setAccess(option?.value as ProjectAccess);
                setValue('access', option?.value);
                mixpanelTrack(`Create a Project > Invite members > ${option?.value}`, {
                  'Organisations default settings': orgAccess,
                });
              }
            }}
          />

          {access === 'AVAILABLE_TO_ALL' && (
            <>
              <p className='text-sm'>Set the default project role</p>
              <DetailedSelect
                variant='outlined'
                value={defaultRole}
                items={roleOptions('ADMIN')}
                handleChange={(option) => {
                  if (option?.value) {
                    const value = option.value as ProjectMemberRole;

                    setDefaultRole(value);
                    setValue('defaultRole', option.value);
                    mixpanelTrack('Create a Project > Invite members > Available to All > Set Role', {
                      'Role set': option.value,
                    });

                    const defaultMember = members.map((m) => (
                      { ...m, role: !m.email ? value : m.role }
                    ));
                    onUpdateMembers(defaultMember);
                  }
                }}
              />
            </>
          )}
        </div>

        <div className='flex-grow pt-1 pb-2 pl-2 pr-1 border rounded h-fit border-placeholder'>
          <Tabs onClick={(tabIndex) => setActiveTab(tabIndex)}>
            <Tab
              label="Add individually"
              counter={activeTab === 0 ? counter : undefined}
              disabled={activeTab === 1 && multiCounter > 0}
              icon={activeTab === 0 ? <PersonFilled /> : <Person />}
              iconPosition='start'
            >
              <div className='flex flex-col gap-1'>
                <div className='flex gap-2'>
                  <p className='w-[55%] text-sm'>Add email, select members or invite your entire organisation</p>
                  <p className='w-[45%] text-sm'>Set role</p>
                </div>

                <IndividualInvites
                  members={members}
                  defaultRole={defaultRole}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  onUpdate={onUpdateMembers}
                  showRole
                  mixpanelEvent='create'
                />
              </div>
            </Tab>

            <Tab
              label="Add multiple"
              counter={activeTab === 1 ? multiCounter : undefined}
              disabled={activeTab === 0 && counter > 0}
              icon={activeTab === 1 ? <PeopleFilled /> : <People />}
              iconPosition='start'
            >
              <div className='flex flex-col gap-1'>
                {view === 'normal'
                  ? <MultipleInvites setMultiCounter={setMultiCounter} onUpdate={onUpdateMembers} setView={setView} defaultRole={defaultRole} />
                  : <IndividualInvites
                    members={members}
                    defaultRole={defaultRole}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    onUpdate={onUpdateMembers}
                    resetView={resetView}
                    showRole
                    mixpanelEvent='create'
                  />
                }
              </div>
            </Tab>
          </Tabs>
        </div>
      </article>
    </section>
  );
};

export default Step3;

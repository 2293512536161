import { InfoInactive } from '@vucity/design_system';
import { Tooltip } from 'components';
import React from 'react';
import { CityIcons } from 'tailwind';
import { OrgLicense } from 'types/graphql';
import { extractCityName } from 'utils/helpers';
import { cityLicense, noLicense } from '../text';

interface CityProps {
  licenses: OrgLicense[];
}

const CitiesTable = ({ licenses }: CityProps) => {
  const activeLicences = licenses?.filter(({ status }) => status === 'active' || status === 'trialing') || [];

  return (
    <div className='flex flex-col px-2 py-1 mt-2 border rounded-sm border-primary-lightest'>
      <div className='flex items-start justify-between mb-2'>
        <div className='flex items-center text-sm text-grey'>
          <p className='leading-6'>{cityLicense()}</p>
          <Tooltip
            placement="right-start"
            header="What is a city licence?"
            content="This is a licence purchase for your entire organisation that all pro subscribers can access."
          >
            <div className='ml-1.2'>
              <InfoInactive color="primary" style={{ padding: '0', height: '16px', width: '16px' }} />
            </div>
          </Tooltip>
        </div>
      </div>

      {activeLicences.length
        ? activeLicences.map((val) => (
          <div className='flex items-center w-full gap-1 py-1 border-b border-b-primary-lightest last-of-type:border-0' key={val.id}>
            <CityIcons locationId={val?.license?.location.id} size='w-[28px] h-[28px]' />
            <p>{extractCityName(val?.license?.stripeProduct.name)}</p>
          </div>
        ))
        : <p>{noLicense()}</p>}
    </div>
  );
};

export default CitiesTable;

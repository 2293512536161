import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { bytesToSize } from 'utils/helpers';

interface IProgressBarProps {
  total: number,
  used: number;
}

const ProgressBar: React.FC<IProgressBarProps> = ({ total, used }) => {
  const progressStyle = {
    width: '270px',
    height: '16px',
    borderRadius: '8px',
  };

  const percentage = (used / total) * 100;

  return (
    <div>
      <p className='mb-1 text-xs'>
        {bytesToSize(used, 2)} used / {bytesToSize(total, 0)}
      </p>
      <LinearProgress
        style={progressStyle}
        variant="determinate"
        aria-label="Used storage"
        value={percentage}
      />
    </div>
  );
};

export default ProgressBar;

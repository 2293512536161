import React, { useEffect, useMemo } from 'react';
import { useHandleError, useMixpanel } from 'hooks';

interface ICopyProps {
  id: string;
  path: string;
  setAction: React.Dispatch<string>;
}

const CopyLink: React.FC<ICopyProps> = ({ id, path, setAction }) => {
  const { mixpanelTrack } = useMixpanel();
  const [, enqueueSnackbar] = useHandleError();
  const linkToCopy = useMemo(() => `${window.location.origin}/${path}#${id}`, [path, id]);

  useEffect(() => {
    const copyLink = async () => {
      mixpanelTrack('Copy link to comment', {
        'Copied comment': linkToCopy,
      });
      await navigator.clipboard.writeText(linkToCopy);
      enqueueSnackbar('Link copied to clipboard', { variant: 'success' });
      setAction('');
    };

    copyLink();
  }, [enqueueSnackbar, linkToCopy, setAction, mixpanelTrack]);

  return <></>;
};

export default CopyLink;

import { Step, StepLabel, Stepper as MuiStepper } from '@material-ui/core';
import { useMixpanel } from 'hooks';
import React from 'react';
import StepNumber from './StepNumber';

interface StepperProps {
  activeStep: number;
  setActiveStep: (arg: number) => void;
  steps: { title: string, button?: string; }[];
  mixpanelLabel?: string;
}

const Stepper = ({
  activeStep, setActiveStep, steps, mixpanelLabel,
}: StepperProps) => {
  const { mixpanelTrack } = useMixpanel();

  return (
    <MuiStepper
      activeStep={activeStep}
      alternativeLabel
      className='pt-0'
      connector={<></>}
    >
      {steps.map(({ title }, index) => {
        const complete = index < activeStep;
        const active = index === activeStep;
        const number = index + 1;

        const unvisited = !complete && !active;

        return (
          <Step key={title}>
            <StepLabel
              StepIconComponent={() => <StepNumber active={active} complete={complete} number={number} />}
              onClick={() => {
                if (index <= activeStep) {
                  setActiveStep(index);
                  mixpanelTrack(`${mixpanelLabel} Step ${number} clicked`);
                }
              }}
            >
              {index > 0 && (
                <div className={`${unvisited ? 'border-b-primary-lightest' : 'border-b-primary'} top-2.5 absolute border-b left-[calc(-50%+35px)] right-[calc(50%+35px)] mx-4`} />
              )}
              <p className={`text-sm font-normal ${(active || complete) ? 'text-content' : 'text-primary-light'}`}>
                {title}
              </p>
            </StepLabel>
          </Step>
        );
      })}
    </MuiStepper>
  );
};

export default Stepper;

import format from 'date-fns/format';
import parse from 'date-fns/parseISO';
import React from 'react';

import { CloseIcon } from '@vucity/design_system';
import ProfileCard from 'components/ProfileCard/ProfileCard';
import { FileNameEditor } from 'tailwind';
import { File, ProjectMemberRole } from 'types/graphql';

interface IFileMeta {
  id?: string;
  file?: File;
  onClosePreview: () => void;
  role?: ProjectMemberRole;
}

const FileMeta: React.FC<IFileMeta> = ({ id, file, onClosePreview, role }) => {
  if (!file) return <div className='p-1 pl-3' />;
  const isUserRemoved = file?.isUserRemoved;

  return (
    <div className='p-1 pl-3'>
      <button className='absolute right-3 top-[26px] cursor-pointer [&_svg]:w-[14px] [&_svg]:h-[14px]' onClick={onClosePreview} >
        <CloseIcon htmlColor="#333" />
      </button>

      <div className='break-words font-medium max-w-[90%]'>
        <FileNameEditor
          isPreview
          nodeId={file.id}
          initialContent={file.name}
          type='File'
          readonly={role && role === 'VIEWER'}
        />
      </div>

      <p className='mt-1 text-sm text-grey'>
        {id
          ? <ProfileCard redirect={!isUserRemoved} projectId={id} email={isUserRemoved ? '' : file.creator.email} leaveDelay={800} placement='left-start'>
            <span className='transition duration-300 p-0.5 hover:cursor-default hover:bg-slate-200'>{isUserRemoved ? 'Deleted User' : file.creator.name}</span>
          </ProfileCard>
          : <span className='p-0.5'>{isUserRemoved ? 'Deleted User' : file.creator.name}</span>}
      </p>

      <p className='p-0.5 text-xs'>
        {format(parse(file.createdAt), "do MMMM yyyy 'at' HH:mm")}
      </p>

      <hr className='my-1' />
    </div>
  );
};

FileMeta.displayName = 'FileMeta';

export default FileMeta;

import { DrawMap, GQLWrapper, Licenses, SwitchStyle, VuMap } from 'components';
import { useLicences, useQueryParam } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { License, ProjectInput } from 'types/graphql';

interface Coords {
  lng: number,
  lat: number;
}

interface Step2Props {
  setValue: UseFormSetValue<ProjectInput>;
  watch: UseFormWatch<ProjectInput>;
}

const Step2 = ({ watch, setValue }: Step2Props) => {
  const query = useQueryParam().get('licence');
  const { allLicences, loading, error } = useLicences();

  const initialValues = {
    location: allLicences?.find(({ id }) => id === watch('location')?.licenseId) as License,
    centre: watch('location')?.coords || { x: 0, y: 0 },
  };

  const [selectedLicense, setSelectedLicense] = useState<License>(initialValues.location);
  const [selectedCoords, setSelectedCoords] = useState<Coords>({ lng: initialValues.centre?.x, lat: initialValues.centre?.y });

  const getCoords = (licence: License) => licence?.location?.centre?.convert;

  const onLicenseChange = useCallback((e: string) => {
    const findLicence = allLicences.find(licence => licence.id === e);
    if (!findLicence) return;

    const coords = getCoords(findLicence);

    setSelectedLicense(findLicence);
    setValue('location', {
      licenseId: findLicence.id,
      coords: { x: coords?.x || 0, y: coords?.y || 0 },
    });

    if (coords)
      setSelectedCoords({ lng: coords.x, lat: coords.y });
  }, [allLicences, setValue]);

  useEffect(() => {
    if (initialValues.location) {
      setSelectedLicense(initialValues.location);
    }
  }, [initialValues.location]);

  useEffect(() => {
    if (query && !selectedCoords.lng) onLicenseChange(query);
  }, [query, selectedCoords, onLicenseChange]);

  const updateCoords = ({ lng, lat }: Coords) => {
    setValue('location', {
      licenseId: selectedLicense.id,
      name: selectedLicense.name,
      coords: {
        x: lng,
        y: lat,
      },
    });
    setSelectedCoords({ lng, lat });
  };

  return (
    <GQLWrapper loading={loading} error={error}>
      <h3>Add a location</h3>
      <p className='mt-1 text-lg'>
        You can enter in a location or select an area using the map. This can be updated later once the project is set up.
      </p>

      <div className='flex items-start gap-3 mt-3'>
        <Licenses
          licenses={allLicences}
          selectedLicense={selectedLicense?.id}
          onLicenseChange={onLicenseChange}
          className='flex-shrink-0 max-w-220'
        />

        <div className='w-full border rounded h-500 border-primary-light'>
          {!getCoords(selectedLicense)?.x
            ? <div className='flex flex-col items-center justify-center w-full h-full gap-1'>
              <p className='text-xl font-medium'>Select a location for your project</p>
              <p>A location must be set in order to open the VU.CITY 3D App</p>
            </div>
            : <SwitchStyle showBtn>
              {(style: string) => (
                <VuMap center={[selectedCoords.lng, selectedCoords.lat]} zoom={9} style={style} customControls controlsClass='m-3' controlsPosition='top-right'>
                  <DrawMap
                    selectedCoords={selectedCoords}
                    setSelectedCoords={updateCoords}
                    setAddress={() => { }}
                  />
                </VuMap>
              )}
            </SwitchStyle>
          }
        </div>
      </div>
    </GQLWrapper>
  );
};

export default Step2;

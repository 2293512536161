import TextModal from 'components/Modal/TextModal/TextModal';
import { useMixpanel } from 'hooks';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface InvitationLockProps {
  upgradeModal: boolean;
  setUpgradeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function InvitationLockModal(props: InvitationLockProps) {
  const { upgradeModal, setUpgradeModal } = props;
  const { mixpanelTrack } = useMixpanel();
  const history = useHistory();
  return (
    <TextModal
      open={upgradeModal}
      setOpen={setUpgradeModal}
      mixpanelPath="No spaces available"
      headerText="Looks like you’ve no spaces available"
      closeBtnLabel="Close"
      mainBtnLabel="See organisation admins"
      onClose={() => mixpanelTrack('Close on "No spaces available"')}
      mainBtnHandle={() => {
        mixpanelTrack('See organisation admins on "No spaces available"');
        setUpgradeModal(false);
        history.push({
          pathname: '/account/organisation/members',
          state: { fromDashboard: true },
        });
      }}
    >
      You can unlock unlimited project spaces by upgrading your account’s
      subscription. Contact an organisation admin to upgrade your account.
    </TextModal>
  );
}

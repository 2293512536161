import { gql } from '@apollo/client';

const MOVE_NODES = gql`
  mutation($nodes: [ID], $projectId: ID, $folder: NodeInput) {
    moveNodes(nodes: $nodes, projectId: $projectId, folder: $folder) {
      id
    }
  }
`;

export default MOVE_NODES;

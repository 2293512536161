import { MenuItem } from '@material-ui/core';
import { useMixpanel } from 'hooks';
import React from 'react';

interface IProps {
  projectId?: string;
  setIsTransferring: React.Dispatch<React.SetStateAction<{ selected: boolean; toPersonal: boolean; }>>;
}

const UploadToPersonal: React.FC<IProps> = ({ projectId, setIsTransferring }) => {
  const { mixpanelTrack } = useMixpanel();

  if (!projectId) return null;

  return (
    <>
      <MenuItem onClick={() => {
        setIsTransferring({ selected: true, toPersonal: true });
        mixpanelTrack('Upload to personal drive');
      }}>
        Upload to personal drive
      </MenuItem>
      <hr className='my-1 border-grey-border' />
    </>
  );
};

export default UploadToPersonal;

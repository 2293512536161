import { useMixpanel } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import mapStyle from './images/mapStyle.png';
import satelliteStyle from './images/satelliteStyle.png';

interface ISwitchStyleProps {
  showBtn?: boolean;
  children(style: string): React.ReactNode | JSX.Element;
  showCoverage?: boolean;
}

function SwitchStyle({ showBtn = false, children, showCoverage }: ISwitchStyleProps) {
  const { mixpanelTrack } = useMixpanel();
  const [style, setStyle] = useState<string>('mapbox://styles/vucity/ckfadf5fg283r19pbezzang9o');
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const onToggleButton = useCallback((isStyle: boolean) => {
    const newStyle = isStyle ? '' : 'satellite';
    localStorage.setItem('mapStyle', newStyle);
    setIsSelected(!isStyle);

    switch (newStyle) {
      case 'satellite':
        return setStyle('mapbox://styles/mapbox/satellite-v9');
      default:
        return setStyle('mapbox://styles/vucity/ckfadf5fg283r19pbezzang9o');
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('mapStyle')) { onToggleButton(false); }
  }, [onToggleButton]);

  return (
    <div className='relative w-full h-full bg-white rounded'>
      <div className={`absolute z-10 flex justify-start ${showCoverage ? 'left-[370px] bottom-3' : 'bottom-3 left-3'}`}>
        <button
          onClick={() => {
            mixpanelTrack(`${!isSelected ? 'Switch to Satellite view' : 'Switch to Streets view'}`);
            onToggleButton(isSelected);
          }}
          className={`relative w-8 h-8 rounded text-xs font-normal ${showBtn ? 'visible' : 'invisible'}`}
          style={{
            background: isSelected ? `linear-gradient(180deg, rgba(51, 51, 51, 0) 46.88%, rgba(51, 51, 51, 0.92) 100%), url(${mapStyle})` : `url(${satelliteStyle})`,
          }}
        >
          <div className='absolute bottom-0 w-full text-white'>
            {isSelected ? 'Map' : 'Satellite'}
          </div>
        </button>
      </div>

      {children(style)}
    </div>
  );
}

export default SwitchStyle;

import { gql, TypedDocumentNode } from '@apollo/client';
import { License, User } from 'types/graphql';

const LICENCE = `
  id
  name
  stripeProduct {
    name
  }
  location {
    id
    name
    displayName
    centre {
      x
      y
      epsg
      convert (epsg: 4326) {
        x
        y
      }
    }
    epsg
  }
`;

const GET_LICENCES: TypedDocumentNode<{ cityLicenses: License[]; currentUser: User; }> = gql`
  query GetLicences {
    cityLicenses {
      ${LICENCE}
    }
    currentUser {
      siteLicenses {
        licenseId
      }
      organisation {
        siteLicenses {
          license {
            ${LICENCE}
          }
        }
      }
    }
  }
`;
export default GET_LICENCES;

import { ApolloError, useMutation } from '@apollo/client';
import { FormControl, FormHelperText, MenuItem, OutlinedInput } from '@material-ui/core';
import { IContextMenuBase } from 'components/Drive/types';
import GQLWrapper from 'components/GQLWrapper/GQLWrapper';
import { TextModal } from 'components/Modal';
import { useHandleError, useMixpanel } from 'hooks';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RENAME_NODES } from '../apollo';

interface IRenameProps extends IContextMenuBase { }

const Rename = ({
  projectId, files, closeContextMenu, resetFiles,
}: IRenameProps) => {
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();
  const [open, setOpen] = useState<boolean>(false);
  const { handleSubmit, register, formState: { errors } } = useForm<{ name: string; }>();

  const splitFile = files[0]?.name.filename.split('.');
  const filename = splitFile.length === 1
    ? splitFile[0]
    : splitFile.slice(0, splitFile.length - 1).join('.');

  const closeModal = useCallback(() => {
    setOpen(false);
    closeContextMenu();
    resetFiles();
  }, [setOpen, closeContextMenu, resetFiles]);

  const getFileExt = (str: string) => {
    const words = str.split('.');
    return words[words.length - 1];
  };

  const [renameNodes, { loading }] = useMutation(RENAME_NODES, {
    onCompleted: () => {
      mixpanelTrack('Rename Successful');
      enqueueSnackbar('File renamed', { variant: 'success' });
      closeModal();
    },
    onError: (err: ApolloError) => { handleError(err); mixpanelTrack('Rename Failed'); },
  });

  const onRename = ({ name }: { name: string; }) => {
    const extension = files[0]?.mimeType === 'vucity/project' ? 'vu' : getFileExt(files[0]?.name.filename);

    renameNodes({
      variables: {
        node: { id: files[0]?.id },
        projectId,
        name: files[0]?.folder ? name?.trim() : `${name?.trim()}.${extension}`,
      },
    });
  };

  return (
    <GQLWrapper loading={loading}>
      <MenuItem onClick={() => setOpen(true)}>
        Rename
      </MenuItem>
      <TextModal
        open={open}
        setOpen={closeModal}
        mixpanelPath='Rename'
        headerText='Rename'
        closeBtnLabel='Cancel'
        mainBtnLabel='Rename'
        mainBtnHandle={handleSubmit(onRename)}
        loading={loading}
        component='div'
      >
        <p className='mb-2'>
          Please enter a new name for the item
        </p>

        <FormControl fullWidth>
          <OutlinedInput
            labelWidth={0}
            type="name"
            onKeyDown={(e) => e.stopPropagation()}
            defaultValue={files[0]?.folder ? files[0]?.name.filename : filename}
            placeholder="New name"
            {...register('name', {
              required: true,
            })}
            error={!!errors.name}
          />
          {errors.name && <FormHelperText error>{errors.name.message}</FormHelperText>}
        </FormControl>
      </TextModal>
    </GQLWrapper>
  );
};

export default Rename;

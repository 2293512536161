import { gql } from '@apollo/client';

export const FOLDERS = gql`
  fragment folderFragment on Folder {
    id
    name
    path
  }
`;

export const GET_PROJECT_DRIVE_FOLDERS = gql`
  ${FOLDERS}
  query getProjectDrive($id: ID!) {
    project(id: $id) {
      id
      drive {
        nodeByPath {
          id
          path
          name
          ... on Folder {
            ...folderFragment
            folders {
              ...folderFragment
              folders {
                ...folderFragment
                folders {
                  ...folderFragment
                  folders {
                    ...folderFragment
                    folders {
                      ...folderFragment
                      folders {
                        ...folderFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

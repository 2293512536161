import { ApolloError, useMutation } from '@apollo/client';
import { Folder, Mutation } from 'types/graphql';
import { useHandleError } from 'hooks';
import { useState } from 'react';
import { getApolloErrorData, parseGraphqlError } from 'utils/helpers';
import useMixpanel from '../useMixpanel';
import MOVE_NODES from './apollo';

export const useMoveNodes = (
  onCompleted: (res: Folder | undefined)=>void,
  onError?: (e: string) => void,
) => {
  const [handleError, enqueueSnackbar] = useHandleError();
  const { mixpanelTrack } = useMixpanel();
  const [errorCode, setErrorCode] = useState<string>('');

  const [moveNodes, { data, loading, error }] = useMutation(MOVE_NODES, {
    onError: (err: ApolloError) => {
      mixpanelTrack('Move nodes Failed');
      const [code] = parseGraphqlError(err.graphQLErrors);
      setErrorCode(code);
      if (onError) onError(code);

      if (code !== 'MOVE_PRIMARY_VU_FILE') handleError(err);
    },
    onCompleted: (resData: Mutation) => {
      mixpanelTrack('Move nodes Successful');
      if (onCompleted) onCompleted(resData.moveNodes);
      enqueueSnackbar('Your files have been successfully moved.', { variant: 'success' });
    },
  });

  const handleMoveNodes = async (
    nodes: number[], folderId?: string, projectId?: string | number,
  ) => {
    moveNodes({
      variables: {
        projectId,
        nodes,
        folder: { id: folderId },
      },
    });
  };

  const { primaryVuFile } = getApolloErrorData(error, {
    primaryVuFile: [],
  });

  return {
    moveNodes: handleMoveNodes,
    data,
    loading,
    error,
    errorData: { primaryVuFile },
    errorCode,
    setErrorCode,
  };
};

export default useMoveNodes;

import { DialogContent } from '@material-ui/core';
import styled from 'styled-components';

export const StyledDialogContent = styled(DialogContent)`
  :first-child {
    padding-top: 0px;
  }
`;

export const StyledLogo = styled.div<{ image: string; imageId?: string }>`
  ${({ image, imageId }) => `
    position: relative;
    width: 100%;
    height: 180px;
    background: url(${image}) no-repeat;
    background-size: ${imageId ? 'cover' : 'contain'};
  `}
`;
import { MenuItem } from '@material-ui/core';
import { DriveUploadIcon, theme } from '@vucity/design_system';
import { useMixpanel } from 'hooks';
import React from 'react';

const iconStyle = {
  width: '20px',
  height: '16px',
  marginRight: '10px',
  color: theme.palette.text.primary,
};

const Transfer: React.FC<{ onTransfer: () => void; }> = ({ onTransfer }) => {
  const { mixpanelTrack } = useMixpanel();

  return (
    <MenuItem onClick={() => {
      onTransfer();
      mixpanelTrack('Upload from personal drive');
    }}>
      <DriveUploadIcon style={iconStyle} />
      Upload from personal drive
    </MenuItem>
  );
};

export default Transfer;

import styled from 'styled-components';

export const Mention = styled('span')`
  cursor: pointer;
  color: #0075FF;
  text-decoration: underline;
`;

export const MentionsInputWrapper = styled('div')`
  & .mentions__control {
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;

    & .mentions__highlighter {
      padding: 12px 16px;
      max-height: calc(35vh);
    };

    & .mentions__input {
      border: 1px solid #5c5c5c;
      border-color: #0000003b;
      border-radius: 8px;
      padding: 12px 16px;
      max-height: calc(35vh);
      background: #FFFFFF;
      color: #333;
      outline: 0;
      overflow-y: auto !important;

      &:hover {
        border: 1px solid #375675;
      }

      &:focus {
        outline: 0;
        border-radius: 8px;
        border: 1px solid #375675;
        box-shadow: 0px 0px 5px #004e63;
      };

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #5C5C5C;
        border-radius: 4px;

        &:hover{
          background:#efefef;
        }
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
      }

      &::placeholder {
        color: #e7eaed;
        opacity: 1;
      }

      &::-webkit-scrollbar-button {    
        height: 10px;
        background: transparent;
      }
    };

    & .user {
      z-index: 1;
      color: #0075FF;
      position: relative;
      cursor: pointer;
    }
  };

  & .mentions--multiLine {
    & .mentions__control {
      min-height: 63px;
    };
  }

  & .mentions__suggestions {
    margin-top: 20px!important;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

    .mentions__list {
      background-color: white;
      border: 1px solid rgba(0;0;0;0.15);
      font-size: 14px;
      max-height: 200px;
      overflow: auto;
      position: absolute;
      bottom: 14;
    };

    & .mentions__suggestions__item {
      padding: 4px 8px;
      border-bottom: 1px solid rgba(0;0;0;0.15);
    };

    & .mentions__suggestions__item--focused {
      background-color: #E6EDEF;
    };
  };
`;

import { CloudIcon } from '@vucity/design_system';
import { useMixpanel } from 'hooks';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IconButton } from '../QuickActions.style';

const ShowInDrive = ({ fileId }: { fileId: string; }) => {
  const { projectId } = useParams<{ projectId: string; }>();
  const history = useHistory();
  const { mixpanelTrack } = useMixpanel();

  const handleClick = () => {
    mixpanelTrack('Show In Drive clicked', { fileId });
    history.push(`/project/${projectId}/drive?id=${fileId}`);
  };

  return (
    <IconButton onClick={handleClick} className='mb-2'>
      <CloudIcon htmlColor="#333" />
      Show in Drive
    </IconButton>
  );
};
export default ShowInDrive;

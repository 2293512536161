import MuiAvatar from '@material-ui/core/Avatar';
import { CloseIcon } from '@vucity/design_system';
import styled from 'styled-components';

export const Wrapper = styled.div<{ $isOpen: boolean; }>`
  position: relative;
  padding: 8px 16px;
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #A6AFB9;
  border-radius: ${({ $isOpen }) => ($isOpen ? '8px 8px 0 0' : '8px')};
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 100%;

  input {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    border: 0;
    outline: 0;
  }

  img {
    width: 32px;
    height: 32px;
  }
`;

export const List = styled.div`
  position: absolute;
  width: calc(100% + 2px);
  top: 100%;
  left: -1px;
  background-color: #fff;
  border: 1px solid #A6AFB9;
  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  z-index: 9;
`;

export const ListOptions = styled.div`
  position: relative;
  max-height: 245px;
  overflow: hidden auto;
`;

export const Option = styled.div<{ $all?: boolean; }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 16px;
  column-gap: 8px;

  &:hover {
    background: #E6EDEF;
    border-radius: inherit;
  }

  ${({ theme, $all }) => $all && `
    padding: 8px 16px;
    border-top: 1px solid ${theme.palette.placeholder.main};
  `}
`;

export const Image = styled(MuiAvatar)`
  ${({ theme }) => `
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${theme.palette.primary.lighter};
    font-size: ${theme.typography.body1.fontSize};

    & img {
      padding: 0;
      object-fit: cover;
    }
  `}
`;

export const Error = styled.p`
  font-size: 12px;
  color: red;
`;

export const Clear = styled(CloseIcon)`
  ${({ theme }) => `
    position: absolute;
    color: ${theme.palette.placeholder.main};
    width: 14px;
    height: 14px;
    top: 17px;
    right: 17px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    opacity: 1;
  `}
`;

import { DownloadIcon } from '@vucity/design_system';
import React from 'react';

import { useMixpanel } from 'hooks';

import Tooltip from 'components/Tooltip/Tooltip';
import { IconButton } from '../QuickActions.style';
import useDownloadFile from './utils';

const Download: React.FC<{
  file: { signedUrl: string | undefined; name: string; mimeType?: string; };
}> = ({ file }) => {
  const { mixpanelTrack } = useMixpanel();
  const { downloadFile } = useDownloadFile(file);

  return (
    <Tooltip
      placement='bottom-start'
      content='Download file'
      enterDelay={1000}
    >
      <IconButton
        className='mb-2'
        onClick={() => {
          downloadFile();
          mixpanelTrack('Download in Preview');
          document.querySelector<HTMLDivElement>('#previewColumn')?.focus();
        }}
      >
        <DownloadIcon htmlColor='#333' />
      </IconButton>
    </Tooltip>
  );
};

export default Download;

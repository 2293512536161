import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useState } from 'react';
import Map, { ControlPosition, MapRef, NavigationControl } from 'react-map-gl';
import CustomNavigationControl from './CustomNavigationControl/CustomNavigationControl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { mapboxCrendetials } from 'utils/configs';

interface IVuMapProps {
  center: [number, number];
  zoom: number;
  maxZoom?: number;
  scrollZoom?: boolean;
  style?: string;
  customControls?: boolean;
  controlsPosition?: ControlPosition;
  controlsClass?: string;
  controlsStyle?: React.CSSProperties;
  dragPan?: boolean;
  children: React.ReactNode;
  onMapLoad?: (map: mapboxgl.Map) => void;
}

const VuMap = React.forwardRef<MapRef, IVuMapProps>(({
  center,
  zoom,
  maxZoom = 23,
  scrollZoom,
  children,
  style = 'mapbox://styles/vucity/ckfadf5fg283r19pbezzang9o',
  customControls = false,
  controlsPosition = 'bottom-right',
  controlsClass,
  controlsStyle,
  dragPan = true,
  onMapLoad,
  ...props
}, ref) => {
  const mapElement = document.querySelector('.mapboxgl-map');
  const canvas = document.querySelector('.mapboxgl-canvas');

  const [map, setMap] = useState<mapboxgl.Map>();
  const [interactiveLayerIds, setInteractiveLayerIds] = useState<string[]>([]);
  const [zoomLevel, setZoomLevel] = useState<number>(zoom);

  const resizer = useCallback(
    () => new ResizeObserver(() => {
      if (!canvas) return null;
      if (map) {
        map.resize();
      }
    }),
    [map, canvas],
  );

  useEffect(() => {
    if (canvas && map && mapElement) {
      resizer()?.observe(mapElement!);
      map.on('zoom', (ev) => {
        setZoomLevel(ev.viewState.zoom);
      });
    }
  }, [map, canvas, resizer, mapElement]);

  const onLoaded = (mapObj: mapboxgl.Map) => {
    setMap(mapObj);
    if (onMapLoad) {
      onMapLoad(mapObj); // Notify parent that the map is loaded
    }
    setInteractiveLayerIds(['250_fill_layer', '250_fill_layer_selected']);
  };

  return (
    <Map
      ref={ref}
      onLoad={({ target }) => onLoaded(target)}
      mapboxAccessToken={mapboxCrendetials}
      dragRotate={false}
      mapStyle={style}
      style={{
        width: '100%',
        height: '100%',
      }}
      reuseMaps
      initialViewState={{
        longitude: center[0],
        latitude: center[1],
        zoom,
      }}
      scrollZoom={scrollZoom}
      maxZoom={maxZoom}
      interactiveLayerIds={interactiveLayerIds}
      attributionControl={false}
      dragPan={dragPan}
      {...props}
    >
      {customControls ? (
        <CustomNavigationControl 
          handleZoomIn={() => map?.zoomIn()} 
          handleZoomOut={() => map?.zoomOut()} 
          position={controlsPosition}
          style={{ ...controlsStyle }}
          controlsClass={controlsClass}
          disableZoomIn={zoomLevel === maxZoom}
          disableZoomOut={zoomLevel === 0}
        />
      ) : (
        <NavigationControl
          showCompass={true}
          position={controlsPosition}
          style={{ ...controlsStyle }}
        />
      )}
      {children}
    </Map>
  );
});

export default VuMap;

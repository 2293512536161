import React, { memo } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import { PreviewRoute } from 'components/Preview';

// TODO: Handle file scrolling here and push new location to navigate between files
const Preview = memo(({ setLoading }: { setLoading: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const match = useRouteMatch();

  return <Route
    path={`${match.path}/:fileId`}
    render={
      () => <PreviewRoute setLoading={setLoading} />
    }
  />;
});

export default Preview;

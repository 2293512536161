import { useHandleError, useMixpanel } from 'hooks';
import React from 'react';
import { Project } from 'types/graphql';
import KebabMenu from './KebabMenu';
import CustomMenuItem from './MenuItem';

interface Props {
  setVisibilityPreference: (preference: string) => void;
  projectView: string;
  visibilityPreference: string;
  projects: Project[];
  applyFilter: (preference: string, userProjects: Project[]) => Project[];
  setFilteredProjects: React.Dispatch<React.SetStateAction<Project[]>>;
}

const ProjectsVisibilityMenu = ({ visibilityPreference,
  setVisibilityPreference,
  projects,
  applyFilter,
  setFilteredProjects,
  projectView }: Props) => {
  const [, enqueueSnackbar] = useHandleError();
  const { mixpanelTrack } = useMixpanel();

  const handlePreferenceClick = (preference: string) => {
    mixpanelTrack('Hub Home > Kebab menu > New view', {
      'Project view': projectView,
    });
    setVisibilityPreference(preference);
    setFilteredProjects(applyFilter(preference, projects));
    enqueueSnackbar(`Your visibility has been saved to ${preference.toLowerCase()}`, {
      variant: 'success',
    });

  };

  return (
    <div>
      <KebabMenu
        className='w-5 h-5 bg-white rounded-sm flex'
        onClick={() => {
          mixpanelTrack('Hub Home > Kebab Menu - Clicked', { 'Project view': projectView });
        }}
      >
        <CustomMenuItem
          value='All Projects'
          onClick={handlePreferenceClick}
          selected={visibilityPreference === 'All Projects'}
        >
          All projects
        </CustomMenuItem>
        <CustomMenuItem
          value='Visible Projects'
          onClick={handlePreferenceClick}
          selected={visibilityPreference === 'Visible Projects'}
        >
          Visible Projects
        </CustomMenuItem>
        <CustomMenuItem
          value='Hidden Projects'
          onClick={handlePreferenceClick}
          selected={visibilityPreference === 'Hidden Projects'}
        >
          Hidden Projects
        </CustomMenuItem>
      </KebabMenu>
    </div>
  );
};

export default ProjectsVisibilityMenu;

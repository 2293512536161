import { palette, typography } from '../utils';

export const formHelperText = {
  root: {
    display: 'flex',
    alignItems: 'center',
    color: palette.gray.main,
    fontSize: typography.subtitle2.fontSize,
    marginTop: 0,

    '&$error': {
      color: palette.error.main,
    },

    '&$disabled': {
      color: palette.gray.main,
    },
  },
  contained: {
    marginLeft: '0',
  },
};

export default formHelperText;

import { gql } from '@apollo/client';

const GET_EMAIL_VERIFIED = gql`
  query currentUserEmailVerified {
    currentUser {
      id
      emailVerified
    }
  }
`;

export default GET_EMAIL_VERIFIED;

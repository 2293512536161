import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import * as Sentry from '@sentry/react';
import LoadingBar from 'components/Loading/LoadingBar';
import { TextModal } from 'components/Modal';
import { useHandleError, useMixpanel, useSignedUrl } from 'hooks';
import React, { useState } from 'react';
import { Checkbox } from 'tailwind/Input';
import { downloadDrive } from 'utils/drive';
import { REMOVE_ORG_MEMBER, RESEND_ORG_INVITE } from 'views/account/apollo';
import { GET_USER_DRIVE } from 'views/drive/apollo';
import { ACCOUNT_STATUS_INACTIVE } from 'views/organisation/members/constants';
import GET_ORG_MEMBERS from 'views/organisation/members/data';
import StyledIconButton from './KebabMenu.style';

const KebabMenu = ({ row }: DataTypeProvider.ValueFormatterProps) => {
  const client = useApolloClient();
  const { user } = useAuth0();
  const { mixpanelTrack } = useMixpanel();
  const [handleError, enqueueSnackbar] = useHandleError();
  const [downloadingInProgress, setDownloadingInProgress] = useState(false);
  const [open, setOpen] = useState(false);
  const [hasReadList, setHasReadList] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openOptions = Boolean(anchorEl);
  const { getSignedUrl } = useSignedUrl();

  const [name, email] = row.name;
  const { activeStatus, userId, status, organisation, orgId } = row;

  const [getUserDrive, { data }] = useLazyQuery(GET_USER_DRIVE, {
    variables: { userId, orgId },
    onCompleted: async () => {
      await downloadDrive({ files: data.userDrive, isSingleFile: false, setDownloadingInProgress, client, mixpanelTrack, getSignedUrl });
    },
  });

  const [removeOrgMember, { loading }] = useMutation(REMOVE_ORG_MEMBER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ORG_MEMBERS }],
    variables: { memberId: row.id },
    onCompleted: (response) => {
      const { profile, email: removedUserEmail } = response?.removeOrgMember || {};
      const removedUserName = profile?.name || removedUserEmail;
      setAnchorEl(null);
      setOpen(false);
      enqueueSnackbar(`${removedUserName} has been removed from ${organisation}`, { variant: 'success' });
    },
    onError: handleError,
  });

  const [reinviteMember, { loading: reinviteLoading }] = useMutation(RESEND_ORG_INVITE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_ORG_MEMBERS }],
    variables: { memberId: row.id },
    onCompleted: () => {
      setAnchorEl(null);
      enqueueSnackbar(`${name} has been re-invited to ${organisation}`, { variant: 'success' });
    },
    onError: handleError,
  });

  const mixpanelOptions = { OrgMemberName: name, OrgMemberEmail: email, OrgMemberId: row?.userId || '' };
  const isLoading = loading || reinviteLoading;

  const handleKebabClick = (e: React.MouseEvent<HTMLElement>) => {
    mixpanelTrack('Opened the Member options', mixpanelOptions);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    mixpanelTrack('Cancel Remove Member');
    setAnchorEl(null);
    setOpen(false);
  };

  const handleRemoveButtonClick = async (rowData: any) => {
    mixpanelTrack('Confirm Remove Member', mixpanelOptions);
    try {
      await getUserDrive({ variables: { userId: rowData.userId, orgId: rowData.orgId } });
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
      mixpanelTrack('Download user drive for remove member failed', { mixpanelOptions, error: e });
      Sentry.captureException(e?.message);
    }
    removeOrgMember();
  };

  const handleRemoveOptionClick = () => {
    mixpanelTrack('Remove Member', mixpanelOptions);
    setOpen(true);
  };

  const handleRemoveInvite = () => {
    try {
      mixpanelTrack('Remove Invite', {
        ...mixpanelOptions,
        'Removed Member User ID': userId,
      });
      removeOrgMember();
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
      mixpanelTrack('Remove org member invite failed', { mixpanelOptions, error: e });
      Sentry.captureException(e?.message);
    }
  };

  const handleResendInvite = () => {
    mixpanelTrack('Resend Invite', {
      ...mixpanelOptions,
      'Re-invited Member User ID': userId,
      'Previous Status': status,
    });

    reinviteMember();
  };

  if (email === user?.email) return <></>;

  return (
    <>
      <div className='flex justify-center'>
        <StyledIconButton
          aria-label='remove organisation member'
          aria-controls="remove-options"
          aria-haspopup="true"
          onClick={handleKebabClick}
        >
          <MoreVert />
        </StyledIconButton>

        <Menu
          id="remove-options"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          keepMounted
          open={openOptions}
          PaperProps={{ style: { width: '20ch' } }}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {activeStatus !== ACCOUNT_STATUS_INACTIVE ? (
            <MenuItem className='text-base text-error' onClick={handleRemoveOptionClick}>
              Remove member
            </MenuItem>
          ) : [
            isLoading && <LoadingBar key='loading' />,
            <MenuItem className='text-base' disabled={isLoading} key='resend' onClick={handleResendInvite}>
              Resend invite
            </MenuItem>,
            status === 'Invited'
            && <MenuItem className='text-base text-error' disabled={isLoading} key='remove' onClick={handleRemoveInvite}>
              Remove invite
            </MenuItem>,
          ]}
        </Menu>
      </div>

      <TextModal
        open={open}
        closeBtnLabel="Cancel"
        mainBtnLabel="Remove member"
        headerText='Remove member'
        closeBtnHandle={handleClose}
        setOpen={setOpen}
        loading={loading || downloadingInProgress}
        mainBtnHandle={() => handleRemoveButtonClick(row)}
        mainBtnDisabled={!hasReadList || downloadingInProgress}
        headerComponent={`Confirm removal of ${name} from your organisation?`}
        closeBtnDisabled={downloadingInProgress}
      >
        {!downloadingInProgress ?
          <div className='px-2 pb-1 text-lg text-left'>
            <p className='mb-2'>
              Removing <span className='font-medium'>{name}</span> from your organisation will result in the following:
            </p>

            <ul className='ml-3 list-disc'>
              <li>
                They will be removed from all projects
              </li>
              <li>
                If they are currently using any platform, they will be exited and unable to complete or save any work
              </li>
              <li>
                You will be invited, with restricted access, to all projects where they were the sole admin
              </li>
              <li>
                Their personal drive will be downloaded to your machine as a zip folder right now
              </li>
              <li>
                Their personal drive will be permanently deleted after 24 hours
              </li>
            </ul>

            <div className='px-2 pb-1 my-3 text-lg text-left'>
              <Checkbox name='hasReadList' title='Please confirm you have read the implications' checked={hasReadList} onChange={() => setHasReadList(!hasReadList)} labelClassName="text-lg" />
            </div>


            <p className='px-2 pb-1 text-lg text-left'>
              Are you sure you want to remove <span className='font-medium'>{name}</span> from your organisation?
            </p>
          </div>
          :
          <div>
            <CircularProgress size={100} />
            <p className='mb-2'>
              Please do not close this window while {name}'s personal drive downloads
            </p>
          </div>}
      </TextModal>
    </>
  );
};

export default KebabMenu;

import { TextModal } from 'components';
import { IFileRow } from 'components/Drive/types';
import React from 'react';
import { getExt } from 'utils/helpers';

interface DeleteFilesModalProps {
  open: boolean;
  handleMainButtonClick: () => void;
  handleNoClick: () => void;
  selectedFiles: IFileRow[];
  handleClose: () => void;
}

export default function (props: DeleteFilesModalProps) {
  const { open, handleNoClick, handleMainButtonClick, selectedFiles, handleClose } = props;

  return (
    <TextModal
      open={open}
      setOpen={handleClose}
      headerText='Would you like to remove files from your personal drive?'
      mixpanelPath='Create project from personal drive/Delete files modal opened'
      closeBtnHandle={handleNoClick}
      closeBtnLabel='No, keep the files'
      mainBtnHandle={handleMainButtonClick}
      mainBtnLabel='Yes, remove'
      component='div'
    >
      <p className='mx-auto my-2 text-lg'>
        This will result in your files being removed from the drive.
      </p>

      <section className='w-full max-h-[200px] min-w-600 overflow-auto border rounded-sm border-primary-lighter'>
        <div className='flex justify-between px-2 py-1 border-b border-b-primary-lightest'>
          <p className='text-xs text-grey'>Name</p>
          <p className='text-xs text-grey'>File Type</p>
        </div>

        {selectedFiles.map(({ name, folder }) => (
          <div
            className='flex justify-between w-full px-2 py-1 text-base border-b border-b-primary-lightest last-of-type:border-b-0'
            key={name.filename}
          >
            <p>{name.filename}</p>
            <p>
              {!folder ? getExt(name.filename) : 'folder'}
            </p>
          </div>
        ))}
      </section>
    </TextModal>
  );
}

import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { SelectButton } from 'tailwind';
import { ISingleOverlay } from 'views/coverage/types';

import { ReactComponent as M250Filled } from '../../icons/250mFilled.svg';
import { ReactComponent as M250Outlined } from '../../icons/250mOutlined.svg';

const Tiles250m = ({ selected, disabled, tiles, onClick }: ISingleOverlay) => (
  <>
    <SelectButton
      fullWidth
      selected={selected}
      onClick={() => onClick('250m')}
      startIcon={selected || disabled ? <M250Filled /> : <M250Outlined />}
      disabled={disabled}
    >
      <span>
        <span>250m<sup>2</sup></span> tiles
      </span>
    </SelectButton>

    {selected && tiles && (
      <>
        {/* Layers to handle hover and selected states */}
        <Source id='250_fill_source' promoteId='id' type='vector' tiles={[tiles[0]]}>
          <Layer
            interactive
            id='250_fill_layer'
            source='250_fill_source'
            source-layer='default'
            type='fill'
            minzoom={11.5}
            layout={{
              visibility: selected ? 'visible' : 'none',
            }}
            paint={{
              'fill-color': '#80A6B1',
              'fill-outline-color': '#375675',
              'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0.5,
                0,
              ],
            }}
          />
        </Source>

        <Source id='250_fill_source_selected' promoteId='id' type='vector' tiles={[tiles[0]]}>
          <Layer
            interactive
            id='250_fill_layer_selected'
            source='250_fill_source_selected'
            source-layer='default'
            type='fill'
            minzoom={11.5}
            layout={{
              visibility: selected ? 'visible' : 'none',
            }}
            paint={{
              'fill-color': '#375675',
              'fill-outline-color': '#375675',
              'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                0.5,
                0,
              ],
            }}
          />
        </Source>

        {/* Base layers to display outline and labels */}
        <Source id='250m_tiles' type='vector' tiles={[tiles[0]]}>
          <Layer
            id='250m_tiles'
            source-layer='default'
            type='line'
            minzoom={11.5}
            layout={{
              visibility: selected ? 'visible' : 'none',
            }}
            paint={{
              'line-color': '#80A6B1',
              'line-width': 1.2,
              'line-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                8.5, 0.1, 13, 0.65,
              ],
            }}
          />
        </Source>

        <Source id='250m_tiles_centroid' type='vector' tiles={[tiles[1]]}>
          <Layer
            id='250m_tiles_centroid'
            source-layer='default'
            type='symbol'
            minzoom={14}
            layout={{
              'text-allow-overlap': true,
              'text-field': ['get', 'id'],
              'text-size': 12,
              'text-letter-spacing': 0.02,
              visibility: selected ? 'visible' : 'none',
            }}
            paint={{
              'text-color': '#004e63',
              'text-halo-color': '#e6edef',
              'text-halo-width': 10,
            }}
          />
        </Source>
      </>
    )}
  </>
);

export default Tiles250m;

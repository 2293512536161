import { useCreateFolder, useMixpanel } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button } from 'tailwind';
import FolderTree from '../FolderTree';
import AddFolder from '../Upload/AddFolder/AddFolder';
import TransferLoading from './TransferLoading/TransferLoading';

interface ITransferProps {
  projectId?: string;
  files: string[] | { id: string; }[];
  closeContextMenu: () => void;
  treeData: any;
  initialQueryLoading: boolean;
  useCopyOrMoveNodes: any;
  refetchQuery: { query: any, variables?: { id?: string; }; };
  rootFolder: string;
  setIsUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  isMoving?: boolean;
}

const TransferContextMenu: React.FC<ITransferProps> = ({
  treeData,
  projectId,
  files,
  initialQueryLoading,
  closeContextMenu,
  setIsUpdated,
  useCopyOrMoveNodes,
  refetchQuery,
  rootFolder,
  isMoving,
}) => {
  const { mixpanelTrack } = useMixpanel();
  const [newFolder, setNewFolder] = useState<string>('');
  const [selectedFolder, setSelectedFolder] = useState<any>({
    selected: [],
    expanded: [],
  });

  const [selected, setSelected] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => setExpanded([treeData?.id]), []);

  const handleToggle = (e: any, nodeIds: string[]) => {
    if (e.target.textContent !== rootFolder) { setExpanded(nodeIds); }
  };
  const handleSelect = (e: React.ChangeEvent<{}>, nodeIds: string[]) => setSelected(nodeIds);

  const updateFolder = (e: React.ChangeEvent<HTMLInputElement>) => setNewFolder(e.target.value);

  const { createFolder, folderLoading, folderError } = useCreateFolder(
    [refetchQuery],
    () => {
      setNewFolder('');
      setSelected(selectedFolder.selected);
      setExpanded(selectedFolder.expanded);
    },
  );

  const { copyNodes, moveNodes, loading, errorCode, setErrorCode, errorData } = useCopyOrMoveNodes(
    (res: any) => {
      if (setIsUpdated) setIsUpdated(true);
      if (res.id) {
        setErrorCode('');
        closeContextMenu();
      }
    },
  );

  const onCloseModal = () => {
    setErrorCode('');
  };

  const onCreateFolder = () => {
    setSelectedFolder({ selected, expanded: [...expanded, selected] });
    createFolder(selected, newFolder, projectId);
  };

  const onTransferFiles = (data: string[] | { id: string; }[]) => {
    mixpanelTrack(`${isMoving ? 'Move' : 'Upload'} here`);
    if (isMoving) return moveNodes(data, selected, projectId);
    return copyNodes(data, selected, projectId);
  };

  return (
    <>
      <div className='pt-0 px-2 min-h-[20vh] max-h-[20vh] overflow-auto'>
        <FolderTree
          treeData={[treeData]}
          rootFolder={rootFolder}
          disabledFolder={isMoving && files}
          folderControls={{ selected, expanded, handleSelect, handleToggle }}
          exclude
        />
      </div>
      <div className='flex items-center justify-between gap-1 px-2 mt-3'>
        <AddFolder
          newFolder={newFolder}
          setNewFolder={updateFolder}
          onCreateFolder={onCreateFolder}
          isUpdated={!folderLoading && !initialQueryLoading}
          loading={folderLoading}
          disabled={selected.length < 1}
        />

        <Button
          variant="contained"
          size='xs'
          disabled={selected.length === 0 || loading}
          onClick={() => onTransferFiles(files)}
        >
          {isMoving ? 'Move here' : 'Upload here'}
        </Button>
      </div>

      <TransferLoading
        projectId={projectId}
        state={{ loading, error: errorCode || folderError }}
        errorData={errorData}
        onCancel={closeContextMenu}
        onCloseModal={onCloseModal}
        isMoving={isMoving}
      />
    </>
  );
};

export default TransferContextMenu;

import Button from '@material-ui/core/Button';
import { LoadingIcon } from '@vucity/design_system';
import { useMixpanel } from 'hooks';
import React from 'react';

interface IDriveProps {
  onRefreshHandle: () => void;
}

// TODO: Update this button
const RefreshDrive: React.FC<IDriveProps> = ({
  onRefreshHandle,
}) => {
  const { mixpanelTrack } = useMixpanel();

  return (
    <Button id='refresh-drive' onClick={() => {
      onRefreshHandle();
      mixpanelTrack('Refresh Drive');
    }}
      className='iconOnly'
      color='primary'
      aria-label="Refresh Drive"
    >
      <LoadingIcon />
    </Button>);
};

export default RefreshDrive;

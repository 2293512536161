import React from 'react';
import styled from 'styled-components';
import MuiLink from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Link as RouterLink } from 'react-router-dom';

import { theme } from 'theme/theme';

export { default as Dropdown } from './Dropdown';

export const Link = styled(RouterLink)`
  cursor: pointer;
  color: ${theme.palette.primary.main};
  svg {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

interface ButtonLinkProps {
  readonly disabled?: boolean;
}

export const ButtonLink = styled(MuiLink)<ButtonLinkProps>`
  cursor: pointer;
  color: ${theme.palette.primary.main};
  svg {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  ${({ disabled }) => (disabled ? `
    cursor: default;
    color: ${theme.palette.gray.main};
    text-decoration: none !important;
  ` : '')}
`;

ButtonLink.defaultProps = {
  tabIndex: 0,
};

export const UrlLink = styled(MuiLink)`
  cursor: pointer;
  color: #0075ff;
  text-decoration: underline;
  word-break: break-word;
`;

export const CircularProgressWithLabel = (props:any) => (
  <Box position="relative" display="inline-flex">
    <CircularProgress variant="determinate" style={{ color: theme.palette.primary.main }} {...props} />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="body2"
        component="div"
      >
        {`${Math.round(props.value)}%`}
      </Typography>
    </Box>
  </Box>
);

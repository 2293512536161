import MuiBox from '@material-ui/core/Box';
import MuiPaper from '@material-ui/core/Paper';
import styled from 'styled-components';

export const SearchPlace = styled(MuiBox)`
  min-width: 166px;
  padding-left: 8px;
  margin-right: 32px;

  #searchPlace {
    padding: 8px 12px;
  }
`;

export const Popper = styled.div`
  position: absolute;
  top: 39px;
  z-index: 9999;
  width: 100%;
`;

export const Paper = styled(MuiPaper)`
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #004e63;

  &::before {
    content: 'Recent searches';
    display: block;
    font-size: 14px;
    line-height: 1.4;
    margin: 8px 16px 0;
    padding-bottom: 5px;
    color: #5c5c5c;
  }
`;

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import { auth0Credentials } from '../../utils/configs';
import ApolloProvider from './ApolloProvider';

function AuthProvider({ children }: { children: JSX.Element }) {
  const { domain, clientId } = auth0Credentials;
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: import.meta.env.VITE_GRAPHQL_URI,
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <ApolloProvider>{children}</ApolloProvider>
    </Auth0Provider>
  );
}

export default AuthProvider;

import React from 'react';
import { SelectButton } from 'tailwind';

interface FormatProps {
  data: { format: string[]; };
  onFormatClick: (arg: string) => void;
}

const FileFormatModal = ({ data, onFormatClick }: FormatProps) => {
  const { format } = data;

  const formats = ['DWG Solids', 'Sketchup', 'Revit', 'STL', 'FBX', '3DS', 'MAX', '3DM', 'DXF', 'Other'];

  return (
    <>
      <p className='text-base'>Select the format you like to receive your files</p>

      <section className='flex flex-wrap justify-center gap-2 py-2 max-w-500'>
        {formats.map((item) => (
          <SelectButton key={item} className='rounded-sm' selected={format.includes(item)} onClick={() => onFormatClick(item)}>
            {item}
          </SelectButton>
        ))}
      </section>

      {format.length > 2 && (
        <p className='pb-2 m-auto text-sm text-center max-w-350'>
          *Please note additional charges will apply when selecting more than two file formats
        </p>
      )}
    </>
  );
};

export default FileFormatModal;

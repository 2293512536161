import { gql } from '@apollo/client';

export const SEND_ORDER = gql`
  mutation sendCoverageMapOrder($order: JSON, $data: JSON, $comments: String) {
    createTilesOrder(order: $order, comments: $comments, data: $data)
  }
`;

export const GET_USER_DETAILS_FOR_ORDER = gql`
  query {
    currentUser {
      id
      name
      email
      jobTitle
      organisation {
        id
        name
        type
      }
    }
  }
`;